<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Receipt Form</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple title="Save" type="submit" (click)="onSave()" icon="pi pi-save"
                                [disabled]="_IsSaveDisable"></button>
                            <!-- All entered input fields should be cleared. -->
                            <button pButton pRipple title="Clear" icon="pi pi-trash" class="p-button-danger"
                                [disabled]="_IsClearDisable"></button>
                            <!-- on clicking back button , it Navigates to the search screen -->
                            <button pButton pRipple title="Send Email" icon="las la-envelope" type="button"
                                (click)="SendMail($event)"></button>
                            <button pButton pRiple icon="pi pi-search" title="Back to Search"
                                class="p-button p-button-success p-mr-2" (click)="goBack($event)"></button>
                            <!-- <button pButton pRiple icon="las la-times" title="Cancel"
                                class="p-button p-button-danger" (click)="Cancel($event)"></button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_receiptform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <div class="p-formgroup-inline">
                                    <h6>Receipt Mode <span class="hlt-txt">*</span></h6>
                                    <div class="p-field-radiobutton" *ngFor="let RM of _ReceiptModes">
                                        <p-radioButton label={{RM.metasubdescription}} value={{RM.metasubcode}}
                                            formControlName="receiptmode" (onClick)="OnChangeReceiptMode()">
                                        </p-radioButton>
                                    </div>
                                </div>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _receiptform.get('receiptmode').touched) && _receiptform.get('receiptmode').errors?.SelectReceiptMode">
                                    Please Select Receipt Mode
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" name="voucherno" formControlName="voucherno" disabled pInputText>
                                    <label for="voucherno">Voucher No </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="receiptdate" [monthNavigator]="true" [disabled]="_isdatedisabled"
                                        [yearNavigator]="true" yearRange="1980:2030" dateFormat="dd/mm/yy"
                                        readonlyInput="true" [maxDate]="_maxDate"></p-calendar>
                                    <label for="receiptdate"> Receipt Date <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _receiptform.get('receiptdate').touched) && _receiptform.get('receiptdate').errors?.SelectReceiptDate">
                                    Please Select Receipt Date
                                </span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_partyTypeList"
                                        optionLabel="metasubdescription" optionValue="metasubid"
                                        formControlName="partytype" (onChange)="GetCustomerByPartyType($event)">
                                    </p-dropdown>
                                    <label for="partytype">Party Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _receiptform.get('partytype').touched) && _receiptform.get('partytype').errors?.SelectFromAccount">
                                    Please Select Party Type
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_fromaccounts" optionLabel="name"
                                        optionValue="ledgerid" formControlName="fromaccount" [filter]="true"
                                        filterBy="name" [showClear]="true" (onChange)="getfromcustomerclosingbalance()"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-dropdown> -->
                                    <p-dropdown [options]="_fromaccounts" formControlName="fromaccount" optionLabel="name" [autoDisplayFirst]="false"
                                        [showClear]="true" optionValue="ledgerid" [virtualScroll]="true" itemSize="30" 
                                        [filter]="true" filterBy="name" [showClear]="true" (onChange)="getfromcustomerclosingbalance()">
                                        <ng-template let-account pTemplate="item">
                                            <div [style]="account.docstatus == 'MSC00002' ? 'background-color: #f1f734;' : '' || account.docstatus == 'MSC00344' ? 'background-color: #cff9fc;' : ''">{{account.name}}</div>
                                        </ng-template>
                                    </p-dropdown>

                                    <label for="fromaccount">From Account <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _receiptform.get('fromaccount').touched) && _receiptform.get('fromaccount').errors?.SelectFromAccount">
                                    Please Select From Account
                                </span>
                            </td>
                            <td>
                                <form [formGroup]="_receiptbalanceform">
                                    <span class="p-float-label">
                                        <input type="number" name="fromaccountclosingbalance"
                                            formControlName="fromaccountclosingbalance" disabled pInputText>
                                        <label for="fromaccountclosingbalance"> Closing Balance (in Usd)</label>
                                    </span>
                                </form>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr [hidden]="_action == 'view'">
                            <td>
                                <form [formGroup]="_receiptotherform">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_receiptsFor"
                                            optionLabel="metasubdescription" optionValue="metasubcode"
                                            (onChange)="getfromcustomerclosingbalance()" formControlName="paymentfor"
                                            [filter]="true" filterBy="name" [showClear]="true">
                                        </p-dropdown>
                                        <label for="paymentfor">Receipt For <span class="hlt-txt">*</span></label>
                                    </span>
                                </form>
                            </td>

                            <td>
                                <form [formGroup]="_receiptotherform">
                                    <span class="p-float-label">
                                        <input type="text" name="invoiceno" formControlName="documentno" pInputText
                                            (keyup.enter)="getfromcustomerclosingbalance()">
                                        <label for="documentno">Document No </label>
                                    </span>
                                </form>
                            </td>

                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_toaccounts" optionLabel="name"
                                        optionValue="ledgerid" formControlName="toaccount" [filter]="true"
                                        filterBy="name" [showClear]="true" (onChange)="gettoclosingbalance($event)">
                                    </p-dropdown>
                                    <!-- <p-dropdown [options]="_toaccounts" formControlName="toaccount" optionLabel="name"
                                        [showClear]="true" optionValue="ledgerid">
                                        <ng-template let-account pTemplate="item">
                                            <div style="background-color: aqua;">{{account.name}}</div>
                                        </ng-template>
                                    </p-dropdown> -->


                                    <label for="toaccount">To Account <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _receiptform.get('toaccount').touched) && _receiptform.get('toaccount').errors?.SelectToAccount">
                                    Please Select To Account
                                </span>
                            </td>
                            <td>
                                <form [formGroup]="_receiptbalanceform">
                                    <span class="p-float-label">
                                        <input type="number" name="toaccountclosingbalance"
                                            formControlName="toaccountclosingbalance" disabled pInputText>
                                        <label for="toaccountclosingbalance">Closing Balance (in Usd)</label>
                                    </span>
                                </form>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <!-- <input type="number" name="receiptamount" formControlName="receiptamount" pInputText
                                        (keyup)="allocateReceiptAmount($event)"> -->
                                        <p-inputNumber name="receiptamount" maxlength="16" formControlName="receiptamount" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" (onFocus)="allocateReceiptAmount($event)" (onBlur)="allocateReceiptAmount($event)"></p-inputNumber>

                                    <label for="receiptamount"> Receipt Amount <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _receiptform.get('receiptamount').touched) && _receiptform.get('receiptamount').errors?.ReceiptAmountRequired">
                                    Please Enter Receipt Amount
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" name="referenceno" formControlName="referenceno" pInputText>
                                    <label for="referenceno">Transaction No </label>
                                </span>
                            </td>
                            <td>
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <div class="browse-links">
                                            <p-button label="Document" styleClass="p-button-link"
                                                title="{{imageselect}}" (click)="showdocumentDialog(document)">
                                            </p-button>
                                        </div>
                                    </span>
                                    <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                        pTooltip="Browse File" tooltipPosition="top" mode="basic" #document
                                        [disabled]="isDisabledButton" [auto]="true"
                                        accept="image/*,.pdf,.xlsx,.xls,.xlsm,.docx,.txt"
                                        (onSelect)="onDocumentSelect($event,document)">
                                    </p-fileUpload>

                                    <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                        (click)="onDocumentClear(document)" [disabled]="isDisabledButton"
                                        tooltipPosition="top">
                                        <i class="pi pi-times"></i>
                                    </button>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currencies"
                                        (onChange)="ChangeCurrencies($event)" optionLabel="currencyname"
                                        optionValue="currencyid" formControlName="currentcurrencyid">
                                    </p-dropdown>
                                    <label for="currentcurrencyid"> Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _receiptform.get('currentcurrencyid').touched) && _receiptform.get('currentcurrencyid').errors?.SelectCurrencyName">
                                    Please Select Currency Name
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="exchangerateinusd" formControlName="exchangerateinusd"
                                        disabled pInputText>
                                    <label for="exchangerateinusd">Exchange Rate </label>
                                </span>
                            </td>
                            <td rowspan="4">
                                <span class="p-float-label">
                                    <textarea formControlName="narration" pInputTextarea></textarea>
                                    <label for="narration">Narration</label>
                                </span>
                            </td>
                            <td>
                                <form [formGroup]="_receiptbalanceform">
                                    <span class="p-float-label">
                                        <input type="number" name="totalreceiptamount"
                                            formControlName="totalreceiptamount" pInputText disabled>
                                        <label for="totalreceiptamount">Total Receipt Amount </label>
                                    </span>
                                </form>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="number" name="advanceamount" formControlName="advanceamount" pInputText
                                        disabled>
                                    <label for="advanceamount">Advance Amount </label>
                                </span>
                            </td>
                    </table>
                    <p-dialog header="!Warning This Supplier or Customer Under Hold" [(visible)]="supplierholdreason" [modal]="true"
                            [style]="{width: '20vw',height: '215px'}" [baseZIndex]="10000">
                            <ng-template pTemplate="content">
                                <div class="popup-body">
                                    <div class="card">
                                        <textarea name="reasonforsupplierhold" [disabled]="popupdisable"
                                        [(ngModel)]="reasonforsupplierhold" [ngModelOptions]="{standalone: true}" pInputTextarea
                                            style="width: 100%; height: 100px; padding: 5px;">
                                        </textarea>
                                    </div>
                                    <div style="text-align: right;" *ngIf="supplierholdpopupsubmit">
                                        <button pButton pRipple label="Close" (click)="Supplierholdreasonpopupclose()" title="Close"
                                            icon="pi pi-times" class="p-button-danger p-mr-2">
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dialog>
                </form>

                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Bill Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_BillDetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['billdate','vouchertypedesc','billno','billamount','paidamount','pendingamount','branchname']"
                        [rowHover]="true" (onRowSelect)="RowSelect($event)" (onRowUnselect)="RowUnSelect($event)"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedBillDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 50px;" *ngIf="_action != 'view'">
                                    <p-tableHeaderCheckbox (click)="selectall()"></p-tableHeaderCheckbox>
                                </th>
                                <!-- <th style="text-align: center;width: 100px;">Action</th> -->
                                <th>Voucher No</th>
                                <th>Voucher Type</th>
                                <th pSortableColumn="branchname">Branch<p-sortIcon field="branchname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="billdate">Bill Date<p-sortIcon field="billdate"></p-sortIcon>
                                </th>
                                <th>Bill No</th>
                                <th pSortableColumn="creditamount">Credit Amount<p-sortIcon field="creditamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="debitamount">Debit Amount<p-sortIcon field="debitamount">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="paidamount">Receipt Amount<p-sortIcon field="paidamount">
                                    </p-sortIcon>
                                </th>
                                <th *ngIf="_action != 'view'">Balance</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_BillDetail>
                            <tr>
                                <td style="text-align: center;width: 50px;" *ngIf="_action != 'view'">
                                    <p-tableCheckbox [value]="_BillDetail"></p-tableCheckbox>
                                </td>
                                <td>
                                    {{_BillDetail.voucherno}}
                                </td>
                                <td>
                                    {{_BillDetail.vouchertypedesc}}
                                </td>
                                <td>
                                    {{_BillDetail.branchname}}
                                </td>
                                <td>
                                    {{_BillDetail.billdate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_BillDetail.billno}}
                                </td>
                                <td>
                                    {{_BillDetail.creditamount}}
                                </td>
                                <td>
                                    {{_BillDetail.debitamount}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_BillDetail.paidamount" pInputText
                                                *ngIf="_action != 'view' && _BillDetail.vouchertypeid != 7 && _BillDetail.isselected"
                                                (keyup)="OnChangePaidAmount($event.target.value,_BillDetail)">
                                            <div
                                                *ngIf="_action == 'view' || _BillDetail.vouchertypeid == 7 || !_BillDetail.isselected">
                                                {{_BillDetail.paidamount}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_BillDetail.paidamount}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td *ngIf="_action != 'view'">
                                    {{_BillDetail.pendingamount}}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table class="normal-table">
                    <tr>

                        <td>
                            <!-- Debug -->
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            <p-dialog #warning [style]="{width: '400px'}" header="Confirm" [(visible)]="receiptmodel.showdialog"
                [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
                <ng-template pTemplate="content">
                    <label [innerHTML]="receiptmodel.message"></label>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button type="button" pButton icon="las la-check" label="Yes" autofocus
                        (click)="receiptmodel.Yes()"></button>
                    <button type="button" pButton icon="pi pi-times" label="No" autofocus
                        (click)="receiptmodel.Cancel()"></button>
                </ng-template>
            </p-dialog>
        </div>
    </div>
</div>