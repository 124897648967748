import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from 'src/app/core/Models/Customer';
import { Customeraccountdetail } from 'src/app/core/Models/Customeraccountdetail';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from 'ace-builds';
import { CustomerService } from 'src/app/core/Services/masters/customer.service';
import { Accountgroup } from 'src/app/core/Models/Accountgroup';
import { Country } from 'src/app/core/Models/Country';
import { Currency } from 'src/app/core/Models/Currency';
import { District } from 'src/app/core/Models/District';
import { Level } from 'src/app/core/Models/Level';
import { State } from 'src/app/core/Models/State';
import { Customeraddressdetail } from 'src/app/core/Models/Customeraddressdetail';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import {
  vMobileNumber,
  vAddress,
  vCountryNameSelect,
  vStateNameSelect,
  vDistrictNameSelect,
  vMarkuplevelSelect,
  vCurrencySelect,
  vContactPersonName,
  vEmailID,
} from 'src/app/core/Validators/validation';
import { Bank } from 'src/app/core/Models/Bankmaster';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { KeyPress } from 'src/assets/js/KeyPress';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Documentdescription } from 'src/app/core/Models/DocumentDescription';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { Customerdocumentdetail } from 'src/app/core/Models/Customerdocumentdetail';
import { Category } from 'src/app/core/Models/Category';
import { Subcategory } from 'src/app/core/Models/Subcategory';
import { Customerbranchheader } from 'src/app/core/Models/customerbranchheader';
import { Customerbranchdetail } from 'src/app/core/Models/Customerbranch';

@Component({
  selector: 'app-c-customer',
  templateUrl: './c-customer.component.html',
  styleUrls: ['./c-customer.component.css'],
})
export class CCustomerComponent implements OnInit {
  _limitcurrency: string;
  _customerform: FormGroup;
  _customeraddressdetailform: FormGroup;
  _customeraccountdetailform: FormGroup;
  _DocumentDescriptions: Documentdescription[];
  _Customerdocumentdetails: Customerdocumentdetail[] = [];
  _customerdocumentdetailform: FormGroup;
  position: string;
  position1: string;
  documentselect: string = '';
  displaydocumentgridpopup: boolean = false;
  _submitted2 = false;
  _LastAddedDocument: File;
  _selectedDocuments: File[] = [];
  documentimage: SafeUrl;
  _LastAddedGridDocument: File;
  _action: string = 'create';
  _submitted = false;
  _CustomerOBJ: any;
  _addresssubmitted = false;
  displayBasic: boolean;
  _Customer: Customer;
  _Customeraddressdetail: Customeraddressdetail;
  _Customeraccountdetail: Customeraccountdetail;
  addressRowIndex: number = -1;
  _CustomersAddress: Customeraddressdetail[] = [];
  _CustomerAddress: Customeraddressdetail;
  _Customerdocumentdtl: Customerdocumentdetail;
  _Customerdocumentdetaill: Customerdocumentdetail;
  customerdocumentdetails: FormGroup;
  _Accountgroups: Accountgroup;
  _Currencies: Currency[] = [];
  _TempCurrencies: Currency[] = []; //8.Currency should load from Currecy Master(Currency Code, Currency Name)
  _Markuplevels: Level[] = []; //7.MarkUp level should load from Level Master(Level No, Level Name)
  _Customerstatus: Metadatum[] = [];
  _Customerseries: Metadatum[] = [];
  _Countries: Country[] = []; //11. Country should load from Country Master (Country Name, Country Code)
  _States: State[] = [];
  _Cities: District[] = [];
  _Accounttypes: Metadatum[] = [];
  _Addresstype: Metadatum[] = [];
  _PayModes: Metadatum[] = [];
  _VatDetails: Metadatum[] = [];
  _SpecificVatType: Metadatum[] = [];
  _ShowVat: Metadatum[] = [];
  _SpecificVatTax: Metadatum[] = [];
  _CreditsApplicable: Metadatum[] = [];
  selectedaddress: any;
  _customerid: number;
  isDisabledaddbuttn = false;
  selectedrows: Customeraddressdetail[] = [];
  _IsProgressSpinner: boolean = true;
  isDisabledSave = false;
  isDisabledClear = false;
  _customerdocumentdetail: Customerdocumentdetail;
  isDisabledButton = false;
  isDisabledreset = false;
  Banklist: Bank;
  EBank: Bank;
  _SaveHide: boolean = false;
  _removedocoumenthidden: boolean = false;
  _ClearHide: boolean = false;
  _bankcode: Bank;
  _bankname: Bank;
  selectedtab: number = 0;
  isDisabledlimit = false;
  isDisabledcreditdays = false;
  _Series: Boolean;
  _Action: IAction;
  showseries: boolean = true;
  disableseries: boolean = false;
  _tempattachmentURL: any;
  _IsSuperAdmin: boolean = false;
  _IsVatTypeDisabled: boolean = true;
  isOptionalFieldRequired: boolean = false;
  _userlist: any[] = [];
  _tempuserlist: any[] = [];
  _category: Category[] = [];
  _subcategory: Subcategory[] = [];
  _tempsubcategory: Subcategory[] = [];
  customerholdreason: boolean = false;
  customerholdpopupclose: boolean = false;
  customerholdpopupsubmit: boolean = false;
  _customerbranchlist: Customerbranchheader[] = [];
  _Iscustomerbranchenable: boolean = false;
  _customerbranch: Customerbranchdetail[] = [];
  // customerbranchid: any[] = [];

  @ViewChild('editor', { static: true })
  private editor: ElementRef<HTMLElement>;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _DomSanitizationService: DomSanitizer,
    public keypress: KeyPress,
    private _CustomerService: CustomerService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService
  ) {
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._customerform = this.fb.group({
      companyid: [0],
      branchid: [sessionStorage['BranchID']],
      customerid: [0],
      customercode: [],
      customername: [''],
      paymode: [],
      accountgroupcode: [11],
      levelid: ['', vMarkuplevelSelect],
      currencyid: ['', vCurrencySelect],
      bpno: [],
      vatno: [], //vVatNumber
      vattype: [],
      vatpertcentage: [],
      isshowvat: [],
      specificvattype: [],
      specificvattax: [],
      creditapplicable: [],
      creditdays: [],
      creditlimit: [],
      rewardpoints: [],
      rewardpointsvalue: [],
      series: [],
      customerstatus: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      customeraddressdetails: [],
      customeraccountdetails: [],
      customerwisediscounts: [],
      savetype: ['MSC00187'],
      salesorders: [],
      salesquotations: [],
      salesreturns: [],
      tempSoA354g6er4g6d4vs: [],
      tempSq4654r6g4r6e4g8s: [],
      tempSrD2g4r5eg545e4rg45s: [],
      tempcreditdays: [],
      temprewardpoints: [],
      tempcreditlimit: [],
      approvalstatus: [],
      password: [],
      dob: [],
      gender: [],
      lastname: [],
      employeeid: [],
      userid: [],
      customerdocumentdetails: [],
      otp: [],
      couponcode: [],
      currentusagelimitperuser: [],
      attachmenturl: [],
      attachmentname: [],
      isonlinecustomer: [false],
      ecdefaultmailid: [],
      ecdefaultmobileno: [],
      hobbies: [],
      responsibleperson: [],
      categoryid: [],
      subcategoryid: [],
      countryid: [],
      reasonforcustomerhold: [],
      isconsignmentcustomer: []
    });
    this._customeraddressdetailform = this.fb.group({
      customeraddressdetailid: [0],
      customerid: [0],
      contactpersonname: ['', vContactPersonName],
      mobileno: ['', vMobileNumber],
      phoneno: [],
      address: ['', vAddress],
      mailid: ['', vEmailID],
      countryid: ['', vCountryNameSelect],
      stateid: ['', vStateNameSelect],
      districtid: ['', vDistrictNameSelect],
      faxno: [],
      addresstype: ['', vCountryNameSelect],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      isdefaultaddress: [],
      customer: [],
      pincode: [0],
      lastname: [''],
      suburbanid: [0],
    });
    this._customeraccountdetailform = this.fb.group({
      customeraccountdetailid: [0],
      customerid: [0],
      bankcode: [],
      bankname: [],
      swiftcode: [],
      branchname: [],
      accountholdername: [],
      accountno: [],
      accounttype: [],
      accountingyear: [],
      accountstatus: ['MSC00001'],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      customer: [],
    });
    this._customerform.controls['rewardpoints'].setValue('0');
    if (this._Currencies && this._Currencies.length)
      this._customerform.controls['currencyid'].setValue(
        this._Currencies[0].currencyid
      );
    if (this._Markuplevels && this._Markuplevels.length)
      this._customerform.controls['levelid'].setValue(
        this._Markuplevels[0].levelid
      );
    this._customerform.controls['customerstatus'].setValue('MSC00001');
    this._customerform.controls['series'].setValue('MSC00093');
    if (this._PayModes && this._PayModes.length)
      this._customerform.controls['paymode'].setValue(
        this._PayModes[0].metasubcode
      );
  }
  InitializeDocumentForm() {
    this._customerdocumentdetailform = this.fb.group({
      customerdocumentdetailid: [0],
      customerid: [0],
      documentid: [0],
      documentname: [],
      documentdescription: [],
      attachmenturl: [],
      attachmentname: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(21);
    this.utility.pageLoadScript();
    this._customerid = history.state?.customerid
      ? history.state?.customerid
      : 0;
    this._action =
      history.state.action == null ? 'create' : history.state.action;
    this.InitializeForm();
    this.InitializeDocumentForm();
    this.checkseriesRights();
    // this.Change(event);
    this._IsProgressSpinner = true;
    this._CustomerService.PageOnLoad(this._customerid).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log('bh', resultJSON);
        this._Accountgroups = resultJSON.accountGroups;
        this._DocumentDescriptions = resultJSON.documentdescriptions;
        this._Currencies = resultJSON.currencies;
        this._TempCurrencies = resultJSON.currencies;
        this._Markuplevels = resultJSON.markupLevels;
        this._Customerstatus = resultJSON.customerStatus;
        this._Customerseries = resultJSON.series;
        this._Countries = resultJSON.countries;
        this._States = resultJSON.states;
        this._Cities = resultJSON.cities;
        this._Accounttypes = resultJSON.accounttypes;
        this._Addresstype = resultJSON.addresstype;
        this._PayModes = resultJSON.paymodes;
        this._VatDetails = resultJSON.vatDetails;
        this._SpecificVatType = resultJSON.specifyVatTypes;
        this._SpecificVatTax = resultJSON.specifyTaxes;
        this._CreditsApplicable = resultJSON.creditdetails;
        this.Banklist = resultJSON.banks;
        this._userlist = resultJSON.users;
        this._tempuserlist = resultJSON.users;
        this._category = resultJSON.categories;
        // this._subcategory = resultJSON.subcategory;
        this._tempsubcategory = resultJSON.subcategories;
        this._customerbranchlist = resultJSON.customerbranchlist;
        this._customerform.controls['currencyid'].setValue(
          this._Currencies[0].currencyid
        );
        this._customerform.controls['levelid'].setValue(
          this._Markuplevels[0].levelid
        );
        this._customerform.controls['customerstatus'].setValue('MSC00001');
        this._customerform.controls['series'].setValue('MSC00093');
        this._customerform.controls['paymode'].setValue(
          this._PayModes[0].metasubcode
        );
        this._customerform.controls['vattype'].setValue('MSC00063');
        this._customerform.controls['isshowvat'].setValue(true);
        this._customerform.controls['specificvattax'].setValue('MSC00103');
        this._customerform.controls['creditapplicable'].setValue('MSC00011');
        this.getVattype();
        this.OnChangeShowVat();
        this.onClickCreditDetail();

        this.changeRights();

        if (this._action == 'edit' || this._action == 'view') {
          this.disableseries = true;
          this.isOptionalFieldRequired = false;
          const updateJSON = JSON.parse(JSON.stringify(result));
          this._States = updateJSON.states;
          this._Cities = updateJSON.cities;
          this._customerform.setValue(updateJSON.customer);
          this.changeRights();
          this.oncategorychange();

          this._CustomersAddress = updateJSON.customeraddressdetails || [];
          this.selectedrows = updateJSON.customeraddressdetails || [];
          this._Customerdocumentdetails =
            updateJSON.customerdocumentdetails || [];
          this.selectedrows = updateJSON.customerdocumentdetails || [];
          
		var consigncustomer = this._customerform.get("isconsignmentcustomer").value;
		if(consigncustomer == true)
		{
			this._Iscustomerbranchenable = true;
		}
          this._customerform.get('creditdays').clearValidators();
          if (updateJSON.customeraccountdetail) {
            this._customeraccountdetailform.setValue(
              updateJSON.customeraccountdetail
            );
          }
          this.isDisabledClear = true;
          let roleid = sessionStorage['currentRole'];
          var customerstatus = this._customerform.get('customerstatus').value;
          if (roleid == 1 && this._action == 'edit') {
            this._IsSuperAdmin = false;
          } else {
            this._IsSuperAdmin = true;
          }
          if (customerstatus == 'MSC00344' && this._action == 'edit') {
            this.customerholdreason = true;
            this.customerholdpopupsubmit = true;
          }

          if (customerstatus == 'MSC00344' && this._action == 'view') {
            this.customerholdreason = true;
            this.customerholdpopupsubmit = false;
            this._customerform.get('reasonforcustomerhold').disable();
          }
        }
        if (this._action == 'view') {
          this._customerform.disable();
          this._removedocoumenthidden = true;
          this._customeraddressdetailform.disable();
          this._customeraccountdetailform.disable();
          this._customerdocumentdetailform.disable();
          this.isDisabledSave = true;
          this.isDisabledButton = true;
          this.isDisabledaddbuttn = true;
          this.isDisabledreset = true;
        }
        this._IsProgressSpinner = false;
        this._customerform.get('creditdays').updateValueAndValidity();
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  checkseriesRights() {
    if (this._Action._Series) {
      this.showseries = false;
    }
  }
  getcurrency(event) {}
  onclicklimit(event) {
    this._limitcurrency = this._Currencies[0].currencyname;
  }
  showcustomergrid(document: FileUpload, position1: string) {
    let url = '';
    if (this._LastAddedGridDocument) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(
        URL.createObjectURL(this._LastAddedGridDocument)
      );
      this.documentselect = this._LastAddedGridDocument.name;
      url = URL.createObjectURL(this._LastAddedGridDocument);
    }
    if (this.documentimage == null) {
      return;
    }
    if (url) window.open(url, '_blank');
  }
  onRowSelect(event) {}
  onDocumentCustomerSelect(event, document) {
    this._LastAddedGridDocument = event.currentFiles[0];
    this.documentselect = this._LastAddedGridDocument.name;
    this._customerdocumentdetailform
      .get('attachmentname')
      .setValue(this._LastAddedGridDocument.name);
    document.clear();
  }
  onDocumentCustomerClear() {
    this._submitted2 = true;
    this._customerdocumentdetailform.reset();
    this.InitializeDocumentForm();
    this._LastAddedGridDocument = null;
    this.documentimage = null;
    this._submitted2 = false;
  }
  onAddDocuments(event) {
    this._submitted2 = true;
    if (
      this._customerdocumentdetailform.get('documentname').value == null ||
      this._customerdocumentdetailform.get('documentname').value == ''
    ) {
      this._CustomExceptionService.handleWarning('Please Select Document Name');
      return;
    }
    if (
      this._customerdocumentdetailform.valid &&
      this._LastAddedGridDocument != null
    ) {
      var documentdetail = new Customerdocumentdetail();
      documentdetail.customerdocumentdetailid =
        this._customerdocumentdetailform.get('customerdocumentdetailid').value;
      documentdetail.customerid =
        this._customerdocumentdetailform.get('customerid').value;
      documentdetail.documentid =
        this._customerdocumentdetailform.get('documentid').value;
      documentdetail.documentname =
        this._customerdocumentdetailform.get('documentname').value;
      documentdetail.documentdescription = this._customerdocumentdetailform.get(
        'documentdescription'
      ).value;
      documentdetail.attachmenturl = URL.createObjectURL(
        this._LastAddedGridDocument
      );
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(
        URL.createObjectURL(this._LastAddedGridDocument)
      );
      documentdetail.attachmentname = this._LastAddedGridDocument.name;
      documentdetail.createdby = sessionStorage['userid'];
      documentdetail.modifiedby = sessionStorage['userid'];
      documentdetail.modifiedon = new Date();
      documentdetail.createdon = new Date();
      this._Customerdocumentdetails.push(documentdetail);
      this._selectedDocuments.push(this._LastAddedGridDocument);
      this.CustomerClear();
    }
  }

  getStates(event) {
    var selectedCountry = event.value;
    this._IsProgressSpinner = true;
    this._States = [];
    this._Cities = [];
    this._CustomerService.getStates(selectedCountry).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._States = resultJSON.states;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  getDistrict(event) {
    var selectedState = event.value;
    this._IsProgressSpinner = true;
    this._Cities = [];
    this._CustomerService.getDistrict(selectedState).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Cities = resultJSON.cities;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  onRowCustomerselect(address: Customeraddressdetail, rowIndex: number) {
    this._customeraddressdetailform.setValue(address);
    this.addressRowIndex = rowIndex;
  }
  onRowCustomerdocselect(address: Customerdocumentdetail, rowIndex: number) {
    this._customerdocumentdetailform.setValue(address);
    this.addressRowIndex = rowIndex;
  }
  onRowUnselect(event) {}
  getVattype() {
    var vattype = this._customerform.get('vattype').value;
    if (vattype == 'MSC00064') {
      // specific
      this._customerform.get('specificvattype').enable();
      this._customerform.get('isshowvat').enable();
      this._customerform.get('specificvattax').enable();
      this._customerform.controls['isshowvat'].setValue(true);
      this._customerform.controls['specificvattax'].setValue('MSC00103');
    } else {
      this._customerform.get('specificvattype').disable();
      this._customerform.get('isshowvat').disable();
      this._customerform.get('specificvattax').disable();
      this._customerform.get('specificvattype').reset();
      this._customerform.get('isshowvat').reset();
      this._customerform.get('specificvattax').reset();
    }
  }
  OnChangeShowVat() {
    let isshowvat = this._customerform.get('isshowvat').value;
    if (isshowvat) this._customerform.get('specificvattax').enable();
    else {
      this._customerform.get('specificvattax').disable();
      this._customerform.get('specificvattax').reset();
    }
  }
  CustomerClear() {
    this._submitted2 = true;
    this._customerdocumentdetailform.reset();
    this.InitializeDocumentForm();
    this._LastAddedGridDocument = null;
    this.documentimage = null;
    this._submitted2 = false;
  }
  onClickCreditDetail() {
    var creditType = this._customerform.get('creditapplicable').value;

    if (creditType == 'MSC00011') {
      // Yes
      this._customerform.get('creditlimit').enable();
      this._customerform.get('creditdays').enable();
      this._customerform.get('creditdays').setValidators([Validators.required]);
      this.isOptionalFieldRequired = true;
    } else {
      this._customerform.get('creditlimit').disable();
      this._customerform.get('creditdays').disable();
      this._customerform.get('creditlimit').reset();
      this._customerform.get('creditdays').reset();
      this._customerform.get('creditdays').clearValidators();
      this.isOptionalFieldRequired = false;
    }
    this._customerform.get('creditlimit').updateValueAndValidity();
    this._customerform.get('creditdays').updateValueAndValidity();
  }

  onSave() {
    if (this.customerholdpopupclose == true) {
      this._submitted = false;
      this._submitted2 = false;
      this.customerholdpopupclose = false;
      return;
    }
    this._submitted = true;
    this._addresssubmitted = false;
    var series = this._customerform.get('series').value;
    var vattype = this._customerform.get('vattype').value;
    var customerstatus = this._customerform.get('customerstatus').value;
    var customerholdreason = this._customerform.get(
      'reasonforcustomerhold'
    ).value;
    var isconsignmentcustomer = this._customerform.get(
      'isconsignmentcustomer'
    ).value;
    if (this._customerform.valid) {
      if (
        this._CustomersAddress.length > 0 ||
        this._customerform.get('savetype').value == 'MSC00230'
      ) {
        //msc00230 -employee
        if (this._customeraccountdetailform.valid) {
          this._customeraddressdetailform
            .get('customerid')
            .setValue(this._customerform.get('customerid').value);
          this._customeraccountdetailform
            .get('customerid')
            .setValue(this._customerform.get('customerid').value);
          this._customerdocumentdetailform
            .get('customerid')
            .setValue(this._customerform.get('customerid').value);
          this._Customer = this._customerform.value;
          if (this._Customer.customername.trim() === '') {
            this._CustomExceptionService.handleWarning(
              'Customer Name Cannot be empty'
            );
            return;
          }
          this._Customer.tempcreditlimit = this._Customer.creditlimit;
          this._Customer.tempcreditdays = this._Customer.creditdays;
          this._Customeraccountdetail = this._customeraccountdetailform.value;
          if (
            this._CustomersAddress &&
            !this._CustomersAddress.length &&
            this._customerform.get('savetype').value != 'MSC00230'
          ) {
            this._CustomExceptionService.handleError(
              usererrors.Customeraddress01
            );
            this.selectedtab = 1;
            return;
          } else if (
            this._CustomersAddress &&
            this._CustomersAddress.length &&
            !this._CustomersAddress.some((s) => s.isdefaultaddress == true)
          ) {
            this._CustomExceptionService.handleError(
              usererrors.Customeraddress02
            );
            this.selectedtab = 1;
            return;
          }
          if (
            this._Customer.creditapplicable == 'MSC00011' &&
            !this._Customer.creditlimit &&
            !this._Customer.creditdays
          ) {
            this._CustomExceptionService.handleError(
              usererrors.CreditLimitAndDays
            );
            return;
          }
          if (series == 'MSC00093') {
            if (vattype == 'MSC00064' || vattype == '') {
              this._CustomExceptionService.handleError('please select vattype');
              return;
            }
          } else if (series == 'MSC00094') {
            if (vattype == '') {
              this._CustomExceptionService.handleError('please select vattype');
              return;
            }
          }
          if (
            customerstatus == 'MSC00344' &&
            (!customerholdreason || customerholdreason == null)
          ) {
            this._CustomExceptionService.handleWarning(
              'Please Enter Reason for Holding the Customer'
            );
            return;
          }
          this._Customer.accountingyear = new Date().getFullYear();
          this._Customeraccountdetail.accountingyear = new Date().getFullYear();
          if (this._action == 'create') {
            this._Customer.createdon = new Date();
            this._Customeraccountdetail.createdon = new Date();
            this._Customer.createdby = sessionStorage['userid'];
            this._Customeraccountdetail.createdby = sessionStorage['userid'];
          }
          if (
            this._Customeraccountdetail.bankcode == null &&
            this._Customeraccountdetail.swiftcode == null &&
            this._Customeraccountdetail.accountno == null
          ) {
            this._Customeraccountdetail = null;
          }
          if (this._action == 'create') {
            this._CustomerOBJ = this.formconverstion(
              this._Customer,
              this._Customeraccountdetail,
              this._CustomersAddress,
              this._Customerdocumentdetails,
              this._selectedDocuments
            );
            this._IsProgressSpinner = true;
            this._CustomerService.create(this._CustomerOBJ).subscribe(
              (result) => {
                this._IsProgressSpinner = false;
                const resutJSON = JSON.parse(JSON.stringify(result));
                if (
                  resutJSON.tranStatus.result == true &&
                  (resutJSON != null || resutJSON != undefined)
                ) {
                  if (resutJSON.tranStatus.lstErrorItem) {
                    this._CustomExceptionService.handleSuccess(
                      resutJSON.tranStatus.lstErrorItem[0].message
                    );
                    this._submitted = false;
                  } else {
                    this._CustomExceptionService.handleSuccess(
                      usererrors.Update_Success_04
                    );
                    this._submitted = false;
                  }

                  this.reset(null);
                  this._States = null;
                  this._Cities = null;
                } else {
                  this._CustomExceptionService.handleError(
                    resutJSON.tranStatus.lstErrorItem[0].message
                  );
                }
              },
              (error) => {
                this._CustomExceptionService.handleError(error);
                this._IsProgressSpinner = false;
              }
            );
          } else if (this._action == 'edit') {
            if (this._Customeraccountdetail != null) {
              this._Customeraccountdetail.modifiedon = new Date();
              this._Customeraccountdetail.modifiedby = sessionStorage['userid'];
              if (!this._Customeraccountdetail.createdby) {
                this._Customeraccountdetail.createdby =
                  sessionStorage['userid'];
                this._Customeraccountdetail.createdon = new Date();
              }
              this._Customeraccountdetail.modifiedon = new Date();
              this._Customeraccountdetail.modifiedby = sessionStorage['userid'];
            }
            this._Customer.modifiedon = new Date();
            this._Customer.modifiedby = sessionStorage['userid'];
            this._IsProgressSpinner = true;
            this._CustomerOBJ = this.formconverstion(
              this._Customer,
              this._Customeraccountdetail,
              this._CustomersAddress,
              this._Customerdocumentdetails,
              this._selectedDocuments,
            );
            this._CustomerService.edit(this._CustomerOBJ).subscribe(
              (result) => {
                this._IsProgressSpinner = false;
                const resutJSON = JSON.parse(JSON.stringify(result));
                if (
                  resutJSON.tranStatus.result == true &&
                  (resutJSON != null || resutJSON != undefined)
                ) {
                  if (resutJSON.tranStatus.lstErrorItem) {
                    this._CustomExceptionService.handleSuccess(
                      resutJSON.tranStatus.lstErrorItem[0].message
                    );
                    this._submitted = false;
                  } else {
                    this._CustomExceptionService.handleSuccess(
                      usererrors.Update_Success_04
                    );
                    this._submitted = false;
                  }
                } else {
                  this._CustomExceptionService.handleError(
                    resutJSON.tranStatus.lstErrorItem[0].message
                  );
                }
              },
              (error) => {
                this._CustomExceptionService.handleError(error);
                this._IsProgressSpinner = false;
              }
            );
          }
        } else {
          this._CustomExceptionService.handleError(
            usererrors.Customeraddress04
          );
          this.selectedtab = 2;
          return;
        }
      } else {
        this._CustomExceptionService.handleError(usererrors.Customeraddress03);
        this.selectedtab = 1;
        return;
      }
    } else {
      this._CustomExceptionService.handleError(usererrors.Customeraddress05);
      this.selectedtab = 0;
      return;
    }
  }
  RemoveDocumentDetail(p_documentDetail: Customerdocumentdetail) {
    var index = this._Customerdocumentdetails.indexOf(p_documentDetail);
    this._Customerdocumentdetails.splice(index, 1);
  }

  formconverstion(
    p_Customer: Customer,
    p_Customeraccountdetail: Customeraccountdetail,
    p_Customeraddressdetail: Customeraddressdetail[],
    p_Customerdocumentdetails: Customerdocumentdetail[],
    documents: File[]
  ) {
    let time = new Date();
    const formData: FormData = new FormData();
    for (var x in documents) {
      var index = p_Customerdocumentdetails.findIndex(
        (obj) => obj.attachmentname == documents[x].name
      );
      if (index != -1) {
        var NewFileName = time.getTime() + '_' + documents[x].name;
        p_Customerdocumentdetails[index].attachmentname = NewFileName;
        p_Customerdocumentdetails[index].attachmenturl = NewFileName;
        if (documents[x] instanceof Blob)
          formData.append('files', documents[x], NewFileName);
        else formData.append('files', NewFileName);
      }
    }
    var EmployeeOBJ = {
      Customer: p_Customer,
      Customeraccountdetail: p_Customeraccountdetail,
      Customeraddressdetail: p_Customeraddressdetail,
      Customerdocumentdetail: p_Customerdocumentdetails,
    };
    var Employee = JSON.stringify(EmployeeOBJ);
    formData.append('strcustomer', Employee);
    return formData;
  }
  onRowDoubleClick(evt, _CustomerAddress) {
    alert('You clicked row ' + _CustomerAddress + '!');
  }
  Mobilevalidate() {
    var mobileno = this._customeraddressdetailform.get('mobileno').value;
    if (mobileno.length < 10) {
      this._CustomExceptionService.handleError(
        'Please Enter Atleast 10 Digits'
      );
      this._customeraddressdetailform.get('mobileno').reset();
      return;
    }
  }
  onAddAddress(event) {
    this._addresssubmitted = true;
    if (this._customeraddressdetailform.valid) {
      if (this.addressRowIndex > -1) {
        this._CustomersAddress[this.addressRowIndex] =
          this._customeraddressdetailform.value;
        this._CustomersAddress[this.addressRowIndex].createdon = new Date();
      } else {
        var addressdetail: any = {};
        addressdetail.customeraddressdetailid = 0;
        addressdetail.customerid = 0;
        addressdetail.contactpersonname =
          this._customeraddressdetailform.get('contactpersonname').value;
        addressdetail.mobileno =
          this._customeraddressdetailform.get('mobileno').value;
        addressdetail.phoneno =
          this._customeraddressdetailform.get('phoneno').value || 0;
        addressdetail.address =
          this._customeraddressdetailform.get('address').value;
        addressdetail.mailid =
          this._customeraddressdetailform.get('mailid').value;
        addressdetail.countryid =
          this._customeraddressdetailform.get('countryid').value;
        addressdetail.stateid =
          this._customeraddressdetailform.get('stateid').value;
        addressdetail.districtid =
          this._customeraddressdetailform.get('districtid').value;
        addressdetail.faxno =
          this._customeraddressdetailform.get('faxno').value;
        addressdetail.isdefaultaddress =
          this._customeraddressdetailform.get('isdefaultaddress').value;
        addressdetail.createdby = sessionStorage['userid'];
        addressdetail.modifiedby = 0;
        addressdetail.modifiedon = null;
        addressdetail.createdon = new Date();
        addressdetail.isdefaultaddress = this._CustomersAddress.length == 1;
        addressdetail.customer = null;
        addressdetail.addresstype =
          this._customeraddressdetailform.get('addresstype').value;
        addressdetail.pincode = 0;
        addressdetail.lastname = '';
        addressdetail.suburbanid = 0;
        this._CustomersAddress.push(addressdetail);
      }
      this.resetaddress(null);
    }
  }
  Removeselectedchecked(
    _CustomerAddress: Customeraddressdetail,
    rowIndex: number
  ) {
    if (this._CustomersAddress && this._CustomersAddress.length) {
      for (let i = 0; i < this._CustomersAddress.length; i++) {
        if (i === rowIndex) continue;
        this._CustomersAddress[i].isdefaultaddress = false;
      }
    }
    this._CustomersAddress[rowIndex].isdefaultaddress =
      _CustomerAddress.isdefaultaddress;
  }
  showdocumentGridDialog(position: string, _Customerdocumentdetail: any) {
    if (_Customerdocumentdetail.attachmenturl) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(
        _Customerdocumentdetail.attachmenturl
      );
      this._tempattachmentURL = _Customerdocumentdetail.attachmenturl;
    }
    this.position = position;
    if (this._tempattachmentURL) window.open(this._tempattachmentURL, '_blank');
  }
  showdocumentasNewWindow() {
    if (this._tempattachmentURL) {
      window.open(this._tempattachmentURL, 'MsgWindow', 'width=800,height=800');
    }
  }
  closePopupGridDialog(position: string) {
    this.position1 = position;
    this.displaydocumentgridpopup = false;
  }
  reset(event) {
    this._submitted = true;
    this._addresssubmitted = false;
    this._customerform.reset();
    this._customeraddressdetailform.reset();
    this._customeraccountdetailform.reset();
    this.InitializeForm();
    this._submitted = false;
    this._CustomersAddress = [];
    // this.customerbranchid = [];
    this._Iscustomerbranchenable = false;
    setTimeout(() => {
      this.getVattype();
      this.OnChangeShowVat();
      this.onClickCreditDetail();
    }, 1000);
  }
  resetaddress(event) {
    this._customeraddressdetailform.reset();
    this._addresssubmitted = false;
    this.addressRowIndex = -1;
  }
  goBack(event) {
    this._router.navigate(['/vCustomer']);
  }
  onEd() {
    ace.config.set('fontSize', '14px');
    ace.config.set(
      'basePath',
      'https://unpkg.com/ace-builds@1.4.12/src-noconflict'
    );
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode('ace/mode/json');
    var myObj = this._CustomerOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
  showBasicDialog() {
    this.displayBasic = true;
  }
  onRowSelectPopup(event: any) {
    var row = event.data;
    this._customeraccountdetailform.controls['bankcode'].setValue(row.bankcode);
    this._customeraccountdetailform.controls['bankname'].setValue(row.bankname);
    this.displayBasic = false;
  }
  RemoveAddressDetail(p_addressDetail: Customeraddressdetail) {
    var index = this._CustomersAddress.indexOf(p_addressDetail);
    this._CustomersAddress.splice(index, 1);
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+s',
          (event: KeyboardEvent): boolean => {
            this.onSave();
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    if (this._action == 'create') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+alt+c',
          (event: KeyboardEvent): boolean => {
            this.reset(null);
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    this._hotkeysService.add(
      new Hotkey(
        'ctrl+left',
        (event: KeyboardEvent): boolean => {
          this.goBack(null);
          return false; // Prevent bubbling
        },
        ['INPUT', 'SELECT', 'TEXTAREA']
      )
    );
  }
  ChangeSwift() {
    if (
      !this._customerform
        .get('customername')
        .value.toString()
        .match(new RegExp('^[a-zA-Z0-9 ]*$')) &&
      this._action != 'edit'
    ) {
      this._CustomExceptionService.handleWarning(
        'special characters not allowed on customername'
      );
      this._customerform.controls['customername'].reset();
      return;
    }
  }

  // For rights based currency loading:
  changeRights() {
    var series = this._customerform.get('series').value;
    this._IsVatTypeDisabled = series == 'MSC00093';
    if (series == 'MSC00094') {
      //full rights
      this._Currencies = this._TempCurrencies.filter((f) => f.currencyid != 4);
      this._userlist = this._tempuserlist.filter((f) => f.series == 'MSC00094');
    } else {
      this._Currencies = this._TempCurrencies;
      this._userlist = this._tempuserlist.filter((f) => f.series == 'MSC00093');
    }
  }

  shouldHideVatType(vattype: any): boolean {
    const series = this._customerform.get('series').value;
    return series === 'MSC00093' && vattype.metasubcode === 'MSC00064';
  }

  oncategorychange() {
    var categoryid = this._customerform.get('categoryid').value;
    if (categoryid) {
      this._subcategory = this._tempsubcategory.filter(
        (f) => f.categoryid == categoryid
      );
    }
  }

  Customerholdreason(event) {
    var supplierstatus = event.value;
    // var supplierholdreason = this._supplierform.get("reasonforsupplierhold").value;
    if (supplierstatus == 'MSC00344') {
      this.customerholdreason = true;
      this.customerholdpopupsubmit = true;
    } else {
      this.customerholdreason = false;
    }
  }

  Customerholdreasonpopupclose() {
    var remark = this._customerform.get('reasonforcustomerhold').value;
    if (!remark || remark == 'null' || remark == undefined) {
      this._CustomExceptionService.handleWarning(
        'Please Enter the Reason for Customer Hold'
      );
      this.customerholdpopupclose = true;
      return;
    }
    this.customerholdreason = false;
    this.customerholdpopupclose = true;
    return;
  }

  CustomerbranchEnable() {
    var isconsign = this._customerform.get('isconsignmentcustomer').value;
    if (isconsign == true) {
      this._Iscustomerbranchenable = true;
    } else {
      this._Iscustomerbranchenable = false;
    }
  }
}
