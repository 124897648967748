<div class="page-container">
  <div class="inner-page-container">
    <div class="page-loader" *ngIf="_IsProgressSpinner">
      <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>
    <div class="white">
      <div class="page-title">
        <div class="row">
          <div class="col-md-5">
            <h3>Consignment Delivery Note</h3>
          </div>
          <div class="col-md-7 text-right">
            <div class="action-btn">
              <button
                pButton
                pRipple
                label=""
                title="Save"
                icon="pi pi-save"
                class=""
                (click)="onSave()"
              ></button>
              <button
                pButton
                pRipple
                label=""
                title="Clear"
                icon="pi pi-trash"
                class="p-button-danger"
                (click)="Reset()"
              ></button>
              <button
                pButton
                pRipple
                label=""
                icon="pi pi-search"
                title="Back to Search"
                class="p-button p-button-success p-mr-2"
                (click)="goBack($event)"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-container scroll-y">
        <p-toast position="bottom-right"></p-toast>
        <form [formGroup]="_deliverynoteform">
          <table class="normal-table">
            <tr>
              <td>
                <span class="p-float-label">
                  <input pInputText 
                  name="consignmentdeliverynoteno"
                  formControlName="consignmentdeliverynoteno" 
                  disabled />
                  <label for="consignmentdeliverynoteno">Delivery Note No</label>
                </span>
              </td>
              <td style="width: 20%">
                <span class="p-float-label">
                  <p-calendar
                    name="quoteDate"
                    [showIcon]="true"
                    dateFormat="dd/mm/yy"
                    formControlName="consignmentdeliverynotedate"
                    [minDate]="mindate"
                    [maxDate]="maxdate"
                    [disabled]="true"
                  ></p-calendar>
                  <label for="quoteDate"
                    >Delivery Note Date <span class="hlt-txt">*</span></label
                  >
                </span>
              </td>
              <td style="width: 20%">
                <span class="p-float-label">
                  <p-dropdown
                    [options]="_Customerlist"
                    optionValue="customerid"
                    formControlName="customerid"
                    [autoDisplayFirst]="false"
                    [filter]="true"
                    [virtualScroll]="true"
                    itemSize="30"
                    filterBy="customername"
                    optionLabel="customername"
                    (onChange)="OnselectCustomer()"
                  >
                  </p-dropdown>
                  <label>Customer Name <span class="hlt-txt">*</span></label>
                </span>
              </td>
              <td style="width: 20%">
                <span class="p-float-label">
                  <p-dropdown
                    [options]="_Consignmentbranch"
                    optionValue="cutomerbranchid"
                    formControlName="customerbranchid"
                    [autoDisplayFirst]="false"
                    [filter]="true"
                    [virtualScroll]="true"
                    itemSize="10"
                    filterBy="customerbranchname"
                    optionLabel="customerbranchname"
                    (onChange)="OnselectCustomerBranch()"
                  >
                  </p-dropdown>
                  <label>Customer Branch <span class="hlt-txt">*</span></label>
                </span>
              </td>
              <td style="width: 20%">
                <span class="p-float-label">
                  <p-dropdown
                    [options]="_Consignmentbranchaddresslist"
                    optionValue="customerbranchaddressdetailid"
                    [autoDisplayFirst]="false"
                    [filter]="true"
                    formControlName="customeraddressid"
                    [(ngModel)]="_Addressid"
                    [virtualScroll]="true"
                    itemSize="10"
                    filterBy="addressdetail"
                    optionLabel="addressdetail"
                    (onChange)="OnselectAddress()"
                  >
                  </p-dropdown>
                  <label>Customer Address <span class="hlt-txt">*</span></label>
                </span>
              </td>
            </tr>
            <tr>
              <td style="width: 20%">
                <span class="p-float-label">
                  <input
                    pInputText
                    name="contactperson"
                    [(ngModel)]="_Contactperson"
                    [ngModelOptions]="{ standalone: true }"
                    disabled
                  />
                  <label for="contactperson">Contact Person</label>
                </span>
              </td>
              <td style="width: 20%">
                <span class="p-float-label">
                  <input
                    pInputText
                    name="mobileno"
                    [(ngModel)]="_Mobileno"
                    [ngModelOptions]="{ standalone: true }"
                    disabled
                  />
                  <label for="mobileno">Mobile No</label>
                </span>
              </td>
              <td style="width: 20%">
                <span class="p-float-label">
                  <input
                    pInputText
                    name="mailid"
                    [(ngModel)]="_Mailid"
                    [ngModelOptions]="{ standalone: true }"
                    disabled
                  />
                  <label for="mailid">Mail Id</label>
                </span>
              </td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </form>
        <form [formGroup]="_deliverynotedetailform">
          <table class="normal-table">
            <tr>
              <td colspan="5">
                <div class="sub-title">
                  <h5>Product Details</h5>
                </div>
              </td>
            </tr>
            <tr>

              <td style="width: 20%">
                <span class="p-float-label">
                  <input
                    type="text"
                    name="productferno"
                    disabled
                    [(ngModel)]="_Producttypename"
                    [ngModelOptions]="{ standalone: true }"
                    pInputText
                  />
                  <label for="producttypeid">Product Type </label>
                </span>
              </td>
              <td style="width: 20%">
                <span class="p-float-label">
                  <input
                    type="text"
                    name="productferno"
                    disabled
                    [(ngModel)]="_Productcategoryname"
                    [ngModelOptions]="{ standalone: true }"
                    pInputText
                  />
                  <label for="productcategoryid">Product Category </label>
                </span>
              </td>
              <td>
                <span class="p-float-label">
                  <p-autoComplete
                    formControlName="productid"
                    [suggestions]="filteredProducts"
                    (completeMethod)="filterProducts($event)"
                    field="productname"
                    (onSelect)="getProductVariants($event)"
                  >
                    <ng-template let-product pTemplate="item">
                      <div>{{ product.productname }}</div>
                    </ng-template>
                  </p-autoComplete>
                  <label for="productid"
                    >Product Name <span class="hlt-txt">*</span></label
                  >
                </span>
                <!-- <span class="text-danger"
                    *ngIf="(_submitted1 || _deliverynotedetailform.get('productid').touched) && _deliverynotedetailform.get('productid').errors?.SelectProductName">
                    Please Select Product Name
                </span> -->
              </td>
            </tr>
            <tr>
              <td>
                <span class="p-float-label">
                  <input
                    type="text"
                    name="stockinhand"
                    disabled
                    maxlength="7"
                    formControlName="stockinhand"
                    pInputText
                  />
                  <label for="stockinhand"
                    >Stock In Hand <span class="hlt-txt">*</span></label
                  >
                </span>
              </td>

              <td>
                <span class="p-float-label">
                  <p-inputNumber
                    *ngIf="hidequantity == true"
                    formControlName="quantity"
                    autocomplete="off"
                    maxlength="10"
                    mode="decimal"
                    [minFractionDigits]="0"
                    [maxFractionDigits]="0"
                  >
                  </p-inputNumber>
                  <label *ngIf="hidequantity == true" for="requestqty"
                    >Quantity
                  </label>
                </span>

                <span class="p-float-label">
                  <p-inputNumber
                    *ngIf="hidequantity != true"
                    formControlName="quantity"
                    autocomplete="off"
                    maxlength="10"
                    mode="decimal"
                    [minFractionDigits]="3"
                    [maxFractionDigits]="3"
                  >
                  </p-inputNumber>
                  <label *ngIf="hidequantity != true" for="requestqty">
                    Quantity <span class="hlt-txt">*</span>
                  </label>
                </span>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <!-- <button
                  pButton
                  pRipple
                  label="Add"
                  icon="pi pi-plus"
                  class="p-button-success"
                ></button> -->
                <button
                  pButton
                  pRipple
                  label="Add"
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-success"
                  (click)="AddProduct($event)"
                ></button>
                <button
                  pButton
                  pRipple
                  label="Clear"
                  icon="pi pi-trash"
                  class="p-button-danger"
                  (click)="Clear()"
                ></button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </form>
        <div class="card">
          <p-table
            [value]="_Productdetails"
            [rows]="50"
            [paginator]="true"
            [rowsPerPageOptions]="[1000, 1500, 2000, 2500, 3000]"
            [globalFilterFields]="['productName', 'requestqty', 'uomname']"
            [rowHover]="true"
            dataKey="productName"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true">
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align: center; width: 100px">Action</th>
                <th style="width: 100px">S.No</th>
                <th>Product Type</th>
                <th>Product Category</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>UOM</th>
                <th>Available</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-_productdetail let-i="rowIndex">
              <tr>
                <td style="text-align: center">
                  <button
                    pButton
                    pRipple
                    title="Remove"
                    icon="pi pi-trash"
                    class="p-button-danger p-mr-2"
                    [disabled]="_action=='view'"
                    (click)="RemoveProduct(_productdetail)"
                  ></button>
                </td>
                <td>{{i + 1}}</td>
                <td>{{ _productdetail.producttype }}</td>
                <td>{{ _productdetail.productcategory }}</td>
                <td>{{ _productdetail.productname }}</td>
                <td>{{ _productdetail.quantity }}</td>
                <td>{{ _productdetail.uomname }}</td>
                <td>{{ _productdetail.available }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <p-confirmDialog [style]="{ width: '750px' }"></p-confirmDialog>
      </div>
      <p-dialog #warning [style]="{width: '500px'}" header="Confirm" [(visible)]="Consignmodel.showdialog" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
        <ng-template pTemplate="content">
            <label [innerHTML]="Consignmodel.message"></label>
        </ng-template>
        <ng-template pTemplate="footer">
            <button type="button" pButton icon="las la-check" label="Approve" autofocus (click)="Yes()"></button>
            <button type="button" pButton icon="pi pi-times" label="No" autofocus (click)="Cancel()"></button>
        </ng-template>
    </p-dialog>
    </div>
  </div>
</div>
