import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { IAction } from '../../security/IActions';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ConsignmentcustomerbranchService {

  public _Action: IAction;
  public _ConsignmentcustomerService = environment.ConsignmentCustomerBranchService;
  public _httpOptions;
  token: any;
  header: any;
  constructor(private http: HttpClient, private _CustomExceptionService: CustomExceptionService) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 2)
  }
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(p_branchid: number): Observable<any> {
    // const headers = this.createHeaders(this.token, 2)
    var Query = this._ConsignmentcustomerService + '/' + p_branchid;
    return this.http.get<{ data: any }>(Query);
  }

  FetchAll(obj: object): Observable<any> {
    // const headers = this.header;
    var Query = this._ConsignmentcustomerService + '/FetchAll';
    return this.http.post<{ data: any }>(Query, obj);
  }

  Create(p_branch: FormData) {
    // const headers = this.createHeaders(this.token, 2)
    var Query = this._ConsignmentcustomerService + '/Createbranch';
    return this.http.post<{ data: any }>(Query, p_branch);
  }

  edit(p_customer: FormData) {
    // const headers = this.createHeaders(this.token, 2)
    var Query = this._ConsignmentcustomerService + '/Edit';
    return this.http.post<{ data: any }>(Query, p_customer);
  }

  Cancel(branchid:number, modifiedby:number)
  {
    const headers = this.header;
    var Query = this._ConsignmentcustomerService + '/Cancel/' + branchid + '/' + modifiedby ;
    return this.http.get<{ data: any }>(Query, { headers });
  }
}