<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Purchase Invoice </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" type="button" [disabled]="isDisabledsave" (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Clear" icon="pi pi-trash" (click)="reset($event)" class="p-button-danger"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" [disabled]="isDisabledsendapparoval" (click)="SendToApproval($event)"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_purchaseinvoiceform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="purchaseinvoicedate"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2030"
                                        dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate" [disabled]="isDisablededit">
                                    </p-calendar>
                                    <label for="purchaseinvoicedate">Purchase Invoice Date<span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseinvoiceform.get('purchaseinvoicedate').touched || _submitted) && _purchaseinvoiceform.get('purchaseinvoicedate').errors?.SelectDate">
                                    Please Select Purchase Invoice Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Invoicetype"
                                        (onChange)="InvoiceTypeChange($event)" optionLabel="metasubdescription"
                                        optionValue="metasubcode" formControlName="invoicetype">
                                    </p-dropdown>
                                    <label for="grnno">Invoice Type<span class="hlt-txt">*</span> </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="invoicebarcode" autocomplete="off" [(ngModel)]="PIbarcode"
                                    [ngModelOptions]="{standalone: true}" (keyup.enter)="FetchBarcode()" pInputText>
                                    <label for="invoicebarcode">Barcode</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_Suppliers" 
                                        (onChange)="GetGRNNos($event)" [filter]="true" filterBy="suppliername"
                                        optionValue="supplierid" optionLabel="suppliername" itemSize="15" 
                                        formControlName="supplierid">
                                        <ng-template let-account pTemplate="item">
                                            <div [style]="account.supplierstatus == 'MSC00344' ? 'background-color: #cff9fc;' : ''">{{account.suppliername}}</div>
                                        </ng-template>
                                    </p-dropdown>
                                    <label for="grnno">Supplier<span class="hlt-txt">*</span> </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_grnlist"
                                        [disabled]="isDisablededit" (onChange)="OnGRNChange($event)" [filter]="true"
                                        filterBy="grnno" optionValue="grnno" optionLabel="grnno" itemSize="15"
                                        formControlName="grnno">
                                    </p-dropdown>
                                    <label for="grnno">GRN No<span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseinvoiceform.get('grnno').touched || _submitted) && _purchaseinvoiceform.get('grnno').errors?.SelectInvoiceNumber">
                                    Please Select GRN No.
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_Temppurchaseinvoiceform">
                                    <span class="p-float-label">
                                        <input type="text" disabled pInputText formControlName="branchname">
                                        <label for="branchname">GRN Branch</label>
                                    </span>
                                </form>
                            </td>                   
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_referenceno" [disabled]="_action == 'view'"
                                        optionLabel="paymentrefno" optionValue="refNo" [ngModelOptions]="{standalone: true}"
                                        (onChange)="onSelectreferenceno()" [(ngModel)]="_selectedreference"
                                        name="_selectrefid"></p-multiSelect>
                                    <label for="_selectrefid">Reference No. </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_debitaccountdetails"
                                        formControlName="debitaccount" optionLabel="name" optionValue="ledgerid">
                                    </p-dropdown>
                                    <label for="currency">Debit Account<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_purchaseinvoiceform.get('debitaccount').touched || _submitted) && _purchaseinvoiceform.get('debitaccount').errors?.DebitNoteRequired">
                                    Please Select Debit Account.
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currencydetails"
                                        [disabled]="isDisabledControll" formControlName="currencyid"
                                        optionLabel="currencyname" optionValue="currencyid"></p-dropdown>
                                    <label for="currency">Currency</label>
                                </span>
                            </td>
                            <td style="width: 20%;" rowspan="3">
                                <span class="p-float-label">
                                    <textarea rows="2" cols="30" pInputTextarea formControlName="address"
                                        disabled></textarea>
                                    <label for="address">Supplier Address </label>
                                </span>
                            </td>
                            <td style="width: 20%;" rowspan="3">
                                <span class="p-float-label">
                                    <textarea rows="2" cols="30" pInputTextarea [(ngModel)]="productingrn"
                                        [ngModelOptions]="{standalone: true}" name="productingrn" disabled></textarea>
                                    <label for="address">GRN </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="invoiceno">
                                    <label for="invoiceno">supplier Invoice No</label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label" *ngIf="shouldShowTextBox">
                                    <input type="text" [value]="_selectedreference" pInputText  [(ngModel)]="_selectedreference" 
                                    name="selectedref" [ngModelOptions]="{standalone: true}" formControlName="referenceno">
                                    <label for="selectedref">Reference No.</label>
                                </span>
                            </td>
                        </tr>
                    </table>
                    <p-dialog header="!Warning This Supplier Under Hold" [(visible)]="supplierholdreason" [modal]="true"
                            [style]="{width: '20vw',height: '215px'}" [baseZIndex]="10000">
                            <ng-template pTemplate="content">
                                <div class="popup-body">
                                    <div class="card">
                                        <textarea name="reasonforsupplierhold" [(ngModel)]="reasonforsupplierhold" 
                                        [ngModelOptions]="{standalone: true}" pInputTextarea [disabled]="popupdisable"
                                            style="width: 100%; height: 100px; padding: 5px;">
                                        </textarea>
                                    </div>
                                    <div style="text-align: right;" *ngIf="supplierholdpopupsubmit">
                                        <button pButton pRipple label="Close" (click)="Supplierholdreasonpopupclose()" title="Close"
                                            icon="pi pi-times" class="p-button-danger p-mr-2">
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dialog>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_purchaseinvoicedetails" [rows]="15" [paginator]="true" [rowsPerPageOptions]="[15,25,50,100]" 
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
                    [globalFilterFields]="['purchaseorderno','productname','variantname','invoicequantity','uomname','unitprice','taxamount','amount']"
                        [rowHover]="true" dataKey="suppliername" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Order No</th>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>Invoice Quantity</th>
                                <th>UOM</th>
                                <th>Product Rate</th>
                                <th>Tax Amount</th>
                                <th [hidden]="true">Tax ID</th>
                                <th [hidden]="true">Tax Percentage</th>
                                <th>Final Amount</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_purchaseinvoicedetail let-ri="rowIndex">
                            <tr>
                                <td>
                                    {{_purchaseinvoicedetail.purchaseorderno}}
                                </td>
                                <td>
                                    {{_purchaseinvoicedetail.productname}}
                                </td>
                                <td>
                                    {{_purchaseinvoicedetail.variantname}}
                                </td>
                                <td>
                                    {{_purchaseinvoicedetail.invoicequantity}}
                                </td>
                                <td>
                                    {{_purchaseinvoicedetail.uomname}}
                                </td>
                                <td>
                                    {{_purchaseinvoicedetail.unitprice | number: '1.3'}}
                                </td>
                                <td>
                                    {{_purchaseinvoicedetail.taxamount | number: '1.3'}}
                                </td>
                                <td [hidden]="true">
                                    {{_purchaseinvoicedetail.taxid}}
                                </td>
                                <td [hidden]="true">
                                    {{_purchaseinvoicedetail.taxpercentage }}
                                </td>
                                <td>
                                    {{_purchaseinvoicedetail.amount | number: '1.3'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <form [formGroup]="_purchaseinvoiceform">
                    <div class="sub-title">
                        <div class="row">
                            <div class="col-md-5">
                                <h5>Payable Details</h5>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                    </div>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="grossamount" formControlName="grossamount" disabled
                                        pInputText>
                                    <label for="grossamount">Gross Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="taxamount" formControlName="taxamount" disabled pInputText>
                                    <label for="taxamount">Tax Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="otherchargesamount" formControlName="otherchargesamount"
                                        disabled pInputText>
                                    <label for="otherchargesamount">Other Charges</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="discountamount" formControlName="discountamount" disabled
                                        pInputText>
                                    <label for="discountamount">Discount Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="advanceamount" formControlName="advanceamount" disabled
                                        pInputText>
                                    <label for="advanceamount">Advance Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input pInputText type="text" name="netamount" (keyup)="payableamount()"
                                        formControlName="netamount" disabled>
                                    <label for="netamount">Net Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;" hidden>
                                <span class="p-float-label">
                                    <input type="text" name="invoiceamount" formControlName="invoiceamount" pInputText>
                                    <label for="invoiceamount">Invoice Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <table>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </table>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>