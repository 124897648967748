<div class="page-container">
  <div class="inner-page-container">
    <div class="page-loader" *ngIf="_IsProgressSpinner">
      <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>
    <div class="white">
      <div class="page-title">
        <div class="row">
          <div class="col-md-5">
            <h3>Customer Consignment Branch</h3>
          </div>
          <div class="col-md-7 text-right">
            <div class="action-btn">
              <button
                pButton
                pRipple
                type="submit"
                title="Save"
                (click)="onSave()"
                icon="pi pi-save"
                [disabled]="_action == 'view'"
                class=""
              ></button>
              <button
                pButton
                pRipple
                type="button"
                title="Clear"
                icon="pi pi-trash"
                class=""
                (click)="Clear()"
                class="p-button-danger"
                [disabled]="_action != 'create'"
              ></button>
              <button
                pButton
                pRiple
                type="button"
                icon="pi pi-search"
                title="Back to Search"
                [routerLink]="['/vCustomerbranchmaster']"
                class="p-button p-button-success p-mr-2"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-container scroll-y">
        <p-toast position="bottom-right"></p-toast>
        <p-tabView>
          <p-tabPanel header="Branch Details">
            <form [formGroup]="_customerbranchform" (ngSubmit)="onSave()">
              <table class="normal-table">
                <tr>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <input
                        type="text"
                        pInputText
                        name="customerbranchcode"
                        formControlName="customerbranchcode"
                        (focusout)="BranchcodeCheck()"
                      />
                      <label for="customerbranchcode">
                        Customer Branch Code
                        <span class="hlt-txt">*</span></label
                      >
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (_submitted ||
                          _customerbranchform.get('customerbranchcode')
                            .touched) &&
                        _customerbranchform.get('customerbranchcode').errors
                          ?.BranchcodeRequired
                      "
                    >
                      Please enter customer branch code
                    </span>
                  </td>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <input
                        type="text"
                        pInputText
                        name="customerbranchname"
                        formControlName="customerbranchname"
                        (focusout)="BranchnameCheck()"
                      />
                      <label for="customerbranchname">
                        Customer Branch Name
                        <span class="hlt-txt">*</span></label
                      >
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (_submitted ||
                          _customerbranchform.get('customerbranchname')
                            .touched) &&
                        _customerbranchform.get('customerbranchname').errors
                          ?.BranchnameRequired
                      "
                    >
                      Please enter customer branch name
                    </span>
                  </td>

                  <td style="width: 20%">
                    <span class="p-float-label">
                      <p-dropdown
                        [autoDisplayFirst]="false"
                        [options]="_Statuslist"
                        optionLabel="metasubdescription"
                        optionValue="metasubcode"
                        name="customerbranchstatus"
                        formControlName="customerbranchstatus"
                      >
                      </p-dropdown>
                      <label for="customerbranchstatus">
                        Status <span class="hlt-txt">*</span></label
                      >
                    </span>
                  </td>
                  <td style="width: 20%">
                    <!-- <label for="inputtext">Profile Image </label> -->
                    <div class="p-inputgroup" *ngIf="_Iscreatefileupload" [disabled]="_action == 'view'">
                      <div
                        class="file-upload-wrapper"
                        data-text="Branch Logo(JPEG)"
                      >
                        <input
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          class="file-upload-field"
                          (change)="fileChangeEvent($event)"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="p-inputgroup" *ngIf="_Isviewfileupload" [disabled]="_action == 'view'">
                      <span class="p-float-label">
                        <div class="browse-links">
                          <a
                            href="javascript:void(0)"
                            (click)="OpenEmployeeImage($event)"
                          >
                            <p-button
                              label="Branch Logo"
                              styleClass="p-button-link"
                            >
                            </p-button>
                          </a>
                        </div>
                        <button
                          [disabled]="_action == 'view'"
                          class="p-inputgroup-addon p-button"
                          pTooltip="Cancel"
                          type="button"
                          (click)="onImageClear()"
                          tooltipPosition="top"
                        >
                          <i class="pi pi-times"></i>
                        </button>
                      </span>
                    </div>
                  </td>
                  <td style="width: 20%;">
                    <span class="p-float-label">
                        <p-multiSelect [options]="_CustomerList" optionLabel="customername"
                            optionValue="customerid" [(ngModel)]="customerid"
                            [ngModelOptions]="{ standalone: true }"
                            [disabled]="_action == 'view'"
                            [virtualScroll]="true" itemSize="30">
                        </p-multiSelect>
                        <label for="productid">Customer Name </label>
                    </span>
                </td>
                </tr>
              </table>
              <p-dialog
                header="Branch Logo"
                [(visible)]="displaydocumentpopup"
                [position]="position1"
                [style]="{ width: '500px' }"
                [baseZIndex]="10000"
              >
                <img [src]="branchlogo" alt="" class="img-preview" />

                <ng-template pTemplate="footer">
                  <p-button
                    icon="pi pi-check"
                    (click)="showPopupDialog()"
                    label="Ok"
                    styleClass="p-button-text"
                  >
                  </p-button>
                </ng-template>
              </p-dialog>
              <table>
                <tr>
                  <td>
                    <!-- Debug -->
                    <div
                      #editor
                      style="width: 500px; height: 250px; overflow-y: auto"
                    ></div>
                  </td>
                </tr>
              </table>
            </form>
          </p-tabPanel>
          <p-tabPanel header="Branch Address Details">
            <form [formGroup]="_customerbranchaddressform">
              <table class="normal-table">
                <tr>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <input
                        type="text"
                        pInputText
                        name="contactperson"
                        formControlName="contactperson"
                      />
                      <label for="contactperson">
                        Contact Person Name
                        <span class="hlt-txt">*</span></label
                      >
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (_addresssubmitted ||
                        _customerbranchaddressform.get('contactperson')
                            .touched) && _customerbranchaddressform.get('contactperson').errors
                          ?.ContactPersonNameRequired"
                    >
                    Please enter contact person name
                    </span>
                  </td>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <input
                        type="text"
                        pInputText
                        name="mobileno"
                        formControlName="mobileno"
                      />
                      <label for="mobileno">
                        Mobile Number <span class="hlt-txt">*</span></label
                      >
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (_addresssubmitted ||
                          _customerbranchaddressform.get('mobileno').touched) &&
                        _customerbranchaddressform.get('mobileno').errors
                          ?.MobileNumberRequired
                      "
                    >
                      Please enter mobile number
                    </span>
                  </td>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <input
                        type="text"
                        pInputText
                        name="mailid"
                        formControlName="mailid"
                      />
                      <label for="mailid">
                        Mail Id <span class="hlt-txt">*</span></label
                      >
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (_customerbranchaddressform.get('mailid').touched ||
                          _addresssubmitted) &&
                        _customerbranchaddressform.get('mailid').errors
                          ?.EmailIDRequired
                      "
                    >
                      Please enter email ID
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (_customerbranchaddressform.get('mailid').touched ||
                          _addresssubmitted) &&
                        _customerbranchaddressform.get('mailid').errors
                          ?.ValidEmailID
                      "
                    >
                      Please enter valid email ID
                    </span>
                  </td>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <p-dropdown
                        [autoDisplayFirst]="false"
                        [options]="_Countrylist"
                        optionLabel="countryname"
                        optionValue="countryid"
                        name="countryid"
                        formControlName="countryid"
                        (onChange)="getStates($event)"
                      >
                      </p-dropdown>
                      <label for="countryid">
                        Country Name <span class="hlt-txt">*</span></label
                      >
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (_addresssubmitted ||
                          _customerbranchaddressform.get('countryid')
                            .touched) &&
                        _customerbranchaddressform.get('countryid').errors
                          ?.SelectCountryName
                      "
                    >
                      Please select country
                    </span>
                  </td>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <p-dropdown
                        [autoDisplayFirst]="false"
                        [options]="_Statelist"
                        optionLabel="statename"
                        optionValue="stateid"
                        name="stateid"
                        formControlName="stateid"
                        (onChange)="getDistrict($event)"
                      >
                      </p-dropdown>
                      <label for="stateid">
                        Province <span class="hlt-txt">*</span></label
                      >
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (_addresssubmitted ||
                          _customerbranchaddressform.get('stateid').touched) &&
                        _customerbranchaddressform.get('stateid').errors
                          ?.SelectStateName
                      "
                    >
                      Please select province
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 20%">
                    <span class="p-float-label">
                      <p-dropdown
                        [autoDisplayFirst]="false"
                        [options]="_Citylist"
                        optionLabel="districtname"
                        optionValue="districtid"
                        name="districtid"
                        formControlName="districtid"
                      >
                      </p-dropdown>
                      <label for="districtid">
                        City Name <span class="hlt-txt">*</span></label
                      >
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (_addresssubmitted ||
                          _customerbranchaddressform.get('districtid')
                            .touched) &&
                        _customerbranchaddressform.get('districtid').errors
                          ?.SelectDistrictName
                      "
                    >
                      Please select city
                    </span>
                  </td>
                  <td style="width: 20%" rowspan="2">
                    <span class="p-float-label">
                      <textarea
                        rows="3"
                        cols="30"
                        name="address"
                        formControlName="addressdetail"
                        pInputTextarea
                      ></textarea>
                      <label for="address">
                        Address <span class="hlt-txt">*</span></label
                      >
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        (_addresssubmitted ||
                          _customerbranchaddressform.get('addressdetail')
                            .touched) &&
                        _customerbranchaddressform.get('addressdetail').errors
                          ?.AddressRequired
                      "
                    >
                      Please enter address
                    </span>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <button
                      pButton
                      pRipple
                      label="Add"
                      title="Add"
                      icon="pi pi-plus"
                      [disabled]="isDisabledaddbuttn"
                      (click)="onAddAddress($event)"
                      class="p-button-success p-mr-2"
                    ></button>
                    <button
                      pButton
                      pRipple
                      label="Clear"
                      title="Clear"
                      icon="pi pi-times"
                      [disabled]="isDisabledreset"
                      (click)="resetaddress($event)"
                      class="p-button-danger p-mr-2"
                    ></button>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </form>
            <div class="card">
            <p-table
              #dt
              [value]="_CustomersAddress"
              [rows]="5"
              [paginator]="true"
              [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
              [globalFilterFields]="[
                'producttypename',
                'categoryname',
                'productname',
                'productprice'
              ]"
              [rowHover]="true"
              dataKey="address"
              [(selection)]="selectedrows"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [showCurrentPageReport]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <!-- <th style="text-align: center; width: 100px">Action</th> -->
                  <!-- <th>Is Default</th> -->
                  <th>Contact Person</th>
                  <th>Mobile Number</th>
                  <th>Email ID</th>
                  <th>Address</th>
                  <!-- <th>Status</th> -->
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-_CustomerAddress
                let-ri="rowIndex"
              >
                <tr (dblclick)="onRowCustomerselect(_CustomerAddress, ri)">
                  <!-- <td style="text-align: center">
                    <button
                      pButton
                      pRipple
                      label=""
                      title="Remove"
                      icon="pi pi-trash"
                      (click)="RemoveAddressDetail(_CustomerAddress)"
                      class="p-button-danger p-mr-2"
                      type="button"
                    ></button>
                  </td> -->
                  <!-- <td style="text-align: center">
                    <p-checkbox
                      [(ngModel)]="_CustomerAddress.isdefaultaddress"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="_action == 'view'"
                      (onChange)="Removeselectedchecked(_CustomerAddress, ri)"
                      binary="true"
                    >
                    </p-checkbox>
                  </td> -->
                  <td>
                    {{ _CustomerAddress.contactperson }}
                  </td>
                  <td>
                    {{ _CustomerAddress.mobileno }}
                  </td>
                  <td>
                    {{ _CustomerAddress.mailid }}
                  </td>
                  <td>
                    {{ _CustomerAddress.addressdetail }}
                  </td>
                  <!-- <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown #id [autoDisplayFirst]="false"
                                [options]="_Statuslist" optionLabel="metasubdescription"
                                optionValue="metasubcode"
                                [(ngModel)]="_Addressstatus"
                                (onChange)="OnchangeAddressStatus(_Addressstatus)">
                            </p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                [options]="_Statuslist"
                                optionLabel="metasubdescription"
                                optionValue="metasubcode"
                                [(ngModel)]="_Addressstatus"
                                (onChange)="OnchangeAddressStatus(_Addressstatus)">
                            </p-dropdown>
                        </ng-template>
                    </p-cellEditor>
                </td> -->
                </tr>
              </ng-template>
            </p-table>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
  </div>
</div>
<p-dialog
  header="Image Cropper"
  [(visible)]="imageCropperTool"
  [modal]="true"
  [style]="{ width: '500px' }"
  [baseZIndex]="10000"
>
  <div class="text-center mb10">
    <button
      pButton
      pRipple
      label=""
      icon="las la-plus"
      pTooltip="Zoom +"
      tooltipPosition="top"
      (click)="zoomIn()"
      class="p-button p-mr-2"
    ></button>
    <button
      pButton
      pRipple
      label=""
      icon="las la-minus"
      pTooltip="Zoom -"
      tooltipPosition="top"
      (click)="zoomOut()"
      class="p-button p-mr-2"
    ></button>
    <button
      pButton
      pRipple
      label=""
      icon="las la-redo-alt"
      pTooltip="Reset Image"
      tooltipPosition="top"
      (click)="resetImage()"
      class="p-button-danger p-mr-2"
    ></button>
    <button
      pButton
      pRipple
      label=""
      icon="las la-save"
      pTooltip="Save"
      tooltipPosition="top"
      (click)="onSaveImage()"
      class="p-button-success p-mr-2"
    ></button>
  </div>
  <div class="row">
    <div class="col-md-6">
      <h5>Input</h5>
      <div class="crop-input">
        <image-cropper
          class="img-fluid"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio"
          [aspectRatio]="7 / 3"
          [resizeToWidth]="140"
          [cropperMinWidth]="128"
          [onlyScaleDown]="true"
          [roundCropper]="false"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'left'"
          [style.display]="showCropper ? null : 'none'"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
    </div>
    <div class="col-md-6">
      <h5>Output</h5>
      <img [src]="croppedImage" />
    </div>
  </div>
</p-dialog>
