<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Product Request Report </h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_rptproductrequestform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="fromdate">From Date</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                    </p-calendar>
                                    <label for="todate">To Date</label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_branchlist" optionLabel="branchname"
                                        optionValue="branchid" [(ngModel)]="branchid"
                                        [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                        <ng-template let-account pTemplate="item">
                                            <div
                                                [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                {{account.branchname}}</div>
                                        </ng-template>
                                    </p-multiSelect>
                                    <label for="branchid">Branch Name </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [options]="_requesttypelist" optionLabel="metasubdescription"
                                        optionValue="metasubcode" formControlName="requesttypeid" [autoDisplayFirst]="false">
                                    </p-dropdown>
                                    <label for="requesttypeid">Request Type </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_userslist" optionLabel="username" optionValue="userid"
                                        [(ngModel)]="requesteduserid" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Requested By </label>
                                </span>
                            </td>                             
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_userslist" optionLabel="username" optionValue="userid"
                                        [(ngModel)]="userid" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Created By </label>
                                </span>
                            </td> 
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_producttypelist" optionLabel="producttypename"
                                        optionValue="producttypeid" [(ngModel)]="producttypeid"
                                        [ngModelOptions]="{standalone: true}" (onChange)="getProductCategory($event)"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="producttypeid">Product Type Name </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productcategorylist" optionLabel="categoryname"
                                        [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}"
                                        (onChange)="getProduct($event)" optionValue="productcategoryid"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Category Name</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_productlist" optionLabel="productname"
                                        optionValue="productid" [(ngModel)]="productid"
                                        (onChange)="ChangeProduct($event)" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="productid">Product Name </label>
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_brandlist" optionLabel="brandname" optionValue="brandid"
                                        [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}"
                                        [virtualScroll]="true" itemSize="30">
                                    </p-multiSelect>
                                    <label for="brandid">Brand Name </label>
                                </span>
                            </td>                     
                            
                        </tr>
                        <tr>
                            <td>
                                <button pButton pRipple label="Search" (click)="Print($event)" icon="pi pi-search" class="p-button-sm p-button-success"></button>
                                <button pButton pRipple label="Clear" (click)="clear($event)" icon="pi pi-times" class="p-button-danger"></button>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()"
                                class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" (click)="exportExcel()"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                ></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" (click)="ExportToPdf()"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                ></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_ReportLists" [scrollable]="true" scrollHeight="450px" [rows]="10"
                        [paginator]="true" [rowsPerPageOptions]="[10,50,100,500]"
                        [globalFilterFields]="['materialrequestdate','producttypename','categoryname','productname','brandname','branchname','requesttype','requestreason','requestedby','createdby']"
                        [rowHover]="true" dataKey="productname"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="materialrequestdate">Material Request Date
                                    <p-sortIcon field="materialrequestdate">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="producttypename"> Product Type
                                    <p-sortIcon field="producttypename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="categoryname">Product Category
                                    <p-sortIcon field="categoryname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="productname">Product Name
                                    <p-sortIcon field="productname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="brandname">Brand Name
                                    <p-sortIcon field="brandname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="branchname">Branch Name
                                    <p-sortIcon field="branchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="requesttype"> Request Type
                                    <p-sortIcon field="requesttype">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="requestreason"> Request Reason
                                    <p-sortIcon field="requestreason">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="requestedby">Requested By
                                    <p-sortIcon field="requestedby">
                                    </p-sortIcon>
                                <th pSortableColumn="createdby">Created By
                                    <p-sortIcon field="createdby">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ReportList let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    {{i+ 1}}
                                </td>
                                <td>
                                    {{_ReportList.materialrequestdate |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_ReportList.producttypename}}
                                </td>
                                <td>
                                    {{_ReportList.categoryname}}
                                </td>
                                <td>
                                    {{_ReportList.productname}}
                                </td>
                                <td>
                                    {{_ReportList.brandname}}
                                </td>
                                <td>
                                    {{_ReportList.branchname}}
                                </td>
                                <td>
                                    {{_ReportList.requesttype}}
                                </td>
                                <td>
                                    {{_ReportList.requestreason}}
                                </td>
                                <td>
                                    {{_ReportList.requestedby}}
                                </td>
                                <td>
                                    {{_ReportList.createdby}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>