import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Action } from 'rxjs/internal/scheduler/Action';
import { Brand } from 'src/app/core/Models/Brand';
import { Customer } from 'src/app/core/Models/Customer';
import { Customerbranchdetail } from 'src/app/core/Models/Customerbranch';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Customerbranchaddressdetail } from 'src/app/core/Models/customerbranchaddressdetail';
import { ConsignmentdeliverynoteService } from 'src/app/core/Services/consignmentcustomer/consignmentdeliverynote/consignmentdeliverynote.service';
import { RptbranchwisestockService } from 'src/app/core/Services/reports/Stock/RptBranchwiseStock/rptbranchwisestock.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { KeyPress } from 'src/assets/js/KeyPress';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-consignmentstockreport',
  templateUrl: './consignmentstockreport.component.html',
  styleUrls: ['./consignmentstockreport.component.css']
})
export class ConsignmentstockreportComponent implements OnInit {

  _IsProgressSpinner: boolean = false;
  Date: Date
  rowdata: any[]= [];
  _rptconsignmentstockform: FormGroup;
  mindate: Date;
	maxdate: Date;
  _Action: IAction;
  _Customerlist: Customer[] = [];
  _TempCustomerlist: Customer[] = [];
  _Consignmentbranch: any[];
  _Tempconsignmentbranch: Customerbranchaddressdetail[];
  _TempConsignmentbranchaddresslist: Customerbranchaddressdetail[];
  _Consignmentbranchaddresslist: any[];
  _Producttypelist: Producttype[];
  customerbranchdetail: Customerbranchdetail[] = [];
  productcatid: any[] = [];
  productid: any[] = [];
  _productlist: any[] = [];
  _productcategorylist: any[] = [];
  _brandlist: any;
	_Tempbrandlist: Brand;
  _userid = sessionStorage['userid'];
  _ReportLists: any[] = [];

  constructor(
    private router: Router,
    private utility: CommonUtilities,
    private fb: FormBuilder,
    public keypress: KeyPress,
    private _CustomExceptionService: CustomExceptionService,
    private _AccessRightsService: AccessRightsService,
    private _ConsignmentdeliverynoteService: ConsignmentdeliverynoteService,
    private _RptbranchwisestockService: RptbranchwisestockService,
    private exportUtility: ExportUtility,
  ) { }

  InitializeForm() {
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptconsignmentstockform = this.fb.group({
      fromdate: [],
      todate: [],
      customerid: [],
      customerbranchid: [],
      customeraddressid: [],
      deliverynoteno: [],
      producttypeid: [],
      productcategoryid: [],
      productid: [],
      brandid: []
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptconsignmentstockform.get("fromdate").setValue(new Date);
      this._rptconsignmentstockform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptconsignmentstockform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptconsignmentstockform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(374);
    this.InitializeForm();
    this.utility.pageLoadScript();

    var obj = { 
      "ConsignmentDeliveryNoteId": 0, 
    };
    this._ConsignmentdeliverynoteService.PageOnLoad(obj).subscribe((result) =>
    {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._TempCustomerlist = resultJSON.customerslist;
      this._Consignmentbranch = resultJSON.customerbranchlist || [];
      this._Tempconsignmentbranch = resultJSON.customerbranchlist || [];
	  this._brandlist = resultJSON.brandlist;
      this._TempConsignmentbranchaddresslist = resultJSON.customerbranchaddressdetaillist || [];
      if(this._TempCustomerlist.length > 0)
      {
        this._Customerlist = this._TempCustomerlist.filter(a => a.isconsignmentcustomer == true);
      }
      
      this._Producttypelist = resultJSON.producttypeList;
      this.customerbranchdetail = resultJSON.customerbranchdetaillist || [];
    });
    this._IsProgressSpinner = false;
  }

  getProductCategory(event) {
		var selectedProductType = event.value;
		// var branchid = this._rptconsignmentstockform.get("branchid").value == null ? 0 : this._rptconsignmentstockform.get("branchid").value;
		this.productcatid = [];
		this.productid = [];
		this._productlist = [];
		if (selectedProductType.length == 0 || selectedProductType == null) {
			this._productcategorylist = [];
			this._rptconsignmentstockform.controls["productcategoryid"].reset();
			return;
		}
		else {
			this._IsProgressSpinner = true;
			var Obj = {
				"Producttype": selectedProductType || [],
				"branchid": parseInt(sessionStorage["currentbranchid"])
			};
			this._RptbranchwisestockService.getProductCategory(Obj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._productcategorylist = resultJSON.productcategories;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}

  getProduct(event) 
  {
		var selectedProductCategory = event.value;
		this.productid = [];
		// var branchid = this._rptbranchwisestockform.get("branchid").value == null ? 0 : this._rptbranchwisestockform.get("branchid").value;
		if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
			this._productlist = [];
			return;
		} else {
			var obj = { 
        "Productcategory": selectedProductCategory || [], 
        "branchid": parseInt(sessionStorage["currentbranchid"]) 
      };
			this._RptbranchwisestockService.getProduct(obj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._productlist = resultJSON.productoverallList;
			});
		}
	}

  ChangeProduct(event) {
		var selectedProduct = event.value;
		
		if (selectedProduct.length == 0 || selectedProduct == null) {
			this._brandlist = this._Tempbrandlist
			return;
		}
		else {
			this._IsProgressSpinner = true;
			var obj = {
				"Products": selectedProduct || [],
				"branchid": parseInt(sessionStorage["currentbranchid"])
			};
			this._RptbranchwisestockService.getBrand(obj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				let productbrand = resultJSON.productbrandlist;
				let uniqueChars = [...new Set(productbrand)];
				uniqueChars = productbrand.filter((test, index, array) =>
					index === array.findIndex((findTest) =>
						findTest.brandid === test.brandid
					)
				);
				this._brandlist = uniqueChars;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}

  GenerateReport(event) {
		var _fromdate = this._rptconsignmentstockform.get("fromdate").value;
		var _todate = this._rptconsignmentstockform.get("todate").value;
		var datePipe = new DatePipe("en-US");
		var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
		var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
		if (fromdate > todate) {
			this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
			return;
		}
		var customerid = this._rptconsignmentstockform.get("customerid").value || [];
		var customerbranchid = this._rptconsignmentstockform.get("customerbranchid").value || [];
		var deliverynoteno = this._rptconsignmentstockform.get("deliverynoteno").value || [];
		var producttypeid = this._rptconsignmentstockform.get("producttypeid").value || [];
		var categoryid = this._rptconsignmentstockform.get("productcategoryid").value || [];
		var productid = this._rptconsignmentstockform.get("productid").value || [];
		var brandid = this._rptconsignmentstockform.get("brandid").value || [];

		var obj = {
      "FromDate": _fromdate,
      "ToDate": _todate,
			"CustomerId": customerid || [],
			"CustomerBranchId": customerbranchid || [], 
			"DeliveryNoteId": deliverynoteno || [],
			"ProductTypeId": producttypeid || [],
			"ProductCategoryId": categoryid || [],
			"ProductId": productid || [],
			"BrandId": brandid || [],
		};
		this._IsProgressSpinner = true;
		this._ConsignmentdeliverynoteService.ConsignmentStockReport(obj).subscribe((result) => {
			this._IsProgressSpinner = false;
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._ReportLists = [];
			if (!resultJSON.consignmentStockReports || !resultJSON.consignmentStockReports.length) {
				this._CustomExceptionService.handleError("No Data Available");
			} else {
				this._ReportLists = resultJSON.consignmentStockReports;
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

  exportExcel() {
		let consignmentstocklists = this.GetdDeliverList();
		this.exportUtility.exportExcel(consignmentstocklists, 'ConsignmentStockdelivery', 'xlsx');
	}

	ExportToPdf() {
		let consignmentstocklists = this.GetdDeliverList();
		const data = consignmentstocklists.map(Object.values);
		const head = [[
			'Delivery Note Date',
			'Delivery Note No',
			'Customer Name',
			'Customer Branch Name',
			'Product Type',
			'Product Category',
			'Product Name',
			'Brand Name',
			'UOM',
      'Stock'
		]]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					}, headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					}, bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('ConsignmentStockdelivery.pdf');
			})
		})
	}

	GetdDeliverList() {
		let consignmentstocklists = [];
		this._ReportLists.forEach(element => {
		let consignmentstock: any = {};
			consignmentstock["Delivery Note Date"] = element.consignmentdeliverynotedate;
			consignmentstock["Delivery Note No"] = element.consignmentdeliverynoteno;
			consignmentstock["Customer Name"] = element.customername;
			consignmentstock["Customer Branch Name"] = element.customerbranchname;
			consignmentstock["Product Type"] = element.producttypename;
			consignmentstock["Product Category"] = element.productcategoryname;
			consignmentstock["Product Name "] = element.productname;
			consignmentstock["Brand Name"] = element.brandname;
			consignmentstock["UOM"] = element.uom;
			consignmentstock["Stock"] = element.stock || 0;
			consignmentstocklists.push(consignmentstock);
		});
		return consignmentstocklists;
	}

	Clear()
	{
		this._rptconsignmentstockform.reset();
		this._ReportLists = [];
	}
}



