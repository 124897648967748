<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Consignment Delivery Note</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple icon="pi pi-plus" title="Add" [disabled]="!_Action._Add"
                                class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast></p-toast>
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate"
                                    [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate"
                                    yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="FetchAll()" title="Search"
                                icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV"
                                (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel"
                                class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"
                                (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Consignmentdeliverynoteviews" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols"
                        [globalFilterFields]="['consignmentdeliverynoteno','consignmentdeliverynotedate','customername','bholabranchname','createdbyname','createdbyon','status','createdon','modifiedbyname','modifiedon']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="salesinvoiceno"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="Action" style="width: 100px;text-align: center;">Action <p-sortIcon
                                        field="name"></p-sortIcon>
                                </th>
                                <th pSortableColumn="consignmentdeliverynoteno">Delivery Note No <p-sortIcon field="consignmentdeliverynoteno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="consignmentdeliverynotedate">Delivery Note Date <p-sortIcon
                                        field="consignmentdeliverynotedate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="customername">Customer Name<p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="customername">Customer Branch<p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="bholabranchname">Bhola Branch<p-sortIcon field="bholabranchname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="statusname">Status<p-sortIcon field="statusname">
                                </p-sortIcon>
                            </th>
                                <th  pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname"></p-sortIcon>
                                </th>
                                <th  pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon"></p-sortIcon>
                                </th>
                                <th  pSortableColumn="modifiedbyname">Modified By
                                    <p-sortIcon field="modifiedbyname"></p-sortIcon>
                                </th>
                                <th pSortableColumn="modifiedon">Modified On<p-sortIcon field="modifiedon">
                                    </p-sortIcon>
                                </th>
                               
                               
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Consignmentdeliverynoteview>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2"
                                        [disabled]="!_Action._View" (click)="view(_Consignmentdeliverynoteview)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Delete"
                                        class="p-button p-button-danger" (click)="confirm(_Consignmentdeliverynoteview)"
                                        [disabled]="!_Action._Delete || _Consignmentdeliverynoteview.cstatus =='MSC00150' || _Consignmentdeliverynoteview.invoicestatus =='YES'"></button>
                                    <button pButton pRipple icon="las la-print" title="Print"
                                        class="p-button-warning p-mr-2" (click)="Print(_Consignmentdeliverynoteview)"
                                        [disabled]="(_Consignmentdeliverynoteview.cstatus !='MSC00047')"></button>
                                </td>
                                <td>
                                    {{_Consignmentdeliverynoteview.consignmentdeliverynoteno}}
                                </td>
                                <td>
                                    {{_Consignmentdeliverynoteview.consignmentdeliverynotedate | date:'dd-MM-yyyy'}}
                                </td>
                                <td>
                                    {{_Consignmentdeliverynoteview.customername}}
                                </td>
                                <td>
                                    {{_Consignmentdeliverynoteview.customerbranchname}}
                                </td>
                                <td>
                                    {{_Consignmentdeliverynoteview.bholabranchname }}
                                </td>
                                <td>
                                    {{_Consignmentdeliverynoteview.status}}
                                </td>
                                <td>
                                    {{_Consignmentdeliverynoteview.createdbyname}}
                                </td>
                                <td>
                                    {{_Consignmentdeliverynoteview.createdon| date:'dd-MM-yyyy'}}
                                </td>
                                <td>
                                    {{_Consignmentdeliverynoteview.modifiedbyname }}
                                </td>
                                <td>
                                    {{ _Consignmentdeliverynoteview.modifiedon && _Consignmentdeliverynoteview.modifiedon !== '0001-01-01T00:00:00' 
                                    ? (_Consignmentdeliverynoteview.modifiedon | date: 'dd-MM-yyyy') 
                                    : '' }}
                                </td>
                              
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>