import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { ConsignmentcustomerbranchService } from 'src/app/core/Services/consignmentcustomer/consignmentcustomerbranch/consignmentcustomerbranch.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Consignmentcustomerbranchview } from 'src/app/core/Views/Consignmentcustomerbranchview';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-consignmentcustomerbranch',
  templateUrl: './v-consignmentcustomerbranch.component.html',
  styleUrls: ['./v-consignmentcustomerbranch.component.css']
})
export class VConsignmentcustomerbranchComponent implements OnInit {

  _IsProgressSpinner: boolean = false;
  _Customerbranchview: any[] = [];
  _branchid: number;
  _userid = sessionStorage["userid"];
  _branchlist: any[] = [];
   

  constructor(
    private _AccessRightsService: AccessRightsService,
    private _ConsignmentCustomerService: ConsignmentcustomerbranchService,
    private _CustomExceptionService: CustomExceptionService,
    private exportUtility: ExportUtility,
    private router: Router,
  ) { }

  ngOnInit(): void {


    this._ConsignmentCustomerService.FetchAll({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Customerbranchview = resultJSON.customerbranchviews;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }

  edit(event) {
    this.router.navigate(['/cCustomerbranchmaster'], { state: { cutomerbranchid: event.cutomerbranchid, action: 'edit' } });
  }

  view(event) { 
    this.router.navigate(['/cCustomerbranchmaster'], { state: { cutomerbranchid: event.cutomerbranchid, action: 'view' } });
  }

  Cancel(event) {
    this._IsProgressSpinner = true;
    this._branchid = parseInt(event.cutomerbranchid);
    this._userid = parseInt(sessionStorage["userid"]);
    this._ConsignmentCustomerService.Cancel(this._branchid, this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._CustomExceptionService.handleSuccess(usererrors.MasterInactive_08);
        this.ngOnInit();
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GetbranchList() {
		let branchlists = [];
		this._Customerbranchview.forEach(element => {
		let branch: any = {};
			branch["Branch Code"] = element.customerbranchcode;
			branch["Branch Name"] = element.customerbranchname;
			branch["Branch Status"] = element.customerbranchstatus;
			branch["Created By"] = element.createdby;
			branch["Created On"] = element.createdon;
			branch["Modified By"] = element.modifiedby;
			branch["Modified On"] = element.modifiedon;
			branchlists.push(branch);
		});
		return branchlists;
	}

  exportExcel() {
		let branchlists = this.GetbranchList();
		this.exportUtility.exportExcel(branchlists, 'Customerbranch', 'xlsx');
	}

	ExportToPdf() {
		let branchlists = this.GetbranchList();
		const data = branchlists.map(Object.values);
		const head = [[
			'Branch Code',
			'Branch Name',
			'Branch Status',
			'Created By',
			'Created On',
			'Modified By',
			'Modified On'
		]]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					}, headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					}, bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('Customerbranch.pdf');
			})
		})
	}


}
