<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Consignment Stock Adjustment</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" [disabled]="isDisabledsave" type="submit" (click)="onSave()" title="Save" icon="pi pi-save" class=""></button>
                            <button pButton pRipple label="" [disabled]="isDisablereset" type="button" (click)="reset()" title="Clear" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button" (click)="SendToApproval($event)" [disabled]="isDisabledsendapparoval"></button>
                            <!-- 27.should redirect to search page. -->
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" type="button" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_stockadjustmentform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="stocktakeno">
                                    <label for="id">Consignment Stock Taker No</label>
                                </span>
                            </td>
                        
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" name="stockadjustmentdate" formControlName="stockadjustmentdate"
                                    [minDate]="mindate" [maxDate]="maxdate"
                                        dateFormat="dd/mm/yy" [disabled]="true">
                                    </p-calendar>
                                    <label for="stocktakedate">Adjustment Date <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger" *ngIf="(_stockadjustmentform.get('stocktakedate').touched || _submitted) && _stockadjustmentform.get('stocktakedate').errors?.StockAdjustmentdateRequired">
                                    Please Select Date
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_cycleno" [filter]="true"
                                        filterBy="metasubdescription" [showClear]="true" optionValue="metasubcode"
                                        formControlName="cycleno" optionLabel="metasubdescription">
                                    </p-dropdown>
                                    <label for="id">Cycle No <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger" *ngIf="(_stockadjustmentform.get('cycleno').touched || _submitted) && _stockadjustmentform.get('cycleno').errors?.CyclenoRequired">
                                    Please Select Cycle No
                                </span> -->
                                <!-- 15.if "Cycle No." is empty, show error "Please Select Cycle No." -->
                            </td>
                            <!-- <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_BranchNames" formControlName="customerbranchid"
                                        optionLabel="customerbranchname" optionValue="cutomerbranchid" [virtualScroll]="true" [filter]="true"
                                        itemSize="30" (onChange)="getcustomer($event)" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="branchid">Customer Branch Name <span class="hlt-txt">*</span></label>
                                </span> -->
                                <!-- <span class="text-danger" *ngIf="(_stockadjustmentform.get('cutomerbranchid').touched || _submitted) && _stockadjustmentform .get('cutomerbranchid').errors?.BranchNameRequired">
                                    Please Select Branch
                                </span> -->
                            <!-- </td>  -->
                            <!-- <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_customernames" formControlName="customerid"
                                        optionLabel="customername" optionValue="customerid" [virtualScroll]="true" [filter]="true"
                                        itemSize="30" (onChange)="getdeliverynoteno()" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="Customerid">Customer Name <span class="hlt-txt">*</span></label>
                                </span> -->
                                <!-- <span class="text-danger" *ngIf="(_stockadjustmentform.get('Customerid').touched || _submitted) && _stockadjustmentform .get('Customerid').errors?.SelectCustomerName">
                                    Please Select Customer
                                </span> -->
                            <!-- </td>  -->
                           
                        </tr>
                        <tr>
                             <!-- <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_deliverynoteno" formControlName="deliverynoteno"
                                        optionLabel="consignmentdeliverynoteno" optionValue="consignmentdeliverynoteno" [virtualScroll]="true"
                                        itemSize="30" (onChange)="getproductdetails()" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="consignmentdeliverynoteid">Delivery Note No <span class="hlt-txt">*</span></label>
                                </span>
                               
                            </td>  -->
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stocktakers"
                                        optionValue="employeeid" [filter]="true" filterBy="employeename"
                                        [virtualScroll]="true" itemSize="15" [showClear]="true"
                                        formControlName="teamleadid" optionLabel="employeename">
                                    </p-dropdown>

                                    <label for="id">Team Lead <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger" *ngIf="(_stockadjustmentform.get('teamlead').touched || _submitted) && _stockadjustmentform.get('teamlead').errors?.StocktakerRequired">
                                    Please Select Team Lead
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stocktakers"
                                        optionValue="employeeid" [filter]="true" filterBy="employeename"
                                        [virtualScroll]="true" itemSize="15" [showClear]="true"
                                        formControlName="stocktakerid1" optionLabel="employeename">
                                    </p-dropdown>

                                    <label for="id">Stock Taker 1 <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger" *ngIf="(_stockadjustmentform.get('stocktaker1').touched || _submitted) && _stockadjustmentform.get('stocktaker1').errors?.SelectStockTakers">
                                    Please Select Stock Taker 1
                                </span> -->

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stocktakers"
                                        optionValue="employeeid" [filter]="true" filterBy="employeename"
                                        [virtualScroll]="true" itemSize="15" [showClear]="true"
                                        formControlName="stocktakerid2" optionLabel="employeename">
                                    </p-dropdown>

                                    <label for="id">Stock Taker 2 <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger" *ngIf="(_stockadjustmentform.get('stocktaker2').touched || _submitted) && _stockadjustmentform.get('stocktaker2').errors?.SelectStockTakers">
                                    Please Select Stock Taker 2
                                </span> -->
                            </td>
                         
                            <td style="width: 20%;">
                            </td>
                            <td style="width: 20%;">
                            </td>
                        </tr>
                    </table>
                </form>

                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_stockadjustmentdetailform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_BranchNames" formControlName="customerbranchid"
                                        optionLabel="customerbranchname" optionValue="cutomerbranchid" [virtualScroll]="true" [filter]="true"
                                        itemSize="30" (onChange)="getcustomer($event)" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="branchid">Customer Branch Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger" *ngIf="(_stockadjustmentform.get('cutomerbranchid').touched || _submitted) && _stockadjustmentform .get('cutomerbranchid').errors?.BranchNameRequired">
                                    Please Select Branch
                                </span> -->
                            </td> 
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_customernames" formControlName="customerid"
                                        optionLabel="customername" optionValue="customerid" [virtualScroll]="true" [filter]="true"
                                        itemSize="30" (onChange)="getdeliverynoteno()" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="Customerid">Customer Name <span class="hlt-txt">*</span></label>
                                </span>
                                <!-- <span class="text-danger" *ngIf="(_stockadjustmentform.get('Customerid').touched || _submitted) && _stockadjustmentform .get('Customerid').errors?.SelectCustomerName">
                                    Please Select Customer
                                </span> -->
                            </td> 
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-multiSelect [options]="_deliverynoteno" formControlName="deliverynoteno"
                                        optionLabel="consignmentdeliverynoteno" optionValue="consignmentdeliverynoteno" [virtualScroll]="true"
                                        itemSize="30" (onChange)="getproductdetails()" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="consignmentdeliverynoteid">Delivery Note No <span class="hlt-txt">*</span></label>
                                </span>
                               
                            </td> 
                            <td></td>
                        </tr>
                    </table>
                </form>

                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>

                    <p-table #dt [value]="_productdetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" [globalFilterFields]="['productname','customerbranchname','closingstock','actualstock','stockdiffinusd','customername','consignmentdeliverynoteno','remarks','invoiceqty','purchasevalueinusd','stockvalueinusd','differencevalueinusd']" [rowHover]="true"
                        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 50px;">
                                    <p-tableHeaderCheckbox (click)="SelectAll($event)" [disabled]="_action == 'view'">
                                    </p-tableHeaderCheckbox>
                                </th>
                                <th>Customer Branch</th>
                                <th>Customer Name</th>
                                <th>Delivery Note No</th>
                                <th>Product Name</th>
                                <!-- <th>Invoice Qty</th> -->
                                <th>Closing Stock</th>
                                <th>Actual Ok Stock</th>
                                <th>Stock Difference</th>
                                <th>Rate</th>
                                <th>Stock Value</th>
                                <th>Difference Value</th>
                                <th>Remarks</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_productdetail let-ri="rowIndex">
                            <tr>
                                <td style="text-align: center;width: 50px;">
                                    <p-tableCheckbox [value]="_productdetail" [disabled]="isDisabledselectall">
                                    </p-tableCheckbox>
                                </td>
                               <td> {{_productdetail.customerbranchname}}</td>
                               <td> {{_productdetail.customername}}</td>
                               <td> {{_productdetail.consignmentdeliverynoteno}}</td>
                               <td> {{_productdetail.productname}}</td>
                               <!-- <td> {{_productdetail.invoiceqty}}</td> -->
                               <td> {{_productdetail.closingstock}}</td>
                               <td pEditableColumn>
                                <!-- <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-inputNumber autocomplete="off" (onBlur)="onchangeactualstock(_productdetail)" *ngIf="_productdetail.uomcode != 'MSC00039'&& _action != 'view' " (keyup)="onchangeactualstock(_productdetail)" (keyup.enter)="onchangeactualstock(_productdetail)"
                                            (Keydown.enter)="onchangeactualstock(_productdetail)" (focusout)="onchangeactualstock(_productdetail)" maxlength="13" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_productdetail.actualstock"
                                            inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                        </p-inputNumber>
                                        <p-inputNumber autocomplete="off" (onBlur)="onchangeactualstock(_productdetail)" *ngIf="_productdetail.uomcode == 'MSC00039'&& _action != 'view' " (keyup)="onchangeactualstock(_productdetail)" (keyup.enter)="onchangeactualstock(_productdetail)"
                                            (Keydown.enter)="onchangeactualstock(_productdetail)" (focusout)="onchangeactualstock(_productdetail)" maxlength="13" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_productdetail.actualstock"
                                            inputId="minmaxfraction" mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                        </p-inputNumber>
                                        <div *ngIf="_action == 'view'">{{_productdetail.actualstock}}</div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{_productdetail.actualstock}}
                                    </ng-template>
                                </p-cellEditor> -->
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="number" [(ngModel)]="_productdetail.actualstock"
                                            [disabled]="_action != 'create'"
                                            (keyup)="onchangeactualstock(_productdetail)"
                                            (focusout)="onchangeactualstock(_productdetail)" pInputText>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{_productdetail.actualstock}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                               <td> {{_productdetail.stockdiffinusd}}</td>
                               <td> {{_productdetail.purchasevalueinusd}}</td>
                               <td> {{_productdetail.stockvalueinusd}}</td>
                               <td> {{_productdetail.differencevalueinusd}}</td>
                               <td pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="text" [(ngModel)]="_productdetail.remarks" *ngIf="_action != 'view'" pInputText>
                                        <div *ngIf="_action == 'view'">{{_productdetail.remarks}}</div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{_productdetail.remarks}}
                                    </ng-template>
                                </p-cellEditor>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-toast position="bottom-right"></p-toast>
            </div>
        </div>
    </div>
</div>