import { Component, OnInit } from '@angular/core';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { RptMarkupLevelService } from 'src/app/core/Services/reports/rpt-product-markup-level/rpt-markup-level.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

@Component({
  selector: 'app-rpt-product-markup-level',
  templateUrl: './rpt-product-markup-level.component.html',
  styleUrls: ['./rpt-product-markup-level.component.css']
})
export class RptProductMarkupLevelComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _producttypelist: any[] = []
  _productcategorylist: any[] = []
  _productlist: any[] = []
  _brandlist: any[] = []
  producttypeid: any[] = []
  productcatid: any[] = []
  productid: any[] = []
  brandid: any[] = []
  _ReportLists: any[] = []
  cols: any[];
  _Action: IAction;

  constructor(
    private utility: CommonUtilities,
    private RptMarkupLevelService: RptMarkupLevelService,
    private exportUtility: ExportUtility,
    private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService
  ) { }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(341);
    let TaskName = "PageOnLoad"
    let Data = {
      "TaskName": TaskName,
      "ProductTypeId": this.producttypeid || [],
      "ProductCategoryId": this.productcatid || [],
      "ProductId": this.productid || [],
      "BrandId": this.brandid || [],
    }
    this._IsProgressSpinner = true;
    this.RptMarkupLevelService.PageOnLoad(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._producttypelist = resultJSON.productTypeList;
      this._brandlist = resultJSON.brandList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProductCategory() {
    this._productcategorylist = []
    this._productlist = []
    this.productcatid = []
    this.productid = []
    this._ReportLists = []
    if (!this.producttypeid || !this.producttypeid.length) {
      this._CustomExceptionService.handleWarning("Please Select At Least One Product Type")
      return
    }
    let TaskName = "GetProductCategoryList"
    let Data = {
      "TaskName": TaskName,
      "ProductTypeId": this.producttypeid || [],
      "ProductCategoryId": this.productcatid || [],
      "ProductId": this.productid || [],
      "BrandId": this.brandid || [],
    }
    this._IsProgressSpinner = true
    this.RptMarkupLevelService.PageOnLoad(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productcategorylist = resultJSON.productCategoryList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getProduct() {
    this._productlist = []
    this.productid = []
    this._ReportLists = []
    if (!this.producttypeid || !this.producttypeid.length) {
      this.productcatid = []
      this._CustomExceptionService.handleWarning("Please Select At Least One Product Type")
      return
    }
    if (!this.productcatid || !this.productcatid.length) {
      this._CustomExceptionService.handleWarning("Please Select At Least One Product Category")
      return
    }
    let TaskName = "GetProductList"
    let Data = {
      "TaskName": TaskName,
      "ProductTypeId": this.producttypeid || [],
      "ProductCategoryId": this.productcatid || [],
      "ProductId": this.productid || [],
      "BrandId": this.brandid || [],
    }
    this._IsProgressSpinner = true
    this.RptMarkupLevelService.PageOnLoad(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._productlist = resultJSON.productList;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GenerateReport() {
    let TaskName: string = ''
    // if (!this.brandid || !this.brandid.length || this.brandid.length == 0) {
    //   TaskName = "GetProductWiseReport"
    // } else {
    //   TaskName = "GetBrandWiseReport"
    //   this.producttypeid = []
    //   this.productcatid = []
    //   this.productid = []
    // }
    TaskName = 'GetProductWiseReport';

    let Data = {
      "TaskName": TaskName,
      "ProductTypeId": this.producttypeid || [],
      "ProductCategoryId": this.productcatid || [],
      "ProductId": this.productid || [],
      "BrandId": this.brandid || [],
    }
    this._IsProgressSpinner = true
    this.RptMarkupLevelService.PageOnLoad(Data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ReportLists = resultJSON.generateReport;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear() {
    this._ReportLists = []
    this._productcategorylist = []
    this._productlist = []
    this.producttypeid = []
    this.productcatid = []
    this.productid = []
    this.brandid = []
  }
  exportExcel() {
    let AssetRegistrationList = this.GetAssetRegistrationList();
    this.exportUtility.exportExcel(AssetRegistrationList, 'Product_Markup_Level_Report', 'xlsx');
  }
  ExportToPdf() {
    let finalizedList = this.GetAssetRegistrationList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Product Type',
      'Product Category',
      'Product',
      'Product Code',
      'Brand',
      'Cost Price',
      'RETAIL 1',
      'RETAIL 2',
      'ONLINE',
      'WHOLESALE',
      'SPECIAL',
      'PROJECT',
      'LAY BUY'
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Product_Markup_Level_Report.pdf');
      })
    })
  }
  GetAssetRegistrationList() {
    let AssetRegistrationList = [];
    if (!this._ReportLists || !this._ReportLists.length) {
      this._CustomExceptionService.handleWarning("No Data Available");
      return
    }
    this._ReportLists.forEach(element => {
      let assetregistration: any = {};
      assetregistration["Product Type"] = element.producttypename;
      assetregistration["Product Category"] = element.productcategoryname;
      assetregistration["Product"] = element.productname;
      assetregistration["Product Code"] = element.productcode;
      assetregistration["Brand"] = element.brandname;
      assetregistration["Cost Price"] = element.costprice;
      assetregistration["RETAIL 1"] = element.retail1;
      assetregistration["RETAIL 2"] = element.retail2;
      assetregistration["ONLINE"] = element.online;
      assetregistration["WHOLESALE"] = element.wholsale;
      assetregistration["SPECIAL"] = element.special;
      assetregistration["PROJECT"] = element.project;
      assetregistration["LAY BUY"] = element.laybuy;
      AssetRegistrationList.push(assetregistration);
    });
    return AssetRegistrationList;
  }
}