<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Purchase Return </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" type="button"
                                [disabled]="isDisabledsave" (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Clear" icon="pi pi-trash" (click)="reset($event)"
                                class="p-button-danger"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check"
                                [disabled]="isDisabledsendapparoval" (click)="SendToApproval($event)"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_purchasereturnform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="purchasereturndate"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="1980:2050"
                                        [disabled]="isDisablededit" [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="purchasereturndate">purchase Return Date<span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_purchasereturnform.get('purchasereturndate').touched || _submitted) && _purchasereturnform.get('purchasereturndate').errors?.SelectDate">
                                    Please Select Return Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_suppliers"
                                        (onChange)="OnSupplierChange($event)" [filter]="true" filterBy="suppliername"
                                        optionValue="supplierid" optionLabel="suppliername" itemSize="15"
                                        formControlName="supplierid">
                                    </p-dropdown>
                                    <label for="supplierid">Supplier Name<span class="hlt-txt">*</span> </label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_temppurchasereturnrefno"
                                        [(ngModel)]="invoiceno" [disabled]="isDisablededit"
                                        (onChange)="OnInvoiceChange($event)" [filter]="true" filterBy="invoiceno"
                                        optionValue="invoiceno" optionLabel="invoiceno" formControlName="invoiceno">
                                    </p-dropdown>
                                    <label for="invoiceno">Invoice No<span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_purchasereturnform.get('invoiceno').touched || _submitted) && _purchasereturnform.get('invoiceno').errors?.SelectInvoiceNumber">
                                    Please Select Invoice No.
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="currencyname" disabled>
                                    <label for="currencyname">Currency </label>
                                </span>
                            </td>
                            <td style="width:20%">
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox formControlName="isreplacementreturn" label="Is Replacement Return"
                                            binary="true"></p-checkbox>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_ReturnReasons"
                                         [filter]="true" filterBy="metasubdescription"
                                        optionValue="metasubcode" optionLabel="metasubdescription"
                                        formControlName="returnreason">
                                    </p-dropdown>
                                    <label for="returnreason">Return Reason<span class="hlt-txt">*</span> </label>
                                </span>
                                <span class="text-danger"
                                *ngIf="(_purchasereturnform.get('returnreason').touched || _submitted) && _purchasereturnform.get('returnreason').errors?.PurchaseReturnReasonRequired">
                                Please Select Return Reason.
                            </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-table [value]="_ProductDetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['productname','variantdescription']" [rowHover]="true"
                        dataKey="productid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <!-- <th>Lot No</th> -->
                                <th>Severity</th>
                                <th>Invoice Quantity</th>
                                <th>Current Stock</th>
                                <th>Return Quantity</th>
                                <th>Return Reason</th>
                                <th>UOM</th>
                                <th>Unit Price</th>
                                <th>Return Product Amount</th>
                                <th [hidden]="true">Tax ID</th>
                                <th [hidden]="true">Tax Percentage</th>

                                <th>Tax Amount</th>
                                <th>Final Amount</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_ProductDetail>
                            <tr>
                                <td>
                                    {{_ProductDetail.productname}}
                                </td>
                                <td>
                                    {{_ProductDetail.variantname}}
                                </td>
                                <!-- <td>
                                    {{_ProductDetail.lotnumber}}
                                </td> -->
                                <td>
                                    {{_ProductDetail.severityname}}
                                </td>
                                <td>
                                    {{_ProductDetail.quantity}}
                                </td>
                                <td>
                                    {{_ProductDetail.currentstock}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off"
                                                (onBlur)="allocatequantity(_ProductDetail,$event)"
                                                *ngIf="_ProductDetail.uom != 'MSC00039'&& _action != 'view' "
                                                (keyup)="allocatequantity(_ProductDetail,$event)"
                                                (keyup.enter)="allocatequantity(_ProductDetail,$event)"
                                                (Keydown.enter)="allocatequantity(_ProductDetail,$event)"
                                                (focusout)="allocatequantity(_ProductDetail,$event)" maxlength="13"
                                                (keypress)="keypress.kpNumber($event)"
                                                [(ngModel)]="_ProductDetail.returnquantity" inputId="minmaxfraction"
                                                mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off"
                                                (onBlur)="allocatequantity(_ProductDetail,$event)"
                                                *ngIf="_ProductDetail.uom == 'MSC00039'&& _action != 'view' "
                                                (keyup)="allocatequantity(_ProductDetail,$event)"
                                                (keyup.enter)="allocatequantity(_ProductDetail,$event)"
                                                (Keydown.enter)="allocatequantity(_ProductDetail,$event)"
                                                (focusout)="allocatequantity(_ProductDetail,$event)" maxlength="13"
                                                (keypress)="keypress.kpNumber($event)"
                                                [(ngModel)]="_ProductDetail.returnquantity" inputId="minmaxfraction"
                                                mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                            </p-inputNumber>
                                            <div *ngIf="_action == 'view'">{{_ProductDetail.returnquantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_ProductDetail.returnquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [(ngModel)]="_ProductDetail.returnquantity" pInputText
                                                (keyup)="allocatequantity(_ProductDetail,$event)"
                                                *ngIf="_action != 'view'">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_ProductDetail.returnquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Returnreason"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                [disabled]="true" [(ngModel)]="_ProductDetail.returnreason">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                [options]="_Returnreason" optionLabel="metasubdescription"
                                                optionValue="metasubcode" [disabled]="true"
                                                [(ngModel)]="_ProductDetail.returnreason">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_ProductDetail.uomname}}
                                </td>
                                <td>
                                    {{_ProductDetail.unitprice | number: '1.3-4' }}
                                </td>
                                <td>
                                    {{_ProductDetail.transactionamount | number: '1.3-4' }}
                                </td>
                                <td [hidden]="true">
                                    {{_ProductDetail.taxid}}
                                </td>
                                <td [hidden]="true">
                                    {{_ProductDetail.Percentage}}
                                </td>
                                <td>
                                    {{_ProductDetail.taxamount | number: '1.3-4' }}
                                </td>
                                <td>
                                    {{_ProductDetail.purchasereturnamount | number: '1.3-4' }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <form [formGroup]="_purchasereturnform">
                    <div class="sub-title">
                        <div class="row">
                            <div class="col-md-5">
                                <h5>Payment Details</h5>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                    </div>
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">

                                <span class="p-float-label">
                                    <input type="text" name="transactionamount" formControlName="transactionamount"
                                        disabled pInputText>
                                    <label for="transactionamount">Gross Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="taxamount" formControlName="taxamount" disabled pInputText>
                                    <label for="taxamount">Tax Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="purchasereturnamount"
                                        formControlName="purchasereturnamount" disabled pInputText>
                                    <label for="purchasereturnamount">Net Amount</label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <td>
                                <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                            </td>
                        </tr>
                    </table>
                </form>
                <!-- <table class="normal-table">
                    <tr>
                        <td>
                            <button pButton pRipple  label="Send To Approval"
                                (click)="SendToApproval($event)" icon="las la-check"></button>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </table> -->
            </div>

        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>