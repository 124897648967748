import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { SalesinvoicetaxService } from 'src/app/core/Services/sales/salesinvoicetax.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Salesinvoicetaxview } from 'src/app/core/Views/Salesinvoicetaxview';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { HotKeyIntegration } from 'src/assets/js/hot-key-integration';

@Component({
  selector: 'app-v-consignmentcustomerinvoice',
  templateUrl: './v-consignmentcustomerinvoice.component.html',
  styleUrls: ['./v-consignmentcustomerinvoice.component.css']
})
export class VConsignmentcustomerinvoiceComponent implements OnInit {

  selectedrows: any;
	pSelectableRow: any;
	_Add: Boolean;
	_Delete: Boolean;
	_Update: Boolean;
	_View: Boolean;
	_Approve: Boolean;
	_Print: Boolean;
	_Series: Boolean;
	_PriceHistory: Boolean;
	_Profit: Boolean;
	_roleid: number;
	_Action: IAction;
	fromdate: Date;
	todate: Date;
	datepipe = new DatePipe('en-US');
	_IsProgressSpinner: boolean = false;
	_Saleinvoicetaxviews: Salesinvoicetaxview[];
	_Saleinvoicetaxview: Salesinvoicetaxview;
	_userid = sessionStorage["userid"];
	_branchid = sessionStorage["currentbranchid"]
	DATE: any
	cols: any[];
	mindate: Date;
	maxdate: Date;
	_invoicetype: string = "CON"
	constructor(
		private fb: FormBuilder,
		private _SalesInvoiceTaxService: SalesinvoicetaxService,
		private router: Router,
		private utility: CommonUtilities,
		private confirmationService: ConfirmationService,
		private _CustomExceptionService: CustomExceptionService,
		private _AccessRightsService: AccessRightsService,
		private _hotkeysService: HotKeyIntegration<void>,
		private exportUtility: ExportUtility
	) { }
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this._Action = this._AccessRightsService.getAccessRights(55);
		this._roleid = parseInt(sessionStorage["currentRole"]);
		this._hotkeysService.Navigate(event => {
			this.GoToCreate(event);
		}, this._Action, 'right');
		this.SetFromToDate();
		this.FetchAll();
		this.cols = [
			{ field: 'salesinvoiceno', header: 'Invoice No' },
			{ field: 'salesinvoicedate', header: 'Invoice Date' },
			{ field: 'customername', header: 'Customer Name' },
			{ field: 'amountinusd', header: 'Invoice Amount' },
			{ field: 'statusname', header: 'Status' }
		]
	}
	SetFromToDate() {
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		if (sessionStorage['Environmentenddate'] == "null") {
			let date = new Date();
			date.setDate(date.getDate() - 1);
			this.fromdate = date;
			this.todate = new Date();
			this.maxdate = new Date();
		}
		else {
			let date = new Date(sessionStorage['Environmentenddate']);
			date.setDate(date.getDate() - 1);
			this.fromdate = date;
			this.todate = new Date(sessionStorage['Environmentenddate']);
		}
	}
	FetchAll() {
		this._IsProgressSpinner = true;
		let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
		let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
		let startTime = performance.now();
		this._Saleinvoicetaxviews = [];
		this._SalesInvoiceTaxService.FetchAll(this._branchid, this._userid, fromdate, todate, this._roleid, this._invoicetype).subscribe((result) => {
			let endTime = performance.now();
			let totalTime = endTime - startTime;// time took to run in milliseconds
			startTime = performance.now();
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._Saleinvoicetaxviews = resultJSON.salesinvoiceviews;
			this._IsProgressSpinner = false;
			endTime = performance.now();
			totalTime = endTime - startTime;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	edit(event) {
		this.router.navigate(['/cConsignmentCustomerInvoice'], { state: { salesinvoiceno: event.salesinvoiceno, action: 'edit' } });
	}
	view(event) {
		this.router.navigate(['/cConsignmentCustomerInvoice'], { state: { salesinvoiceno: event.salesinvoiceno, action: 'view' } });
	}
	Cancel(event) {
		let salesinvoiceno = event.salesinvoiceno;
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.Cancel(salesinvoiceno, this._branchid, this._userid).subscribe((response: any) => {
			if (response.tranStatus && response.tranStatus.result) {
				this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
				this.FetchAll();
			} else {
				if (response.tranStatus.lstErrorItem && response.tranStatus.lstErrorItem.length) {
					this._CustomExceptionService.handleError(response.tranStatus.lstErrorItem[0].message)
				} else { this._CustomExceptionService.handleError("Error Occurred.") }
			}
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	confirm(event) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to cancel this Invoice <b>"' + event.salesinvoiceno + '"</b> ?',
			accept: () => { //Actual logic to perform a confirmation
				this.Cancel(event);
			}
		});
	}
	GoToCreate(event) {
		this.router.navigate(['/cConsignmentCustomerInvoice']);
	}
	exportExcel() {
		let finalizedList = this.GetFinalizedList();
		this.exportUtility.exportExcel(finalizedList, 'Sales_Invoice_Tax', 'xlsx');
	}
	ExportToPdf() {
		// let finalizedList = this.GetFinalizedList();
		// this.exportUtility.ExportToPdf(finalizedList, 'Sales_Invoice_Tax.pdf');
		if (!this._Saleinvoicetaxviews || !this._Saleinvoicetaxviews.length)
			return;
		let finalizedList = this.GetFinalizedList();
		const data = finalizedList.map(Object.values);
		const head = [[
			'Invoice No',
			'Invoice Date',
			'Customer Name',
			'Currency',
			'Invoice Amount',
			'Applied Credit Note/Invoice',
			'Status',
			'Created by']]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					},
					headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					},
					bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('Sales_Invoice_Tax.pdf');
			})
		})
	}
	GetFinalizedList() {
		let finalizedList = [];
		this._Saleinvoicetaxviews.forEach(element => {
			let quotes: any = {};
			quotes["Invoice No"] = element.salesinvoiceno;
			quotes["Invoice Date"] = this.datepipe.transform(element.salesinvoicedate, 'dd/MM/yyyy');
			quotes["Customer Name"] = element.customername;
			quotes["Currency"] = element.currencyname;
			quotes["Invoice Amount"] = element.amountinusd;
			quotes["Applied Credit Note/Invoice"] = element.creditnoteinvoiceno || '';
			quotes["Status"] = element.statusname;
			quotes["Created by"] = element.username;
			finalizedList.push(quotes);
		});
		return finalizedList;
	}
	Print(event) {
		var salesinvoiceno = event.salesinvoiceno;
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.Print({ "sino": salesinvoiceno, "branchid": this._branchid }).subscribe((result: any) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			let url: any = URL.createObjectURL(result);
			window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError("PRICE MISMATCH PLEASE CONTACT SUPER ADMIN PERSON"); this._IsProgressSpinner = false; });
	}

}
