import { Injectable } from '@angular/core';
import { IAction } from '../../security/IActions';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ConsignmentdeliverynoteService {

  public _Action: IAction;
  public _DeliverynoteService = environment.ConsignmentDeliveryNoteService;
  public _httpOptions;
  token: any;
  header: any;
  constructor(private http: HttpClient, private _CustomExceptionService: CustomExceptionService) {
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token, 2)
  }
  createHeaders(token: any, type: number): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here	
    });
  }

  PageOnLoad(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._DeliverynoteService + '/PageOnLoad';
    return this.http.post(Query, obj, { headers });
  }

  Create(Data: object) {
    const headers = this.header;
    var Query = this._DeliverynoteService + '/Create';
    return this.http.post<{ data: any }>(Query, Data, { headers });
  }
  Print(obj: object) {
		const headers = this.header;
		var Query = this._DeliverynoteService + '/Print';
		return this.http.post(Query, obj, { responseType: 'blob', headers });
  
  }
  Search(obj: object) : Observable<any> {
		const headers = this.header;
		var Query = this._DeliverynoteService + '/Search';
	  return this.http.post<{ data: any }>(Query, obj, { headers });
	}
  Cancel(obj: object) : Observable<any> {
		const headers = this.header;
		var Query = this._DeliverynoteService + '/Cancel';
	  return this.http.post<{ data: any }>(Query, obj, { headers });
	}
  ConsignmentStockReport(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._DeliverynoteService + '/ConsignmentStockReport';
    return this.http.post(Query, obj, { headers });
  }

}
