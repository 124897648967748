import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { NotificationService } from 'src/app/core/Services/masters/notification.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-v-notification',
  templateUrl: './v-notification.component.html',
  styleUrls: ['./v-notification.component.css']
})
export class VNotificationComponent implements OnInit {
  _Notificationsview: any[] = [];
  _NotificationsSenditmesview: any[] = [];
  selectedrows: any;
  _Action: IAction;
  _IsProgressSpinner: boolean = true;
  _Userid: number = sessionStorage["userid"]
  _isviewed: boolean = true;
  OpenAttachmentPopup:boolean;
  Fromname:string;
  Toname:string;
  Body:string;
  Subject:string;
  MessageDate:Date;
  AttachmentLink: any[] = [];
  safeHtml: SafeHtml;
  imageselect: string;
  Attachment: SafeUrl;
  selectedtab: number = 0;
  constructor(private _NotificationService: NotificationService, private router: Router,private sanitizer: DomSanitizer,
    private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private _CustomExceptionService: CustomExceptionService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this.utility.pageLoadScript();
    this._Action = this._AccessRightsService.getAccessRights(162);
    this._NotificationService.FetchAll(this._Userid, this._isviewed).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Notificationsview=resultJSON.notificationviewlst;
      this.SendItemsFetchall();
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  SendItemsFetchall(){
    this._IsProgressSpinner = true;
    this._NotificationService.SendItemsFetchAll(this._Userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._NotificationsSenditmesview=resultJSON.notificationsenditemlst;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event){
    this._IsProgressSpinner = true;
    this._NotificationService.View(event.msgid,this._Userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.AttachmentLink=resultJSON.messageattachmentlst;
      this.Fromname=resultJSON.messageheaderlst[0].fromname;
      this.Toname=resultJSON.messageheaderlst[0].toname;
      this.Subject=resultJSON.messageheaderlst[0].subject;
      this.MessageDate=resultJSON.messageheaderlst[0].messagedate;
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(resultJSON.messageheaderlst[0].body)
      event.isviewed=true;
      // for (let i = 0; i < this._Notificationsview.length; i++) {
      //   if (this._Notificationsview[i].msgid==event.msgid) {
      //     this._Notificationsview[i].isviewed=true;
      //   }
      // }
      this._IsProgressSpinner = true;
      this._NotificationService.ViewedNotification(event.toid, event.msgid).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        if(resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined))
        {
          this._IsProgressSpinner = false;
        }
      })

      this._Notificationsview
      this._IsProgressSpinner = false;
      this.OpenAttachmentPopup=true;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  closeAttachmentPopup(){
    this.OpenAttachmentPopup = false;
    window.location.reload();
  }
  OpenAttachment(AL) {
    let url = null;
    if (AL.attachmenturl) {
      this.Attachment = this.sanitizer.bypassSecurityTrustUrl(AL.attachmenturl);
    }
    url =AL.attachmenturl;

    if (!url)
      return;

    window.open(url, "MsgWindow", "width=800,height=800");

    // let attachment = AL.attachmentname
    // if (attachment) {
    //   url = attachment;
    //   window.open(url, "MsgWindow", "width=800,height=800");
    // }
  
}
}
