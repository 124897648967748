import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { ConfirmationService } from 'primeng/api';
import { Branch } from 'src/app/core/Models/Branch';
import { Customer } from 'src/app/core/Models/Customer';
import { ConsignmentdeliverynoteService } from 'src/app/core/Services/consignmentcustomer/consignmentdeliverynote/consignmentdeliverynote.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { HotKeyIntegration } from 'src/assets/js/hot-key-integration';

@Component({
  selector: 'app-v-consignmentdeliverynote',
  templateUrl: './v-consignmentdeliverynote.component.html',
  styleUrls: ['./v-consignmentdeliverynote.component.css']
})
export class VConsignmentdeliverynoteComponent implements OnInit {

  selectedrows: any;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Print: Boolean;
  _Series: Boolean;
  _roleid: number;
  _Action: IAction;
  fromdate: Date;
  todate: Date;
	datepipe = new DatePipe('en-US');
	_IsProgressSpinner: boolean = false;
	_Consignmentdeliverynoteview: any[];
	_Consignmentdeliverynoteviews: any[];
	_Customerlist: Customer[] = [];
	_Bholabranch: Branch[] = [];
	_userid = sessionStorage["userid"];
	_branchid = sessionStorage["currentbranchid"]
	cols: any[];
	DATE: any
	mindate: Date;
	maxdate: Date;
	_deliverynoteid=0;
  constructor(
		private fb: FormBuilder,
		private _ConsignmentdeliverynoteService: ConsignmentdeliverynoteService,
		private router: Router,
		private utility: CommonUtilities,
		private confirmationService: ConfirmationService,
		private _CustomExceptionService: CustomExceptionService,
		private _AccessRightsService: AccessRightsService,
		private _hotkeysService: HotKeyIntegration<void>,
		private exportUtility: ExportUtility
	) { }

  ngOnInit(): void {
	this._Action = this._AccessRightsService.getAccessRights(372);
	this.utility.pageLoadScript();
	this.mindate = new Date(sessionStorage['Environmentstartdate']);
	this.maxdate = new Date(sessionStorage['Environmentenddate']);
	if (sessionStorage['Environmentenddate'] == "null") {
		this.fromdate = new Date();
		this.todate = new Date();
		this.maxdate = new Date();
	}
	else {
		this.fromdate = new Date(sessionStorage['Environmentenddate']);
		this.todate = new Date(sessionStorage['Environmentenddate']);
	}
	this.fromdate.setDate(this.fromdate.getDate() - 1);
	this.FetchAll()
}


	FetchAll() {
		 this._IsProgressSpinner = true;
		 let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
		 let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');

		 this._Consignmentdeliverynoteview = [];
		 this._IsProgressSpinner = true;
		 var obj = { 	
			"BholaBranchId":this._branchid, 
			"FromDate":fromdate,
			"ToDate":todate,
		 };
	this._ConsignmentdeliverynoteService.Search(obj).subscribe((result) => {
		const resultJSON = JSON.parse(JSON.stringify(result));
		console.log(resultJSON);
		this._Consignmentdeliverynoteviews = resultJSON.consignmentdeliverynoteviews;
	
		this._IsProgressSpinner = false;
	  },
		error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}



	edit(event) {
		this.router.navigate(['/cConsignmentDeliverynote'], { state: { _ConsignmentDeliveryNoteId: event.consignmentdeliverynoteid, action: 'edit' } });
	}
	view(event) {
		this.router.navigate(['/cConsignmentDeliverynote'], { state: { _ConsignmentDeliveryNoteId: event.consignmentdeliverynoteid, action: 'view' } });
	}
	Cancel(event) {
		var consignmentdeliverynoteid = event.consignmentdeliverynoteid;
		this._IsProgressSpinner = true;
		this._ConsignmentdeliverynoteService.Cancel({ "ConsignmentDeliveryNoteId": consignmentdeliverynoteid, "UserId": this._userid }).subscribe((response: any) => {
			if (response.tranStatus && response.tranStatus.result) {
				this._CustomExceptionService.handleSuccess(usererrors.Cancel_Success_00);
				this.FetchAll();
			} else {
				if (response.tranStatus.lstErrorItem && response.tranStatus.lstErrorItem.length) {
					this._CustomExceptionService.handleError(response.tranStatus.lstErrorItem[0].message)
				} else { this._CustomExceptionService.handleError("Error Occurred.") }
			}
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	confirm(event) {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to cancel this Invoice <b>"' + event.consignmentdeliverynoteno + '"</b> ?',
			accept: () => { //Actual logic to perform a confirmation
				this.Cancel(event);
			}
		});
	}
	GoToCreate(event) {
		this.router.navigate(['/cConsignmentDeliverynote']);
	}
	exportExcel() {
		let finalizedList = this.GetFinalizedList();
		this.exportUtility.exportExcel(finalizedList, 'Consignment_Delivery_Note', 'xlsx');
	}
	ExportToPdf() {
		if (!this._Consignmentdeliverynoteviews || !this._Consignmentdeliverynoteviews.length)
			return;
		let finalizedList = this.GetFinalizedList();
		const data = finalizedList.map(Object.values);
		const head = [[
			'Delivery Note No',
			'Delivery Note Date',
			'Customer Name',
			'Customer Branch',
			'Bhola Branch',
			'Status',
			'Created By',
			'Created On',
			'Modified By',
		'Modified On']]
		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(x => {
				const doc = new jsPDF.default('l', 'mm', 'a4');
				autoTable(doc, {
					head: head,
					body: data,
					tableLineColor: [0, 0, 0],
					tableLineWidth: 0,
					styles: {
						lineColor: [0, 0, 0],
						lineWidth: 0.1,
					},
					headStyles: {
						fillColor: [211, 211, 211],
						textColor: 0
					},
					bodyStyles: {
						textColor: 0
					},
					didDrawCell: (data) => { },
				});
				doc.save('Consignment_Delivery_Note.pdf');
			})
		})
	}
	GetFinalizedList() {
		let finalizedList = [];
		this._Consignmentdeliverynoteviews.forEach(element => {
			let quotes: any = {};
			quotes["Delivery Note No"] = element.consignmentdeliverynoteno;
			quotes["Delivery Note Date"] = this.datepipe.transform(element.consignmentdeliverynotedate, 'dd/MM/yyyy');
			quotes["Customer Name"] = element.customername;
			quotes["Customer Name"] = element.customerbranchname;
			quotes["Bhola Branch"] = element.bholabranchname;
			quotes["Status"] = element.status;
			quotes["Create By"] = element.createdbyname || '';
			quotes["Created On"] = this.datepipe.transform(element.createdon, 'dd/MM/yyyy');
			quotes["Modified By"] = element.modifiedbyname;
			quotes["Modified On"] = element.modifiedon && element.modifiedon !== '0001-01-01T00:00:00' 
            ? this.datepipe.transform(element.modifiedon, 'dd/MM/yyyy') 
            : '';
			finalizedList.push(quotes);
		});
		return finalizedList;
	}
	Print(event) {
		var consignmentdeliverynoteid = event.consignmentdeliverynoteid;
		this._IsProgressSpinner = true;
		this._ConsignmentdeliverynoteService.Print({ "ConsignmentDeliveryNoteId": consignmentdeliverynoteid, "BranchId": this._branchid }).subscribe((result: any) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			let url: any = URL.createObjectURL(result);
			window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

}



