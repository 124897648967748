import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Branch } from 'src/app/core/Models/Branch';
import { Customer } from 'src/app/core/Models/Customer';
import { Customerbranchdetail } from 'src/app/core/Models/Customerbranch';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Customerbranchaddressdetail } from 'src/app/core/Models/customerbranchaddressdetail';
import { ProductCategory } from 'src/app/core/Models/overallclass';
import { ConsignmentdeliverynoteService } from 'src/app/core/Services/consignmentcustomer/consignmentdeliverynote/consignmentdeliverynote.service';
import { ProductFreezingService } from 'src/app/core/Services/inventory/product-freezing.service';
import { ProductRequestService } from 'src/app/core/Services/inventory/product-request.service';
import { RptbranchwisestockService } from 'src/app/core/Services/reports/Stock/RptBranchwiseStock/rptbranchwisestock.service';
import { SalesinvoicetaxService } from 'src/app/core/Services/sales/salesinvoicetax.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { KeyPress } from 'src/assets/js/KeyPress';
import { CommonUtilities } from 'src/assets/js/common-utilities';

@Component({
  selector: 'app-c-consignmentdeliverynote',
  templateUrl: './c-consignmentdeliverynote.component.html',
  styleUrls: ['./c-consignmentdeliverynote.component.css'],
})
export class CConsignmentdeliverynoteComponent implements OnInit {
  _IsProgressSpinner: boolean = false;
  _deliverynoteform: FormGroup;
  _deliverynotedetailform: FormGroup;
  _Action: IAction;
  _action: string = 'create';
  _deliverynoteid: number;
  mindate: Date;
	maxdate: Date;
  _Customerlist: Customer[] = [];
  _Consignmentbranch: any[];
  _Tempconsignmentbranch: Customerbranchaddressdetail[];
  _TempConsignmentbranchaddresslist: Customerbranchaddressdetail[];
  _Consignmentbranchaddresslist: any[];
  _Bholabranch: Branch[] = [];
  _Producttypelist: Producttype[];
  _productcategorylist: Productcategory[] = [];
  producttypeid:number;
  productcatid: number[];
  filteredProducts: any[] = [];
  _selectedproduct: number;
  producttypeids: any[] = [];
  hidequantity: boolean = false;
  customerbranchdetail: Customerbranchdetail[] = [];
  _Customeraddressdetail: Customerbranchaddressdetail[] = [];
  _customeraddress: any;
  _Contactperson: string;
  _Mobileno: string;
  _Mailid: string;
  _Addressid: number;
  _branchid = sessionStorage["currentbranchid"];
  _Userid = sessionStorage["userid"]
  _ProductObj: any;
  _Productheader: Productheader[] = [];
  _Productcategory: any[] = [];
  _productname: Productheader[];
  _tempproductname: Productheader[];
  _Producttypename: string;
  _Productcategoryname: string;
  _submitted1: Boolean = false;
  _submitted: Boolean = false;
  _ProductNames: Productoverallview[] = [];
	_tempproductnames: Productoverallview[] = [];
  _Productdetails: any[] = [];
  _isproductgrid: boolean = false;
  Consignmodel: any = {};
  Ans = 0

  constructor(
    private router: Router,
    private utility: CommonUtilities,
    private fb: FormBuilder,
    public keypress: KeyPress,
    private _CustomExceptionService: CustomExceptionService,
    private _ConsignmentdeliverynoteService: ConsignmentdeliverynoteService,
    private _AccessRightsService: AccessRightsService,
    private _RptbranchwisestockService: RptbranchwisestockService,
    private _SalesInvoiceTaxService: SalesinvoicetaxService,
    public productRequestService: ProductRequestService,
  ) {
    // this._deliverynoteid = history.state.consignmentdeliverynoteid ? history.state.consignmentdeliverynoteid : 0;
    this._deliverynoteid = history.state?._ConsignmentDeliveryNoteId ? history.state?._ConsignmentDeliveryNoteId : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
  }
  InitializeForm() {
    this._deliverynoteform = this.fb.group({
      consignmentdeliverynoteid: [0],
      consignmentdeliverynoteno: [''],
      consignmentdeliverynotedate: [],
      customerid: [],
      customerbranchid: [],
      customeraddressid: [],
      status: ['MSC00047'],
      bholabranchid: [],
      remarks: [],
      rv: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      consignmentdeliverynotedetails:[]
    });

    if (sessionStorage['Environmentenddate'] == 'null') {
      this._deliverynoteform.get('consignmentdeliverynotedate').setValue(new Date());
    } else {
      let date = new Date(sessionStorage['Environmentenddate']);
      this._deliverynoteform.get('consignmentdeliverynotedate').setValue(date);
    }
    this._deliverynotedetailform = this.fb.group({
      consignmentdeliverynotedetailid: [0],
      consignmentdeliverynoteid: [0],
      productid: [],
      quantity: [],
      stockinhand: [],
    });
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(372);
    this.hidequantity = true;
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();
    // var deliverynoteid = this._deliverynoteform.get("consignmentdeliverynoteid").value || 0;
    this._IsProgressSpinner = true;
    var obj = { 
      "ConsignmentDeliveryNoteId": this._deliverynoteid 
    };
    this._ConsignmentdeliverynoteService.PageOnLoad(obj).subscribe((result) =>
    {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Customerlist = resultJSON.customerslist;
      this._Tempconsignmentbranch = resultJSON.customerbranchlist || [];
      this._TempConsignmentbranchaddresslist = resultJSON.customerbranchaddressdetaillist || [];
      this._Producttypelist = resultJSON.producttypeList;
      this.customerbranchdetail = resultJSON.customerbranchdetaillist || [];
      // this._Productheader = resultJSON.productheaderList || [];
      // this._Productcategory = resultJSON.productcategorylist || [];
      if (this._action == 'edit' || this._action == 'view') {
        this._submitted = false;
        this._Consignmentbranch = this._Tempconsignmentbranch;
        this._Consignmentbranchaddresslist = this._TempConsignmentbranchaddresslist;
        this._Productdetails = resultJSON.consignmentdeliverynotedetailsviews || [];
        resultJSON.consignmentdeliverynote.consignmentdeliverynotedate = new Date(resultJSON.consignmentdeliverynote.consignmentdeliverynotedate);
        // let deliverynote = resultJSON.consignmentdeliverynote;
        // if(deliverynote.length > 0)
        // {
          this._deliverynoteform.setValue(resultJSON.consignmentdeliverynote);
          this.OnselectAddress();
        // }
        
        this._deliverynoteform.disable();
        this._deliverynotedetailform.disable();
        this._isproductgrid = true;
      }
    })
    this._IsProgressSpinner = false;
  }

  // Product Category 
  getProductCategory(event) {
    var selectedProductType = event.value;
    this.productcatid = [];
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      let Data = 
      { 
        "Producttype": selectedProductType || [], 
        "branchid": parseInt(sessionStorage["currentbranchid"])
     };
      this._RptbranchwisestockService.getProductCategory(Data).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategorylist = resultJSON.productcategories;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  OnselectCustomer()
  {
    if(this.customerbranchdetail.length > 0)
    {
      this._Consignmentbranch = [];
      this._Contactperson = "";
      this._Mobileno = "";
      this._Mailid = "";
      this._deliverynoteform.get("customerbranchid").reset();
      this._Addressid = null;
      this._Consignmentbranchaddresslist = [];
      var customerid = this._deliverynoteform.get("customerid").value;
      var branch = this.customerbranchdetail.filter(c => c.customerid == customerid);
      var branchid = branch[0].customerbranchid;
      this._Consignmentbranch = this._Tempconsignmentbranch.filter(a => a.cutomerbranchid == branchid);
      // var branchid = this.customerbranchdetail.filter(a => a.customerid == customerid).map(a => a.customerbranchid);
      // this._Consignmentbranch = this._Tempconsignmentbranch.filter(a => branchid.includes(a.cutomerbranchid))
    }
    else
    {
      return null;
    }
    
  }

  OnselectCustomerBranch()
  {
    if(this._TempConsignmentbranchaddresslist.length > 0)
    {
      this._Consignmentbranchaddresslist = [];
      this._Contactperson = "";
      this._Mobileno = "";
      this._Mailid = "";
      var branchid = this._deliverynoteform.get("customerbranchid").value;
      // var detailid = this._Customeraddressdetail.filter(a => a.cutomerbranchid == branchid).map(a => a.cutomerbranchid);
      this._Consignmentbranchaddresslist = this._TempConsignmentbranchaddresslist.filter(a => a.cutomerbranchid == branchid);
    }
    else
    {
      return null;
    }
  } 

  OnselectAddress()
  {
    if(this._TempConsignmentbranchaddresslist.length > 0)
    {
      var detailid = this._Addressid;
      this._Contactperson = "";
      this._Mobileno = "";
      this._Mailid = "";
      this._Consignmentbranchaddresslist = this._TempConsignmentbranchaddresslist.filter(a => a.customerbranchaddressdetailid == detailid);
      this._Contactperson = this._Consignmentbranchaddresslist[0].contactperson;
      this._Mobileno = this._Consignmentbranchaddresslist[0].mobileno;
      this._Mailid = this._Consignmentbranchaddresslist[0].mailid;
    }
    else
    {
      return null;
    }
  } 

  filterProducts(event) {
		if (event.query == "" || event.query == null) {
			return;
		}
		this._IsProgressSpinner = true;
		this.productRequestService.GetProductSearch(event.query, this._branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON);
			this._ProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];
			this._tempproductnames = <Productoverallview[]>resultJSON.productsearchlist || [];
			let filtered: any[] = [];
			let query = (<string>event.query).toLowerCase();
			this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

  AddProduct(event) {

		this._submitted1 = true;
		if (!this._deliverynotedetailform.valid) return
		if (!this._deliverynotedetailform.get("productid").value.productid) {
			this._CustomExceptionService.handleWarning("Please select  Product Name");
			return
		}
		let available = this._deliverynotedetailform.get('stockinhand').value;
		let enterqnty = this._deliverynotedetailform.get('quantity').value;
		if (!enterqnty || enterqnty == 0) {
			this._CustomExceptionService.handleWarning("Please enter quantity");
			return
		}
		if (available < enterqnty) {
			this._CustomExceptionService.handleWarning("Quantity exceeds available quantity");
			return
		}
		if (this._deliverynotedetailform.valid) {
			var productid = parseInt(this._deliverynotedetailform.get("productid").value.productid);
			var productName = (this._ProductNames || []).filter(f => f.productid == productid)[0].productname;
			var uom = (this._ProductNames || []).filter(f => f.productid == productid)[0].salesuom;
      var producttype = this._Producttypename;
      var productcategory = this._Productcategoryname;

			var qty = this._deliverynotedetailform.get('quantity').value;

			var uomname = (this._ProductNames || []).filter(f => f.productid == productid)[0].salesuomname;
			var uomname = (this._ProductNames || []).filter(f => f.productid == productid)[0].salesuomname;
			var uomname = (this._ProductNames || []).filter(f => f.productid == productid)[0].salesuomname;
			let existingProduct = this._Productdetails.find(product => product.productid === productid);
			if (existingProduct) {
				this._CustomExceptionService.handleWarning(productName + " already exists");
				return;
			}
			let ProductRequst = {
				"productid": productid,
        "producttype":producttype,
        "productcategory":productcategory,
				"productname": productName,
				"uomid": uom,
				"uomname": uomname,
				"quantity": qty,
        "available":available
			}
			this._Productdetails.push(ProductRequst)

			this._Productdetails = [...this._Productdetails]
			this._deliverynotedetailform.reset();
      this._Producttypename = "";
      this._Productcategoryname = "";
			this._submitted1 = false
		}
	}

  getProductVariants(event) {
		var selectedProductVariant = event.productid;
		this._IsProgressSpinner = true;
		this.productRequestService.getProductVariants(selectedProductVariant, this._branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
		
			this._Producttypelist = resultJSON.producttypes;
			this._productcategorylist = resultJSON.productCategoriesList;
			let product = this._tempproductnames.filter(f => f.productid == selectedProductVariant)[0];
			this._Producttypename = product.producttypename;
			this._Productcategoryname = product.categoryname;
			this._deliverynotedetailform.get("quantity").reset();
			var uom = product.salesuom;
			if (uom == "MSC00037") {
				this.hidequantity = true;
			}
			else {
				this.hidequantity = false;
			}
			this._deliverynotedetailform.controls['stockinhand'].setValue(resultJSON.currentBranchStock.stock1);
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
  goBack(event: any) {
		this.router.navigate(['/vConsignmentDeliverynote']);
	}

  onSave()
  {
    let customer = this._deliverynoteform.get("customerid").value;
    let customerbranch = this._deliverynoteform.get("customerbranchid").value;
    if(!customer){
      this._CustomExceptionService.handleWarning("Please select the Customer");
      return;
    }
    if(!customerbranch)
    {
      this._CustomExceptionService.handleWarning("Please select the customers branch");
      return;
    }
    if(!this._Addressid)
    {
      this._CustomExceptionService.handleWarning("Please select the branch address");
      return;
    }
    if(this._Productdetails.length == 0)
    {
      this._CustomExceptionService.handleWarning("Please add one product ");
    }
    this._deliverynoteform.get("bholabranchid").setValue(this._branchid);
    this._submitted = true
    if (this._action == 'create') {
			if (this._deliverynoteform.valid) {
        if (this.Ans == 0) {
          this.showconfirm('Are you sure you want to proceed this stocks for consignment delivery?', () => {
            // this.InActive(obj);
          }, null)
          return
        }
        let deliverynote = this._deliverynoteform.getRawValue();
        deliverynote.createdby = this._Userid;
        deliverynote.createdon = new Date;
				
				let Data = {
					"ConsignmentDeliveryNote": deliverynote,
					"ConsignmentDeliveryNoteDetail": this._Productdetails
				}
				if (this._Productdetails.length == 0) { return }
				this._IsProgressSpinner = true;
				this._ConsignmentdeliverynoteService.Create(Data).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
          
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this.Reset();
            this.ngOnInit();
            this._Productdetails = [];
            this._IsProgressSpinner = false;
          } else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },
          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
		}
  }

  showconfirm(message: string, yesHandler: any, NoHandler?: any) {
    this.Consignmodel.showdialog = true;
    this.Consignmodel.message = message;
    this.Consignmodel.Yes = () => {
      if (typeof yesHandler === 'function')
        yesHandler();
      this.Consignmodel.showdialog = false;
      // this.confirm1('')
    };
    this.Consignmodel.Cancel = () => {
      if (typeof NoHandler === 'function')
        NoHandler();
      this.Consignmodel.showdialog = false;
      return
    };
  }
  Yes() {
    this.showconfirm('Are you sure to finalize this delivery note?', () => {
      // this.InActive(obj);
    }, null)
    this.Consignmodel.showdialog = false;
    this.Ans = 1;
    this.onSave();

  }
  Cancel() {
    this.Consignmodel.showdialog = false;
    return
  }

  Reset()
  {
    this._deliverynoteform.reset();
    this._deliverynotedetailform.reset();
    this._Producttypename = "";
    this._Productcategoryname = ""
    this._Productdetails = [];
    this._Contactperson = "";
    this._Mobileno = "";
    this._Mailid = "";
    this._Consignmentbranch = [];
    this._Consignmentbranchaddresslist = [];
  }

  Clear()
  {
    this._deliverynotedetailform.reset();
    this._Producttypename = "";
    this._Productcategoryname = ""
  }

  RemoveProduct(pProduct: any)
  {
    var index = this._Productdetails.indexOf(pProduct);
		this._Productdetails.splice(index, 1)
  }

}
