import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerconsignmentRoutingModule } from './customerconsignment-routing.module';
import { CConsignmentcustomerbranchComponent } from './consignmentcustomerbranch/c-consignmentcustomerbranch/c-consignmentcustomerbranch.component';
import { VConsignmentcustomerbranchComponent } from './consignmentcustomerbranch/v-consignmentcustomerbranch/v-consignmentcustomerbranch.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { CConsignmentdeliverynoteComponent } from './ConsignmentDeliverynote/c-consignmentdeliverynote/c-consignmentdeliverynote.component';
import { VConsignmentdeliverynoteComponent } from './ConsignmentDeliverynote/v-consignmentdeliverynote/v-consignmentdeliverynote.component';
import { CConsignmentcustomerinvoiceComponent } from './consignmentcustomerinvoice/c-consignmentcustomerinvoice/c-consignmentcustomerinvoice.component';
import { VConsignmentcustomerinvoiceComponent } from './consignmentcustomerinvoice/v-consignmentcustomerinvoice/v-consignmentcustomerinvoice.component';
import { ConsignmentstockreportComponent } from './consignmentstockreport/consignmentstockreport.component';
import { VConsignmentstockadjustmentComponent } from './ConsignmentStockAdjustment/v-consignmentstockadjustment/v-consignmentstockadjustment.component';
import { CConsignmentstockadjustmentComponent } from './ConsignmentStockAdjustment/c-consignmentstockadjustment/c-consignmentstockadjustment.component';


@NgModule({
  declarations: [
    CConsignmentcustomerbranchComponent, 
    VConsignmentcustomerbranchComponent, 
    CConsignmentdeliverynoteComponent, 
    VConsignmentdeliverynoteComponent, 
    CConsignmentcustomerinvoiceComponent, 
    VConsignmentcustomerinvoiceComponent, 
    ConsignmentstockreportComponent,
    VConsignmentstockadjustmentComponent,
    CConsignmentstockadjustmentComponent
  ],
  imports: [
    CommonModule,
    CustomerconsignmentRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChipsModule,
    FieldsetModule,
    AutoCompleteModule,
    OverlayPanelModule,
    CheckboxModule,
    PasswordModule,
    HttpClientModule,
    TabViewModule,
    EditorModule,
    ProgressSpinnerModule,
    KeyFilterModule,
    ImageCropperModule,
    DragDropModule
  ]
})
export class CustomerconsignmentModule { }
