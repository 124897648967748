import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Customer } from 'src/app/core/Models/Customer';
import { Customeraccountdetail } from 'src/app/core/Models/Customeraccountdetail';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from "ace-builds";
import { CustomerService } from 'src/app/core/Services/masters/customer.service';
import { Accountgroup } from 'src/app/core/Models/Accountgroup';
import { Country } from 'src/app/core/Models/Country';
import { Currency } from 'src/app/core/Models/Currency';
import { District } from 'src/app/core/Models/District';
import { Level } from 'src/app/core/Models/Level';
import { State } from 'src/app/core/Models/State';
import { Customeraddressdetail } from 'src/app/core/Models/Customeraddressdetail';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { vCustomerName, vMobileNumber, vAddress, vCountryNameSelect, vStateNameSelect, vDistrictNameSelect, vMarkuplevelSelect, vCurrencySelect, vContactPersonName, vEmailID } from 'src/app/core/Validators/validation';
import { Bank } from 'src/app/core/Models/Bankmaster';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { KeyPress } from 'src/assets/js/KeyPress';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
@Component({
	selector: 'app-c-default-customer',
	templateUrl: './c-default-customer.component.html',
	styleUrls: ['./c-default-customer.component.css']
})
export class CDefaultCustomerComponent implements OnInit {

	_limitcurrency: string;
	_customerform: FormGroup;
	_customeraddressdetailform: FormGroup;
	_customeraccountdetailform: FormGroup;

	_action: string = "create";
	_submitted = false;
	_addresssubmitted = false;
	displayBasic: boolean;

	_Customer: Customer;
	_Customeraddressdetail: Customeraddressdetail;
	_Customeraccountdetail: Customeraccountdetail;

	addressRowIndex: number = -1;

	_CustomersAddress: Customeraddressdetail[] = [];
	_CustomerAddress: Customeraddressdetail;


	_Accountgroups: Accountgroup; //5.Account Group load from account Group master. it should contains Account group name and Account group code.
	// 6.MarkUp level,Currency,Country,BankName,Sales Reference Name drop downs should be load in pageload 
	_Currencies: Currency[] = []; //8.Currency should load from Currecy Master(Currency Code, Currency Name)
	_Markuplevels: Level[] = [] //7.MarkUp level should load from Level Master(Level No, Level Name)
	_Customerstatus: Metadatum[] = [];
	_Customerseries: Metadatum[] = [];;
	_Countries: Country[] = [];; //11. Country should load from Country Master (Country Name, Country Code)
	_States: State[] = [];;
	_Cities: District[] = [];;
	_Accounttypes: Metadatum[] = [];;
	_Addresstype: Metadatum[] = [];;
	//_Salesman
	//radio button
	_PayModes: Metadatum[] = []
	_VatDetails: Metadatum[] = []
	_SpecificVatType: Metadatum[] = []
	_ShowVat: Metadatum[] = []
	_SpecificVatTax: Metadatum[] = []
	_CreditsApplicable: Metadatum[] = []
	selectedaddress: any;
	_customerid: number;
	_CustomerOBJ: any;
	isDisabledaddbuttn = false;
	selectedrows: Customeraddressdetail[] = [];;
	_IsProgressSpinner: boolean = true;
	isDisabledSave = false;
	isDisabledClear = false;
	isDisabledButton = false;
	isDisabledreset = false;
	// For Pop Up
	Banklist: Bank;
	EBank: Bank
	_bankcode: Bank
	_bankname: Bank
	selectedtab: number = 0;
	isDisabledlimit = false;
	isDisabledcreditdays = false;
	_Series: Boolean;
	_Action: IAction;
	showseries: boolean = true;
	disableseries: boolean = false;
	_IsSuperAdmin: boolean = false
	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(
		private utility: CommonUtilities,
		private fb: FormBuilder,
		public keypress: KeyPress,
		private _CustomerService: CustomerService,
		private _router: Router,
		private _CustomExceptionService: CustomExceptionService,
		private _hotkeysService: HotkeysService,
		private _AccessRightsService: AccessRightsService
	) { this.HotKeyIntegration(); }
	InitializeForm() {
		this._customerform = this.fb.group({
			companyid: [0],
			branchid: [sessionStorage["BranchID"]],
			customerid: [0],
			customercode: [],
			customername: ['', vCustomerName],
			paymode: [],
			accountgroupcode: [11],
			levelid: ['', vMarkuplevelSelect],
			currencyid: ['', vCurrencySelect],
			bpno: [],
			vatno: [],//vVatNumber
			vattype: [],
			vatpertcentage: [],
			isshowvat: [],
			specificvattype: [],
			specificvattax: [],
			creditapplicable: [],
			creditdays: [],
			creditlimit: [],
			rewardpoints: [],
			rewardpointsvalue: [],
			series: [],
			customerstatus: [],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			customeraddressdetails: [],
			customeraccountdetails: [],
			customerwisediscounts: [],
			savetype: ['MSC00187'],
			salesorders: [],
			salesquotations: [],
			salesreturns: [],
			tempSoA354g6er4g6d4vs: [],
			tempSq4654r6g4r6e4g8s: [],
			tempSrD2g4r5eg545e4rg45s: [],
			tempcreditdays: [],
			temprewardpoints: [],
			tempcreditlimit: [],
			approvalstatus: [],
			password: [],
			dob: [],
			gender: [],
			lastname: [],
			employeeid: [],
			userid: [],
			customerdocumentdetails: [],
			hobbies: [],
			otp: [],
			couponcode: [],
			currentusagelimitperuser: [],
			attachmenturl: [],
			attachmentname: [],
			isonlinecustomer: [false],
			ecdefaultmailid: [],
			ecdefaultmobileno: [],
			responsibleperson: [],
			categoryid: [],
			subcategoryid: [],
			countryid:[],
			reasonforcustomerhold: [],
			isconsignmentcustomer: []
		});
		this._customeraddressdetailform = this.fb.group({
			customeraddressdetailid: [0],
			customerid: [0],
			contactpersonname: ['', vContactPersonName],
			mobileno: ['', vMobileNumber],
			phoneno: [],
			address: ['', vAddress],
			mailid: ['', vEmailID],
			countryid: ['', vCountryNameSelect],
			stateid: ['', vStateNameSelect],
			districtid: ['', vDistrictNameSelect],
			faxno: [],
			addresstype: ['', vCountryNameSelect],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			isdefaultaddress: [],
			customer: [],
			pincode: [],
			lastname: [],
			suburbanid: [],
		});
		this._customeraccountdetailform = this.fb.group({
			customeraccountdetailid: [0],
			customerid: [0],
			bankcode: [],
			bankname: [],
			swiftcode: [],
			branchname: [],
			accountholdername: [],
			accountno: [],
			accounttype: [],
			accountingyear: [],
			accountstatus: ['MSC00001'],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			customer: []
		});
		this._customerform.controls['rewardpoints'].setValue("0");
		if (this._Currencies && this._Currencies.length)
			this._customerform.controls['currencyid'].setValue(this._Currencies[0].currencyid);
		if (this._Markuplevels && this._Markuplevels.length)
			this._customerform.controls['levelid'].setValue(this._Markuplevels[0].levelid);
		this._customerform.controls['customerstatus'].setValue("MSC00001");
		this._customerform.controls['series'].setValue("MSC00093");
		if (this._PayModes && this._PayModes.length)
			this._customerform.controls['paymode'].setValue(this._PayModes[0].metasubcode);
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(147);
		this.utility.pageLoadScript();
		this._customerid = history.state?.customerid ? history.state?.customerid : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.InitializeForm();
		this.checkseriesRights();
		this._IsProgressSpinner = true;
		this._CustomerService.PageOnLoad(this._customerid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._Accountgroups = resultJSON.accountGroups;
			this._Currencies = resultJSON.currencies;
			this._Markuplevels = resultJSON.markupLevels;
			this._Customerstatus = resultJSON.customerStatus;
			this._Customerseries = resultJSON.series;
			this._Countries = resultJSON.countries;
			this._States = resultJSON.states;
			this._Cities = resultJSON.cities;
			this._Accounttypes = resultJSON.accounttypes;
			this._Addresstype = resultJSON.addresstype;
			this._PayModes = resultJSON.paymodes;
			this._VatDetails = resultJSON.vatDetails;
			this._SpecificVatType = resultJSON.specifyVatTypes;
			this._SpecificVatTax = resultJSON.specifyTaxes;
			this._CreditsApplicable = resultJSON.creditdetails;
			this.Banklist = resultJSON.banks;
			this._customerform.controls['currencyid'].setValue(this._Currencies[0].currencyid);
			this._customerform.controls['levelid'].setValue(this._Markuplevels[0].levelid);
			this._customerform.controls['customerstatus'].setValue("MSC00001");
			this._customerform.controls['series'].setValue("MSC00093");
			this._customerform.controls['paymode'].setValue(this._PayModes[0].metasubcode);
			this._customerform.controls['vattype'].setValue("MSC00062");
			this._customerform.controls['creditapplicable'].setValue("MSC00011")
			this._customerform.controls['specificvattax'].disable();
			this.getVattype();
			this.OnChangeShowVat();
			this.onClickCreditDetail();
			if (this._action == 'edit' || this._action == 'view') {
				this.disableseries = true;
				const updateJSON = JSON.parse(JSON.stringify(result));
				this._States = updateJSON.states;
				this._Cities = updateJSON.cities;
				this._customerform.setValue(updateJSON.customer);
				this._CustomersAddress = updateJSON.customeraddressdetails || [];
				this.selectedrows = updateJSON.customeraddressdetails || [];
				var vattype = this._customerform.get("vattype").value;
				if (vattype == "MSC00064") { // specific
					this._customerform.get("isshowvat").enable();
					this._customerform.get("specificvattype").enable();
					let isshowvat = this._customerform.get("isshowvat").value;
					if (isshowvat) {
						this._customerform.get("specificvattax").enable();
					}
				}
				if (updateJSON.customeraccountdetail) {
					this._customeraccountdetailform.setValue(updateJSON.customeraccountdetail);
				}
				this.isDisabledClear = true;
				let roleid = sessionStorage["currentRole"];
				if (roleid == 1 && this._action == 'edit') {
					this._IsSuperAdmin = false
				} else {
					this._IsSuperAdmin = true
				}
			}
			if (this._action == 'view') {
				this._customerform.disable();
				this._customeraddressdetailform.disable();
				this._customeraccountdetailform.disable();
				this.isDisabledSave = true;
				this.isDisabledButton = true;
				this.isDisabledaddbuttn = true;
				this.isDisabledreset = true;
			}
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	checkseriesRights() {
		if (this._Action._Series) {
			this.showseries = false;
		}
	}
	getcurrency(event) {
	}
	onclicklimit(event) {
		this._limitcurrency = this._Currencies[0].currencyname;
	}
	getStates(event) {
		var selectedCountry = event.value;
		this._IsProgressSpinner = true;
		this._States = [];
		this._Cities = [];
		this._CustomerService.getStates(selectedCountry).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			//  console.log(resultJSON);
			this._States = resultJSON.states;
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	getDistrict(event) {
		var selectedState = event.value;
		this._IsProgressSpinner = true;
		this._Cities = [];
		this._CustomerService.getDistrict(selectedState).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._Cities = resultJSON.cities;
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	onRowCustomerselect(address: Customeraddressdetail, rowIndex: number) {
		// address.customeraddressdetailid = 0;
		//address.createdby = 0;
		this._customeraddressdetailform.setValue(address);
		this.addressRowIndex = rowIndex;
	}
	onRowUnselect(event) {
	}

	getVattype() {
		var vattype = this._customerform.get("vattype").value;

		if (vattype == "MSC00064") { // specific
			this._customerform.get("specificvattype").enable();
			this._customerform.get("isshowvat").enable();
			this._customerform.get("specificvattax").enable();
			this._customerform.controls['isshowvat'].setValue(true);
			this._customerform.controls['specificvattax'].setValue("MSC00103");
		} else {
			this._customerform.get("specificvattype").disable();
			this._customerform.get("isshowvat").disable();
			this._customerform.get("specificvattax").disable();

			this._customerform.get("specificvattype").reset();
			this._customerform.get("isshowvat").reset();
			this._customerform.get("specificvattax").reset();
		}

	}

	OnChangeShowVat() {
		let isshowvat = this._customerform.get("isshowvat").value;
		if (isshowvat)
			this._customerform.get("specificvattax").enable();
		else {
			this._customerform.get("specificvattax").disable();
			this._customerform.get("specificvattax").reset();
		}
	}

	onClickCreditDetail() {
		var creditType = this._customerform.get("creditapplicable").value;
		if (creditType == "MSC00011") { // Yes
			this._customerform.get("creditlimit").enable();
			this._customerform.get("creditdays").enable();
		} else {
			this._customerform.get("creditlimit").disable();
			this._customerform.get("creditdays").disable();

			this._customerform.get("creditlimit").reset();
			this._customerform.get("creditdays").reset();
		}
	}

	onSave() {
		this._submitted = true;
		this._addresssubmitted = false;
		if (this._customerform.valid) {
			if (this._CustomersAddress.length > 0 || this._customerform.get("savetype").value == "MSC00230") { //msc00230 -employee
				if (this._customeraccountdetailform.valid) {
					this._customeraddressdetailform.get("customerid").setValue(this._customerform.get("customerid").value);
					this._customeraccountdetailform.get("customerid").setValue(this._customerform.get("customerid").value);
					//For Model and Form Mapping
					this._Customer = this._customerform.value;
					this._Customer.tempcreditlimit = this._Customer.creditlimit;
					this._Customer.tempcreditdays = this._Customer.creditdays;
					this._Customeraccountdetail = this._customeraccountdetailform.value;
					//this._CustomersAddress = this._customeraddressdetailform.value;
					if (this._CustomersAddress && !this._CustomersAddress.length && this._customerform.get("savetype").value != "MSC00230") {
						this._CustomExceptionService.handleError(usererrors.Customeraddress01);
						this.selectedtab = 1;
						return;
					}
					else if (this._CustomersAddress && this._CustomersAddress.length && !this._CustomersAddress.some(s => s.isdefaultaddress == true)) {
						this._CustomExceptionService.handleError(usererrors.Customeraddress02);
						this.selectedtab = 1;
						return;
					}
					if (this._Customer.creditapplicable == "MSC00011" && !this._Customer.creditlimit && !this._Customer.creditdays) {
						this._CustomExceptionService.handleError(usererrors.CreditLimitAndDays);
						return;
					}
					//For Object Call
					//  this._CustomerOBJ = { "Customer": this._Customer, "Customeraddressdetail": this._CustomersAddress, "Customeraccountdetail": this._Customeraccountdetail };
					//  console.log(this._CustomerOBJ);

					if (this._action == 'edit') {
						this._Customer.modifiedon = new Date();
						this._Customer.modifiedby = sessionStorage["userid"];
						this._Customeraccountdetail.modifiedon = new Date();
						this._Customeraccountdetail.modifiedby = sessionStorage["userid"];
						if (this._customerform.get("savetype").value != "MSC00230") {
							this._Customeraccountdetail = null;
						}
						this._CustomerOBJ = this.formconverstion(this._Customer, this._Customeraccountdetail, this._CustomersAddress, [], []);

						this._IsProgressSpinner = true;
						this._CustomerService.edit(this._CustomerOBJ).subscribe((result) => {
							const resutJSON = JSON.parse(JSON.stringify(result));
							if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
								// after updating the record get respond A, shows the message "Customer A Modified successfully"
								if (resutJSON.tranStatus.lstErrorItem) {
									this._CustomExceptionService.handleSuccess(resutJSON.tranStatus.lstErrorItem[0].message);
									this._submitted = false;
								} else {
									this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
									this._submitted = false;
								}
							}
							else {
								this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
							}
							this._IsProgressSpinner = false;
						},
							error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
					}
				}
				else {
					this._CustomExceptionService.handleError(usererrors.Customeraddress04);
					this.selectedtab = 2;
					return;
				}
			}
			else {
				this._CustomExceptionService.handleError(usererrors.Customeraddress03);
				this.selectedtab = 1;
				return;
			}
		}
		else {
			this._CustomExceptionService.handleError(usererrors.Customeraddress05);
			this.selectedtab = 0;
			return;

		}

	}

	formconverstion(p_Customer: Customer, p_Customeraccountdetail: Customeraccountdetail, p_Customeraddressdetail: Customeraddressdetail[], [], documents: File[]) {
		let time = new Date();
		const formData: FormData = new FormData();

		var CustomerOBJ = { "Customer": p_Customer, "Customeraccountdetail": p_Customeraccountdetail, "Customeraddressdetail": p_Customeraddressdetail, "Customerdocumentdetail": [] };
		var Customer = JSON.stringify(CustomerOBJ);
		formData.append('strcustomer', Customer);
		return (formData);
	}

	onRowDoubleClick(evt, _CustomerAddress) {
		alert('You clicked row ' + _CustomerAddress + '!');
	}
	onAddAddress(event) {
		this._addresssubmitted = true;
		if (this._customeraddressdetailform.valid) {

			if (this.addressRowIndex > -1) {
				this._CustomersAddress[this.addressRowIndex] = this._customeraddressdetailform.value;
				this._CustomersAddress[this.addressRowIndex].createdon = new Date();
			} else {
				var addressdetail: any = {};
				addressdetail.customeraddressdetailid = 0;
				addressdetail.customerid = 0;
				addressdetail.contactpersonname = this._customeraddressdetailform.get("contactpersonname").value;
				addressdetail.mobileno = this._customeraddressdetailform.get("mobileno").value;
				addressdetail.phoneno = this._customeraddressdetailform.get("phoneno").value || 0;
				addressdetail.address = this._customeraddressdetailform.get("address").value;
				addressdetail.mailid = this._customeraddressdetailform.get("mailid").value;
				addressdetail.countryid = this._customeraddressdetailform.get("countryid").value;
				addressdetail.stateid = this._customeraddressdetailform.get("stateid").value;
				addressdetail.districtid = this._customeraddressdetailform.get("districtid").value;
				addressdetail.faxno = this._customeraddressdetailform.get("faxno").value;
				addressdetail.isdefaultaddress = this._customeraddressdetailform.get("isdefaultaddress").value;
				addressdetail.createdby = sessionStorage["userid"];
				addressdetail.modifiedby = 0;
				addressdetail.modifiedon = null;
				addressdetail.createdon = new Date();
				addressdetail.isdefaultaddress = (this._CustomersAddress.length == 1);
				addressdetail.customer = null;
				addressdetail.addresstype = this._customeraddressdetailform.get("addresstype").value;
				this._CustomersAddress.push(addressdetail);
			}
			this.resetaddress(null);
		}
	}
	Removeselectedchecked(_CustomerAddress: Customeraddressdetail, rowIndex: number) {
		if (this._CustomersAddress && this._CustomersAddress.length) {
			for (let i = 0; i < this._CustomersAddress.length; i++) {
				if (i === rowIndex)
					continue;

				this._CustomersAddress[i].isdefaultaddress = false;
			}
		}
		this._CustomersAddress[rowIndex].isdefaultaddress = _CustomerAddress.isdefaultaddress;

	}

	reset(event) {
		this._submitted = true;
		this._addresssubmitted = false;
		this._customerform.reset();
		this._customeraddressdetailform.reset();
		this._customeraccountdetailform.reset();
		this.InitializeForm();

		this._submitted = false;
		// this._submitted = false;
		this._CustomersAddress = [];

		setTimeout(() => {
			this.getVattype();
			this.OnChangeShowVat();
			this.onClickCreditDetail();
		}, 1000);
	}
	resetaddress(event) {
		this._customeraddressdetailform.reset();
		this._addresssubmitted = false;
		this.addressRowIndex = -1;
	}
	goBack(event) {
		//alert('This');
		this._router.navigate(['/vDefaultCustomer']);
	}
	onEd() {
		ace.config.set("fontSize", "14px");
		ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
		const aceEditor = ace.edit(this.editor.nativeElement);
		aceEditor.getSession().setMode("ace/mode/json");
		var myObj = this._CustomerOBJ;

		var myJSON = JSON.stringify(myObj);
		aceEditor.session.setValue(myJSON);
	}
	showBasicDialog() {
		this.displayBasic = true;
	}
	onRowSelectPopup(event: any) {
		var row = event.data;
		this._customeraccountdetailform.controls['bankcode'].setValue(row.bankcode);
		this._customeraccountdetailform.controls['bankname'].setValue(row.bankname);
		this.displayBasic = false;
	}
	//Remove Document in Grid:
	RemoveAddressDetail(p_addressDetail: Customeraddressdetail) {
		var index = this._CustomersAddress.indexOf(p_addressDetail);
		this._CustomersAddress.splice(index, 1)
	}
	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._action != 'view') {
			this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
				this.onSave();
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		if (this._action == 'create') {
			this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
				this.reset(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
			this.goBack(null);
			return false; // Prevent bubbling
		}, ['INPUT', 'SELECT', 'TEXTAREA']));
	}

	//Customer Name Validation:
	ChangeSwift() {
		if (!this._customerform.get("customername").value.toString().match(new RegExp('^[a-zA-Z0-9 ]*$')) && this._action != 'edit') {
			this._CustomExceptionService.handleWarning("special characters not allowed on customername");
			this._customerform.controls['customername'].reset()
			return;
		}
	}
}
