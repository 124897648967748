<div class="page-container">
  <div class="inner-page-container">
    <div class="page-loader" *ngIf="_IsProgressSpinner">
      <p-progressSpinner strokeWidth="5"></p-progressSpinner>
    </div>
    <div class="white">
      <div class="page-title">
        <div class="row">
          <div class="col-md-5">
            <h3>Cash Book Report</h3>
          </div>
        </div>
      </div>
      <div class="form-container scroll-y">
        <table class="normal-table">
          <tr>
            <td style="width: 20%">
              <span class="p-float-label">
                <p-calendar
                  name="Date"
                  [(ngModel)]="_Date"
                  [showIcon]="true"
                  dateFormat="dd/mm/yy"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  [minDate]="mindate"
                  [maxDate]="maxdate"
                  yearRange="2021:2030"
                >
                </p-calendar>
                <label for="Date"
                  >Closing Date <span class="hlt-txt">*</span></label
                >
              </span>
            </td>

            <td style="width: 20%">
              <span class="p-float-label">
                <p-dropdown
                  [autoDisplayFirst]="false"
                  [options]="_statuslist"
                  optionValue="metasubcode"
                  (onChange)="getUsers()"
                  optionLabel="metasubdescription"
                  [(ngModel)]="status"
                >
                </p-dropdown>
                <label for="Status">Status</label>
              </span>
            </td>
            <td style="width: 20%">
              <span class="p-float-label">
                <p-multiSelect
                  [filter]="false"
                  [options]="_Users"
                  optionLabel="username"
                  optionValue="userid"
                  [(ngModel)]="userids"
                  displaySelectedLabel="true"
                  [filter]="true"
                  filterBy="username"
                  [virtualScroll]="true"
                  itemSize="30"
                >
                </p-multiSelect>
                <label for="tellerid"
                  >User Name <span class="hlt-txt">*</span></label
                >
              </span>
            </td>
            <td style="width: 20%"></td>
            <td style="width: 20%"></td>
            <td style="width: 20%"></td>
          </tr>

          <tr>
            <td>
              <button
                pButton
                pRipple
                label="Search"
                type="button"
                (click)="search()"
                title="Search"
                icon="pi pi-search"
              ></button>
              <button
                pButton
                pRipple
                label="Clear"
                title="Clear"
                (click)="clear()"
                icon="pi pi-times"
                class="p-button-danger"
              ></button>
            </td>
          </tr>
        </table>

        <div class="card">
          <p-toolbar styleClass="p-mb-4">
            <ng-template pTemplate="left">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search..."
                />
              </span>
            </ng-template>
            <ng-template pTemplate="right">
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-o"
                title="Export CSV"
                (click)="exportExcel()"
                class="p-mr-2"
                pTooltip="CSV"
                tooltipPosition="bottom"
              ></button>
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                title="Export Excel"
                class="p-button-success p-mr-2"
                pTooltip="XLS"
                tooltipPosition="bottom"
                (click)="exportExcel()"
              ></button>
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-pdf"
                title="Export PDF"
                class="p-button-warning p-mr-2"
                pTooltip="PDF"
                tooltipPosition="bottom"
                (click)="exportPdf()"
              ></button>
            </ng-template>
          </p-toolbar>

          <p-table
            #dt
            [value]="_Mergelist"
            [columns]="cols"
            [rows]="10"
            [paginator]="true"
            [rowsPerPageOptions]="[10, 25, 50, 100]"
            [globalFilterFields]="[
              'BANK',
              'BOND',
              'Indian Rupee',
              'LEDGERID',
              'NAME',
              'NOSTRO',
              'PARTYCODE',
              'RAND',
              'USD',
              'USD SP',
              'zim1'
            ]"
            [(selection)]="selectedrows"
            [rowHover]="true"
            dataKey="productname"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of cols" pSortableColumn="{{ col }}">
                  {{ col }}
                  <p-sortIcon field="{{ col }}"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-colvalues="columns">
              <tr>
                <td *ngFor="let col of colvalues">
                  {{ rowData[col] }}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer" let-columns>
                <tr>
                  <td *ngFor="let col of cols" >
                    {{ _Totallist[col] }}
                  </td>
                  <!-- <td *ngFor="let col of cols">
                        {{ _Totallist[col] }}
                  </td> -->
                </tr>
              </ng-template>
          </p-table>
        </div>
        <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-right"></p-toast>
