export class Consignmentstockadjustmentdetail {
    consignmentstockadjustmentdetailid: number;
    stockadjustmentid: number;
    customerbranchid: number;
    customerid: number;
    deliverynoteno: string;
    productid: number;
    invoiceqty: number;
    closingstock: number;
    actualstock: number;
    stockdiffinusd: number;
    purchasevalueinusd: number;
    stockvalueinusd: number;
    differencevalueinusd: number;
    remarks: string;
}