import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsignmentstockadjustmentService {
  public _ConsignmentStockAdjustmentService = environment.ConsignmentStockAdjustmentService;
  public _httpOptions;
  token: any;
  header: any;
  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
}
createHeaders(token: any): HttpHeaders {
  return new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`, // Include your token here	
  });
} PageOnLoad(stocktakeid: number ): Observable<any> {
  const headers = this.header;
  var Query = this._ConsignmentStockAdjustmentService + '/' + stocktakeid ;
  return this.http.get<{ data: any }>(Query, { headers });
}
getdeliverynoteno(productheader:object) {
  const headers = this.header;
  var Query = this._ConsignmentStockAdjustmentService+'/getdeliverynoteno';
  return this.http.post<{ data: any }>(Query, productheader, { headers });
}
getproductdetails(productheader:object) {
  const headers = this.header;
  var Query = this._ConsignmentStockAdjustmentService+'/getProductDetails';
  return this.http.post<{ data: any }>(Query, productheader, { headers });
}

Create(adjustment: object) {
  const headers = this.header;
  var Query = this._ConsignmentStockAdjustmentService+'/Create';
  return this.http.post<{ data: any }>(Query, adjustment, { headers });
}

SendToApproval(adjustment: object) {
  const headers = this.header;
  var Query = this._ConsignmentStockAdjustmentService+'/SendToApproval';
  return this.http.post<{ data: any }>(Query, adjustment, { headers });
}

Search(adjustment: object) {
  const headers = this.header;
  var Query = this._ConsignmentStockAdjustmentService+'/Search';
  return this.http.post<{ data: any }>(Query, adjustment, { headers });
}

}
