<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Product Price Checking</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <!-- <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                (click)="onSave()"></button> -->
                            <button pButton pRipple type="button" (click)="reset($event)" title="Clear"
                                icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <!-- <button pButton pRiple type="button" (click)="goBack($event)" icon="pi pi-search"
                                title="Back to Search" class="p-button p-button-success p-mr-2"></button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_productpricecheckingform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <form [formGroup]="_productpriceform">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_ProductTypes" optionLabel="producttypename"
                                            optionValue="producttypeid" [(ngModel)]="producttypeid"
                                            [ngModelOptions]="{standalone: true}" displaySelectedLabel=true
                                            (onChange)="GetProductCategory($event)">
                                        </p-multiSelect>
                                        <label for="producttypeid">Product Type <span class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="(_submitted || _productpriceform.get('producttypeid').touched) && _productpriceform.get('producttypeid').errors?.SelectProductType">
                                        Please Select Atleast One Product Type
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;">
                                <form [formGroup]="_productpriceform">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_ProductCategories" optionLabel="categoryname"
                                            optionValue="productcategoryid" (onChange)="GetProductName($event)"
                                            [(ngModel)]="productcatid" [ngModelOptions]="{standalone: true}"
                                            displaySelectedLabel=true>
                                        </p-multiSelect>
                                        <label for="productcategoryid">Product Category <span
                                                class="hlt-txt">*</span></label>
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="(_submitted || _productpriceform.get('productcategoryid').touched) && _productpriceform.get('productcategoryid').errors?.SelectProductCategory">
                                        Please Select Atleast One Product Category
                                    </span>
                                </form>
                            </td>
                            <td style="width: 20%;" *ngIf="isproductdropdown">
                                <span class="p-float-label">
                                    <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_ProductNames"
                                        optionLabel="productname" optionValue="productid"
                                         formControlName="productid" [filter]="true"
                                        filterBy="productname" [showClear]="true" (onChange)="GetProductPrice($event)">
                                    </p-dropdown> -->
                                    <p-multiSelect [options]="_ProductNames" optionLabel="productname"
                                        optionValue="productid" (onChange)="GetProductPrice($event)"
                                        formControlName="productid" displaySelectedLabel=true>
                                    </p-multiSelect>
                                    <label for="productcategoryid">Product Name <span class="hlt-txt">*</span></label>
                                </span>

                                <!-- <p-autoComplete formControlName="productid" [suggestions]="filteredProducts"
                                        (completeMethod)="filterProducts($event)" field="productname"
                                        (onSelect)="GetProductPrice($event)">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span> -->
                                <span class="text-danger"
                                    *ngIf="(_submitted || _productpricecheckingform.get('productid').touched) && _productpricecheckingform.get('productid').errors?.SelectProductName">
                                    Please Select Product Name
                                </span>
                            </td>
                            <td style="width: 20%;" *ngIf="isautocomplete">
                                <span class="p-float-label">
                                    <p-autoComplete formControlName="productid" [suggestions]="filteredautoProduct"
                                        (completeMethod)="AutoComplete($event)" field="productname"
                                        (onSelect)="GetProductPrice($event)">
                                        <ng-template let-product pTemplate="item">
                                            <div>{{product.productname}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <label for="productid">Product Name <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_branchlist"
                                        optionValue="branchid" optionLabel="branchname" formControlName="branchid"
                                        [filter]="true" filterBy="branchname" (onChange)="onchangebranch($event)">
                                    </p-dropdown>
                                    <label for="branchname">Branch Name</label>
                                </span>

                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText [(ngModel)]="barcodevalue" [ngModelOptions]="{standalone: true}" (keyup.Enter)="GetProductPriceUsingBarcode()">
                                    <label for="suppliername">Barcode </label>
                                </span>
                            </td>
                            <!-- <td style="width: 20%;">
                                <form [formGroup]="_productpriceform">
                                <span class="p-float-label">
                                    <input type="text" name="stockinhand" formControlName="stockinhand" disabled
                                        pInputText>
                                    <label for="stockinhand">Stock In Hand </label>
                                </span>
                                </form>
                            </td> -->
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Price Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_pricedetails" [rows]="10" [paginator]="true"
                        [rowsPerPageOptions]="[5,10,25,50,100]"
                        [globalFilterFields]="['productname','quantity','usd','rand','bond','bank','tempusd','temprand','tempbond','tempbank','stock']" [rowHover]="true"
                        dataKey="productid" [(selection)]="selectedrows"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>USD</th>
                                <th>RAND</th>
                                <th>BOND</th>
                                <th>BANK</th>
                                <th [hidden]="true">TEMP_USD</th>
                                <th [hidden]="true">TEMP_RAND</th>
                                <th [hidden]="true">TEMP_ZIM1</th>
                                <th [hidden]="true">TEMP_ZIM2</th>
                                <th>Stock</th>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_pricedetail>
                            <tr>
                                <td>{{_pricedetail.productname}}</td>
                                <!-- <td>{{_pricedetail.quantity}}</td> -->
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_pricedetail.quantity" pInputText
                                                *ngIf="_action != 'view'"
                                                (keyup)="allocatequantity(_pricedetail,$event)" maxlength="7"
                                                (keypress)="keypress.kpNumber($event)">
                                            <div *ngIf="_action == 'view'">{{_pricedetail.quantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_pricedetail.quantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td>{{_pricedetail.usd | number: '1.2-4'}}
                                </td>
                                <td>{{_pricedetail.rand | number: '1.2-4'}}
                                </td>
                                <td>
                                    {{_pricedetail.bond | number: '1.2-4'}}
                                </td>
                                <td>
                                    {{_pricedetail.bank | number: '1.2-4'}}
                                </td>
                                <td [hidden]="true">
                                    {{_pricedetail.tempusd | number: '1.2-4'}}
                                </td>
                                <td [hidden]="true">
                                    {{_pricedetail.temprand | number: '1.2-4'}}
                                </td>
                                <td [hidden]="true">
                                    {{_pricedetail.tempbond | number: '1.2-4'}}
                                </td>
                                <td [hidden]="true">
                                    {{_pricedetail.tempbank | number: '1.2-4'}}
                                </td>
                                <td>
                                    {{_pricedetail.stock}}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <table>
                    <tr>
                        <td>
                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                        </td>
                    </tr>
                </table>

            </div>
            <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>