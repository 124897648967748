import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { stringify } from 'querystring';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Employee } from 'src/app/core/Models/Employee';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productdetail } from 'src/app/core/Models/Productdetail';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Stockadjustment } from 'src/app/core/Models/Stockadjustment';
import { Stockadjustmentdetail } from 'src/app/core/Models/Stockadjustmentdetail';
import { StockadjustmentService } from 'src/app/core/Services/inventory/stockadjustment.service';
import {
  vBranchName,
  vCustomerNameSelect,
  vcycleno,
  vProductCategorySelect,
  vProductName,
  vProductTypeName,
  vProductTypeSelect,
  vstockdate,
  vstocker,
  vStockTakersSelect,
  vVariantId,
} from 'src/app/core/Validators/validation';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { KeyPress } from 'src/assets/js/KeyPress';
import { ConsignmentstockadjustmentService } from 'src/app/core/Services/consignmentcustomer/consignmentstockadjustment/consignmentstockadjustment.service';
import { Customer } from 'src/app/core/Models/Customer';
import { Branch } from 'src/app/core/Models/Branch';
import { Customerbranchdetail } from 'src/app/core/Models/Customerbranch';
import { consignmentstockadjustmentdetailview } from 'src/app/core/Views/consignmentstockadjustmentdetailview';
import { Consignmentstockadjustment } from 'src/app/core/Models/consignmentstockadjustment';
import { Consignmentstockadjustmentdetail } from 'src/app/core/Models/Consignmentstockadjustmentdetail';
import { ConsignmentdeliverynoteService } from 'src/app/core/Services/consignmentcustomer/consignmentdeliverynote/consignmentdeliverynote.service';

@Component({
  selector: 'app-c-consignmentstockadjustment',
  templateUrl: './c-consignmentstockadjustment.component.html',
  styleUrls: ['./c-consignmentstockadjustment.component.css'],
})
export class CConsignmentstockadjustmentComponent implements OnInit {
  _producttype: Producttype[] = [];
  _productcategory: Productcategory[] = [];
  _submitted = false;

  _brandlist: any[] = [];
  _stockadjustmentform: FormGroup;
  _validate = true;
  _variantdetails: Productvariant[] = [];
  _stockadjustmentid: number;
  _stockadjustmentdetailform: FormGroup;
  isDisabledsave = false;
  isDisablereset = false;
  _StockadjustmentObj: any;
  _stockadjustment: any;
  _productdetail: Stockadjustmentdetail;
  _productdetails: consignmentstockadjustmentdetailview[] = [];
  selectedProductDetailsRows: Stockadjustmentdetail[] = [];
  isDisabledaddproduct = true;
  _stockadjustmentno: string;
  isDisabledclearproduct = true;
  _action: string = 'create';
  _branchid: number;
  _cycleno: Metadatum[] = [];
  _customernames: Customer[] = [];
  _deliverynoteno: any;
  isDisabledremoveproduct = true;
  isDisabledproductcategory = true;
  _stocktakers: Employee[] = [];
  _Customerbranchdetails: Customerbranchdetail[] = [];
  _BranchNames: Branch[] = [];
  isDisabledprint = true;
  isDisabledsendapparoval = true;
  isDisabledproducttype = true;
  isDisabledButton = false;
  _submitted1 = false;
  _IsProgressSpinner: boolean = false;
  isDisabledselectall = false;
  isdisabledactualstock: boolean = true;
  isdisabledremarks: boolean = true;
  _BranchObj: any;
  _ProductCategoryObj: any;
  _ProductHeaderObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _severities: Metadatum[] = [];
  _Action: IAction;
  mindate: Date;
  maxdate: Date;
  _tempcustomernames: Customer[] = [];
  _Consignmentstockadjustment: Consignmentstockadjustment[] = [];
  _Consignmentstockadjustmentdetailview: consignmentstockadjustmentdetailview[] = [];
  _Customerbranchid: any[];

  constructor(
    private fb: FormBuilder,
    private utility: CommonUtilities,
    private _AccessRightsService: AccessRightsService,
    public keypress: KeyPress,
    private _Consignmentsstockadjustservice: ConsignmentstockadjustmentService,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _router: Router,
    private _ConsignmentdeliverynoteService: ConsignmentdeliverynoteService
  ) {
    this._stockadjustmentid = history.state?._ConsignmentAdjustmentid
      ? history.state?._ConsignmentAdjustmentid
      : 0;
    this._action =
      history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }

  InitializeForm() {
    this._stockadjustmentform = this.fb.group({
      stockadjustmentid: [0],
      stockadjustmentno: [],
      stockadjustmentdate: [],
      cycleno:[],
      teamleadid: [],
      stocktakerid1: [],
      stocktakerid2: [],
      rv: [],
      bholabranchid: [],
      status: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      consignmentstockadjustmentdetails: []
    });
    // companyid: [],
      // Customerid: ['', vCustomerNameSelect],
      // cutomerbranchid: [],
      // consignmentdeliverynoteid: [],
      // stocktakeid: [],
      // stocktakeno: [],
      // stocktakedate: ['', vstockdate],
      // cycleno: ['', vcycleno],
      // teamlead: ['', vstocker],
      // stocktaker1: ['', vStockTakersSelect],
      // stocktaker2: ['', vStockTakersSelect],
      // status: [],
      // statusremarks: [],
      // accountingyear: [],
      // createdby: [],
      // createdon: [],
      // modifiedby: [],
      // modifiedon: [],
      // rv: [],
      // stockadjustmentdetails: [],
      // stockinhand: [],

    // if (sessionStorage['Environmentenddate'] == 'null') {
    //   this._stockadjustmentform.get('stocktakedate').setValue(new Date());
    // } else {
    //   let date = new Date(sessionStorage['Environmentenddate']);
    //   this._stockadjustmentform.get('stocktakedate').setValue(date);
    // }

    this._stockadjustmentdetailform = this.fb.group({
      consignmentstockadjustmentdetailid: [0],
      stockadjustmentid: [0],
      productid: [],
      invoiceqty: [],
      closingstock: [],
      actualstock: [],
      customerid: [],
      deliverynoteno: [],
      customerbranchid: [],
      stockdiffinusd: [],
      purchasevalueinusd: [],
      stockvalueinusd: [],
      differencevalueinusd: [],
      remarks: []
    });
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(375);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._stockadjustmentform.get("stockadjustmentdate").setValue(new Date());
    // this.mindate = new Date(sessionStorage['Environmentstartdate']);
    // this.maxdate = new Date(sessionStorage['Environmentenddate']);
    

    this._branchid = parseInt(sessionStorage['BranchID']);
    this._IsProgressSpinner = true;
    this._Consignmentsstockadjustservice.PageOnLoad(this._stockadjustmentid).subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._cycleno = resultJSON.cycleNos;
          this._BranchNames = resultJSON.branchNames;
          this._customernames = resultJSON.customernames;
          this._tempcustomernames = resultJSON.customernames;
          this._stocktakers = resultJSON.stockTakers;
          this._Customerbranchdetails = resultJSON.customerbranchdetails;
          // this._IsProgressSpinner = false;
          if(this._action == "view" || this._action == "edit")
          {
            this._Consignmentstockadjustment = resultJSON.consignmentstockadjustment;
            // this._Consignmentstockadjustmentdetailview = resultJSON.consignmentstockadjustmentdetailview;
            resultJSON.consignmentstockadjustment.stockadjustmentdate = new Date(resultJSON.consignmentstockadjustment.stockadjustmentdate);
            this._stockadjustmentform.setValue(resultJSON.consignmentstockadjustment);
            this._productdetails = resultJSON.consignmentstockadjustmentdetailview;
            this._stockadjustmentdetailform.get("customerbranchid").setValue(resultJSON.consignmentstockadjustmentdetailview.map((a) => a.branchid));
            this._Customerbranchid = this._stockadjustmentdetailform.get("customerbranchid").value;
            this.getcustomer(event);
          }
          if(this._action == "view")
          {
            this._stockadjustmentform.disable();
            this._stockadjustmentdetailform.disable();
            this.isDisabledselectall = true;
            this.isDisabledsave = true;
            this.isDisablereset = true;
            this.isDisabledsendapparoval = true;
          }
          this._IsProgressSpinner = false;
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );
  }

  getcustomer(event) {
    this._IsProgressSpinner = true;
    var branchid = event.value || this._Customerbranchid;
    if (branchid.length && this._Customerbranchdetails.length) {
      var filtercustomers = this._Customerbranchdetails
        .filter((f) => branchid.includes(f.customerbranchid))
        .map((f) => f.customerid);
      if(this._action == "view" || this._action == "edit")
      {
        this._stockadjustmentdetailform.get("customerid").setValue(filtercustomers);
      }
      if (filtercustomers.length) {
        this._customernames = this._tempcustomernames.filter((f) =>
          filtercustomers.includes(f.customerid)
        );
        this.getdeliverynoteno();
        this.getproductdetails();
        this._IsProgressSpinner = false;
      } else {
        this._customernames = [];
        this._IsProgressSpinner = false;
      }
    }
  }

  getdeliverynoteno() {
    this._IsProgressSpinner = true;
    const Branchid = this._stockadjustmentdetailform.get('customerbranchid').value;
    const Customerid = this._stockadjustmentdetailform.get('customerid').value || [];

    if (Branchid.length > 0 || Customerid.length > 0) {
      this._ProductHeaderObj = {
        CustomerId: Customerid,
        BranchId: Branchid || [],
      };
      this._Consignmentsstockadjustservice
        .getdeliverynoteno(this._ProductHeaderObj)
        .subscribe(
          (result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            this._deliverynoteno = resultJSON.deliverynotenos;
            // if(this._action == "view" || this._action == "edit")
            // {
            //   this._stockadjustmentdetailform.get("deliverynoteno").setValue(this._deliverynoteno);
            // }
            this.getproductdetails();
            this._IsProgressSpinner = false;
          },
  
          (error) => {
            this._CustomExceptionService.handleError(error);
          }
        );
        
    } else {
      this._deliverynoteno = [];
      this._IsProgressSpinner = false;
    }
  }
  getproductdetails() {
    this._IsProgressSpinner = true;
    const Branchid = this._stockadjustmentdetailform.get('customerbranchid').value;
    const Customerid = this._stockadjustmentdetailform.get('customerid').value || [];
    const deliverynoteid =
      this._stockadjustmentdetailform.get('deliverynoteno').value || [];

    if (Branchid.length == 0 && Customerid.length == 0 && deliverynoteid.length == 0) {
      this._productdetails = [];
      return;
    } else {
      this._ProductHeaderObj = {
        cutomerbranchid: Branchid,
        Customerid: Customerid,
        consignmentdeliverynoteid: deliverynoteid,
      };
      this._Consignmentsstockadjustservice.getproductdetails(this._ProductHeaderObj).subscribe(
          (result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            this._productdetails =
              resultJSON.consignmentStockadjustmentproductdetails;
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
          }
        );
    }
  }

  onchangeactualstock(row: any) {
    if (row.actualstock == null || row.actualstock == undefined) {
      row.actualstock = 0;
    }

    if (row.closingstock < row.actualstock) {
      row.stockvalueinusd = 0;
      row.stockdiffinusd = 0;
      row.differencevalueinusd = 0;
      row.actualstock = 0;

      this._CustomExceptionService.handleError(
        'Actual Qty Should not be Greater than Clossing stock'
      );
      return;
    }

    if (
      row.actualstock < 0 ||
      row.actualstock == null ||
      row.actualstock == undefined
    ) {
      row.actualstock = 0;
      var stockdiffinusd = (row.actualstock - row.closingstock).toFixed(3);
      row.stockdiffinusd = stockdiffinusd;
      row.differencevalueinusd = parseFloat((row.purchasevalueinusd * row.stockdiffinusd).toFixed(2));
      row.stockvalueinusd = parseFloat(
        (row.purchasevalueinusd * row.actualstock).toFixed(2)
      );
      row.selected = true;
      return;
    }
    var stockdiffinusd = (row.actualstock - row.closingstock).toFixed(3);
    row.stockdiffinusd = stockdiffinusd;
    row.stockvalueinusd = parseFloat(
      (row.purchasevalueinusd * row.actualstock).toFixed(2));
      row.differencevalueinusd = parseFloat((row.purchasevalueinusd * row.stockdiffinusd).toFixed(2));
  }

  onRowSelect(event) { }
  onRowUnselect(event) { }
  SelectAll(event) { }



  onSave() {

    if (this._stockadjustmentform.valid) {
      var l_StockDetails: Consignmentstockadjustmentdetail[] = [];

    Object.keys(this.selectedProductDetailsRows).map((Index) => {
      var currentRow = this.selectedProductDetailsRows[Index];
      var l_productdetails = new Consignmentstockadjustmentdetail();
      l_productdetails.productid = currentRow.productid;
      l_productdetails.deliverynoteno = currentRow.consignmentdeliverynoteno;
      l_productdetails.customerbranchid = currentRow.branchid;
      l_productdetails.customerid = currentRow.customerid;
      l_productdetails.invoiceqty = currentRow.invoiceqty || 0;
      l_productdetails.closingstock = currentRow.closingstock;
      l_productdetails.actualstock = currentRow.actualstock;
      l_productdetails.stockdiffinusd = currentRow.stockdiffinusd;
      l_productdetails.purchasevalueinusd = currentRow.purchasevalueinusd;
      l_productdetails.stockvalueinusd = currentRow.stockvalueinusd;
      l_productdetails.differencevalueinusd = currentRow.differencevalueinusd;
      l_productdetails.remarks = currentRow.remarks;

      // if(l_productdetails.remarks.length == 0)
      // {
      //   this._CustomExceptionService.handleWarning("Please add remarks for this"+ currentRow.productname);
      //   l_StockDetails = [];
      //   return;
      // }
      l_StockDetails.push(l_productdetails);
    })
   
    if (this.selectedProductDetailsRows.length == 0) {
      this._CustomExceptionService.handleWarning("Please select Atleast one row in a grid.");
      return;
    }
      if (this._action == 'create') {
        var StocktakeDate = this._stockadjustmentform.get("stockadjustmentdate").value;
        var Customerbranchid = this._stockadjustmentdetailform.get("customerbranchid").value;
        var datePipe = new DatePipe("en-US");
        var pStocktakeDate = datePipe.transform(StocktakeDate, 'yyyy-MM-dd');
        this._stockadjustment = this._stockadjustmentform.value;
        this._stockadjustment.stocktakedate = (new Date(pStocktakeDate));
        this._stockadjustment.stockadjustmentid = 0;
        this._stockadjustment.createdon = new Date();
        this._stockadjustment.createdby = sessionStorage["userid"];
        this._stockadjustment.modifiedon = new Date();
        this._stockadjustment.modifiedby = sessionStorage["userid"];
        this._stockadjustment.cutomerbranchid = Customerbranchid;
        this._stockadjustment.bholabranchid = parseInt(sessionStorage["BranchID"]);
        this._stockadjustment.companyid = 0;

        var obj = { 
          "Consignmentstockadjustment": this._stockadjustment, 
          "Consignmentstockadjustmentdetail": l_StockDetails 
        };
        this._IsProgressSpinner = true;
        this._Consignmentsstockadjustservice.Create(obj).subscribe((result) => {
          const resutJSON = JSON.parse(JSON.stringify(result));
          //console.log(resutJSON);
          this._IsProgressSpinner = false;
          if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
            this._stockadjustmentno = resutJSON.stockAdjustmentNo;
            this.isDisabledsendapparoval = false;
            this.isDisabledsave = true;
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            // this.reset(null);
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message);
          }
          this._IsProgressSpinner = false;
        },

          error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      }
    }
    else {
      this._CustomExceptionService.handleError(usererrors.StockProductDetails);
    }


   }
  SendToApproval(event) {
    //21.Check the user rights for the login user if the user has access to "send to Approve", then allow to "Send to Approve"
    this._IsProgressSpinner = true;
    let obj = {
     "stockadjustmentno" : this._stockadjustmentno
    }
    this._Consignmentsstockadjustservice.SendToApproval(obj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if(resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined) )
      {
        this.reset();
        this._CustomExceptionService.handleSuccess(usererrors.StockAdjustmentSendToApproval);
        this.isDisabledsendapparoval = true;
      }
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  reset() {
    this._submitted = true;
    this._stockadjustmentform.reset();
    this._stockadjustmentdetailform.reset();
    // this._stockadjustmentform.get("stockadjustmentdate").setValue(new Date());
    this._productdetails = [];
    this.ngOnInit();
  }

  goBack(event) {
    this._router.navigate(['/vConsignmentstockadjustmentComponent']);
  }
  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+s',
          (event: KeyboardEvent): boolean => {
            this.onSave();
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    if (this._action == 'create') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+alt+c',
          (event: KeyboardEvent): boolean => {
            this.reset();
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    this._hotkeysService.add(
      new Hotkey(
        'ctrl+left',
        (event: KeyboardEvent): boolean => {
          this.goBack(null);
          return false; // Prevent bubbling
        },
        ['INPUT', 'SELECT', 'TEXTAREA']
      )
    );
  }
}
