import { Injectable } from '@angular/core';
import { IAction } from '../../../security/IActions';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class RptProductRequestService {

  public _Action: IAction;
  public _httpOptions;
  public _Service = environment.RptProductRequestService;
  token: any;
  header: any;

  constructor(private http: HttpClient) { 
    this.token = sessionStorage['AuthToken'];
    this.header = this.createHeaders(this.token)
  }

  createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include your token here
    });
  }

  PageOnLoad(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._Service + '/PageOnLoad';
    return this.http.post<{ data: any }>(Query, obj, { headers });
  }
  Print(obj: object): Observable<any> {
    const headers = this.header;
    var Query = this._Service + '/Print';
    return this.http.post<{ data: any }>(Query, obj, { headers });
  }
}
