import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CConsignmentcustomerbranchComponent } from './consignmentcustomerbranch/c-consignmentcustomerbranch/c-consignmentcustomerbranch.component';
import { VConsignmentcustomerbranchComponent } from './consignmentcustomerbranch/v-consignmentcustomerbranch/v-consignmentcustomerbranch.component';
import { VConsignmentdeliverynoteComponent } from './ConsignmentDeliverynote/v-consignmentdeliverynote/v-consignmentdeliverynote.component';
import { CConsignmentdeliverynoteComponent } from './ConsignmentDeliverynote/c-consignmentdeliverynote/c-consignmentdeliverynote.component';
import { VConsignmentcustomerinvoiceComponent } from './consignmentcustomerinvoice/v-consignmentcustomerinvoice/v-consignmentcustomerinvoice.component';
import { CConsignmentcustomerinvoiceComponent } from './consignmentcustomerinvoice/c-consignmentcustomerinvoice/c-consignmentcustomerinvoice.component';
import { ConsignmentstockreportComponent } from './consignmentstockreport/consignmentstockreport.component';
import { CConsignmentstockadjustmentComponent } from './ConsignmentStockAdjustment/c-consignmentstockadjustment/c-consignmentstockadjustment.component';
import { VConsignmentstockadjustmentComponent } from './ConsignmentStockAdjustment/v-consignmentstockadjustment/v-consignmentstockadjustment.component';
const routes: Routes = 
[
  { path: 'cCustomerbranchmaster', component: CConsignmentcustomerbranchComponent },
  { path: 'vCustomerbranchmaster', component: VConsignmentcustomerbranchComponent },
  { path: 'vConsignmentDeliverynote', component: VConsignmentdeliverynoteComponent },
  { path: 'cConsignmentDeliverynote', component: CConsignmentdeliverynoteComponent },
  { path: 'vConsignmentCustomerInvoice', component: VConsignmentcustomerinvoiceComponent },
  { path: 'cConsignmentCustomerInvoice', component: CConsignmentcustomerinvoiceComponent },
  { path: 'RptCustomerConsignmentreport', component: ConsignmentstockreportComponent },
  { path: 'cConsignmentstockadjustmentComponent', component: CConsignmentstockadjustmentComponent},
  { path: 'vConsignmentstockadjustmentComponent', component: VConsignmentstockadjustmentComponent}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerconsignmentRoutingModule { }
