import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { InventoryRoutingModule } from './inventory-routing.module';
import { VStockinwardComponent } from './StockInward/v-stockinward/v-stockinward.component';
import { CStockinwardComponent } from './StockInward/c-stockinward/c-stockinward.component';
import { CStockTransferOrderComponent } from './stock-transfer-order/c-stock-transfer-order/c-stock-transfer-order.component';
import { VStockTransferOrderComponent } from './stock-transfer-order/v-stock-transfer-order/v-stock-transfer-order.component';
// prime ng control
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ChipsModule} from 'primeng/chips';
import { FieldsetModule, } from 'primeng/fieldset';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { HttpClientModule } from '@angular/common/http';
import {TabViewModule} from 'primeng/tabview';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { CustomExceptionService } from '../../errorHandlers/custom-exception.service';
import { CDamagedproductstockComponent } from './Damagedproductstock/c-damagedproductstock/c-damagedproductstock.component';
import { VGoodsReceiptNoteComponent } from './GRN/v-goods-receipt-note/v-goods-receipt-note.component';
import { CGoodsReceiptNoteComponent } from './GRN/c-goods-receipt-note/c-goods-receipt-note.component';
import { CTripstartComponent } from './Tripstart/c-tripstart/c-tripstart.component';
import { VTripstartComponent } from './Tripstart/v-tripstart/v-tripstart.component';
import { CStockadjustmentComponent } from './StockAdjustment/c-stockadjustment/c-stockadjustment.component';
import { VStockadjustmentComponent } from './StockAdjustment/v-stockadjustment/v-stockadjustment.component';
import { CStockallocationComponent } from './Stockallocation/c-stockallocation/c-stockallocation.component';
import { VStockallocationComponent } from './Stockallocation/v-stockallocation/v-stockallocation.component';
import { CStockdeliveryComponent } from './StockDelivery/c-stockdelivery/c-stockdelivery.component';
import { VStockdeliveryComponent } from './StockDelivery/v-stockdelivery/v-stockdelivery.component';
import { CDeliveryChallanComponent } from './Delivery-Note/c-delivery-challan/c-delivery-challan.component';
import { VDeliveryChallanComponent } from './Delivery-Note/v-delivery-challan/v-delivery-challan.component';
import { CTripsheetComponent } from './TripSheet/c-tripsheet/c-tripsheet.component';
import { CProductFreezingComponent } from './Product-Freezing/c-product-freezing/c-product-freezing.component';
import { VProductRequestComponent } from './Product-Request/v-product-request/v-product-request.component';
import { CProductRequestComponent } from './Product-Request/c-product-request/c-product-request.component';
import { ProductPriceUploadComponent } from './product-price-upload/product-price-upload.component';

@NgModule({
  declarations: [VStockinwardComponent, CStockinwardComponent,CStockTransferOrderComponent, 
    VStockTransferOrderComponent,CDamagedproductstockComponent,VGoodsReceiptNoteComponent,CGoodsReceiptNoteComponent,
    CTripstartComponent, VTripstartComponent,
    CStockadjustmentComponent,VStockadjustmentComponent,
    CStockdeliveryComponent,VStockdeliveryComponent,
    CStockallocationComponent,VStockallocationComponent,CStockdeliveryComponent,VStockdeliveryComponent,CDeliveryChallanComponent,VDeliveryChallanComponent, CTripsheetComponent,CProductFreezingComponent, VProductRequestComponent, CProductRequestComponent, ProductPriceUploadComponent ],
  providers:[MessageService,ConfirmationService,CustomExceptionService,DatePipe,
  ],
  imports: [
    CommonModule,
    InventoryRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChipsModule,
    FieldsetModule,
    AutoCompleteModule,
    OverlayPanelModule,
    CheckboxModule,
    PasswordModule,
    HttpClientModule,
    TabViewModule,
    ProgressSpinnerModule
    
  ]
})
export class InventoryModule { }
