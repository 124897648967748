<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Supplier Master</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRiple type="button" icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" (click)="GoToCreate($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <form [formGroup]="_SupplierProductmapping"> -->
            <div class="form-container scroll-y">
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="suppliertype" name="id"
                                    [(ngModel)]="_selectedtype" optionLabel="name" optionValue="id"
                                    (onChange)="Fetchall()">
                                </p-dropdown>
                                <label for="name"> Supplier Type <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [autoDisplayFirst]="false" [options]="_statuslist" name="statusid"
                                    [(ngModel)]="_supplierstatus" optionLabel="statusname" optionValue="statusid"
                                    (onChange)="Fetchall()">
                                </p-dropdown>
                                <label for="name">Status </label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-dropdown [options]="_userlist" [autoDisplayFirst]="false" name="userid" [(ngModel)]="_responsibleperson"
                                    optionLabel="username" optionValue="userid"
                                    [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="username"
                                    [showClear]="true" (onChange)="Fetchall()">
                                </p-dropdown>
                                <label for="userid">Responsible Person </label>
                            </span>
                        </td>
                        <!-- <td style="width: 20%;"></td> -->
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <p-toast position="bottom-right"></p-toast>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_supplierviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['supplierid', 'suppliername','suppliertypename','paymodename','currencyname',
                            'supplierstatusname','createdbyname','totalproducts']" [rowHover]="true" dataKey="supplierid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 120px;">Action </th>
                                <th pSortableColumn="suppliername">Supplier Name
                                    <p-sortIcon field="suppliername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="suppliertypename">Supplier Type
                                    <p-sortIcon field="suppliertypename"></p-sortIcon>
                                </th>
                                <th pSortableColumn="paymodename">Pay Mode
                                    <p-sortIcon field="paymodename">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname">Currency
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname">Mobile No
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname">Mail ID
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="currencyname">Address<p-sortIcon field="currencyname">
                                        </p-sortIcon>
                                    </th> -->
                                <th pSortableColumn="supplierstatusname">Supplier Status
                                    <p-sortIcon field="supplierstatusname"> </p-sortIcon>
                                </th>
                                <th pSortableColumn="responsiblepersonname">Responsible Person
                                    <p-sortIcon field="responsiblepersonname"></p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="approvalstatus">Approval Status<p-sortIcon field="approvalstatus">
                                    </p-sortIcon>
                                </th> -->
                                <th pSortableColumn="totalproducts">No.of.products mapped
                                    <p-sortIcon field="totalproducts"> </p-sortIcon>
                                </th>

                                <th pSortableColumn="createdbyname">Created By
                                    <p-sortIcon field="createdbyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="createdon">Created On
                                    <p-sortIcon field="createdon">
                                    </p-sortIcon>
                                    <th pSortableColumn="modifiedbyname">Modified By
                                        <p-sortIcon field="modifiedbyname">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="modifiedon">Modified On
                                        <p-sortIcon field="modifiedon">
                                        </p-sortIcon>
                                    </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_supplierview>
                            <tr [ngClass]="{'qs-row': _supplierview.supplierstatusname === 'Inactive', 
                            'hold-row': _supplierview.supplierstatusname === 'Hold'}">
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button  p-mr-2" (click)="view(_supplierview)" [disabled]="!_Action._View"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" class="p-button p-button-success p-mr-2" (click)="edit(_supplierview)" [disabled]="!_Action._Update" [disabled]="_supplierview.approvalstatus == 'MSC00046' || !_Action._Update"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Inactive" [disabled]="!_Action._Delete" [disabled]="_supplierview.supplierstatus == 'MSC00002'" class="p-button p-button-danger" (click)="confirm(_supplierview)" [disabled]="_supplierview.supplierstatusname == 'Inactive' || _supplierview.approvalstatus == 'MSC00046' ||!_Action._Delete "></button>

                                </td>
                                <td>
                                    {{_supplierview.suppliername}}
                                </td>
                                <td>
                                    {{_supplierview.suppliertypename}}
                                </td>
                                <td>
                                    {{_supplierview.paymodename}}
                                </td>
                                <td>
                                    {{_supplierview.currencyname }}
                                </td>
                                <td>
                                    {{_supplierview.mobileno }}
                                </td>
                                <td>
                                    {{_supplierview.mailid }}
                                </td>
                                <!-- <td>
                                        {{_supplierview.address }}
                                    </td> -->
                                <td>
                                    {{_supplierview.supplierstatusname}}
                                </td>
                                <td>
                                    {{_supplierview.responsiblepersonname}}
                                </td>
                                <!-- <td>
                                    {{_supplierview.approvalstatusname}}
                                </td> -->
                                <td class="text-center" style="width: 100px;">
                                    <a href="javascript:void(0);" (click)="GetTotalProducts(_supplierview)">
                                        {{_supplierview.totalproducts}}
                                    </a>
                                </td>
                                <td>
                                    {{_supplierview.createdbyname}}
                                </td>
                                <td>
                                    {{_supplierview.createdon |date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{_supplierview.modifiedbyname}}
                                </td>
                                <td>
                                    {{_supplierview.modifiedon |date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
            <p-dialog header="Mapped Product Details" [(visible)]="mappedproductsdetails" [modal]="true" [style]="{width: '80vw',height: '500px'}" [baseZIndex]="10000">
                <br>
                <table>
                    <tr>
                        <!-- <td style="width: 25px;"> </td> -->

                        <td style="width: 25px;">
                            <span class="p-float-label">
                                <input type="text" name="suppliername" disabled="true" [(ngModel)]="selectedSupplier"
                                    pInputText>
                                <label for="suppliername">Supplier Name</label>
                            </span>
                        </td>
                        <td style="width: 25px;">
                        </td>
                        <td style="width: 25px;">
                        </td>
                        <td style="width: 25px;">
                        </td>
                    </tr>
                </table>
                <br>
                <ng-template pTemplate="content">
                    <div class="popup-body">
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtSupplierProductmapping.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>
                                <ng-template pTemplate="right">
                                </ng-template>
                            </p-toolbar>
                            <!-- <p-table #dt [value]="_supplierviews" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['supplierid', 'suppliername','suppliertypename','paymodename','currencyname',
                                'supplierstatusname','createdbyname','totalproducts']" [rowHover]="true"
                                dataKey="supplierid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                                (onRowUnselect)="onRowUnselect($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true"> -->
                            <p-table #dtSupplierProductmapping [value]="_productmappedlist" selectionMode="single" [columns]="ProductWiseLockedcols" [rowHover]="true" dataKey="productid" [globalFilterFields]="['PRODUCTTYPENAME','CATEGORYNAME','PRODUCTNAME']" [showCurrentPageReport]="true"
                                [rowsPerPageOptions]="[100,200,500,1000,10000]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="100" [paginator]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="SNO" style="width: 60px;">S.no
                                            <p-sortIcon field="SNO">
                                            </p-sortIcon>
                                            <th pSortableColumn="PRODUCTTYPENAME">Product Type
                                                <p-sortIcon field="PRODUCTTYPENAME">
                                                </p-sortIcon>
                                                <th pSortableColumn="CATEGORYNAME">Product Category
                                                    <p-sortIcon field="CATEGORYNAME">
                                                    </p-sortIcon>
                                                </th>
                                                <th pSortableColumn="PRODUCTNAME">Product Name
                                                    <p-sortIcon field="PRODUCTNAME">
                                                    </p-sortIcon>
                                                </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-_SupplierProductmapping let-i="rowIndex">
                                    <tr>
                                        <td style="width: 60px;">
                                            {{i+1}}
                                        </td>
                                        <td>
                                            {{_SupplierProductmapping.PRODUCTTYPENAME}}
                                        </td>
                                        <td>
                                            {{_SupplierProductmapping.CATEGORYNAME}}
                                        </td>
                                        <td>
                                            {{_SupplierProductmapping.PRODUCTNAME}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </ng-template>
            </p-dialog>
            <p-dialog header="Product list mapped only to this supplier" [(visible)]="onesuppliermappedproductlist" [modal]="true" [style]="{width: '80vw',height: '500px'}" [baseZIndex]="10000">

                <ng-template pTemplate="content">
                    <div class="popup-body">
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtSupplierProductmapping.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>
                                <ng-template pTemplate="right">
                                    <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcelonesuppliermappedlist()"></button>
                                    <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdfonesuppliermappedlist()"></button>
                                </ng-template>
                            </p-toolbar>
                            <!-- <p-table #dt [value]="_supplierviews" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['supplierid', 'suppliername','suppliertypename','paymodename','currencyname',
                                'supplierstatusname','createdbyname','totalproducts']" [rowHover]="true"
                                dataKey="supplierid" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                                (onRowUnselect)="onRowUnselect($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true"> -->
                            <p-table #dtSupplierProductmapping [value]="_onesuppliermappedproductlist" selectionMode="single" [columns]="ProductWiseLockedcols" [rowHover]="true" dataKey="productid" [globalFilterFields]="['producttypename','categoryname','productcode','productname','brandname']"
                                [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="10" [paginator]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="SNO" style="width: 60px;">S.no
                                            <p-sortIcon field="SNO">
                                            </p-sortIcon>
                                            <th pSortableColumn="producttypename">Product Type
                                                <p-sortIcon field="producttypename">
                                                </p-sortIcon>
                                                <th pSortableColumn="categoryname">Product Category
                                                    <p-sortIcon field="categoryname">
                                                    </p-sortIcon>
                                                </th>
                                                <th pSortableColumn="productcode">Product Code
                                                    <p-sortIcon field="productcode">
                                                    </p-sortIcon>
                                                </th>
                                                <th pSortableColumn="productname">Product Name
                                                    <p-sortIcon field="productname">
                                                    </p-sortIcon>
                                                </th>
                                                <th pSortableColumn="brandname">Brand
                                                    <p-sortIcon field="brandname">
                                                    </p-sortIcon>
                                                </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-_SupplierProductmapping let-i="rowIndex">
                                    <tr>
                                        <td style="width: 60px;">
                                            {{i+1}}
                                        </td>
                                        <td>
                                            {{_SupplierProductmapping.producttypename}}
                                        </td>
                                        <td>
                                            {{_SupplierProductmapping.categoryname}}
                                        </td>
                                        <td>
                                            {{_SupplierProductmapping.productcode}}
                                        </td>
                                        <td>
                                            {{_SupplierProductmapping.productname}}
                                        </td>
                                        <td>
                                            {{_SupplierProductmapping.brandname}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </ng-template>
            </p-dialog>
            <!-- </form> -->
        </div>
    </div>
</div>