import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { MessageService } from 'primeng/api';
import { Salesquotationothercharge } from 'src/app/core/Models/Salesquotationothercharge';
import { Customer } from 'src/app/core/Models/Customer';
import { Currency } from 'src/app/core/Models/Currency';
import { Level } from 'src/app/core/Models/Level';
import { Employee } from 'src/app/core/Models/Employee';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { SalesquotationService } from 'src/app/core/Services/sales/salesquotation.service';
import { Termsandcondition } from 'src/app/core/Models/Termsandcondition';
import { Salescustomerquicksaveview } from 'src/app/core/Models/Salescustomerquicksave';
import { vAddress, vCurrencyNameSelect, vCustomerName, vCustomerNameSelect, vEffectiveFrom, vEmailID, vLevelNameSelect, vMobileNumber, vProductNameSelect, vQuantity, vQuotationPreparedBySelect, vSalesQuotationDateSelect } from 'src/app/core/Validators/validation';
import { Salesquotationterm } from 'src/app/core/Models/Salesquotationterm';
import { Salesquotation } from 'src/app/core/Models/Salesquotation';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { Salesquotationproductdetailsview } from 'src/app/core/Models/Salesquotationproductdetailview';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { KeyPress } from 'src/assets/js/KeyPress';
import { ConfirmationService } from 'primeng/api';
import { Tax } from 'src/app/core/Models/Tax';
import { Customerview } from 'src/app/core/Views/Customerview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';

@Component({
	selector: 'app-c-sales-quotation',
	templateUrl: './c-sales-quotation.component.html',
	styleUrls: ['./c-sales-quotation.component.css']
})
export class CSalesQuotationComponent implements OnInit {
	_salesquotationform: FormGroup;
	_salestermsform: FormGroup;
	_salesproductform: FormGroup;
	_customerform: FormGroup;
	_salescharges: FormGroup;
	filteredProducts: any[];
	_OtherCharges: Salesquotationothercharge[] = [];
	_ProductDetails: Salesquotationproductdetailsview[] = [];
	selectedOtherChargesRows: any;
	selectedProductDetailsRows: any;
	_selectedlevel: number;
	_selectedproduct: number;
	_selectedcustomer: number;
	_SalesQuotation: Salesquotation;
	_SalesQuotationObj: any;
	_Customers: Salescustomerquicksaveview[] = [];
	_Customernames: Customerview[];
	taxes: Tax[] = [];
	_customers: Customer;
	_Currencies: Currency[];
	_CurrenciesTemp: Currency[];
	_Status: Metadatum;
	_customertype: Metadatum;
	_MarkupLevels: Level;
	_Quotationsby: Employee;
	_Descriptions: Othercharge[];
	_ChargesTemp: Othercharge[];
	_ProductTypes: Producttype;
	_ProductCategories: Productcategory[];
	_ProductNames: Productoverallview[] = [];
	_tempproductname: Productoverallview[] = [];
	_Variants: Productvariant[];
	_UomValues: Metadatum[];
	_PaymentTerms: Termsandcondition;
	_DeliveryTerms: Termsandcondition;
	_OtherTerms: Termsandcondition;
	displayBasic: boolean;
	_action: string = "create";
	_SalesQuotationno: string;
	_Customer: Salescustomerquicksaveview;
	_othercharge: Salesquotationothercharge[]
	_OtherBalance: number;
	_TotalAmount: number;
	_submitted = false;
	_submitted1 = false;
	_selectedcurrency: string;
	_selectedamount: number;
	_SelectedPaymentTerms: Salesquotationterm[] = [];
	_SelectedOtherTerms: Salesquotationterm[] = [];
	_SelectedDeliveryTerms: Salesquotationterm[] = [];
	_prevPaymentSelectedTerms: Termsandcondition[] = [];
	_prevDeliverySelectedTerms: Termsandcondition[] = [];
	_prevOtherSelectedTerms: Termsandcondition[] = [];
	_selectedPaymentTermsIDs: number[] = [];
	_selectedDeliveryTermsIDs: number[] = [];
	_selectedOtherTermsIDs: number[] = [];
	minDate: Date;
	_userid = sessionStorage["userid"];
	_series = sessionStorage["Series"];
	_branchid = sessionStorage["currentbranchid"]
	isDisabledsave = true;
	isDisabledClear = false;
	isDisabledcustomer = true;
	isDisabledcurrency = true;
	isDisabledlevel = true;
	_salesquotationid: number;
	ManageDialog: boolean;
	_customersubmitted = false;
	_UnitPrice: number;
	_IsProgressSpinner: boolean = true;
	isDisabledpaymentterms = false;
	isDisableddeliveryterms = false;
	isDisabledother = false;
	isDisabledSendtoapproval = true;
	isDisabledSendEmail = true;
	isDisabledPrint = true;
	isDisabledAdd = false;
	isDisabledProductClear = false;
	isDisabledRemove = false;
	isDisabledAddOtherCharges = false;
	isDisabledRemoveOtherCharges = false;
	_netamount: number;
	_QuotationId: string;
	_Series: string;
	selectedtab: number = 0;
	productcatid: any[] = [];
	producttypeid: any[] = [];
	_stockinhand: any[] = [];
	isDisabledQuicksave = false;
	isDisabledprint = true;
	_ProductTypeObj: any;
	_ProductCategoryObj: any;
	_bankDetails: any[] = [];
	_customercreditlimit: number;
	_tempcustomercreditlimit: number;
	_Action: IAction;
	hidequantity: boolean = false;
	mindate: Date;
	maxdate: Date;

	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(
		private fb: FormBuilder,
		private _router: Router,
		private utility: CommonUtilities,
		public keypress: KeyPress,
		private messageService: MessageService,
		private _CustomExceptionService: CustomExceptionService,
		private _SalesQuotationService: SalesquotationService,
		private _hotkeysService: HotkeysService,
		private confirmationService: ConfirmationService,
		private _AccessRightsService: AccessRightsService

	) {
		this._SalesQuotationno = history.state?.salesquotationno ? history.state?.salesquotationno : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.HotKeyIntegration();
	}
	InitializeSalesquotationForm() {
		this._salesquotationform = this.fb.group({
			salesquotationid: [0],
			companyid: [0],
			branchid: [],
			salesquotationno: [],
			salesquotationdate: ['', vSalesQuotationDateSelect],
			customerid: ['', vCustomerNameSelect],
			billingaddress: [],
			shippingaddress: [],
			currencyreferenceid: [],
			currencyid: ['', vCurrencyNameSelect],
			markuplevelid: ['', vLevelNameSelect],
			quotationpreparedby: ['', vQuotationPreparedBySelect],
			effectivefrom: ['', vEffectiveFrom],
			effectiveto: [],
			productamount: [],
			taxamount: [],
			discountamount: [],
			otherchargesamount: [],
			salesquotationamount: [],
			amountinusd: [],
			status: [],
			statusremarks: [],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			rv: [],
			mobileno: ['', vMobileNumber],
			approvalstatus: [],
			customer: [],
			salesquotationdetails: [],
			salesquotationothercharges: [],
			salesquotationterms: [],
			bankid: [],
			vattype: [],
			specificvattype: [],
			transactionincompleted:[1],
		});
		this._salesquotationform.controls['status'].setValue("MSC00001");
		this._salesquotationform.controls['salesquotationdate'].setValue(new Date());
		this._salesquotationform.controls['effectivefrom'].setValue(new Date());
		this._salesquotationform.controls['quotationpreparedby'].setValue(parseInt(this._userid));
	}
	InitializeSalestermsForm() {
		this._salestermsform = this.fb.group({
			paymenttermsid: [],
			deliveryermsid: [],
			othertermsid: []
		});
	}
	InitializeSalesProductForm() {
		this._salesproductform = this.fb.group({
			producttypeid: [],
			productcategoryid: [],
			productid: ['', vProductNameSelect],
			variantid: [],
			uomcode: [],
			salesquotationdetailid: [],
			salesquotationid: [],
			quantity: [null, [Validators.required]],
			unitprice: [],
			productamount: [],
			taxcode: [],
			taxpercentage: [],
			taxamount: [],
			defaultdiscountpercentage: [],
			customerdiscountpercentage: [],
			approveddiscountpercentage: [],
			discountamount: [],
			profitamount: [],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			stockinhand: []
		});
	}
	InitializeSalesCustomerForm() {
		this._customerform = this.fb.group({
			customerid: [0],
			customercode: [],
			customername: ['', vCustomerName],
			currencyid: [0],
			mailid: ['', vEmailID],
			mobileno: ['', vMobileNumber],
			address: ['', vAddress],
			vatno: [],
			bpno: [],
			createdby: [],
			createdon: [],
			customertype: []
		});
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(53);
		this.hidequantity = true;
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		if (sessionStorage['Environmentenddate'] == "null") {
			this.maxdate = new Date();
		}
		this.utility.pageLoadScript();
		this.InitializeSalesquotationForm();
		this.InitializeSalestermsForm();
		this.InitializeSalesProductForm();
		this.InitializeSalesCustomerForm();
		this._IsProgressSpinner = true;
		this._SalesQuotationService.PageOnLoad(this._SalesQuotationno, this._userid, this._branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._salesquotationform.controls['salesquotationdate'].setValue(new Date());
			this._salesquotationform.controls['status'].setValue("MSC00001");
			var customernames = resultJSON.customers;
			this._Customernames = customernames.filter(c => c.isconsignmentcustomer != true);
			this._Quotationsby = resultJSON.quotationPreparedby;
			this._salesquotationform.controls['quotationpreparedby'].setValue(parseInt(this._userid));
			this._ProductTypes = resultJSON.producttypes;
			this._Currencies = resultJSON.currencies;
			this._MarkupLevels = resultJSON.levels;
			this._DeliveryTerms = resultJSON.deliveryterms
			this._OtherTerms = resultJSON.otherterms;
			this._PaymentTerms = resultJSON.paymentterms;
			this._CurrenciesTemp = resultJSON.currencies;
			this._Descriptions = resultJSON.othercharges;
			this._ChargesTemp = resultJSON.othercharges;
			this.taxes = resultJSON.taxes
			this._Status = resultJSON.salesQuotationstatus;
			this._customertype = resultJSON.customertype;
			this._bankDetails = resultJSON.bankdetails;
			this._IsProgressSpinner = false;
			if (this._action == 'create') {
				this.isDisabledsave = false;
				if (this._Customernames && this._Customernames.length) {
					let userid = sessionStorage["userid"];
					let customer = this._Customernames.find(f => f.userid == userid);
					if (customer) {
						let event = { value: customer.customerid };
						this._salesquotationform.get("customerid").setValue(customer.customerid);
						this.GetCustomer(event);
					}
				}
			}
			if (this._action == 'edit' || this._action == 'view') {
				const updateJSON = JSON.parse(JSON.stringify(result));
				if (updateJSON.salesquotation.effectiveto == null) {
				}
				else {
					updateJSON.salesquotation.effectiveto = new Date(updateJSON.salesquotation.effectiveto);
				}
				updateJSON.salesquotation.salesquotationdate = new Date(updateJSON.salesquotation.salesquotationdate);
				updateJSON.salesquotation.effectivefrom = new Date(updateJSON.salesquotation.effectivefrom);
				this._salesquotationform.setValue(updateJSON.salesquotation);
				this._QuotationId = updateJSON.salesquotation.salesquotationno;
				this._Series = sessionStorage["Series"];
				this._ProductDetails = updateJSON.salesquotationproductdetailsviews;
				this._OtherCharges = updateJSON.salesquotationothercharges;
				var currencyid = updateJSON.salesquotation.currencyid;
				var Name = (this._CurrenciesTemp || []).filter(f => f.currencyid == currencyid)[0].currencyname;
				this._selectedcurrency = Name;
				this._salesquotationform.controls['currencyid'].setValue(parseInt(currencyid));
				var PaymentTermsIds: number[] = [];
				Object.keys(updateJSON.salesquotationPaymentTermsView).map((Index) => {
					PaymentTermsIds.push(updateJSON.salesquotationPaymentTermsView[Index].termsandconditionid);
				});
				this._selectedPaymentTermsIDs = PaymentTermsIds;
				this._prevPaymentSelectedTerms = updateJSON.salesquotationPaymentTermsView;
				this._SelectedPaymentTerms = updateJSON.salesquotationPaymentTermsView;
				var DeliveryTermsIds: number[] = [];
				Object.keys(updateJSON.salesquotationDeliveryTermsView).map((Index) => {
					DeliveryTermsIds.push(updateJSON.salesquotationDeliveryTermsView[Index].termsandconditionid);
				});
				this._selectedDeliveryTermsIDs = DeliveryTermsIds;
				this._prevDeliverySelectedTerms = updateJSON.salesquotationDeliveryTermsView;
				this._SelectedDeliveryTerms = updateJSON.salesquotationDeliveryTermsView;
				var OtherTermsIds: number[] = [];
				Object.keys(updateJSON.salesquotationOthersTermsView).map((Index) => {
					OtherTermsIds.push(updateJSON.salesquotationOthersTermsView[Index].termsandconditionid);
				});
				this._selectedOtherTermsIDs = OtherTermsIds;
				this._prevOtherSelectedTerms = updateJSON.salesquotationOthersTermsView;
				this._SelectedOtherTerms = updateJSON.salesquotationOthersTermsView;
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		if (this._action == 'view') {
			this._salesquotationform.disable();
			this._salesproductform.disable();
			this._salestermsform.disable();
			this.isDisabledsave = true;
			this.isDisabledClear = true;
			this.isDisabledpaymentterms = true;
			this.isDisableddeliveryterms = true;
			this.isDisabledAdd = true;
			this.isDisabledProductClear = true;
			this.isDisabledRemove = true;
			this.isDisabledAddOtherCharges = true;
			this.isDisabledRemoveOtherCharges = true;
			this.isDisabledother = true;
			this._customerform.disable();
			this.isDisabledQuicksave = true;
		}
		else {
			this.isDisabledsave = false;
		}
		if (this._action == 'edit') {
			this.isDisabledcustomer = true;
			this.isDisabledClear = true;
			this.isDisabledSendtoapproval = false;
		}
		else {
			this.isDisabledcustomer = false;
		}
		if (this._action == 'create') {
			this._OtherCharges = [];
			var l_othercharge = new Salesquotationothercharge();
			l_othercharge.salesquotationid = this._salesquotationform.get("salesquotationid").value;
			l_othercharge.salesquotationotherchargesdetailsid = 0;
			l_othercharge.otherchargesamount = 0;
			l_othercharge.createdby = sessionStorage["userid"];
			l_othercharge.modifiedby = sessionStorage["userid"];
			l_othercharge.createdon = new Date();
			l_othercharge.modifiedon = new Date();
			this._OtherCharges.push(l_othercharge);
		}
	}
	AddOtherCharges(event) {
		let otherChargesExists = (this._OtherCharges.filter(s => s.otherchargesid > 0).length == this._OtherCharges.length) && this._OtherCharges.some(s => s.otherchargesid > 0);
		if (!otherChargesExists)
			return;
		var l_othercharge = new Salesquotationothercharge();
		l_othercharge.salesquotationotherchargesdetailsid = 0;
		l_othercharge.salesquotationid = this._salesquotationform.get("salesquotationid").value;
		l_othercharge.createdby = sessionStorage["userid"];
		l_othercharge.modifiedby = sessionStorage["userid"];
		l_othercharge.otherchargesamount = 0;
		l_othercharge.createdon = new Date();
		l_othercharge.modifiedon = new Date();
		l_othercharge.accountingyear = new Date().getFullYear();
		this._OtherCharges.push(l_othercharge);
	}
	RemoveOtherCharges(event, pOthercharges: Salesquotationothercharge) {
		var index = this._OtherCharges.indexOf(pOthercharges);
		this._OtherCharges.splice(index, 1)
		this.CalculateotherCharge();
		if (this._OtherCharges.length <= 0) {
			var l_othercharge = new Salesquotationothercharge();
			l_othercharge.salesquotationotherchargesdetailsid = 0;
			l_othercharge.salesquotationid = this._salesquotationform.get("salesquotationid").value;
			l_othercharge.otherchargesamount = 0;
			l_othercharge.createdby = sessionStorage["userid"];
			l_othercharge.modifiedby = sessionStorage["userid"];
			l_othercharge.createdon = new Date();
			l_othercharge.modifiedon = new Date();
			this._OtherCharges.push(l_othercharge);
		}
	}
	OnchangeOtherCharge(pOtherCharges: Salesquotationothercharge, event: any) {
		let isAlreadyExists = this._OtherCharges.filter(s => s.otherchargesid == event.value).length > 1;
		if (isAlreadyExists) {
			this._CustomExceptionService.handleWarning("Other Charges Already Exists!");
			var index = this._OtherCharges.indexOf(pOtherCharges);
			this._OtherCharges.splice(index, 1)
			return;
		}
		var otherchargeamount = (this._ChargesTemp || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
		pOtherCharges.otherchargesamount = otherchargeamount;
		this.CalculateotherCharge();
	}
	OnChangeotherCharge(row: any, event) {
		let OtherChargesAmount = row.otherchargesamount || 0;
		parseFloat(OtherChargesAmount);
		this._salesquotationform.controls['otherchargesamount'].setValue(OtherChargesAmount.toFixed(3));
		this._salesquotationform.controls['salesquotationamount'].setValue(OtherChargesAmount.toFixed(3));
		this.CalculateotherCharge();
	}
	OnFromDateSelect(event) {
		this.minDate = event;
	}
	CalculateotherCharge() {
		this._OtherBalance = 0;
		this._netamount = 0;
		for (var i = 0; i < this._OtherCharges.length; i++) {
			this._OtherBalance += this._OtherCharges[i].otherchargesamount;
		}
		this._salesquotationform.controls['otherchargesamount'].setValue(this._OtherBalance.toFixed(3));
		for (var i = 0; i < this._ProductDetails.length; i++) {
			this._netamount += this._ProductDetails[i].finalamount;
		}
		this._salesquotationform.controls['otherchargesamount'].setValue(this._OtherBalance.toFixed(3));
		var NetAmount = 0;
		if (this._salesquotationform.get("salesquotationamount").value != undefined || this._salesquotationform.get("salesquotationamount").value != 0) {
			NetAmount = parseFloat(this._netamount + '') + parseFloat(this._OtherBalance + '');
			this._salesquotationform.controls['salesquotationamount'].setValue(NetAmount.toFixed(3));
		} else {
			this._salesquotationform.controls['salesquotationamount'].setValue(this._OtherBalance.toFixed(3));
		}
	}
	GetLevel(event) {
		var selectedlevel = event.value;
		this._selectedlevel = selectedlevel;
	}
	GetCustomer(event): void {
		var selectedcustomer = event.value;
		this._selectedcustomer = selectedcustomer;
		if (selectedcustomer == 0 || selectedcustomer == null) {

			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductDetails = [];
			this._SalesQuotationService.GetCustomerDetails(selectedcustomer).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._IsProgressSpinner = false;
				this._salesquotationform.controls['currencyid'].setValue(resultJSON.customerdetails[0].currencyid);
				this._salesquotationform.controls['mobileno'].setValue(resultJSON.customerdetails[0].mobileno);
				this._salesquotationform.controls['billingaddress'].setValue(resultJSON.customerdetails[0].billingaddress);
				this._salesquotationform.controls['shippingaddress'].setValue(resultJSON.customerdetails[0].shippingaddress);
				this._salesquotationform.controls['markuplevelid'].setValue(resultJSON.customerdetails[0].levelid);
				this._salesquotationform.controls['vattype'].setValue(resultJSON.customerdetails[0].vattype);
				this._salesquotationform.controls['specificvattype'].setValue(resultJSON.customerdetails[0].specificvattype);
				var referenceid = this._Currencies.filter(f => f.currencyid == resultJSON.customerdetails[0].currencyid)[0].currencyuniquerefid;
				this._salesquotationform.controls['currencyreferenceid'].setValue(referenceid);
				this._tempcustomercreditlimit = resultJSON.balancecreditamount || 0.00;
				this._customercreditlimit = resultJSON.balancecreditamount || 0.00;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	GetCurrency(event) {
		var selectedcurrency = event.value;
		var Name = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].currencyname;
		var Amount = (this._CurrenciesTemp || []).filter(f => f.currencyid == event.value)[0].exchangerate;
		this._ProductDetails = [];
		this._selectedcurrency = Name;
		this._selectedamount = Amount;
		this._customercreditlimit = (this._tempcustomercreditlimit || 0.00) * Amount;
	}
	GetProductCategory(event) {
		var selectedProductType = event.value;
		var selectedcurrency = this._salesquotationform.get("currencyid").value;
		var selectedlevel = this._salesquotationform.get("markuplevelid").value;
		var selectedcustomer = this._salesquotationform.get("customerid").value;
		if (selectedcustomer == undefined || selectedcurrency == undefined || selectedlevel == null) {
			this._CustomExceptionService.handleWarning("Please select customer,Currency&level");
			return;
		}
		if (selectedProductType.length == 0 || selectedProductType == null) {
			this._ProductCategories = [];
			this._salesproductform.controls["productcategoryid"].reset();
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": this._branchid || 0 };
			this._SalesQuotationService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ProductCategories = resultJSON.productcategories;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		let productList = this._tempproductname.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
		this._ProductNames = productList.length ? productList : this._tempproductname;
	}
	GetProductName(event) {
		var selectedProductCategory = event.value;
		if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
			this._salesproductform.reset();
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": this._branchid || 0 };
			this._SalesQuotationService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		let productList = [];
		if (this.productcatid.length) {
			productList = this._ProductNames.filter(f => this.producttypeid.includes(f.producttypeid) && this.productcatid.includes(f.productcategoryid)) || [];
		} else {
			productList = this._ProductNames.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
		}
		this._ProductNames = productList;
	}
	GetProduct(event) { }
	GetProductPrice(event) {
		var selectedproduct = event.productid;
		this._selectedproduct = selectedproduct;
		var selectedcurrency = this._salesquotationform.get("currencyid").value;
		var selectedlevel = parseInt(sessionStorage["levelid"]);
		let roleid = sessionStorage["currentRole"];
		var selectedcustomer = this._salesquotationform.get("customerid").value;
		var userid = sessionStorage["userid"];
		if (selectedcustomer == undefined || selectedcustomer == 0 || selectedcurrency == undefined || selectedcurrency == 0 || selectedlevel == 0 || selectedlevel == undefined || selectedproduct == 0 || selectedproduct == undefined) {
			this._CustomExceptionService.handleWarning("Please select customer,Currency&level");
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._SalesQuotationService.GetProductPrice(this._selectedproduct, selectedcustomer, userid, selectedlevel, this._branchid, roleid).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._Variants = resultJSON.productVariantList;
				this._ProductCategories = resultJSON.productcategories;
				this._ProductTypes = resultJSON.producttypes;
				let product = this._ProductNames.filter(f => f.productid == selectedproduct)[0];
				this.producttypeid = [];
				this.producttypeid.push(product.producttypeid);
				this.productcatid = [];
				this._stockinhand = resultJSON.stocks;
				this.productcatid.push(product.productcategoryid);
				this._salesproductform.controls['producttypeid'].setValue(this.producttypeid);
				this._salesproductform.controls['productcategoryid'].setValue(this.productcatid);
				var uom = product.salesuom;
				this._salesproductform.get("quantity").reset();
				if (uom == "MSC00037") {
					this.hidequantity = true;
				}
				else {
					this.hidequantity = false
				}
				let stock = this._stockinhand.filter(f => f.variantid == 0);
				let currentstock = stock[0].stock1;
				this._salesproductform.controls['stockinhand'].setValue(currentstock);
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	AddProducts(event) {
		this._submitted1 = true;
		if (this._salesproductform.valid) {
			var ProductId = parseInt(this._salesproductform.get("productid").value.productid);
			var UserSelectedCurrency = this._salesquotationform.get("currencyid").value;
			var selectedlevel = parseInt(sessionStorage["levelid"]);
			var selectedcustomer = this._salesquotationform.get("customerid").value;
			var userid = sessionStorage["userid"];
			let roleid = sessionStorage["currentRole"];
			if (selectedcustomer == undefined || selectedcustomer == 0 || UserSelectedCurrency == undefined || UserSelectedCurrency == 0 || selectedlevel == 0 || selectedlevel == undefined || ProductId == 0 || ProductId == undefined) {
				this._CustomExceptionService.handleWarning("Please select customer,currency&level");
				return;
			}
			else {
				this._SalesQuotationService.GetProductPrice(ProductId, selectedcustomer, userid, selectedlevel, this._branchid, roleid).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._Variants = resultJSON.productVariantList;
					this._ProductCategories = resultJSON.productcategories;
					this._ProductTypes = resultJSON.producttypes;
					let product = this._ProductNames.filter(f => f.productid == ProductId)[0];
					this.producttypeid = [];
					this.producttypeid.push(product.producttypeid);
					this.productcatid = [];
					this.productcatid.push(product.productcategoryid);
					this._salesproductform.controls['producttypeid'].setValue(this.producttypeid);
					this._salesproductform.controls['productcategoryid'].setValue(this.productcatid);

					this._submitted1 = true;
					var Productdetails = (<Salesquotationproductdetailsview[]>resultJSON["salesproductprice"]) || [];
					var productid = parseInt(this._salesproductform.get("productid").value.productid);
					var productName = (this._ProductNames || []).filter(f => f.productid == productid)[0].productname;
					var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
					var USDCurrency = (this._Currencies || []).filter(f => f.currencyid == 1)[0].exchangerate;
					let errorMsg = '';
					let quantity = this._salesproductform.get("quantity").value || 0;
					let currencyid = this._salesquotationform.get("currencyid").value;
					let userSelectedCurrency = this._Currencies.find(f => f.currencyid == currencyid);
					let userselectedExchangeRate = userSelectedCurrency.exchangerate;
					let customerid = this._salesquotationform.get("customerid").value;
					let customer = this._Customernames.find(f => f.customerid == customerid);
					let vatType = customer.vattype
					for (var p = 0; p < resultJSON["salesproductprice"].length; p++) {
						// usd
						let product = Productdetails[p];
						product.productid = productid;
						product.productname = productName;

						if (this._salesproductform.get("variantid").value == "" || this._salesproductform.get("variantid").value == null) {
							product.variantid = 0;
							product.variantdescription = "";
						}
						else {
							var variantid = parseInt(this._salesproductform.get("variantid").value);
							var variantname = (this._Variants || []).filter(f => f.productvariantdetailid == variantid)[0].variantdescription;
							product.variantid = variantid;
							product.variantname = variantname;
						}
						product.salesquotationid = this._salesquotationform.get("salesquotationid").value;
						product.salesquotationdetailid = 0;
						product.uomcode = resultJSON.salesproductprice[p].salesuom;
						product.uomname = resultJSON.salesproductprice[p].uomname;
						product.quantity = this._salesproductform.get("quantity").value;
						let unitPrice = currencyid == 1 ? product.usd : currencyid == 2 ? product.rand : currencyid == 3 ? product.bond : currencyid == 4 ? product.bank : userselectedExchangeRate * product.usd;
						let unitPrice1 = UserCurrency * product.unitprice;
						let productAmount = (quantity || product.quantity) * unitPrice;
						let productAmount1 = (quantity || product.quantity) * unitPrice1;
						let exculdeTaxAmount = (productAmount * (product.taxpercentage / 100));
						if (product.vattype == "MSC00062") {
							var taxAmount = (productAmount1 * (product.taxpercentage / 100));
						}
						else {
							taxAmount = (productAmount * (product.taxpercentage / 100));
						}
						let app_discountamount = (productAmount * (product.customerdiscountpercentage / 100)) || 0;
						let def_discountamount = (productAmount * (product.discountpercentage / 100)) || 0;
						let finalAmount = (productAmount) - (def_discountamount + app_discountamount)
						let existingProductIndex = this._ProductDetails.findIndex(f => f.productid == product.productid && f.variantid == product.variantid);
						product.customerdiscountamount = product.customerdiscountamount || 0;
						product.approveddiscountamount = product.approveddiscountamount || 0;
						product.approveddiscountpercentage = product.approveddiscountpercentage || 0;
						product.unitprice = unitPrice;
						if (product.vatapplicable) {
							product.taxamount = taxAmount;
							product.taxpercentage = Productdetails[p].taxpercentage;
							product.taxpercentage = resultJSON.salesproductprice[0].taxpercentage || 0;
						}
						else {
							product.taxamount = 0.00;
							product.taxpercentage = 0;
						}
						product.productamount = product.unitprice * quantity;
						product.discountamount = def_discountamount;
						product.customerdiscountamount = app_discountamount;
						product.approveddiscountamount = 0;
						product.finalamount = finalAmount;
						product.discountpercentage = resultJSON.salesproductprice[0].discountpercentage || 0;
						product.customerdiscountpercentage = resultJSON.salesproductprice[0].customerdiscountpercentage || 0;
						//default discount
						product.discountamount = product.productamount * product.discountpercentage / 100;
						var defaultdiscountamount = product.productamount - product.discountamount;
						product.finalamount = defaultdiscountamount;
						if (customer) {
							if (customer.vattype == "MSC00063") // exclude
							{
								var calculatedtaxamount = product.finalamount + product.taxamount;
								product.finalamount = calculatedtaxamount;
							}
						}
						var PurchaseRate = resultJSON.salesproductprice[0].purchaserate;
						var stuffedquantity = parseInt(resultJSON.salesproductprice[0].stuffedqty);
						if (stuffedquantity == 0) {
							product.purchaserate = PurchaseRate;
							var profit = product.quantity * product.purchaserate;
							var finalamtinusd = (product.finalamount) / (UserCurrency)
							product.profitamount = finalamtinusd - profit;
						}
						else {
							var SQPurchaseRate = PurchaseRate / stuffedquantity;
							product.purchaserate = SQPurchaseRate;
							var profit = product.quantity * product.purchaserate;
							var finalamtinusd = (product.finalamount) / (UserCurrency)
							product.profitamount = finalamtinusd - profit;
						}
						this._salesquotationform.controls['taxamount'].setValue(product.taxamount.toFixed(3));
						product.createdby = sessionStorage["userid"];
						product.createdon = new Date();
						product.modifiedby = sessionStorage["userid"];
						product.modifiedon = new Date();
						product.accountingyear = new Date().getFullYear();
						product.barcode = resultJSON.salesproductprice[0].barcode;
						product.defaultdiscountpercentage = resultJSON.salesproductprice[0].discountpercentage || 0;
						product.maximumdiscountpercentage = resultJSON.salesproductprice[0].maximumdiscountpercentage;
						product.userdiscountpercentage = resultJSON.salesproductprice[0].userdiscountpercentage;
						product.taxcode = resultJSON.salesproductprice[0].taxid;
						if (this._ProductDetails.length > 0) {
							for (var i = 0; i < this._ProductDetails.length; i++) {
								if (product.productid == this._ProductDetails[i].productid && product.variantid == this._ProductDetails[i].variantid) {
									this._CustomExceptionService.handleWarning("Product or Variant Already Exists!");
									return;
								}
							}
						}
						this._ProductDetails.push(product);
					}
					this._submitted1 = false;
					this.Clear(event);
					var FinalTotalAmount = 0
					var FinalGrossAmount = 0
					var FinalTaxAmount = 0
					var FinalDiscountAmount = 0
					var FinalCustomerAmount = 0
					for (var i = 0; i < this._ProductDetails.length; i++) {
						FinalTotalAmount += this._ProductDetails[i].finalamount;
						FinalGrossAmount += this._ProductDetails[i].productamount;
						FinalTaxAmount += this._ProductDetails[i].taxamount;
						FinalDiscountAmount += this._ProductDetails[i].discountamount || 0;
						FinalCustomerAmount += this._ProductDetails[i].customerdiscountamount || 0;
					}
					var OtherChargesAmount = this._salesquotationform.get("otherchargesamount").value || 0;
					this._salesquotationform.controls['productamount'].setValue(FinalGrossAmount.toFixed(3));
					this._salesquotationform.controls['taxamount'].setValue(FinalTaxAmount.toFixed(3));
					this._salesquotationform.controls['salesquotationamount'].setValue(FinalTotalAmount.toFixed(3));
					var TotalAmount = 0;
					TotalAmount = parseFloat(FinalTotalAmount + '') + parseFloat(OtherChargesAmount + '');
					this._salesquotationform.controls['salesquotationamount'].setValue(TotalAmount.toFixed(3));
					var TotalDiscountAmount = parseFloat(FinalCustomerAmount + '') + parseFloat(FinalDiscountAmount + '');
					this._salesquotationform.controls['discountamount'].setValue(TotalDiscountAmount.toFixed(3));
					//Net Amount In USD
					var NetAmountInUSD = (TotalAmount) / (UserCurrency);
					this._salesquotationform.get('amountinusd').setValue(NetAmountInUSD.toFixed(3));
					this.OnCustomerCreditChange();
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
		}
	}
	// 27.perticular row should be removed and corresponding qty,price,discount,value sould be recalculate
	// 34.system should calculate the total of value in product details grid and  fetch the total value in 'Gross Amount' text field
	RemoveProduct(pProduct: Salesquotationproductdetailsview) {
		var index = this._ProductDetails.indexOf(pProduct);
		this._ProductDetails.splice(index, 1)
		var UserSelectedCurrency = this._salesquotationform.get("currencyid").value;
		var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
		var FinalTotalAmount = 0
		var FinalGrossAmount = 0
		var FinalTaxAmount = 0
		var FinalDiscountAmount = 0
		var FinalCustomerAmount = 0
		for (var i = 0; i < this._ProductDetails.length; i++) {
			FinalTotalAmount += this._ProductDetails[i].finalamount;
			FinalGrossAmount += this._ProductDetails[i].productamount;
			FinalTaxAmount += this._ProductDetails[i].taxamount;
			FinalDiscountAmount += this._ProductDetails[i].discountamount || 0;
			FinalCustomerAmount += this._ProductDetails[i].customerdiscountamount || 0;
		}
		this._salesquotationform.controls['salesquotationamount'].setValue(FinalTotalAmount.toFixed(3));
		this._salesquotationform.controls['productamount'].setValue(FinalGrossAmount.toFixed(3));
		this._salesquotationform.controls['taxamount'].setValue(FinalTaxAmount.toFixed(3));
		var TotalDiscountAmount = parseFloat(FinalCustomerAmount + '') + parseFloat(FinalDiscountAmount + '');
		this._salesquotationform.controls['discountamount'].setValue(TotalDiscountAmount.toFixed(3));
		//Net Amount In USD
		var NetAmountInUSD = (FinalTotalAmount) / (UserCurrency);
		this._salesquotationform.get('amountinusd').setValue(NetAmountInUSD.toFixed(3));
		this.OnCustomerCreditChange();
	}

	Clear(event) {
		this._submitted1 = true;
		this._salesproductform.reset();
		this.productcatid = [];
		this._Variants = [];
		this._ProductCategories = [];
		this._submitted1 = false;
	}

	//Caluculation
	allocatequantity(row: any, event) {
		var UserSelectedCurrency = this._salesquotationform.get("currencyid").value;
		var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
		if (row.customerdiscountpercentage == 0) {
			var unitprice = row.unitprice;
			row.productamount = row.quantity * unitprice;
			row.finalamount = row.productamount;
			let unitPrice1 = UserCurrency * row.unitprice;

			let productAmount1 = (row.quantity) * unitPrice1;
			//default discount
			row.discountamount = row.productamount * row.discountpercentage / 100 || 0;
			//Customer discount
			row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
			//Tax Calculation
			if (row.vattype == "MSC00062") {
				row.taxamount = (productAmount1 * (row.taxpercentage / 100));
			}
			else {
				row.taxamount = row.productamount * row.taxpercentage / 100;
			}
			//Profit Calculation
			var profit = row.quantity * row.purchaserate;
			row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount);

			if (row.vattype == "MSC00063") // exclude
			{
				var calculatedtaxamount = row.finalamount + row.taxamount;
				row.finalamount = calculatedtaxamount;
			}


			var finalamtinusd = (row.finalamount) / (UserCurrency)
			row.profitamount = finalamtinusd - profit;
			this.Calculate(row);
		}
		else {
			var unitprice = row.unitprice;
			row.productamount = row.quantity * unitprice;
			row.finalamount = row.productamount;
			let unitPrice1 = UserCurrency * row.unitprice;

			let productAmount1 = (row.quantity) * unitPrice1;
			//Customer discount
			row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
			//Tax Calculation
			if (row.vattype == "MSC00062") {
				row.taxamount = (productAmount1 * (row.taxpercentage / 100));
			}
			else {
				row.taxamount = row.productamount * row.taxpercentage / 100;
			}

			//Profit Calculation
			var profit = row.quantity * row.purchaserate;

			row.finalamount = (row.productamount) - (row.customerdiscountamount);

			if (row.vattype == "MSC00063") // exclude
			{
				var calculatedtaxamount = row.finalamount + row.taxamount;
				row.finalamount = calculatedtaxamount;
			}

			var finalamtinusd = (row.finalamount) / (UserCurrency)
			row.profitamount = finalamtinusd - profit;
			this.Calculate(row);
		}
	}

	allocatecustomerdiscount(row: any, event) {
		var UserSelectedCurrency = this._salesquotationform.get("currencyid").value;
		var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
		var userdiscount = parseInt(sessionStorage["userdiscountpercentage"]);
		var customerdiscount = parseInt(row.customerdiscountpercentage);
		let unitPriceforvatcal = 0;
		if (row.vattype == "MSC00062") {
			unitPriceforvatcal = UserSelectedCurrency == 1 ? row.unitpriceusd : UserSelectedCurrency == 2 ? row.unitpricerand : UserSelectedCurrency == 3 ? row.unitpricebond : UserSelectedCurrency == 4 ? row.unitpricebank : UserCurrency * row.unitpriceusd;
		} else {
			unitPriceforvatcal = UserSelectedCurrency == 1 ? row.unitpriceusd : UserSelectedCurrency == 2 ? row.unitpricerand : UserSelectedCurrency == 3 ? row.unitpricebond : UserSelectedCurrency == 4 ? row.unitpricebank : UserCurrency * row.unitpriceusd;
		}
		row.discountamount = 0;
		row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
		//Tax Calculation
		if (row.vattype == "MSC00062" || row.vattype == "MSC00063") {
			row.taxamount = ((row.quantity * unitPriceforvatcal) - (row.discountamount + row.customerdiscountamount)) * (row.taxpercentage / 100);
		} else {
			row.taxamount = 0;
		}
		//Profit Calculation
		var profit = row.quantity * row.purchaserate;
		if (row.vattype == "MSC00063") {
			row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount) + (row.taxamount);
		}
		else {
			row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount);
		}
		var finalamtinusd = (row.finalamount) / (UserCurrency)
		row.profitamount = finalamtinusd - profit;
		this.Calculate(row);
		if (row.customerdiscountpercentage <= 0) {
			//default discount
			row.discountamount = row.productamount * row.discountpercentage / 100 || 0;
			row.customerdiscountpercentage = 0
			row.customerdiscountamount = 0
			//Tax Calculation
			if (row.vattype == "MSC00062" || row.vattype == "MSC00063") {
				row.taxamount = ((row.quantity * unitPriceforvatcal) - row.discountamount) * (row.taxpercentage / 100);
			} else {
				row.taxamount = 0;
			}
			//Profit Calculation
			var profit = row.quantity * row.purchaserate;
			if (row.vattype == "MSC00063") {
				row.finalamount = (row.productamount) - (row.discountamount) + (row.taxamount);
			}
			else {
				row.finalamount = (row.productamount) - (row.discountamount);
			}
			var finalamtinusd = (row.finalamount) / (UserCurrency)
			row.profitamount = finalamtinusd - profit;
			this._salesquotationform.controls['approvalstatus'].setValue("MSC00047");
			this.Calculate(row);
		}
		if (customerdiscount > row.maximumdiscountpercentage) {
			this._CustomExceptionService.handleWarning("Customer discount percentage should not greater than product discount percentage");
			row.customerdiscountpercentage = 0;
			row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
			//default discount
			row.discountamount = row.productamount * row.discountpercentage / 100 || 0;
			//Tax Calculation
			if (row.vattype == "MSC00062" || row.vattype == "MSC00063") {
				row.taxamount = ((row.quantity * unitPriceforvatcal) - (row.discountamount + row.customerdiscountamount)) * (row.taxpercentage / 100);
			} else {
				row.taxamount = 0;
			}
			//Profit Calculation
			var profit = row.quantity * row.purchaserate;
			if (row.vattype == "MSC00063") {
				row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount) + (row.taxamount);
			}
			else {
				row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount);
			}
			var finalamtinusd = (row.finalamount) / (UserCurrency)
			row.profitamount = finalamtinusd - profit;
			this._salesquotationform.controls['approvalstatus'].setValue("MSC00047");
			this.Calculate(row);
			return;
		}
		if (customerdiscount > userdiscount || customerdiscount > row.discountpercentage) {
			this.confirmationService.confirm({
				message: 'Your discount percentage exceeds allocated percentage.so this document go for approval <b>"' + row.customerdiscountpercentage + '"</b> ?',
				accept: () => {
					//Customer discount
					row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
					//Tax Calculation
					if (row.vattype == "MSC00062" || row.vattype == "MSC00063") {
						row.taxamount = ((row.quantity * unitPriceforvatcal) - (row.discountamount + row.customerdiscountamount)) * (row.taxpercentage / 100);
					} else {
						row.taxamount = 0;
					}
					//Profit Calculation
					var profit = row.quantity * row.purchaserate;
					if (row.vattype == "MSC00063") {
						row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount) + (row.taxamount);
					}
					else {
						row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount);
					}
					var finalamtinusd = (row.finalamount) / (UserCurrency)
					row.profitamount = finalamtinusd - profit;
					this._salesquotationform.controls['approvalstatus'].setValue("MSC00045");
					//Actual logic to perform a confirmation
					this.Calculate(row);
				},
				reject: () => {
					row.customerdiscountpercentage = 0;
					row.customerdiscountamount = row.productamount * row.customerdiscountpercentage / 100 || 0;
					//default discount
					row.discountamount = row.productamount * row.discountpercentage / 100 || 0;
					//Tax Calculation
					if (row.vattype == "MSC00062" || row.vattype == "MSC00063") {
						row.taxamount = ((row.quantity * unitPriceforvatcal) - (row.discountamount + row.customerdiscountamount)) * (row.taxpercentage / 100);
					} else {
						row.taxamount = 0;
					}
					//Profit Calculation
					var profit = row.quantity * row.purchaserate;
					if (row.vattype == "MSC00063") {
						row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount) + (row.taxamount);
					}
					else {
						row.finalamount = (row.productamount) - (row.discountamount + row.customerdiscountamount);
					}
					var finalamtinusd = (row.finalamount) / (UserCurrency)
					row.profitamount = finalamtinusd - profit;
					this._salesquotationform.controls['approvalstatus'].setValue("MSC00047");
					this.Calculate(row);
				}
			});

		}
	}

	Calculate(row) {
		let customerid = this._salesquotationform.get("customerid").value;
		let customer = this._Customernames.find(f => f.customerid == customerid);
		let tax = this.taxes.find(f => f.taxid == 1);
		var UserSelectedCurrency = this._salesquotationform.get("currencyid").value;
		var UserCurrency = (this._Currencies || []).filter(f => f.currencyid == UserSelectedCurrency)[0].exchangerate;
		var selectedindex = this._ProductDetails.findIndex(obj => obj == row);

		if (this._ProductDetails[selectedindex].defaultdiscountpercentage == undefined) {
			this._ProductDetails[selectedindex].defaultdiscountpercentage = 0;
		}
		if (this._ProductDetails[selectedindex].customerdiscountamount == undefined) {
			this._ProductDetails[selectedindex].customerdiscountamount = 0;
		}
		if (this._ProductDetails[selectedindex].customerdiscountpercentage == undefined) {
			this._ProductDetails[selectedindex].customerdiscountpercentage = 0;
		}
		if (this._ProductDetails[selectedindex].approveddiscountpercentage == undefined) {
			this._ProductDetails[selectedindex].approveddiscountpercentage = 0;
		}
		if (this._ProductDetails[selectedindex].finalamount == undefined) {
			this._ProductDetails[selectedindex].finalamount = 0;
		}
		if (this._ProductDetails[selectedindex].discountamount == undefined) {
			this._ProductDetails[selectedindex].discountamount = 0;
		}

		var FinalTotalAmount = 0
		var FinalGrossAmount = 0
		var FinalTaxAmount = 0
		var TotalAmount = 0;
		var FinalDiscountAmount = 0
		var FinalCustomerAmount = 0
		let FinalTaxAmout = 0



		for (var i = 0; i < this._ProductDetails.length; i++) {
			const x = this._ProductDetails[i]
			FinalTotalAmount += x.finalamount;
			FinalGrossAmount += x.productamount;
			FinalTaxAmount += x.taxamount;
			FinalTaxAmout += x.taxamount;
			FinalDiscountAmount += x.discountamount;
			FinalCustomerAmount += x.customerdiscountamount;
			x.finalamount = parseFloat(x.finalamount.toFixed(3));
			x.productamount = parseFloat(x.productamount.toFixed(3));
			x.taxamount = parseFloat(x.taxamount.toFixed(3));
			x.taxamount = parseFloat(x.taxamount.toFixed(3));
			x.discountamount = parseFloat((x.discountamount || 0).toFixed(3));
			x.customerdiscountamount = parseFloat((x.customerdiscountamount || 0).toFixed(3));
		}

		// if (customer) {
		// 	if (customer.vattype == "MSC00063") // exclude
		// 	{
		// 		let taxAmount = (TotalAmount * (tax.taxpercentage / 100));
		// 		FinalTaxAmout = taxAmount;
		// 		FinalTotalAmount += FinalTaxAmout;
		// 	}
		// 	else {
		// 		let taxAmount = 0;
		// 		FinalTaxAmout = taxAmount;
		// 		FinalTotalAmount += FinalTaxAmout;
		// 	}
		// }

		var OtherChargesAmount = this._salesquotationform.get("otherchargesamount").value || 0;
		this._salesquotationform.controls['productamount'].setValue(FinalGrossAmount.toFixed(3));
		this._salesquotationform.controls['taxamount'].setValue(FinalTaxAmount.toFixed(3));
		TotalAmount = parseFloat(FinalTotalAmount + '') + parseFloat(OtherChargesAmount + '');
		this._salesquotationform.controls['salesquotationamount'].setValue(TotalAmount.toFixed(3));
		var TotalDiscountAmount = parseFloat(FinalCustomerAmount + '') + parseFloat(FinalDiscountAmount + '');
		this._salesquotationform.controls['discountamount'].setValue(TotalDiscountAmount.toFixed(3));
		//Net Amount In USD
		var NetAmountInUSD = (TotalAmount) / (UserCurrency);
		this._salesquotationform.get('amountinusd').setValue(NetAmountInUSD.toFixed(3));
	}

	//Customers Quick save
	showBasicDialog() {
		this.ManageDialog = true;
	}
	//Customers Quick save

	Save() {
		this._customersubmitted = true;
		if (this._customerform.valid) {
			this._Customer = this._customerform.value;
			if (!this._Customer.customername || (this._Customer.customername.trim()) === "") {
				this._CustomExceptionService.handleWarning("Customer Name Cannot Be Empty.Please Enter Customer Name")
				return
			}
			this._Customer.customerid = 0;
			this._Customer.createdon = new Date();
			this._Customer.createdby = sessionStorage["userid"];
			this._Customer.levelid = sessionStorage["levelid"];
			this._Customer.branchid = sessionStorage["currentbranchid"];
			// this.onEd();
			this._IsProgressSpinner = true;
			this._SalesQuotationService.customercreate(this._Customer).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._IsProgressSpinner = false;
				this._Customernames = resultJSON.customers;
				if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
					this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
					this.Cancel();
					this._IsProgressSpinner = false;
				}
				else {
					this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
				} this._IsProgressSpinner = false;
			},

				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	Cancel() {
		this._customersubmitted = true;
		this.ManageDialog = false;
		this._customerform.reset();
		this.InitializeSalesCustomerForm();
		this._customersubmitted = false;
	}


	onSelectPaymentTerms(event) {
		var l_Paymentterms: Salesquotationterm[] = [];
		var l_paymentterm: Salesquotationterm;

		Object.keys(event.value).map((Index) => {
			l_paymentterm = new Salesquotationterm();
			var l_paymenttermlist: Termsandcondition = this._PaymentTerms;
			l_paymentterm.termsandconditionid = event.value[Index];
			l_paymentterm.salesquotationid = this._salesquotationform.get("salesquotationid").value;
			l_paymentterm.salesquotationtermid = 0;
			l_paymentterm.accountingyear = new Date().getFullYear();
			l_paymentterm.termdescription = l_paymenttermlist[Index].termsdescription;
			l_paymentterm.termtypeid = event.value[Index];
			l_paymentterm.createdon = new Date();
			l_paymentterm.createdby = sessionStorage["userid"];
			l_paymentterm.modifiedby = sessionStorage["userid"];
			l_paymentterm.modifiedon = new Date();
			l_Paymentterms.push(l_paymentterm);
		})
		this._SelectedPaymentTerms = l_Paymentterms;
	}

	onSelectDeliveryTerms(event) {
		var l_Deliveryterms: Salesquotationterm[] = [];
		var l_deliveryterm: Salesquotationterm;
		var l_deliverytermlist: Termsandcondition = this._DeliveryTerms;
		Object.keys(event.value).map((Index) => {
			l_deliveryterm = new Salesquotationterm();

			l_deliveryterm.termsandconditionid = event.value[Index];
			l_deliveryterm.salesquotationid = this._salesquotationform.get("salesquotationid").value;
			l_deliveryterm.salesquotationtermid = 0;
			l_deliveryterm.accountingyear = new Date().getFullYear();
			l_deliveryterm.termdescription = l_deliverytermlist[Index].termsdescription;
			l_deliveryterm.termtypeid = event.value[Index];
			l_deliveryterm.createdon = new Date();
			l_deliveryterm.createdby = sessionStorage["userid"];
			l_deliveryterm.modifiedby = sessionStorage["userid"];
			l_deliveryterm.modifiedon = new Date();
			l_Deliveryterms.push(l_deliveryterm);
		})
		this._SelectedDeliveryTerms = l_Deliveryterms;
	}
	onSelectOtherTerms(event) {
		var l_Otherterms: Salesquotationterm[] = [];
		var l_otherterm: Salesquotationterm;
		Object.keys(event.value).map((Index) => {
			l_otherterm = new Salesquotationterm();
			var l_othertermlist: Termsandcondition = this._OtherTerms;
			l_otherterm.termsandconditionid = event.value[Index];
			l_otherterm.salesquotationid = this._salesquotationform.get("salesquotationid").value;
			l_otherterm.salesquotationtermid = 0;
			l_otherterm.accountingyear = new Date().getFullYear();
			l_otherterm.termdescription = l_othertermlist[Index].termsdescription;
			l_otherterm.termtypeid = event.value[Index];
			l_otherterm.createdon = new Date();
			l_otherterm.createdby = sessionStorage["userid"];
			l_otherterm.modifiedby = sessionStorage["userid"];
			l_otherterm.modifiedon = new Date();
			l_Otherterms.push(l_otherterm);
		})
		this._SelectedOtherTerms = l_Otherterms;

	}

	onSave() {
		this._submitted = true;
		if (this._salesquotationform.get("approvalstatus").value == null) {
			this._salesquotationform.controls['approvalstatus'].setValue("MSC00047");
		}
		if (this._salesquotationform.valid) {

			var SalesQuotationDate = this._salesquotationform.get("salesquotationdate").value;
			var Effectivefrom = this._salesquotationform.get("effectivefrom").value;
			var Effectiveto = this._salesquotationform.get("effectiveto").value;
			if (Effectiveto == null) {
				this._salesquotationform.get("effectiveto").setValue(null);
			}
			else {
				var datePipe = new DatePipe("en-US");

				var peffectiveto = datePipe.transform(Effectiveto, 'yyyy-MM-dd');
			}
			var datePipe = new DatePipe("en-US");
			var peffectivefrom = datePipe.transform(Effectivefrom, 'yyyy-MM-dd');

			var psalesquotationdate = datePipe.transform(SalesQuotationDate, 'yyyy-MM-dd');
			//For Model and Form Mapping
			this._SalesQuotation = this._salesquotationform.value;
			this._SalesQuotation.salesquotationdate = (new Date(psalesquotationdate));

			if (peffectivefrom > peffectiveto) {
				this._CustomExceptionService.handleWarning("Effective From Date should be less than the Effective to Date");
				return;
			}

			this._SalesQuotation.effectivefrom = (new Date(peffectivefrom));
			this._SalesQuotation.effectiveto = (new Date(peffectiveto));
			this._SelectedPaymentTerms = this._SelectedPaymentTerms.concat(this._SelectedOtherTerms);
			this._SelectedPaymentTerms = this._SelectedPaymentTerms.concat(this._SelectedDeliveryTerms);
			if (!this._ProductDetails || this._ProductDetails.length == 0) {
				this._CustomExceptionService.handleError(usererrors.ProductDetails_Tab);
				this.selectedtab = 1;
				return;
			}

			var currencyid = this._salesquotationform.get("currencyid").value;
			var bankid = this._salesquotationform.get("bankid").value;

			if (currencyid == 4)//BANK CURRENCY
			{
				if (!bankid) {
					this._CustomExceptionService.handleWarning("Please Select Bhola Bank Details");
					return;
				}
			}

			this._SalesQuotationObj = { "SalesQuotation": this._SalesQuotation, "SalesQuotationdetails": this._ProductDetails, "SalesQuotationothercharges": this._OtherCharges, "SalesQuotationterms": this._SelectedPaymentTerms };
			if (this._action == 'create') {
				this._SalesQuotation.createdby = sessionStorage["userid"];
				this._SalesQuotation.createdon = new Date();
				this._SalesQuotation.accountingyear = new Date().getFullYear();
				this._SalesQuotation.branchid = sessionStorage["currentbranchid"];

				// this.onEd();
				this._IsProgressSpinner = true;
				this._SalesQuotationService.create(this._SalesQuotationObj).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._QuotationId = resultJSON.salesQuotationno;
					this._Series = resultJSON.series;
					this._IsProgressSpinner = false;
					if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
						this._IsProgressSpinner = false;
						this.reset(null);
						// this.isDisabledSendtoapproval = false;
						this.isDisabledsave = false;

					}
					else {
						this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
					} this._IsProgressSpinner = false;
				},
					error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
			else if (this._action == 'edit') {
				this._SalesQuotation.modifiedon = new Date();
				this._SalesQuotation.modifiedby = sessionStorage["userid"];
				this._SalesQuotationObj.SalesQuotation.customer = "";
				this._SalesQuotationObj.SalesQuotation.salesquotationothercharges = "";
				// this.onEd();
				this._IsProgressSpinner = true;
				this._SalesQuotationService.edit(this._SalesQuotationObj).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._QuotationId = resultJSON.salesQuotationno;
					this._Series = resultJSON.series;
					this.isDisabledsave = true;
					this._IsProgressSpinner = false;
					if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
						this._IsProgressSpinner = false;
						this.isDisabledSendtoapproval = false;
					}
					else {
						this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
					} this._IsProgressSpinner = false;
				},

					error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}

		}
		else {
			this._CustomExceptionService.handleError(usererrors.SalesQuotationDetails_Tab);
			this.selectedtab = 0;
			return;
		}
	}
	// 53.All input fields should be cleared.
	reset(event) {
		this._submitted = false;
		this._salesquotationform.reset();
		this.ngOnInit();
		this._ProductDetails = [];
		//this.InitializeSalesquotationForm();
		// this._salesproductform.reset();
		// this._salestermsform.reset();
		// this._OtherCharges = [];
		// this._ProductDetails = [];
		// var l_othercharge = new Salesquotationothercharge();
		// l_othercharge.salesquotationotherchargesdetailsid = 0;
		// l_othercharge.salesquotationid = this._salesquotationform.get("salesquotationid").value;
		// l_othercharge.createdby = sessionStorage["userid"];
		// l_othercharge.modifiedby = sessionStorage["userid"];
		// l_othercharge.otherchargesamount = 0;
		// l_othercharge.createdon = new Date();
		// l_othercharge.modifiedon = new Date();
		// l_othercharge.accountingyear = new Date().getFullYear();

		// this._OtherCharges.push(l_othercharge);

		// this._submitted = false;
		// this.isDisabledSendtoapproval = true;
		// this.isDisabledsave = false;
	}
	// on clicking back button , it Navigates to the search screen
	//54. should redirect to search page.
	goBack(event) {
		this._router.navigate(['/vSalesQuotation']);
	}
	//Debug
	// onEd() {
	//   ace.config.set("fontSize", "14px");
	//   ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
	//   const aceEditor = ace.edit(this.editor.nativeElement);
	//   aceEditor.getSession().setMode("ace/mode/json");
	//   var myObj = this._SalesQuotationObj;
	//   var myJSON = JSON.stringify(myObj);
	//   aceEditor.session.setValue(myJSON);
	// }

	SendToApproval(event) {
		var workflowid = 19;
		this._IsProgressSpinner = true;
		this._SalesQuotationService.SendToApproval(workflowid, this._userid, this._branchid, this._QuotationId, this._Series).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._CustomExceptionService.handleSuccess(usererrors.SalesQuotationSendToApproval);
			this.isDisabledsave = false;
			this._IsProgressSpinner = false;
			this.isDisabledSendtoapproval = true;
			this.isDisabledprint = false;
			if (this._action != 'edit') {
				this.reset(null);
			}
			if (this._action == 'edit') {
				this.isDisabledsave = true;
			}
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	filterProducts(event) {
		if (event.query == "" || event.query == undefined) {
			return;
		}
		else {
			if (this.productcatid.length > 0) {
				let filtered: any[] = [];
				let query = (<string>event.query).toLowerCase();
				this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
				return;
			}
			else {
				this._IsProgressSpinner = true;
				this._SalesQuotationService.GetProduct(event.query, this._branchid).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._ProductNames = <Productoverallview[]>resultJSON.productsearchlist || [];
					this._tempproductname = <Productoverallview[]>resultJSON.productsearchlist || [];
					let filtered: any[] = [];
					let query = (<string>event.query).toLowerCase();
					this.filteredProducts = this._ProductNames.filter(f => f.searchfilter.toLowerCase().indexOf(query) > -1) || [];
					this._IsProgressSpinner = false;
				},
					error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

			}
		}

	}
	Print(event) {
		this._IsProgressSpinner = true;
		// this._SalesQuotationService.Print(this._SalesQuotationno, this._branchid);
		this._IsProgressSpinner = false;
	}
	// Create
	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._action != 'view') {
			this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
				this.onSave();
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		if (this._action == 'create') {
			this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
				this.reset(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
			this.goBack(null);
			return false; // Prevent bubbling
		}, ['INPUT', 'SELECT', 'TEXTAREA']));
	}

	SendMail(event) {

		var quoteno = event.purchaseorderno;
		this._userid = sessionStorage["userid"];
		this._branchid = sessionStorage["BranchID"];
		this._IsProgressSpinner = true;
		this._SalesQuotationService.SendMail(this._userid, this._branchid, quoteno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && resultJSON) {
				this._CustomExceptionService.handleSuccess(usererrors.MailSent_Success);
			}
			else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);

			}
			this._IsProgressSpinner = false;

		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}


	// Onchange credit amount calculation:
	OnCustomerCreditChange() {
		if (this._ProductDetails.length == 0) {
			this._customercreditlimit = this._tempcustomercreditlimit;
			return;
		}
		Object.keys(this._ProductDetails).map((Index) => {
			this._customercreditlimit = parseFloat(((this._customercreditlimit || 0.00) - this._ProductDetails[Index].finalamount).toFixed(2));
		});
	}


}



