<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <form [formGroup]="_customerform" (ngSubmit)="onSave()">
                <div class="page-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Customer </h3>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="action-btn">
                                <button pButton pRipple label="" title="Save" icon="pi pi-save" class=""
                                    [disabled]="isDisabledSave" type="submit"></button>
                                <!-- 47.clear all the fields which the data has entered -->
                                <button pButton pRipple label="" title="Clear" type="button" icon="pi pi-trash" class=""
                                    [disabled]="isDisabledClear" (click)="reset($event)"
                                    class="p-button-danger"></button>
                                <!--22. on clicking back button , it Navigates to the search screen. -->
                                <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                    (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-container scroll-y">
                    <p-tabView [(activeIndex)]="selectedtab">
                        <p-tabPanel header="Customer Details">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="customercode" pInputText disabled
                                                formControlName="customercode">
                                            <label for="customercode">Customer Code</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">

                                            <input type="text" name="customername" pInputText (focusout)="ChangeSwift()"
                                                formControlName="customername" maxlength="100" required>
                                            <label for="customername">Customer Name <span class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                        <!--25. if customer name is empty show " please enter  customer name " -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _customerform.get('customername').touched) && _customerform.get('customername').errors?.NameEmpty">
                                            Please enter customer name
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted || _customerform.get('customername').touched) && _customerform.get('customername').errors?.vInputNamewithoutspecialcharater">
                                            Customer Name should not allow Special Characters
                                        </span> -->
                                    </td>
                                    <!-- <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Accountgroups"
                                                name="accountgroupcode" optionLabel="accountgroupname"
                                                optionValue="accountgroupcode" formControlName="accountgroupcode">
                                            </p-dropdown>
                                            <label for="accountgroupcode">Account Group </label>
                                        </span>
                                    </td> -->
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="bpno" pInputText formControlName="bpno"
                                                maxlength="20">
                                            <label for="bpno">TIN Number</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Markuplevels"
                                                name="levelid" optionLabel="levelname" optionValue="levelid"
                                                formControlName="levelid">
                                            </p-dropdown>
                                            <label for="levelid">Markup Level <span class="hlt-txt">*</span></label>
                                        </span>
                                        <!--26. if Markup level is empty show "please select Mark Up level  -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _customerform.get('levelid').touched) && _customerform.get('levelid').errors?.SelectMarkupLevel">
                                            Please select mark up level
                                        </span>
                                    </td>
                                    <td rowspan="2">
                                        <span class="p-float-label">
                                            <textarea rows="3" name="hobbies" pInputTextarea
                                                formControlName="hobbies"></textarea>
                                            <label for="address">Hobbies </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="vatno" pInputText formControlName="vatno"
                                                maxlength="20">
                                            <label for="vatno">VAT Number</label>
                                        </span>
                                        <!--30. If VAT No is empty show "please enter VAT No  " -->
                                        <!-- <span class="text-danger"
                                            *ngIf="(_submitted || _customerform.get('vatno').touched) && _customerform.get('vatno').errors?.VatNumberRequired">
                                            Please enter VAT no
                                        </span> -->
                                    </td>

                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Customerstatus"
                                                name="customerstatus" optionLabel="metasubdescription" (onChange)="Customerholdreason($event)"
                                                optionValue="metasubcode" formControlName="customerstatus">
                                            </p-dropdown>
                                            <label for="customerstatus">Status</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Customerseries"
                                                [disabled]="disableseries" name="series" (onChange)="changeRights()"
                                                optionLabel="metasubdescription" optionValue="metasubcode" 
                                                formControlName="series">
                                            </p-dropdown>
                                            <label for="series">Rights</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                                name="currencyid" optionLabel="currencyname" optionValue="currencyid"
                                                (onChange)="getcurrency($event)" formControlName="currencyid">
                                            </p-dropdown>
                                            <label for="currencyid">Currency <span class="hlt-txt">*</span></label>
                                        </span>
                                        <!--27. if Curreny is empty show "please select Curreny  " -->
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _customerform.get('currencyid').touched) && _customerform.get('currencyid').errors?.SelectCurrency">
                                            Please select currency
                                        </span>
                                    </td>


                                </tr>
                                <tr>
                                    <td>
                                        <!-- 12.Payment mode should be radio button with items (M,FIFO), any one button should select on load -->
                                        <div class="p-formgroup-inline">
                                            <h6>Pay Mode</h6>
                                            <div class="p-field-radiobutton" *ngFor="let Pm of _PayModes">
                                                <p-radioButton name="paymode" value="M" formControlName="paymode"
                                                    label={{Pm.metasubdescription}} value={{Pm.metasubcode}}>
                                                </p-radioButton>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="isshowvat" binary="false"
                                                    formControlName="isonlinecustomer" label=" " [disabled]="true">
                                                </p-checkbox>
                                                <label for="IsOnlineCustomer">Is Online Customer</label>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_userlist"
                                                    optionLabel="username"  name="username" optionValue="userid"
                                                    formControlName="responsibleperson" [virtualScroll]="true"
                                                    itemSize="30" [filter]="true" filterBy="username"
                                                    [showClear]="true">
                                                </p-dropdown>
                                                <label for="userid">Responsible Person </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_category"
                                                    optionLabel="categoryname"  name="categoryname" optionValue="categoryid"
                                                    formControlName="categoryid" [virtualScroll]="true" (onChange) = "oncategorychange()"
                                                    itemSize="30" [filter]="true" filterBy="categoryname"
                                                    [showClear]="true">
                                                </p-dropdown>
                                                <label for="userid">Customer Category </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_subcategory"
                                                    optionLabel="subcategoryname"  name="subcategoryname" optionValue="subcategoryid"
                                                    formControlName="subcategoryid" [virtualScroll]="true"
                                                    itemSize="30" [filter]="true" filterBy="subcategoryname"
                                                    [showClear]="true">
                                                </p-dropdown>
                                                <label for="userid">Customer SubCategory </label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 20%;"> 
                                        <div class="p-field-checkbox">
                                            <p-checkbox name="isconsignmentcustomer" binary="true"
                                                formControlName="isconsignmentcustomer" (onChange)="CustomerbranchEnable()">
                                            </p-checkbox>
                                            <label for="isconsignmentcustomer">Is Consignment Customer</label>
                                        </div>
                                    </td>
                                    <!-- <td style="width: 20%;" *ngIf="_Iscustomerbranchenable">
                                        <span class="p-float-label">
                                            <p-multiSelect name="cutomerbranchid" [options]="_customerbranchlist" [disabled]="isDisabledSave"
                                                displaySelectedLabel=true [(ngModel)]="customerbranchid" [ngModelOptions]="{standalone: true}"
                                                optionLabel="customerbranchname" optionValue="cutomerbranchid" [filter]="true">
                                            </p-multiSelect>
                                            <label for="subbranchid">Customer Branch <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td> -->
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>VAT Details</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <div class="p-formgroup-inline">
                                            <h6>VAT Type</h6>
                                            <div class="p-field-radiobutton" *ngFor="let Vt of _VatDetails">
                                                <p-radioButton  name="vattype" value="Include" formControlName="vattype"
                                                    (onClick)="getVattype()" label={{Vt.metasubdescription}}
                                                    *ngIf="!shouldHideVatType(Vt)"
                                                    value={{Vt.metasubcode}}>
                                                </p-radioButton>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 20%;" [hidden]="_IsVatTypeDisabled">
                                        <h6>Specific VAT Type</h6>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-radiobutton" *ngFor="let SVt of _SpecificVatType">
                                                <p-radioButton name="specificvattype" value="Exclude"
                                                    formControlName="specificvattype" label={{SVt.metasubdescription}}
                                                    value={{SVt.metasubcode}}></p-radioButton>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 20%;">

                                    </td>
                                    <td style="width: 20%;">

                                    </td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <!-- <h6>Is Show VAT</h6> -->
                                            <div class="p-field-checkbox">
                                                <p-checkbox name="isshowvat" binary="true" formControlName="isshowvat"
                                                    label="Is Show VAT" (onChange)="OnChangeShowVat()">
                                                </p-checkbox>
                                            </div>
                                        </div>
                                    </td>
                                    <td [hidden]="_IsVatTypeDisabled">
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-radiobutton" *ngFor="let ST of _SpecificVatTax">
                                                <p-radioButton name="specificvattax" value="Include"
                                                    formControlName="specificvattax" label={{ST.metasubdescription}}
                                                    value={{ST.metasubcode}}></p-radioButton>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Credit Details</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">
                                <tr>
                                    <td [hidden]="_IsSuperAdmin" style="width: 20%;">
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-radiobutton" *ngFor="let Ca of _CreditsApplicable">
                                                <p-radioButton name="creditapplicable" (click)="onClickCreditDetail()"
                                                    formControlName="creditapplicable" label={{Ca.metasubdescription}}
                                                    value={{Ca.metasubcode}}>
                                                </p-radioButton>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-inputNumber formControlName="creditlimit" inputId="locale-us"
                                                mode="decimal" locale="en-US" [minFractionDigits]="0"
                                                [disabled]="_IsSuperAdmin" [maxFractionDigits]="0" [useGrouping]="false"
                                                [min]="0" maxlength="15">
                                            </p-inputNumber>
                                            <!-- <input type="text" name="creditlimit" pInputText [disabled]="_IsSuperAdmin"
                                                formControlName="creditlimit" maxlength="15" pKeyFilter="pint"> -->
                                            <label for="creditlimit">Limit in USD</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-inputNumber formControlName="creditdays" inputId="locale-us"
                                                mode="decimal" locale="en-US" [minFractionDigits]="0"
                                                [disabled]="_IsSuperAdmin" [maxFractionDigits]="0" [useGrouping]="false"
                                                [min]="1" maxlength="6" [required]="isOptionalFieldRequired">
                                            </p-inputNumber>
                                            <!-- <input type="number" name="creditdays" pInputText [disabled]="_IsSuperAdmin"
                                                formControlName="creditdays"> -->
                                            <label for="creditdays">Days <span *ngIf="isOptionalFieldRequired"
                                                    class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                            </table>
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Reward Details</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="number" placeholder="0" name="rewardpoints" pInputText
                                                formControlName="rewardpoints" disabled>
                                            <label for="rewardpoints">Reward Points Earned</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="number" name="rewardpointsvalue" pInputText
                                                formControlName="rewardpointsvalue" disabled>
                                            <label for="rewardpointsvalue">Reward Point Value In USD</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                            </table>
                        </p-tabPanel>

                        <p-tabPanel header="Customer Address Details">
                            <form [formGroup]="_customeraddressdetailform">
                                <table class="normal-table">
                                    <tr>
                                        <td style="width: 20%;">
                                            <span class="p-float-label">
                                                <input type="text" name="contactpersonname" pInputText
                                                    formControlName="contactpersonname" maxlength="50">
                                                <label for="contactpersonname">Contact Person Name <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                            <!--28. if Contact Name is empty show "please enter Contact Person Name " -->
                                            <span class="text-danger"
                                                *ngIf="(_addresssubmitted || _customeraddressdetailform.get('contactpersonname').touched) && _customeraddressdetailform.get('contactpersonname').errors?.ContactPersonNameRequired">
                                                Please enter contact person name
                                            </span>
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="p-float-label">
                                                <input type="text" (focusout)="Mobilevalidate()" name="mobileno"
                                                    pInputText formControlName="mobileno" maxlength="20">

                                                <!-- <p-inputNumber name="mobileno" maxlength="20"
                                                    formControlName="mobileno"  inputId="withoutgrouping"
                                                    [useGrouping]="false"> </p-inputNumber> -->

                                                <label for="mobileno">Mobile Number <span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                            <!--29. if Mobile Number is empty show "please enter Mobile Number  " -->
                                            <span class="text-danger"
                                                *ngIf="(_addresssubmitted || _customeraddressdetailform.get('mobileno').touched) && _customeraddressdetailform.get('mobileno').errors?.MobileNumberRequired">
                                                Please enter mobile number
                                            </span>
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="p-float-label">
                                                <!-- <input type="number" name="phoneno" pInputText
                                                    formControlName="phoneno" maxlength="20"> -->
                                                <input type="text" (keypress)="keypress.kpNumber($event)" name="phoneno"
                                                    pInputText formControlName="phoneno" maxlength="20">

                                                <label for="phoneno">TelePhone Number</label>
                                            </span>
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="p-float-label">
                                                <input type="text" name="mailid" pInputText formControlName="mailid"
                                                    maxlength="50">

                                                <label for="mailid">Email ID<span class="hlt-txt">*</span></label>
                                            </span>
                                            <span class="text-danger"
                                                *ngIf="(_customeraddressdetailform.get('mailid').touched || _addresssubmitted) && _customeraddressdetailform.get('mailid').errors?.EmailIDRequired">
                                                Please enter email ID
                                            </span>
                                            <span class="text-danger"
                                                *ngIf="(_customeraddressdetailform.get('mailid').touched || _addresssubmitted) && _customeraddressdetailform.get('mailid').errors?.ValidEmailID">
                                                Please enter valid email ID
                                            </span>
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_Addresstype"
                                                    name="addresstype" optionLabel="metasubdescription"
                                                    optionValue="metasubcode" formControlName="addresstype">
                                                </p-dropdown>
                                                <label for="addresstype">Address Type<span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                            <span class="text-danger"
                                                *ngIf="(_addresssubmitted || _customeraddressdetailform.get('addresstype').touched) && _customeraddressdetailform.get('addresstype').errors?.SelectCountryName">
                                                Please select address type
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_Countries"
                                                    name="countryid" optionLabel="countryname" optionValue="countryid"
                                                    formControlName="countryid" (onChange)="getStates($event)">
                                                </p-dropdown>
                                                <label for="countryid">Country <span class="hlt-txt">*</span></label>
                                            </span>
                                            <!--32. if Country  is empty show "please select Country  " -->
                                            <span class="text-danger"
                                                *ngIf="(_addresssubmitted || _customeraddressdetailform.get('countryid').touched) && _customeraddressdetailform.get('countryid').errors?.SelectCountryName">
                                                Please select country
                                            </span>
                                        </td>
                                        <td>
                                            <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_States"
                                                    name="stateid" optionValue="stateid" optionLabel="statename"
                                                    formControlName="stateid" (onChange)="getDistrict($event)">
                                                </p-dropdown>
                                                <label for="stateid">Province <span class="hlt-txt">*</span></label>
                                            </span>
                                            <!-- if State is empty show "please select State  " -->
                                            <span class="text-danger"
                                                *ngIf="(_addresssubmitted || _customeraddressdetailform.get('stateid').touched) && _customeraddressdetailform.get('stateid').errors?.SelectStateName">
                                                Please select province
                                            </span>
                                        </td>
                                        <td>
                                            <span class="p-float-label">
                                                <p-dropdown [options]="_Cities" [autoDisplayFirst]="false"
                                                    name="districtid" optionLabel="districtname"
                                                    optionValue="districtid" formControlName="districtid">
                                                </p-dropdown>
                                                <label for="districtid">City Name <span class="hlt-txt">*</span></label>
                                            </span>
                                            <!--31. if City is empty show "please select City  " -->
                                            <span class="text-danger"
                                                *ngIf="(_addresssubmitted || _customeraddressdetailform.get('districtid').touched) && _customeraddressdetailform.get('districtid').errors?.SelectDistrictName">
                                                Please select city
                                            </span>
                                        </td>
                                        <td>
                                            <span class="p-float-label">
                                                <!-- <p-inputNumber name="faxno" maxlength="20"
                                                formControlName="faxno"  inputId="withoutgrouping"
                                                [useGrouping]="false"> </p-inputNumber> -->

                                                <input type="text" (keypress)="keypress.kpNumber($event)" name="faxno"
                                                    pInputText formControlName="faxno" maxlength="20">


                                                <label for="faxno">Fax Number</label>
                                            </span>
                                        </td>
                                        <td style="width: 20%;" rowspan="2">
                                            <span class="p-float-label">
                                                <textarea rows="3" name="address" pInputTextarea
                                                    formControlName="address"></textarea>
                                                <label for="address">Address <span class="hlt-txt">*</span></label>
                                            </span>
                                            <!--35. If  Address is empty show "please enter  Address  " -->
                                            <span class="text-danger"
                                                *ngIf="(_addresssubmitted || _customeraddressdetailform.get('address').touched) && _customeraddressdetailform.get('address').errors?.AddressRequired">
                                                Please enter address
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <button pButton pRipple label="Add" title="Add" icon="pi pi-plus"
                                                [disabled]="isDisabledaddbuttn" (click)="onAddAddress($event)"
                                                class="p-button-success p-mr-2"></button>
                                            <button pButton pRipple label="Clear" title="Clear" icon="pi pi-times"
                                                [disabled]="isDisabledreset" (click)="resetaddress($event)"
                                                class="p-button-danger p-mr-2"></button>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </form>
                            <div class="card">
                                <p-table #dt [value]="_CustomersAddress" [rows]="5" [paginator]="true"
                                    [rowsPerPageOptions]="[5,10,25,50,100]"
                                    [globalFilterFields]="['producttypename','categoryname','productname','productprice']"
                                    [rowHover]="true" dataKey="address" [(selection)]="selectedrows"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [showCurrentPageReport]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="text-align: center;width: 100px;">Action</th>
                                            <th>Is Default</th>
                                            <th>Contact Person</th>
                                            <th>Mobile Number</th>
                                            <th>Email ID</th>
                                            <th>Address</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-_CustomerAddress let-ri="rowIndex">
                                        <tr (dblclick)="onRowCustomerselect(_CustomerAddress,ri)">
                                            <td style="text-align: center;">
                                                <button pButton pRipple label="" title="Remove" icon="pi pi-trash"
                                                    [disabled]="isDisabledButton"
                                                    (click)="RemoveAddressDetail(_CustomerAddress)"
                                                    class="p-button-danger p-mr-2" type="button"></button>
                                            </td>
                                            <td style="text-align:center">
                                                <!-- <p-Checkbox [value]="_CustomerAddress.isdefaultaddress" binary="true"
                                                    (onChange)="Removeselectedchecked(_CustomerAddress)">
                                                </p-Checkbox> -->
                                                <p-checkbox [(ngModel)]="_CustomerAddress.isdefaultaddress"
                                                    [ngModelOptions]="{standalone: true}" [disabled]="_action=='view'"
                                                    (onChange)="Removeselectedchecked(_CustomerAddress,ri)"
                                                    binary="true">
                                                </p-checkbox>
                                            </td>
                                            <td>
                                                {{_CustomerAddress.contactpersonname}}
                                            </td>
                                            <td>
                                                {{_CustomerAddress.mobileno}}
                                            </td>
                                            <td>
                                                {{_CustomerAddress.mailid}}
                                            </td>
                                            <td>
                                                {{_CustomerAddress.address}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </p-tabPanel>

                        <p-tabPanel header="Customer Account Details">
                            <form [formGroup]="_customeraccountdetailform">
                                <table class="normal-table">
                                    <tr>
                                        <td style="width: 20%;">
                                            <div class="p-inputgroup">
                                                <span class="p-float-label">
                                                    <input type="text" pInputText name="bankcode" disabled
                                                        formControlName="bankcode">
                                                    <label for="bankcode">Bank Code
                                                    </label>
                                                </span>
                                                <button class="p-inputgroup-addon p-button"
                                                    [disabled]="isDisabledButton" (click)="showBasicDialog()">
                                                    <i class="pi pi-external-link"></i>
                                                </button>
                                            </div>
                                            <!-- if Bank Code is empty show "please enter Bank code  " -->
                                            <!-- <span class="text-danger"
                                                *ngIf="(_submitted || _customeraccountdetailform.get('bankcode').touched) && _customeraccountdetailform.get('bankcode').errors?.SelectBankCode">
                                                Please select bank code
                                            </span> -->
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="p-float-label">
                                                <input type="text" pInputText name="bankname" disabled
                                                    formControlName="bankname">
                                                <label for="bankname"> Enter Bank Name
                                                </label>
                                            </span>
                                            <!-- if Bank Name is Empty show "please select Bank Name  " -->
                                            <span class="text-danger"
                                                *ngIf="(_submitted || _customeraccountdetailform.get('bankname').touched) && _customeraccountdetailform.get('bankname').errors?.SelectBankName">
                                                Please select bank name
                                            </span>
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="p-float-label">
                                                <input type="text" pInputText name="branchname"
                                                    formControlName="branchname" maxlength="100">
                                                <label for="branchname"> Enter Bank Branch
                                                </label>
                                            </span>
                                            <!-- <span class="text-danger"
                                                *ngIf="(_submitted || _customeraccountdetailform.get('branchname').touched) && _customeraccountdetailform.get('branchname').errors?.BranchNameRequired">
                                                Please enter bank branch
                                            </span> -->
                                        </td>
                                        <td style="width: 20%;">
                                            <span class="p-float-label">
                                                <input type="text" pInputText name="swiftcode"
                                                    formControlName="swiftcode" maxlength="30">
                                                <label for="swiftcode"> Enter Swift Code
                                                </label>
                                            </span>
                                            <!--38. if swift Code is empty show "please enter Swift code  " -->
                                            <!-- <span class="text-danger"
                                                *ngIf="(_submitted || _customeraccountdetailform.get('swiftcode').touched) && _customeraccountdetailform.get('swiftcode').errors?.SwiftCodeRequired">
                                                Please enter swift code
                                            </span> -->
                                        </td>
                                        <td style="width: 20%;"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span class="p-float-label">
                                                <input type="number" name="accountno" pInputText
                                                    formControlName="accountno" maxlength="30">
                                                <label for="accountno">Bank Account Number
                                                </label>
                                            </span>
                                            <!--36. if Account No is Empty show "please enter  Account No " -->
                                            <!-- <span class="text-danger"
                                                *ngIf="(_submitted || _customeraccountdetailform.get('accountno').touched) && _customeraccountdetailform.get('accountno').errors?.AccountNoRequired">
                                                Please enter account number
                                            </span> -->
                                        </td>
                                        <td>
                                            <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_Accounttypes"
                                                    name="accounttype" optionLabel="metasubdescription"
                                                    optionValue="metasubcode" formControlName="accounttype">
                                                </p-dropdown>
                                                <label for="accounttype">Account Type</label>
                                            </span>
                                        </td>
                                        <td> <span class="p-float-label">
                                                <input type="text" pInputText name="accountholdername"
                                                    formControlName="accountholdername" maxlength="100">
                                                <label for="accountholdername"> Enter Account Holder Name</label>
                                            </span>
                                        </td>
                                        <td>
                                            <!-- Debug -->
                                            <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                                        </td>
                                        <td></td>
                                    </tr>
                                </table>
                            </form>
                            <!-- For pop Up -->
                            <p-dialog header="Bank Code" [(visible)]="displayBasic" [modal]="true"
                                [style]="{width: '50vw',height: '500px'}" [baseZIndex]="10000">
                                <ng-template pTemplate="content">
                                    <div class="popup-body">
                                        <div class="search-container">
                                            <div class="p-inputgroup">
                                                <span class="p-float-label">
                                                    <input type="text" id="inputtext" pInputText>
                                                </span>
                                                <button class="p-inputgroup-addon p-button" title="Search">
                                                    <i class="pi pi-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <p-table [value]="Banklist" selectionMode="single"
                                                [(selection)]="selectedBank" (onRowSelect)="onRowSelectPopup($event)"
                                                [paginator]="true" [rows]="5">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th pSortableColumn="bankcode">Code<p-sortIcon field="bankcode">
                                                            </p-sortIcon>
                                                        </th>
                                                        <th pSortableColumn="bankname">Name <p-sortIcon
                                                                field="bankname">
                                                            </p-sortIcon>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData let-sBank>
                                                    <tr [pSelectableRow]="rowData">
                                                        <td>{{sBank.bankcode}}</td>
                                                        <td>{{sBank.bankname}}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dialog>
                        </p-tabPanel>
                        <p-tabPanel header="Customer Document Details">

                            <form [formGroup]="_customerdocumentdetailform">
                                <table class="normal-table">
                                    <tr>
                                        <td style="width: 20%;">
                                            <!-- <span class="p-float-label">
                                                                    <input type="text" pInputText formControlName="documentname">
                                                                    <label for="documentname">Document Name <span
                                                                            class="hlt-txt">*</span></label>
                                                                </span> -->
                                            <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_DocumentDescriptions"
                                                    optionValue="documentdescriptionname"
                                                    optionLabel="documentdescriptionname" formControlName="documentname"
                                                    [filter]="true" filterBy="documentdescriptionname">
                                                </p-dropdown>
                                                <label for="documentname">Document Name<span
                                                        class="hlt-txt">*</span></label>

                                            </span>
                                            <span class="text-danger"
                                                *ngIf="(_submitted2 || _customerdocumentdetailform.get('documentname').touched) && _customerdocumentdetailform.get('documentname').errors?.DocumentNameRequired">
                                                Please Select Document Name
                                            </span>
                                        </td>
                                        <!-- <td style="width: 20%;">
                                                                <span class="p-float-label">
                                                                    <input type="text" pInputText formControlName="documentdescription">
                                                                    <label for="documentdescription">Document Description</label>
                                                                </span>
                                                            </td> -->
                                        <td style="width: 20%;">
                                            <div class="p-inputgroup">
                                                <span class="p-float-label">
                                                    <div class="browse-links">
                                                        <p-button label="Customer Document" styleClass="p-button-link"
                                                            title="{{documentselect}}"
                                                            (click)="showcustomergrid(documentgrid,'bottom-right')">
                                                        </p-button>
                                                    </div>
                                                </span>
                                                <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                                    pTooltip="Browse File" tooltipPosition="top" mode="basic"
                                                    #documentgrid accept="image/*,.pdf,.xls,.xlsx,.docx,.doc,.txt"
                                                    maxFileSize="1000000" [auto]="true"
                                                    (onSelect)="onDocumentCustomerSelect($event,documentgrid)"
                                                    [disabled]="_action=='view'">
                                                </p-fileUpload>
                                                <button class="p-inputgroup-addon p-button" pTooltip="Cancel"
                                                    type="button" (click)="onDocumentCustomerClear()"
                                                    tooltipPosition="top" [disabled]="_action=='view'">
                                                    <i class="pi pi-times"></i>
                                                </button>
                                            </div>
                                            <span class="text-danger"
                                                *ngIf="(_submitted2 && _LastAddedGridDocument == null)">
                                                Please Add Document
                                            </span>
                                        </td>
                                        <td style="width: 20%;"></td>
                                        <td style="width: 20%;"></td>
                                        <td style="width: 20%;"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <button type="submit" pButton pRipple label="Add" title="Add"
                                                icon="pi pi-plus" [disabled]="_action == 'view'"
                                                (click)="onAddDocuments($event)"
                                                class="p-button-success p-mr-2"></button>
                                            <button pButton pRipple label="Clear" title="Clear" icon="pi pi-times"
                                                type="button" [disabled]="_action == 'view'"
                                                class="p-button-danger p-mr-2" (click)="CustomerClear()"></button>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </form>
                            <!-- Form group -->

                            <div class="card">
                                <p-table #dt [value]="_Customerdocumentdetails" [rows]="10" [paginator]="true"
                                    [rowsPerPageOptions]="[5,10,25,50,100]"
                                    [globalFilterFields]="['documentdescription','documentname']" [rowHover]="true"
                                    dataKey="address" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                                    (onRowUnselect)="onRowUnselect($event)"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [showCurrentPageReport]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="text-align: center;width: 100px;">Action</th>
                                            <th>Document Name</th>
                                            <!-- <th>Document Description</th> -->
                                            <th>Attachment Name</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-_customerdocumentdetail let-ri="rowIndex">

                                        <tr (dblclick)="onRowCustomerdocselect(_customerdocumentdetail,ri)">
                                            <td style="text-align: center;">
                                                <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                    [disabled]="_removedocoumenthidden"
                                                    (click)="RemoveDocumentDetail(_customerdocumentdetail)"
                                                    class="p-button-danger p-mr-2"></button>
                                            </td>
                                            <td>
                                                {{_customerdocumentdetail.documentname}}
                                            </td>
                                            <!-- <td>
                                                                    {{_Employeedocumentdetail.documentdescription}}
                                                                </td> -->
                                            <td>
                                                <a href="javascript:void(0);"
                                                    (click)="showdocumentGridDialog('bottom-right',_customerdocumentdetail)">
                                                    {{_customerdocumentdetail.attachmentname}}
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                            <!-- Document Upload -->
                            <p-dialog header="Customer Document" [(visible)]="displaydocumentgridpopup"
                                [position]="position" [style]="{width: '500px'}" [baseZIndex]="10000">
                                <img [src]="documentimage" alt="" class="img-preview">
                                <ng-template pTemplate="footer">
                                    <p-button icon="pi pi-check" (click)="closePopupGridDialog()" label="Ok"
                                        styleClass="p-button-text">
                                    </p-button>
                                    <p-button (click)="showdocumentasNewWindow()" label="Open in new window"
                                        styleClass="p-button-text">
                                    </p-button>
                                </ng-template>
                            </p-dialog>

                            <table>
                                <tr>
                                    <td>
                                        <!-- Debug -->
                                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                                    </td>
                                </tr>
                            </table>


                        </p-tabPanel>
                    </p-tabView>
                </div>
                <p-dialog header="Reason for Customer Hold" [(visible)]="customerholdreason" [modal]="true"
                [style]="{width: '20vw',height: '215px'}" [baseZIndex]="10000">
                <ng-template pTemplate="content">
                    <div class="popup-body">
                        <div class="card">
                            <textarea name="reasonforcustomerhold" formControlName="reasonforcustomerhold" pInputTextarea
                                style="width: 100%; height: 100px; padding: 5px;">
                            </textarea>
                        </div>
                        <div style="text-align: right;" *ngIf="customerholdpopupsubmit">
                            <button pButton pRipple label="submit" title="submit" (click)="Customerholdreasonpopupclose()"
                                class="p-mr-2">
                            </button>
                        </div>
                    </div>
                </ng-template>
            </p-dialog>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </form>
        </div>
    </div>
</div>

<p-toast position="bottom-right"></p-toast>