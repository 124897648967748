<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Sales Invoice POS</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple icon="pi pi-plus" title="Add" [disabled]="!_Action._Add" class="p-button p-button-success p-mr-2" (click)="GoToCreate($event)">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast></p-toast>
                <table class="normal-table">
                    <tr>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="fromdate" [(ngModel)]="fromdate" [showIcon]="true"
                                    dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate" [monthNavigator]="true" yearRange="1980:2030">
                                </p-calendar>
                                <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <span class="p-float-label">
                                <p-calendar name="todate" [(ngModel)]="todate" [showIcon]="true" dateFormat="dd/mm/yy"
                                    [monthNavigator]="true"  [minDate]="mindate" [maxDate]="maxdate" yearRange="1980:2030">
                                </p-calendar>
                                <label for="todate">To Date <span class="hlt-txt">*</span></label>
                            </span>
                        </td>
                        <td style="width: 20%;">
                            <button pButton pRipple label="Search" type="button" (click)="FetchAll()" title="Search" icon="pi pi-search"></button>
                        </td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                        <td style="width: 20%;"></td>
                    </tr>
                </table>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                            <!-- <button type="button" pButton pRipple icon="pi pi-filter" title="Filter"
                                (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto"
                                pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
                        </ng-template>

                    </p-toolbar>
                    <p-table #dt [value]="_Saleinvoiceposviews" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [columns]="cols" [globalFilterFields]="['salesinvoiceno','salesinvoicedate','customername','currencyname','salesinvoiceamount','creditnoteinvoiceno','statusname','ispaymentmaded','username']"
                        [(selection)]="selectedrows" [rowHover]="true" dataKey="salesinvoiceno" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">

                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 190px;">Action
                                    <p-sortIcon field="name"></p-sortIcon>
                                </th>
                                <th pSortableColumn="salesinvoiceno">Invoice No
                                    <p-sortIcon field="salesinvoiceno">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="salesinvoicedate">Invoice Date
                                    <p-sortIcon field="salesinvoicedate"></p-sortIcon>
                                </th>
                                <th pSortableColumn="customername">Customer Name
                                    <p-sortIcon field="customername">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="currencyname">Currency Name
                                    <p-sortIcon field="currencyname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="salesinvoiceamount">Invoice Amount
                                    <p-sortIcon field="salesinvoiceamount"></p-sortIcon>
                                </th>
                                <th style="width: 180px;" pSortableColumn="creditnoteinvoiceno">Applied Credit Note/Invoice
                                    <p-sortIcon field="creditnoteinvoiceno"></p-sortIcon>
                                </th>
                                <th pSortableColumn="statusname">Status
                                    <p-sortIcon field="statusname">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="ispaymentmaded">Payment Status
                                    <p-sortIcon field="ispaymentmaded">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="username">Created By
                                    <p-sortIcon field="username">
                                    </p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Saleinvoiceposview>
                            <tr>
                                <td class="text-center">
                                    <button pButton pRipple icon="las la-eye" title="View" class="p-button p-mr-2" [disabled]="!_Action._View" (click)="view(_Saleinvoiceposview)"></button>
                                    <button pButton pRipple icon="las la-pen" title="Edit" [disabled]="((_Saleinvoiceposview.status =='MSC00047' && _Saleinvoiceposview.ispaymentmaded) ||
                                        _Saleinvoiceposview.status !='MSC00047') || _Saleinvoiceposview.paymenttype === 'MSC00299' || !_Action._Update" class="p-button p-button-success p-mr-2" (click)="edit(_Saleinvoiceposview)"></button>
                                    <button pButton pRipple icon="pi pi-times" title="Cancel" class="p-button p-button-danger" [disabled]="!_Action._Delete || _Saleinvoiceposview.status =='MSC00150'" (click)="confirm(_Saleinvoiceposview)"></button>
                                    <!-- [disabled]="_Saleinvoiceposview.status =='MSC00150'" -->
                                    <button pButton pRipple icon="las la-print" title="Full Sheet Print" class="p-button-warning p-mr-2" (click)="Print(_Saleinvoiceposview)" [disabled]="(_Saleinvoiceposview.status =='MSC00047' && !_Saleinvoiceposview.ispaymentmaded 
                                        || (_Saleinvoiceposview.isprintallowed == 'NO') || (_roleid != 7 && _roleid != 1 &&  _roleid != 40 && _Saleinvoiceposview.printcount != 0) )"></button>
                                    <button pButton pRipple icon="las la-print" title="Pos Receipt Print" class="p-button-warning p-mr-2" (click)="RePrint(_Saleinvoiceposview)" [disabled]="((_Saleinvoiceposview.status =='MSC00047' && !_Saleinvoiceposview.ispaymentmaded) ||
                                        (_Saleinvoiceposview.status !='MSC00047' ) || _roleid !=7)"></button>
                                    <!-- <button pButton pRipple icon="las la-money-bill" title="Pay" class="p-button p-mr-2"
                                        [disabled]="(_Saleinvoiceposview.status !='MSC00047'  || _Saleinvoiceposview.paymenttype !== 'MSC00299')"
                                        (click)="RedirectToMultiSwipe(_Saleinvoiceposview)"></button> -->
                                </td>
                                <td>
                                    {{_Saleinvoiceposview.salesinvoiceno}}
                                </td>
                                <td>
                                    {{_Saleinvoiceposview.salesinvoicedate | date:'dd-MMM-yyyy'}}
                                </td>
                                <td>
                                    {{_Saleinvoiceposview.customername}}
                                </td>
                                <td>
                                    {{_Saleinvoiceposview.currencyname}}
                                </td>
                                <td>
                                    {{_Saleinvoiceposview.salesinvoiceamount | number : '1.2'}}
                                </td>
                                <td>
                                    {{_Saleinvoiceposview.creditnoteinvoiceno}}
                                </td>
                                <td>
                                    {{_Saleinvoiceposview.statusname }}
                                </td>
                                <td>
                                    {{_Saleinvoiceposview.ispaymentmadedtext }}
                                </td>
                                <td>
                                    {{_Saleinvoiceposview.username }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
        </div>
    </div>
</div>