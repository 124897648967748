
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,

  //bharat
  UserService: "https://service.bholahardware.com/user",
  NumberingschemaService: "https://service.bholahardware.com/NumberingSchema",
  EmployeeService: "https://service.bholahardware.com/Employee",
  CustomerwisediscountService: "https://service.bholahardware.com/Customerwisediscount",
  SalescommissionService: "https://service.bholahardware.com/Salescommission",
  UserscreenmappingService: "https://service.bholahardware.com/Userscreenmapping",
  CreditnoteService: "https://service.bholahardware.com/CreditNote",
  DirectnoteService: "https://service.bholahardware.com/DirectCreditNote",
  ApprovaltypeService: "https://service.bholahardware.com/ApprovalType",

  // rahul
  CountryService: "https://service.bholahardware.com/Country",
  TaxService: "https://service.bholahardware.com/Tax",
  BranchService: "https://service.bholahardware.com/Branch",
  UsersService: "https://service.bholahardware.com/User",
  CategoryService: "https://service.bholahardware.com/Productcategory",
  TermsandconditionService: "https://service.bholahardware.com/Termsandcondition",
  VehicleService: "https://service.bholahardware.com/Vehicle",
  StateService: "https://service.bholahardware.com/CountryState",
  LevelService: "https://service.bholahardware.com/Level",
  CustomerService: "https://service.bholahardware.com/Customer",
  SupplierService: "https://service.bholahardware.com/Supplier",

  //rajesh
  CityService: "https://service.bholahardware.com/District",
  CurrencyService: "https://service.bholahardware.com/Currency",
  AccountTypeService: "https://service.bholahardware.com/AccountType",
  AccountGroupService: "https://service.bholahardware.com/AccountGroup",
  RoleService: "https://service.bholahardware.com/Role",
  BankService: "https://service.bholahardware.com/Bank",
  ProductTypeService: "https://service.bholahardware.com/ProductType",
  PortService: "https://service.bholahardware.com/Port",
  RoleWiseScreenMappingService: "https://service.bholahardware.com/RoleWiseScreenMapping",
  ChangePasswordService: "https://service.bholahardware.com/ChangePassword",
  ProductService: "https://service.bholahardware.com/Product",
  UserCustomerMappingService: "https://service.bholahardware.com/UserCustomerMapping",
  SchemeService: "https://service.bholahardware.com/Scheme",
  RewardService: "https://service.bholahardware.com/Reward",
  StockTransferOrderService: "https://service.bholahardware.com/StockTransferOrder",
  SalesQuotationService: "https://service.bholahardware.com/SalesQuotation",
  SalesOrderService: "https://service.bholahardware.com/SalesOrder",
  PettycashExpenceService: "https://service.bholahardware.com/PettyCashExpense",
  ReceiptService: "https://service.bholahardware.com/Receipt",
  PaymentService: "https://service.bholahardware.com/Payment",
  BankReconciliationService: "https://service.bholahardware.com/BankReconcilization",
  ContraService: "https://service.bholahardware.com/Contra",
  TellerCashSettlementService: "https://service.bholahardware.com/TellerCashSettlement",
  AccountClosingService: "https://service.bholahardware.com/AccountClosing",
  AccountledgerService: "https://service.bholahardware.com/AccountGroupLedger",
  PettycashService: "https://service.bholahardware.com/Pettycashrequest",
  PettycashSettlementService: "https://service.bholahardware.com/PettycashSettlement",
  AssetregistrationService: "https://service.bholahardware.com/Assetregistration",
  JournalService: "https://service.bholahardware.com/Journal",
  DebitnoteService: "https://service.bholahardware.com/Debitnote",
  TaxreconcilationService: "https://service.bholahardware.com/Taxreconcilation",
  LaybuyService: "https://service.bholahardware.com/LayBuy",
  DirectsalesreturnService: "https://service.bholahardware.com/DirectSalesReturn",
  DocumentDescription: "https://service.bholahardware.com/DocumentDescription",
  ShippingLineService: "https://service.bholahardware.com/ShippingLine",
  RptCashBookService: "https://service.bholahardware.com/RptCashBook",
  RptAccountsMonitorService: "https://service.bholahardware.com/RptAccountsMonitor",
  // rahul
  UsercustomermappingService: "https://service.bholahardware.com/UserCustomerMapping",
  CustomerslabwisediscountService: "https://service.bholahardware.com/CustomerSlabWiseDiscount",

  //kavin
  OtherChargesService: "https://service.bholahardware.com/OtherCharges",
  SmsConfigurationService: "https://service.bholahardware.com/SmsConfiguration",
  RptProductPriceHistoryService: "https://service.bholahardware.com/ProductPriceHistory",

  //Deepak:
  TenderService: "https://service.bholahardware.com/Tender",
  StockinwardService: "https://service.bholahardware.com/StockInward",
  DamagedproductpricedetailsService: "https://service.bholahardware.com/DamagedProductPrice",
  Requestforquoteservice: "https://service.bholahardware.com/Requestforquote",
  DamagedproductstockService: "https://service.bholahardware.com/DamagedProductStock",
  QuotefinalizeService: "https://service.bholahardware.com/QuoteFinalized",
  SalesreturnService: "https://service.bholahardware.com/SalesReturn",
  PurchaseinvoiceService: "https://service.bholahardware.com/PurchaseInvoice",
  PurchaseorderService: "https://service.bholahardware.com/Purchaseorder",
  grnService: "https://service.bholahardware.com/Grn",
  PricecheckingService: "https://service.bholahardware.com/PriceChacking",
  TripstartService: "https://service.bholahardware.com/TripStartClosure",
  PricecomparisonService: "https://service.bholahardware.com/PriceComparison",
  PurchasereturnService: "https://service.bholahardware.com/PurchaseReturn",
  ContainertrackingService: "https://service.bholahardware.com/ContainerTracking",
  StockallocationService: "https://service.bholahardware.com/StockAllocation",
  StockadjustmentService: "https://service.bholahardware.com/StockAdjustment",
  StockdeliveryService: "https://service.bholahardware.com/StockDelivery",
  DocumentverificationService: "https://service.bholahardware.com/DocumentVerification",
  ProductpricechangeService: "https://service.bholahardware.com/ProductPriceChange",
  ImportentryService: "https://service.bholahardware.com/ImportEntry",
  ApprovalconfigurationcorporateService: "https://service.bholahardware.com/ApprovalConfigurationCorporate",
  ApprovalbranchService: "https://service.bholahardware.com/ApprovalConfigurationBranch",
  ApprovalworkflowService: "https://service.bholahardware.com/ApprovalWorkFlow",
  SalesinvoiceposService: "https://service.bholahardware.com/SalesInvoicePOS",
  SalesInvoiceTaxService: "https://service.bholahardware.com/SalesInvoiceTax",
  WorkflowService: "https://service.bholahardware.com/ApprovalWorkFlow",
  HeaderService: "https://service.bholahardware.com/Header",
  StockmissingService: "https://service.bholahardware.com/StockMissing",
  ProductFreezingService: "https://service.bholahardware.com/ProductFreezing",

  //Report Purchase
  RptPurchaseSummaryService: "https://report.bholahardware.com/RptPurchaseSummaryStatusService",
  RptPurchasePriceRevisionService: "https://report.bholahardware.com/RptPurchasePriceRevision",
  RptPurchaseReturnService: "https://report.bholahardware.com/RptPurchaseReturn",
  RptPurchaseInwardService: "https://report.bholahardware.com/RptPurchaseInward",
  RptPOPrintService: "https://report.bholahardware.com/RptPOPrint",
  RptPurchaseInvoiceService: "https://report.bholahardware.com/RptPurchaseInvoicePrint",
  RptPurchaseOrderDetailReport: "https://report.bholahardware.com/RptPurchaseOrderDetailReport",
  RptPurchaseInwardSummaryReport: "https://report.bholahardware.com/RptPurchaseInwardSummary",
  RptPurchaseOrderSummaryReport: "https://report.bholahardware.com/RptPurchaseOrderSummary",

  //Report Stock
  RptStockTransferService: "https://report.bholahardware.com/RptStockTransfer",
  RptGRNService: "https://report.bholahardware.com/RptGrn",
  RptDamagedProductService: "https://report.bholahardware.com/RptDamagedProduct",
  RptProductDeliveryService: "https://report.bholahardware.com/RptProductDelivery",
  RptBranchwisestockService: "https://service.bholahardware.com/BranchWiseStockReport",
  RptGRNPrintService: "https://report.bholahardware.com/RptGRNPrint",
  StockmovementService: "https://service.bholahardware.com/StockMovement",
  RptLockedQuantityDetailService: "https://report.bholahardware.com/RptLockedQuantityDetailReport",
  RptLockedQuantitySummaryService: "https://report.bholahardware.com/RptLockedQuantitySummaryReport",
  RptSalesProfitandLossService: "https://report.bholahardware.com/RptSalesProfitandLoss",
  StocksummaryService: "https://report.bholahardware.com/RptStockSummary",
  StockcountingService: "https://report.bholahardware.com/RptStockCounting",
  StockMovementsummaryService: "https://service.bholahardware.com/StockSummary",
  RptConsignmentStockService: "https://report.bholahardware.com/RptConsignmentStock",
  RptMonthWiseStockService: "https://service.bholahardware.com/RptMonthWiseStock",
  LockedQuantityService: "https://service.bholahardware.com/LockedQuantity",
  DeliveredNotDeliveredService: "https://service.bholahardware.com/RptProductDeliveredUndelivered",
  RptTripSheetPrintService: "https://report.bholahardware.com/RptTripSheetPrint",
  RptCriticalStockService: "https://service.bholahardware.com/RptCriticalStock",

  //Report https://report.bholahardware.com
  RptSalesQuotationService: "https://report.bholahardware.com/RptSalesQuotation",
  //RptSalesCommissionService: "https://report.bholahardware.com/RptSalesCommission",
  RptSalesReturnService: "https://report.bholahardware.com/RptSalesReturn",
  RptrewardpointtransactionService: "https://report.bholahardware.com/RptRewardPointstransaction",
  RptSalesInvoicePrintService: "https://report.bholahardware.com/RptSalesInvoicePrint",
  RptSOPrintService: "https://report.bholahardware.com/RptSOPrint",
  RptSalesQuotationPrintService: "https://report.bholahardware.com/RptSalesQuotationPrint",
  RptSalesReturnPrintService: "https://report.bholahardware.com/RptSalesReturnPrint",
  RptSalesSummaryReport: "https://report.bholahardware.com/RptSalesSummary",
  RptSalesDiscountService: "https://report.bholahardware.com/RptSalesDiscount",
  RptSalesVatIncludeExcludeService: "https://report.bholahardware.com/RptSalesVatIncludeExclude",

  //Report Accounting
  RptAccountLedgerService: "https://service.bholahardware.com/RptAccountLedger",
  RptBalanceSheetService: "https://report.bholahardware.com/RptBalanceSheet",
  RptProfitLossService: "https://report.bholahardware.com/RptProfitLoss",
  RptRevenueExpenseService: "https://report.bholahardware.com/RptRevenueExpense",
  RptPaymentService: "https://report.bholahardware.com/RptPaymentAndReceipt",
  RptOutstandingService: "https://report.bholahardware.com/RptOutstanding",
  RptReconcilationUnreconcilationService: "https://report.bholahardware.com/RptBankreconciledunreconciled",
  RptVATInputOutputService: "https://report.bholahardware.com/RptVATInputOutput",
  RptpayablereceivableService: "https://report.bholahardware.com/RptPayableReceivable",
  RptAgeingService: "https://service.bholahardware.com/RptAgeing",
  RptPOSummaryService: "https://report.bholahardware.com/RptPurchaseOrderSummaryView",
  RptPurchaseQuotationService: "https://report.bholahardware.com/RptPurchaseQuotation",
  RptTrialBalanceMonthService: "https://report.bholahardware.com/RptTrialBalance",
  ProductBarcodePrintService: "https://report.bholahardware.com/ProductPrint",
  RptSalesSummaryReportService: "https://report.bholahardware.com/RptSalesSummaryReport",
  RptSalesQuotationSummaryService: "https://report.bholahardware.com/RptSalesQuotationSummary",
  RptStockValueReportService: "https://report.bholahardware.com/StockValueReport",
  
  salescardintegrationapi: 'http://localhost:9111/api/requests',

  //dashboard
  customerDashboardService: "https://service.bholahardware.com/Customerwidget",
  SupplierDashboardService: "https://service.bholahardware.com/Supplierwidget",
  salesreturnDashboardService: "https://service.bholahardware.com/SalesReturnwidget",
  purchasereturnDashboardService: "https://service.bholahardware.com/PurchaseReturnwidget",
  StockDashboardService: "https://service.bholahardware.com/Stockwidget",
  tellerDashboardService: "https://service.bholahardware.com/Tellerwidget",
  DefaultCustomerService: "https://service.bholahardware.com/DefaultCustomer",
  sendMailService: "https://service.bholahardware.com/Mail",
  CompetitorService: "https://service.bholahardware.com/Competitor",
  StockvaluationService: "https://service.bholahardware.com/StockValuation",
  NotificationService: "https://service.bholahardware.com/Notification",
  DeiveryNoteService: "https://service.bholahardware.com/DeliveryNote",
  //Online&Offline
  DBSyncService: "https://service.bholahardware.com/DbSync",
  GenerateBackupOnlineService: "https://service.bholahardware.com/GenerateBackup",
  GenerateBackupOfflineService: "https://service.bholahardware.com/GenerateBackup",


  ExpenseService: "https://service.bholahardware.com/RptExpense",
  UserDiscountService: "https://service.bholahardware.com/RptSalesDiscountDrillDown",
  RptStockAdjustmentPrintService: "https://report.bholahardware.com/RptStockAdjustmentPrint",
  RptStockAllocationPrintService: "https://report.bholahardware.com/RptStockAllocationPrint",
  signalr_userhub: "https://service.bholahardware.com/NotificationUserHub",

  //Ecommerce
  AdManagementService: "https://service.bholahardware.com/AdManagement",
  CouponService: "https://service.bholahardware.com/Coupon",
  SliderManagementService: "https://service.bholahardware.com/SlideManagement",
  OrderDescriptionService: "https://service.bholahardware.com/OrderStatusDesc",
  OrderStatusService: "https://service.bholahardware.com/Order",
  CmsService: "https://service.bholahardware.com/Cms",
  MailService: "https://service.bholahardware.com/Mail",
  ShippingService: "https://service.bholahardware.com/ShippingCharges",
  BrandService: "https://service.bholahardware.com/Brand",
  PaymentTransactionService: "https://service.bholahardware.com/PaymentGateWay",
  BlogService: "https://service.bholahardware.com/Blog",
  SmsLogService: "https://service.bholahardware.com/Mail",
  OrderPendingStatusService: "https://service.bholahardware.com/OrderpendingStatus",
  CODPaymentStatusService: "https://service.bholahardware.com/CODPaymentStatus",
  OnlineStockDeliveryService: "https://service.bholahardware.com/OnlineStockDelivery",
  MaterialReturnRequestService: "https://service.bholahardware.com/MaterialReturnRequest",
  CODSettlementService: "https://service.bholahardware.com/CodSettlement",
  PaymentReturnStatusService: "https://service.bholahardware.com/PaymentReturnStatus",
  RptDeliveryChallanService: "https://report.bholahardware.com/RptDeliveryChallan",
  RptPurchaseNewInvoiceService: "https://report.bholahardware.com/RptPurchaseInvoiceReport",
  swipeService: "https://service.bholahardware.com/MultiSwipe",
  AdvanceAdjustmentService: "https://service.bholahardware.com/AdvanceAdjustment",
  RptSalesReportService: "https://service.bholahardware.com/RptSales",
  ProductPriceService: "https://service.bholahardware.com/ProductPriceReport",
  RptOutStandingCreditNoteService: "https://service.bholahardware.com/RptOutStandingCreditNote",
  RptSalesCommissionService: "https://service.bholahardware.com/RptSalesCommission",
  ServicerequestService: "https://service.bholahardware.com/ServiceRequest",
  JobcardService: "https://service.bholahardware.com/JobCard",
  ServiceWorkOrderService: "https://service.bholahardware.com/ServiceWorkOrder",
  ServiceProductDeliveryService: "https://service.bholahardware.com/ServiceProductDelivery",
  ServiceInvoiceService: "https://service.bholahardware.com/ServiceInvoice",
  PurchaseServiceInvoiceService: "https://service.bholahardware.com/PurchaseServiceInvoice",
  RptLotwisestockService: "https://service.bholahardware.com/RptLotWiseStock",
  ClosingBalanceUpdationService: "https://service.bholahardware.com/ClosingBalanceUpdation",
  WorkshopSummaryReportService: "https://service.bholahardware.com/WorkshopSummaryReport",
  RptAssetValueReportService: "https://service.bholahardware.com/AssetReport",
  RptMarkupLevelService: "https://service.bholahardware.com/RptProductMarkupLevel",
  PurchaseVsSalesReportService: "https://service.bholahardware.com/PurchaseVsSalesReport",
  MaterialConsumptionreportService: "https://service.bholahardware.com/MaterialConsumptionReport",
  ProductRequestService: "https://service.bholahardware.com/ProductRequest",
  SalesDashboardService: "https://service.bholahardware.com/SalesDashboard",
  RptPendingApprovalStatusService: "https://service.bholahardware.com/RptPendingApprovalStatus",
  PurchaseDashboardService: "https://service.bholahardware.com/PurchaseDashboard",
  RptClosingBalanceVerificationService: "https://service.bholahardware.com/RptClosingbalanceVerification",
  RptreceivablespayablesService: "https://service.bholahardware.com/Rptreceivablespayables",
  OpeningBalanceUpdationService: "https://service.bholahardware.com/OpeningBalanceUpdation",
  TechnologiesService: "http://localhost:8080",
  RptAccountsGroupWiseReportService: "https://service.bholahardware.com/RptAccountGroupWiseClosingBalance",
  OutstandingReceivableDashboardService: "https://service.bholahardware.com/OutstandingReceivableDashboard",
  OutstandingPayableDashboardService: "https://service.bholahardware.com/OutstandingPayableDashboard",
  RptsupplierdueService: "https://service.bholahardware.com/RptSupplierDueReport",
  RptbrandwisestockService: "https://service.bholahardware.com/RptSupplierDueReport",
  RptinvoicedelaytimeService: "https://service.bholahardware.com/RptSupplierDueReport",
  RptPendingDeliveryService: "https://service.bholahardware.com/RptSupplierDueReport",
  // Chennappan
  CusorSupCategoryService: "https://service.bholahardware.com/Category",
  SubcategoryService: "https://service.bholahardware.com/Subcategory",
  powerBiConfig: {
    reportId: 'YOUR_REPORT_ID',
    embedUrl: 'YOUR_EMBED_URL',
    //embedToken: 'YOUR_EMBED_TOKEN'
  },
  OutdstandingPurchaseOrderService: "https://service.bholahardware.com/Outstandingporeport",
  LaybuyreportService: "https://service.bholahardware.com/Rptlaybuy",
  RptpurchasetrackingService: "https://service.bholahardware.com/Rptpurchasetracking",
  RptProductRequestService: "https://service.bholahardware.com/RptProductRequest",
  ConsignmentCustomerBranchService: "https://service.bholahardware.com/ConsignmentCustomerBranch",
  ConsignmentDeliveryNoteService: "https://service.bholahardware.com/ConsignmentDeliveryNote",
  ConsignmentStockAdjustmentService: "https://service.bholahardware.com/ConsignmentStockAdjustment",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

