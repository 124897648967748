import { UpperCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
  base64ToFile,
} from 'ngx-image-cropper';
import { FileUpload } from 'primeng/fileupload';
import { Customerbranchdetail } from 'src/app/core/Models/Customerbranch';
import { District } from 'src/app/core/Models/District';
import { State } from 'src/app/core/Models/State';
import { Customerbranchaddressdetail } from 'src/app/core/Models/customerbranchaddressdetail';
import { Customerbranchheader } from 'src/app/core/Models/customerbranchheader';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { country } from 'src/app/core/Models/overallclass';
import { ConsignmentcustomerbranchService } from 'src/app/core/Services/consignmentcustomer/consignmentcustomerbranch/consignmentcustomerbranch.service';
import { CustomerService } from 'src/app/core/Services/masters/customer.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import {
  vAddress,
  vContactPersonName,
  vCountryName,
  vCountryNameSelect,
  vCustomerBranchCode,
  vCustomerBranchName,
  vDistrictName,
  vDistrictNameSelect,
  vEmailID,
  vMobileNumber,
  vStateName,
  vStateNameSelect,
} from 'src/app/core/Validators/validation';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CommonUtilities } from 'src/assets/js/common-utilities';

declare var $: any;

@Component({
  selector: 'app-c-consignmentcustomerbranch',
  templateUrl: './c-consignmentcustomerbranch.component.html',
  styleUrls: ['./c-consignmentcustomerbranch.component.css'],
})
export class CConsignmentcustomerbranchComponent implements OnInit {
  _customerbranchform: FormGroup;
  _customerbranchaddressform: FormGroup;
  _action: string = 'create';
  _submitted = false;
  isDisabledsave = false;
  isDisabledreset = false;
  _IsProgressSpinner: boolean = true;
  _Statuslist: Metadatum[] = [];
  _Countrylist: country[] = [];
  _Statelist: State[] = [];
  _Citylist: District[] = [];
  _Action: IAction;
  _Customerbranchheader: Customerbranchheader;
  _Customerbranchaddress: Customerbranchaddressdetail;
  selectedtab: number = 0;
  _Customerbranch: any;
  _Customerformdata: FormData;
  _addresssubmitted: boolean = false;
  isDisabledaddbuttn: boolean = false;
  addressRowIndex: number = -1;
  _CustomersAddress: Customerbranchaddressdetail[] = [];
  selectedrows: Customerbranchaddressdetail[] = [];
  displaydocumentpopup: boolean;
  position1: string;
  imageCropperTool: boolean;
  fileUpload: any;
  imageChangedEvent: any;
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  file: any;
  size: any;
  width: number;
  height: number;
  _Initials: any[];
  _CropImage: File;
  _Iscreatefileupload: boolean = true;
  _Isviewfileupload: boolean = false;
  LogoImage: string;
  branchlogo: SafeUrl;
  documentimage: SafeUrl;
  _selectedDocuments: File;
  _LastAddedDocument: File;
  imageselect: string = '';
  _branchid: number;
  isDisabledSave: boolean = false;
  isDisabledButton: boolean = false;
  _Customerbranchheaderlist: Customerbranchheader[]; 
  _CustomerList: Customerbranchheader[]; 
  _Addressstatus: any[];
  customerid: any[] = []; 
  _customerids: any[] = [];

  //Branch Logo
  checkValidation(evt: any) {
    let image: any = evt.target.files[0];
    this.size = image.size;
    let fr = new FileReader();
    fr.onload = () => {
      // when file has loaded
      var img = new Image();

      img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        console.log('Width:', this.width);
        console.log('Height:', this.height);
        if (this.width <= 140 || this.height <= 60) {
          alert('error');
        } else {
          this.fileChangeEvent(event);
        }
      };
      img.src = fr.result as string; // This is the data URL
      // this.imgType.nativeElement.value = '';
    };
    fr.readAsDataURL(image);
  }

  fileChangeEvent(event: any): void {
    console.log('image', event);

    this.imageChangedEvent = event;
    // console.log("Image", this.imageChangedEvent);
    // var fileInput = $(this).find("input[type=file]")[0],
    //     file = fileInput.files && fileInput.files[0];
    // alert(file);
    // console.log("Image Size", this.path[0].value);
    this.imageCropperTool = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  onSaveImage() {
    var imgWidth = $('.source-image').width();
    var imgheight = $('.source-image').height();
    this.createBlobImageFileAndShow();
    this.imageCropperTool = false;
    // alert('Cropper ready Width : ' + imgWidth + ' Cropper ready Height : ' + imgheight);
  }
  createBlobImageFileAndShow(): void {
    const imageBlob: Blob = base64ToFile(this.croppedImage);
    const imageName: string = this.generateName();
    this._CropImage = new File([imageBlob], imageName, {
      type: 'image/jpeg',
    });
  }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text + '.jpg';
  }

  loadImageFailed() {
    console.log('Load failed');
  }
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  constructor(
    private fb: FormBuilder,
    private _AccessRightsService: AccessRightsService,
    private _ConsignmentCustomerService: ConsignmentcustomerbranchService,
    private _CustomExceptionService: CustomExceptionService,
    private _DomSanitizationService: DomSanitizer,
    private _CustomerService: CustomerService,
	  private utility: CommonUtilities
  ) {}

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(377);
	this.utility.pageLoadScript();
    this._customerbranchform = this.fb.group({
	    cutomerbranchid: [0],
      customerbranchcode: ['', vCustomerBranchCode],
      customerbranchname: ['', vCustomerBranchName],
      customerbranchstatus: ['MSC00001'],
      attachmenturl: [''],
      attachmentname: [''],
      createdby: [],  
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      customerbranchaddressdetails: [],
      customerbranchdetails: [],
      
    });

    this._customerbranchaddressform = this.fb.group({
      customerbranchaddressdetailid: [0],
      cutomerbranchid: [0],
      contactperson: ['', vContactPersonName],
      mobileno: ['', vMobileNumber],
      mailid: ['', vEmailID],
      countryid: ['', vCountryNameSelect],
      stateid: ['', vStateNameSelect],
      districtid: ['', vDistrictNameSelect],
      addresstype: [],
      isdefaultaddress: [],
      addressdetail: ['', vAddress],
      addressstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });

    this._IsProgressSpinner = true;
    this._branchid = history.state?.cutomerbranchid ? history.state?.cutomerbranchid : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._ConsignmentCustomerService.PageOnLoad(this._branchid).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Statuslist = resultJSON.statuslist;
        this._Countrylist = resultJSON.countrylist;
        this._Statelist = resultJSON.statelist;
        this._Citylist = resultJSON.citylist;
        this._Customerbranchheaderlist = resultJSON.customerbranchheaderlist;
        this._CustomerList = resultJSON.customerList;
        this._IsProgressSpinner = false;

        this.isDisabledsave = false;
        //this.isDisabledreset = false;
        //For Update
        // this._countryid = history.state.countryid;
        if (this._action == 'edit' || this._action == 'view') {
			
          this._CustomersAddress = resultJSON.customerbranchaddress || [];
          this._customerbranchform.setValue(resultJSON.customerbranchheader[0]);
          this.customerid = resultJSON.customerbranchdetails.map((m) => m.customerid) || []
        }
        if (this._action == 'view') {
          this._customerbranchform.disable();
          // this._removedocoumenthidden = true;
          this._customerbranchaddressform.disable();
          this.isDisabledSave = true;
          this.isDisabledButton = true;
          this.isDisabledaddbuttn = true;
          this.isDisabledreset = true;
        }
        if (this._action == 'edit') {

          this.isDisabledreset = true;
        }
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  onSave() {
    this._submitted = false;
    this._addresssubmitted = false;
    if(this._CustomersAddress.length == 0)
    {
      this._CustomExceptionService.handleWarning("Please Add the address");
    }
    this._Customerbranchaddress = this._customerbranchaddressform.value;
    
    if (this._customerbranchform.valid) {
      if (this._CustomersAddress.length > 0) {
        this._Customerbranchheader = this._customerbranchform.value;
        if (this._Customerbranchheader.customerbranchname.trim() === '') {
          this._CustomExceptionService.handleWarning('Customer Branch Name Cannot be empty');
          return;
        }
        var BranchList: any[] = []
          for (let i = 0; i < this.customerid.length; i++) {
            const x = this.customerid[i];
            const Branch: any = {};
            Branch.customeraddressdetailid = 0;
            Branch.customerbranchid = 0;
            Branch.customerid = x;
            this._customerids.push(Branch);
          }
        if (this._action == 'create') {
          this._Customerbranchheader.createdon = new Date();
          this._Customerbranchaddress.createdon = new Date();
          this._Customerbranchheader.createdby = sessionStorage['userid'];
          this._Customerbranchaddress.createdby = sessionStorage['userid'];
        }
        if (this._action == 'create') {
          this._Customerformdata = this.formconverstion(this._Customerbranchheader, this._CustomersAddress,this._CropImage, this._customerids);
          this._IsProgressSpinner = true;
          this._ConsignmentCustomerService.Create(this._Customerformdata).subscribe((result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            
            if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
              this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
              this.Clear();
              this._IsProgressSpinner = false;
            } else {
              this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            }
            this._IsProgressSpinner = false;
          },
            error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
        } else if (this._action == 'edit') {
          if (this._Customerbranchaddress != null) {
            this._Customerbranchaddress.modifiedon = new Date();
            this._Customerbranchaddress.modifiedby = sessionStorage['userid'];
          }
          this._Customerbranchheader.modifiedon = new Date();
          this._Customerbranchheader.modifiedby = sessionStorage['userid'];
          this._IsProgressSpinner = true;
          var updatedata = this.formconverstion(
            this._Customerbranchheader,
            this._CustomersAddress,
            this._CropImage,
            this._customerids
          );
          this._ConsignmentCustomerService.edit(updatedata).subscribe((result) => {
              const resultJSON = JSON.parse(JSON.stringify(result));
              if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
                this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
                this._IsProgressSpinner = false;
              } else {
                this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
                this._IsProgressSpinner = false;
              }
            },
              error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
        }
      } 
    } else {
      this._CustomExceptionService.handleError(usererrors.Customeraddress05);
      this.selectedtab = 0;
      return;
    }
  }

  formconverstion(
    customerbranch: Customerbranchheader,
    customerbranchaddress: Customerbranchaddressdetail[],
    _CropImage: File,
    _customerids: Customerbranchdetail[],
  ) {
    const formData: FormData = new FormData();
    let time = new Date();
    if (_CropImage != undefined) {
      var file = base64ToFile(this.croppedImage);
      var NewFileName = time.getTime() + '_' + _CropImage.name;
      customerbranch.attachmenturl = NewFileName || "";
      customerbranch.attachmentname = NewFileName || "";
      formData.append('files', _CropImage, NewFileName);
      //}
    }
    var Customerbranch = {
      Customerbranchheader: customerbranch,
      Customerbranchaddressdetail: customerbranchaddress,
      Customerbranchdetail: _customerids
    };
    var Branch = JSON.stringify(Customerbranch);
    formData.append('obj2', Branch);
    return formData;
  }

  onAddAddress(event) {
    this._addresssubmitted = true;
    if (this._customerbranchaddressform.valid) {
      if (this.addressRowIndex > -1) {
        this._CustomersAddress[this.addressRowIndex] =
          this._customerbranchaddressform.value;
        this._CustomersAddress[this.addressRowIndex].createdon = new Date();
      } else {
        var addressdetail: any = {};
        addressdetail.customeraddressdetailid = 0;
        addressdetail.customerid = 0;
        addressdetail.contactperson =
          this._customerbranchaddressform.get('contactperson').value;
        addressdetail.mobileno =
          this._customerbranchaddressform.get('mobileno').value;
        addressdetail.addressdetail =
          this._customerbranchaddressform.get('addressdetail').value;
        addressdetail.mailid =
          this._customerbranchaddressform.get('mailid').value;
        addressdetail.countryid =
          this._customerbranchaddressform.get('countryid').value;
        addressdetail.stateid =
          this._customerbranchaddressform.get('stateid').value;
        addressdetail.districtid =
          this._customerbranchaddressform.get('districtid').value;
        // addressdetail.isdefaultaddress = this._customerbranchaddressform.get("isdefaultaddress").value;
        addressdetail.createdby = sessionStorage['userid'];
        addressdetail.modifiedby = 0;
        addressdetail.modifiedon = null;
        addressdetail.createdon = new Date();
        addressdetail.isdefaultaddress = this._CustomersAddress.length == 1;
        // addressdetail.addresstype = this._customerbranchaddressform.get("addresstype").value;
        this._CustomersAddress.push(addressdetail);
      }
      this.resetaddress(null);
    }
  }

  resetaddress(event) {
    this._customerbranchaddressform.reset();
    this._addresssubmitted = false;
    this.addressRowIndex = -1;
  }

  RemoveAddressDetail(p_addressDetail: Customerbranchaddressdetail) {
    var index = this._CustomersAddress.indexOf(p_addressDetail);
    this._CustomersAddress.splice(index, 1);
  }

  Removeselectedchecked(
    _CustomerAddress: Customerbranchaddressdetail,
    rowIndex: number
  ) {
    if (this._CustomersAddress && this._CustomersAddress.length) {
      for (let i = 0; i < this._CustomersAddress.length; i++) {
        if (i === rowIndex) continue;
        this._CustomersAddress[i].isdefaultaddress = false;
      }
    }
    this._CustomersAddress[rowIndex].isdefaultaddress =
      _CustomerAddress.isdefaultaddress;
  }

  showdocumentDialog(document: FileUpload, position1: string) {
    if (this._LastAddedDocument) {
      this.branchlogo = this._DomSanitizationService.bypassSecurityTrustUrl(
        URL.createObjectURL(this._LastAddedDocument)
      );
      this.imageselect = this._LastAddedDocument.name;
    }
    if (this.branchlogo == null) {
      return;
    }
    this.position1 = position1;
    this.displaydocumentpopup = true;
  }

  OpenEmployeeImage(event) {
    if (this.LogoImage != null) {
      window.open(this.LogoImage, '_blank');
      // window.open(this._Url + "/upload/Employee" + this.imageselect, "_blank");
    } else {
      this._CustomExceptionService.handleWarning('Please select image');
    }
  }
  onImageClear() {
    this._Iscreatefileupload = true;
    this._Isviewfileupload = false;
    this.LogoImage = null;
    // this._LastAddedImage = null;
  }
  //Document Select:
  onDocumentSelect(event, document) {
    this._LastAddedDocument = event.currentFiles[0];
    this._customerbranchform
      .get('attachmentname')
      .setValue(this._LastAddedDocument.name);
    document.clear();
  }

  //Document Clear:
  onDocumentClear() {
    this._customerbranchform.get('attachmenturl').setValue(undefined);
    this._customerbranchform.get('attachmentname').setValue(undefined);
    this.branchlogo = null;
    this._LastAddedDocument = null;
  }
  showPopupDialog(position1: string) {
    this.position1 = position1;
    this.displaydocumentpopup = false;
  }

  BranchnameCheck()
  {
    var branch = this._customerbranchform.get("customerbranchname").value;
    var branchname = branch.trim();
    var branchcount = this._Customerbranchheaderlist.filter(a => a.customerbranchname == branchname);
    if(branchcount.length > 0 && this._action == "create")
    {
      this._CustomExceptionService.handleError("This branchname has already exists");
      return;
    }
    return null;
  }

  BranchcodeCheck()
  {
    var branch = this._customerbranchform.get("customerbranchcode").value;
    var branchcode = branch.trim();
    var branchcount = this._Customerbranchheaderlist.filter(a => a.customerbranchcode == branchcode);
    if(branchcount.length > 0 && this._action == "create")
    {
      this._CustomExceptionService.handleError("This branchcode has already exists");
      return;
    }
    return null;
  }

  Clear()
  {
    this._customerbranchform.reset();
    this._customerbranchaddressform.reset();
    this._CustomersAddress = [];
    this.customerid = [];

  }

  OnchangeAddressStatus(_Addressstatus)
  {
    if(!_Addressstatus || _Addressstatus == undefined || _Addressstatus == null)
    {
      _Addressstatus = "MSC00001"
    }
    this._customerbranchaddressform.get("addressstatus").setValue(_Addressstatus);
  }

  onRowCustomerselect(address: Customerbranchaddressdetail, rowIndex: number) {
    this._customerbranchaddressform.setValue(address);
    this.addressRowIndex = rowIndex;
  }

  getStates(event) {
    var selectedCountry = event.value;
    this._IsProgressSpinner = true;
    this._Statelist = [];
    this._Citylist = [];
    this._CustomerService.getStates(selectedCountry).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Statelist = resultJSON.states;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  getDistrict(event) {
    var selectedState = event.value;
    this._IsProgressSpinner = true;
    this._Citylist = [];
    this._CustomerService.getDistrict(selectedState).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Citylist = resultJSON.cities;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
}
