import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as ace from 'ace-builds';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { timeout } from 'rxjs/operators';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Accountgroup } from 'src/app/core/Models/Accountgroup';
import { Country } from 'src/app/core/Models/Country';
import { Currency } from 'src/app/core/Models/Currency';
import { District } from 'src/app/core/Models/District';
import { Documentdescription } from 'src/app/core/Models/DocumentDescription';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { State } from 'src/app/core/Models/State';
import { Supplier } from 'src/app/core/Models/Supplier';
import { Supplieraccountdetail } from 'src/app/core/Models/Supplieraccountdetail';
import { Supplieraddressdetail } from 'src/app/core/Models/Supplieraddressdetail';
import { Supplierdocumentdetail } from 'src/app/core/Models/Supplierdocumentdetail';
import { Supplierproductdetail } from 'src/app/core/Models/Supplierproductdetail';
import { User } from 'src/app/core/Models/User';
import { SupplierService } from 'src/app/core/services/masters/supplier.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import {
  vAccountGroupName,
  vAccountHolderName,
  vAccountNo,
  vAccountTypeNameSelect,
  vAddress,
  vBankCodeSelect,
  vBankNameSelect,
  vBranchName,
  vContactPersonName,
  vDocumentName,
  vEmailID,
  vInputNamewithoutspecialcharater,
  vMobileNumber,
  vPayModeSelect,
  vSelectCurrency,
  vSupplierCategorySelect,
  vSupplierName,
  vSupplierType,
  vSupplierTypeSelect,
  vSwiftCode,
  vVatTypeSelect,
} from 'src/app/core/Validators/validation';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Supplierpageloadview } from 'src/app/core/Views/Supplierpageloadview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
import { KeyPress } from 'src/assets/js/KeyPress';
@Component({
  selector: 'app-c-supplier',
  templateUrl: './c-supplier.component.html',
  styleUrls: ['./c-supplier.component.css'],
})
export class CSupplierComponent implements OnInit {
  selectedtab: number = 0;
  addressRowIndex: number = -1;
  _supplierform: FormGroup;
  _supplieraccountdetailform: FormGroup;
  _supplierproductdetailform: FormGroup;
  _supplieraddressdetailform: FormGroup;
  _Accountgroups: Accountgroup[] = [];
  _Supplierdocumentdetails: Supplierdocumentdetail[] = [];
  _Currencies: Currency[] = [];
  _Supplierstatus: Metadatum[] = [];
  _selectedDocuments: File[] = []; // For Documents uploads
  _Supplierseries: Metadatum[] = [];
  _Suppliercategories: Metadatum[] = [];
  displaydocumentgridpopup: boolean = false;
  _Countries: Country[] = [];
  _States: State[] = [];
  _LastAddedGridDocument: File;
  _Cities: District[] = [];
  _rating: Metadatum[] = [];
  documentselect: string = '';
  supplierimage: SafeUrl;
  _Accounttypes: Metadatum[] = [];
  position: string;
  position1: string;
  _PayModes: Metadatum[] = [];
  _Suppliertypes: Metadatum[] = [];
  _LastAddedDocument: File;
  _VAT: Metadatum[] = [];
  _Creditdetails: Metadatum[] = [];
  _bankcode: Supplieraccountdetail[] = [];
  _bankname: Supplieraccountdetail[] = [];
  _supplierid: number;
  _action: string = 'create';
  _submitted = false;
  _submitted1 = false;
  _submitted2 = false;
  _supplierdocumentdetailform: FormGroup;
  _DocumentDescriptions: Documentdescription[];
  _Supplier: Supplier;
  _DocumentDetail: Supplierproductdetail[] = [];
  _Supplieraccountdetail: Supplieraccountdetail;
  _Supplierproductdetail: Supplierproductdetail[] = [];
  _SupplierAddress: Supplieraddressdetail[] = [];
  selectedsuppliersAddressrows: Supplieraddressdetail[];
  _SuppliersAddress: Supplieraddressdetail[] = [];
  _SupplierOBJ: any;
  displayBasic: boolean;
  _SupplierProducts: Supplierpageloadview[];
  _TempSupplierProducts: Supplierpageloadview[];
  _SupplierProductsList: Supplierpageloadview[];
  _SupplierProduct: Supplierpageloadview;
  _BankList: Supplieraccountdetail[] = [];
  _selectedBank: Supplieraccountdetail[] = [];
  selectedBank: any;
  selectedSupplierProductsrows: Supplierpageloadview[];
  _selectedProductIndex: number[] = [];
  _producttype: Producttype;
  _productcategory: Productcategory;
  _productname: Productoverallview[] = [];
  selectedrows: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  documentimage: SafeUrl;
  _ProductObj: any;
  isDisabledSave = false;
  isDisabledClear = false;
  IsDisabledPopupButton = false;
  _IsProgressSpinner: boolean = true;
  _Action: IAction;
  showseries: boolean = true;
  disableseries: boolean = false;
  _tempattachmentURL: any;
  _removedocoumenthidden: boolean = false;
  selectedCategory: any;
  _brandlist: any;
  brandid: number;
  UpdateProducts: any[] = [];
  _UserList: User[] = [];
  _UserTempList: User[] = [];
  _prevSelectedProtype: any[] = [];
  _prevSelectedCattype: any[] = [];
  _selectedProTypeIds: number[] = [];
  _selectedProCatIds: number[] = [];
  _tempselectedProCatIds: number[] = [];
  IsDisabledProductTab: boolean = false;
  _suppliernotmappedproductlist: any[] = [];
  _threesuppliermappedproductlist: any[] = [];
  threesuppliermappedproductlist: boolean = false;
  suppliernotmappedproductlist: boolean = false;
  supplierholdreason: boolean = false;
  suppliernotmappedproduct_and_threesuppliermappedlist: boolean = false;
  supplierholdpopupclose: boolean = false;
  supplierholdpopupsubmit: boolean = false;

  @ViewChild('editor', { static: true })
  private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _DomSanitizationService: DomSanitizer,
    private _SupplierService: SupplierService,
    private utility: CommonUtilities,
    private _CustomExceptionService: CustomExceptionService,
    private _router: Router,
    private _hotkeysService: HotkeysService,
    public keypress: KeyPress,
    private _AccessRightsService: AccessRightsService,
    private exportUtility: ExportUtility
  ) {
    this._supplierid = history.state?.supplierid
      ? history.state?.supplierid
      : 0;
    this._action =
      history.state.action == null ? 'create' : history.state.action;
    this._Action = this._AccessRightsService.getAccessRights(19);
    this.HotKeyIntegration();
  }
  InitializeForm() {
    this._supplierform = this.fb.group({
      supplierid: [0],
      suppliercode: [],
      suppliername: [''],
      suppliertypecode: ['', vSupplierTypeSelect],
      accountgroupid: [0],
      currencyid: ['', vSelectCurrency],
      paymodecode: ['', vPayModeSelect],
      vatno: [],
      bpno: [],
      vattype: ['', vVatTypeSelect],
      credittype: [],
      creditlimit: [],
      creditdays: [],
      series: [],
      supplierrating: [],
      supplierstatus: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      suppliercategorycode: ['', vSupplierCategorySelect],
      comapanyid: [0],
      branchid: [sessionStorage['BranchID']],
      productsupplierdetails: [],
      supplieraccountdetails: [],
      supplieraddressdetails: [],
      supplierproductdetails: [],
      savetype: ['MSC00187'],
      tempcreditdays: [],
      tempcreditlimit: [],
      approvalstatus: [],
      dealuserid: [],
      supplierdocumentdetails: [],
      reasonforsupplierhold: [],

      // faxno: []
    });
    this._supplieraccountdetailform = this.fb.group({
      supplieraccountdetailid: [0],
      supplierid: [0],
      bankcode: ['', vBankCodeSelect],
      bankname: ['', vBankNameSelect],
      swiftcode: ['', vSwiftCode],
      branchname: ['', vBranchName],
      accountholdername: ['', vAccountHolderName],
      accountno: ['', vAccountNo],
      accounttype: ['', vAccountTypeNameSelect],
      accountstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      supplier: [],
    });

    this._supplierproductdetailform = this.fb.group({
      supplierproductdetailid: [0],
      supplierid: [0],
      producttypeid: [],
      productcategoryid: [],
      productid: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
    });

    this.InitializeAddressDetailForm();
    if (this._Currencies && this._Currencies.length)
      this._supplierform.controls['currencyid'].setValue(
        this._Currencies[0].currencyid
      );

    this._supplierform.controls['supplierstatus'].setValue('MSC00001');

    this._supplierform.controls['series'].setValue('MSC00093');
    if (this._PayModes && this._PayModes.length)
      this._supplierform.controls['paymodecode'].setValue(
        this._PayModes[0].metasubcode
      );

    if (this._Suppliertypes && this._Suppliertypes.length)
      this._supplierform.controls['suppliertypecode'].setValue(
        this._Suppliertypes[0].metasubcode
      );

    if (this._VAT && this._VAT.length)
      this._supplierform.controls['vattype'].setValue(this._VAT[0].metasubcode);

    if (this._Creditdetails && this._Creditdetails.length)
      this._supplierform.controls['credittype'].setValue(
        this._Creditdetails[0].metasubcode
      );
  }

  InitializeAddressDetailForm() {
    this._supplieraddressdetailform = this.fb.group({
      supplieraddressdetailid: [0],
      supplierid: [0],
      contactpersonname: ['', vContactPersonName],
      mobileno: ['', vMobileNumber],
      phoneno: [],
      address: ['', vAddress],
      mailid: ['', vEmailID],
      countryid: [],
      stateid: [],
      districtid: [],
      faxno: [],
      isdfaultaddress: [],
      supplier: [],
    });
  }
  InitializeDocumentForm() {
    this._supplierdocumentdetailform = this.fb.group({
      supplierdocumentdetailid: [0],
      supplierid: [0],
      documentid: [1],
      documentname: ['', vDocumentName],
      documentdescription: [],
      attachmenturl: [],
      attachmentname: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      // employee: []
    });
  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this.InitializeAddressDetailForm();
    this.InitializeDocumentForm();
    this.checkseriesRights();

    if (this._action == 'view') {
      this._supplierform.disable();
      this._supplieraccountdetailform.disable();
      this._supplieraddressdetailform.disable();
      this._removedocoumenthidden = true;
      this.isDisabledClear = true;
      this.isDisabledSave = true;
      this.IsDisabledPopupButton = true;
    }

    if (this._action == 'edit') {
      this.isDisabledClear = true;
      this.isDisabledSave = false;
    }
    this._IsProgressSpinner = true;
    this._SupplierService
      .PageOnLoad(this._supplierid, sessionStorage['userid'])
      .subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));

          this._Accountgroups = resultJSON.accountgroups;
          this._Currencies = resultJSON.currencies;
          this._Supplierstatus = resultJSON.supplierStatus;
          this._Supplierseries = resultJSON.series;
          this._Suppliercategories = resultJSON.supplierCategories;
          this._Countries = resultJSON.countries;
          //this._States = resultJSON.states;
          this._rating = resultJSON.supplierRatings;
          this._DocumentDescriptions = resultJSON.documentdescriptions;
          this._Cities = resultJSON.cities;
          this._IsProgressSpinner = false;
          this._supplierform.controls['supplierstatus'].setValue('MSC00001');
          // this._supplierform.controls['series'].setValue("MSC00093");
          this._producttype = resultJSON.producttypes;
          this._productcategory = resultJSON.productcategories;
          this._Accounttypes = resultJSON.accountTypes;

          this._BankList = resultJSON.banks;
          this._brandlist = resultJSON.brands;

          this._SupplierProducts = resultJSON.selectedsupplierlist;
          //Radio buttons
          this._PayModes = resultJSON.payModes;
          this._Suppliertypes = resultJSON.supplierTypes;
          this._VAT = resultJSON.vatDetails;
          this._Creditdetails = resultJSON.creditDetails;
          this._UserList = resultJSON.users;
          this._UserTempList = resultJSON.users;

          // for update and view

          if (this._Currencies && this._Currencies.length)
            this._supplierform.controls['currencyid'].setValue(
              this._Currencies[0].currencyid
            );

          this._supplierform.controls['supplierstatus'].setValue('MSC00001');

          this._supplierform.controls['series'].setValue('MSC00093');
          this.OnChangeRights();

          if (this._PayModes && this._PayModes.length)
            this._supplierform.controls['paymodecode'].setValue(
              this._PayModes[0].metasubcode
            );

          if (this._Suppliertypes && this._Suppliertypes.length)
            this._supplierform.controls['suppliertypecode'].setValue(
              this._Suppliertypes[0].metasubcode
            );

          if (this._VAT && this._VAT.length)
            this._supplierform.controls['vattype'].setValue(
              this._VAT[0].metasubcode
            );

          if (this._Creditdetails && this._Creditdetails.length)
            this._supplierform.controls['credittype'].setValue(
              this._Creditdetails[0].metasubcode
            );

          // this._supplierform.controls['creditlimit'].disable();
          // this._supplierform.controls['creditdays'].disable();
          // this._supplierform.controls['vatno'].disable();
          this.IsDisabledProductTab = true;
          if (this._action == 'edit' || this._action == 'view') {
            this.disableseries = true;
            const updateJSON = JSON.parse(JSON.stringify(result));
            this._Countries = updateJSON.countries;
            this._States = updateJSON.states;
            this._Supplierdocumentdetails =
              updateJSON.supplierdocumentdetails || [];
            this.selectedrows = updateJSON.supplierdocumentdetails || [];

            this._supplierform.setValue(updateJSON.supplier);
            var supplierstatus = this._supplierform.get('supplierstatus').value;
            if (
              this._supplierform.get('suppliercategorycode').value == 'MSC00131'
            ) {
              this.IsDisabledProductTab = false;
            } else {
              this.IsDisabledProductTab = true;
            }

            if (supplierstatus == 'MSC00344' && this._action == 'edit') {
              this.supplierholdreason = true;
              this.supplierholdpopupsubmit = true;
            }

            if (supplierstatus == 'MSC00344' && this._action == 'view') {
              this.supplierholdreason = true;
              this.supplierholdpopupsubmit = false;
            }
            // if(this._supplierform.get("suppliertypecode").value =="MSC00187"){

            if (updateJSON.supplier.savetype == 'MSC00187') {
              this._supplieraccountdetailform.setValue(
                updateJSON.supplieraccountdetail
              );
            }

            this._SuppliersAddress = updateJSON.supplieraddressdetails;
            this._SupplierProducts = updateJSON.supplierpageloadviews;
            //this.selectedSupplierProductsrows = updateJSON.selectedsupplierlist;
            // this.selectedsuppliersAddressrows = <Supplieraddressdetail[]>updateJSON.supplieraddressdetails || [];
            // var selectedSupplierProductsrows: Supplierpageloadview[] = [];
            // var l_suppliers: any = this._SupplierProducts;
            // let supplierseleted = <Supplierpageloadview[]>updateJSON.supplierproductdetails || [];
            // console.log("load1", updateJSON.supplierproductdetails);

            // Object.keys(supplierseleted).map((Index) => {
            //   var obj = l_suppliers.find(obj => obj.productid == supplierseleted[Index].productid);
            //   if (obj) {
            //     selectedSupplierProductsrows.push(obj)
            //     console.log("load", obj);
            //   }
            // });
            //this.selectedSupplierProductsrows = selectedSupplierProductsrows;
            //console.log("view", this.selectedSupplierProductsrows);

            //for producttype dropdown
            // this._prevSelectedProtype = updateJSON.selectedsupplierlist;
            // let uniqueProductTypeNames: string[] = [];
            // const uniqueSelectedProtypeArray = this._prevSelectedProtype.map((item) => {
            //   if (!uniqueProductTypeNames.includes(item.producttypename)) {
            //     uniqueProductTypeNames.push(item.producttypename);
            //     return item;
            //   }
            //   return null; // Return null for duplicates
            // }).filter(Boolean); // Filter out null values
            // this._selectedProTypeIds = uniqueSelectedProtypeArray.map((item) => item.producttypeid);

            this._selectedProTypeIds = (
              resultJSON.selectedProducttypes || []
            ).map((m) => m.producttypeid);
            this._tempselectedProCatIds = (
              resultJSON.selectedProductcategories || []
            ).map((m) => m.productcategoryid);
            this._selectedProCatIds = (
              resultJSON.selectedProductcategories || []
            ).map((m) => m.productcategoryid);

            // this._supplierproductdetailform.get("producttypeid").setValue(resultJSON.selectedProducttypes);
            // this._selectedProCatIds = resultJSON.selectedProductcategories;

            // //for productcategory dropdown
            // this._ProductTypeObj = {
            //   "Producttype": this._selectedProTypeIds || [],
            //   "supplier": this._supplierid
            // };
            // this._SupplierService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
            //   const resultJSON = JSON.parse(JSON.stringify(result));
            //   this._productcategory = resultJSON.productcategories;
            //   let uniqueProductCat: string[] = [];
            //   //this.selectedCategory = resultJSON.selectedsupplierlist.map((item) => item.productcategoryid);
            //   this.selectedCategory = this.selectedCategory.map((item) => {
            //     if (!uniqueProductCat.includes(item.categoryname)) {
            //       uniqueProductCat.push(item.categoryname);
            //       return item;
            //     }
            //     return null;
            //   }).filter(Boolean);
            this._SupplierProducts = resultJSON.supplierpageloadviews;
            this.selectedSupplierProductsrows = resultJSON.selectedsupplierlist;

            //   this._prevSelectedCattype = updateJSON.selectedsupplierlist;
            //   let uniqueProductCatNames: string[] = [];
            //   const uniqueSelectedCatArray = this._prevSelectedCattype.map((item) => {
            //     if (!uniqueProductCatNames.includes(item.categoryname)) {
            //       uniqueProductCatNames.push(item.categoryname);
            //       return item;
            //     }
            //     return null; // Return null for duplicates
            //   }).filter(Boolean); // Filter out null values
            //   this._selectedProCatIds = uniqueSelectedCatArray.map((item) => item.productcategoryid);

            // });
            this.getProductCategory();

            // Object.keys(updateJSON.selectedsupplierlist).map((Index) => {
            //   ProtypeIds.push(updateJSON.selectedsupplierlist[Index].producttypeid);
            //   selectedProtypeArray.push(updateJSON.selectedsupplierlist[Index]);
            // });
            // this._selectedProTypeIds = ProtypeIds;

            // var selectedProcatArray: any[] = [];
            // var ProCatIds: number[] = [];
            // Object.keys(updateJSON.selectedsupplierlist).map((Index) => {
            //   ProCatIds.push(updateJSON.selectedsupplierlist[Index].productcategoryid);
            //   selectedProcatArray.push(updateJSON.selectedsupplierlist[Index]);
            //   this._selectedProCatIds = ProCatIds;
            // });
          }
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );
  }

  OnChangeSupCat(event) {
    var selectedSupCat = event.value;

    if (selectedSupCat == 'MSC00131') {
      this.IsDisabledProductTab = false;
    } else {
      this.IsDisabledProductTab = true;
    }
  }

  checkseriesRights() {
    if (!this._Action._Series) {
      this.showseries = true;
    } else {
      this.showseries = false;
    }
  }

  onAddDocuments(event) {
    this._submitted2 = true;
    if (
      this._supplierdocumentdetailform.valid &&
      this._LastAddedGridDocument != null
    ) {
      var documentdetail = new Supplierdocumentdetail();
      documentdetail.supplierdocumentdetailid =
        this._supplierdocumentdetailform.get('supplierdocumentdetailid').value;
      documentdetail.supplierid =
        this._supplierdocumentdetailform.get('supplierid').value;
      documentdetail.documentid =
        this._supplierdocumentdetailform.get('documentid').value;
      documentdetail.documentname =
        this._supplierdocumentdetailform.get('documentname').value;
      documentdetail.documentdescription = this._supplierdocumentdetailform.get(
        'documentdescription'
      ).value;
      documentdetail.attachmenturl = URL.createObjectURL(
        this._LastAddedGridDocument
      );
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(
        URL.createObjectURL(this._LastAddedGridDocument)
      );
      documentdetail.attachmentname = this._LastAddedGridDocument.name;
      documentdetail.createdby = sessionStorage['userid'];
      documentdetail.modifiedby = sessionStorage['userid'];
      documentdetail.modifiedon = new Date();
      documentdetail.createdon = new Date();
      this._Supplierdocumentdetails.push(documentdetail);
      this._selectedDocuments.push(this._LastAddedGridDocument);
      this.SupplierClear();
    }
  }

  onDocumentSupplierClear() {
    // this._submitted2 = true;
    // this._employeedocumentdetailform.reset();
    // this.InitializeDocumentForm();
    this._LastAddedGridDocument = null;
    this.documentimage = null;
    // this._submitted2 = false;
  }
  onDocumentSupplierSelect(event, document) {
    this._LastAddedGridDocument = event.currentFiles[0];
    this.documentselect = this._LastAddedGridDocument.name;
    this._supplierdocumentdetailform
      .get('attachmentname')
      .setValue(this._LastAddedGridDocument.name);
    document.clear();
  }
  showsuppliergrid(document: FileUpload, position1: string) {
    let url = '';
    if (this._LastAddedGridDocument) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(
        URL.createObjectURL(this._LastAddedGridDocument)
      );
      this.documentselect = this._LastAddedGridDocument.name;
      url = URL.createObjectURL(this._LastAddedGridDocument);
    }
    if (this.documentimage == null) {
      return;
    }
    // this.position = position1;
    // this.displaydocumentgridpopup = true;
    if (url) window.open(url, '_blank');
  }
  onDocumentClear() {
    this._supplierdocumentdetailform
      .get('supplierimageurl')
      .setValue(undefined);
    this._supplierdocumentdetailform
      .get('supplierimagename')
      .setValue(undefined);
    this.documentimage = null;
    this._LastAddedDocument = null;
  }
  SupplierClear() {
    this._submitted2 = true;
    this._supplierdocumentdetailform.reset();
    this.InitializeDocumentForm();
    this._LastAddedGridDocument = null;
    this.documentimage = null;
    this._submitted2 = false;
  }
  getStates(event) {
    var selectedCountry = event.value;
    this._IsProgressSpinner = true;
    this._States = [];
    this._SupplierService.getStates(selectedCountry).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._States = resultJSON.states;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  OnChangevattype(event) {
    var VatNumber = this._supplierform.get('vattype').value;
    if (VatNumber == 'MSC00102') {
      this._supplierform.controls['vatno'].enable();
    } else {
      this._supplierform.controls['vatno'].disable();
      this._supplierform.controls['vatno'].reset();
    }
  }
  OnChangecredit(event) {
    var creditdetails = this._supplierform.get('credittype').value;
    if (creditdetails == 'MSC00011') {
      this._supplierform.controls['creditlimit'].enable();
      this._supplierform.controls['creditdays'].enable();
    } else {
      this._supplierform.controls['creditlimit'].disable();
      this._supplierform.controls['creditdays'].disable();

      this._supplierform.controls['creditlimit'].reset();
      this._supplierform.controls['creditdays'].reset();
    }
  }
  showBasicDialog() {
    this.displayBasic = true;
  }
  onRowSelectPopup(event: any) {
    var row = event.data;
    this._supplieraccountdetailform.controls['bankcode'].setValue(row.bankcode);
    this._supplieraccountdetailform.controls['bankname'].setValue(row.bankname);
    this.displayBasic = false;
  }
  onAddAddress(event) {
    this._submitted1 = true;
    if (this._supplieraddressdetailform.valid) {
      var addressdetail: any = {};
      // var addressdetail = new Supplieraddressdetail();
      addressdetail.supplieraddressdetailid = 0; //this._supplieraddressdetailform.get("supplieraddressdetailid").value;
      addressdetail.supplierid =
        this._supplieraddressdetailform.get('supplierid').value;
      addressdetail.contactpersonname =
        this._supplieraddressdetailform.get('contactpersonname').value;
      addressdetail.mobileno =
        this._supplieraddressdetailform.get('mobileno').value;
      addressdetail.phoneno =
        this._supplieraddressdetailform.get('phoneno').value;
      addressdetail.address =
        this._supplieraddressdetailform.get('address').value;
      addressdetail.mailid =
        this._supplieraddressdetailform.get('mailid').value;
      addressdetail.countryid =
        this._supplieraddressdetailform.get('countryid').value;
      addressdetail.stateid =
        this._supplieraddressdetailform.get('stateid').value;
      addressdetail.districtid =
        this._supplieraddressdetailform.get('districtid').value;
      addressdetail.faxno = this._supplieraddressdetailform.get('faxno').value;
      addressdetail.isdfaultaddress =
        this._supplieraddressdetailform.get('isdfaultaddress').value;
      addressdetail.supplier = null;
      if (this.addressRowIndex > -1) {
        for (var i = 0; i < this._SuppliersAddress.length; i++) {
          if (
            addressdetail.contactpersonname ==
              this._SuppliersAddress[i].contactpersonname &&
            addressdetail.contactpersonname ==
              this._SuppliersAddress[i].contactpersonname &&
            this.addressRowIndex != i
          ) {
            this._CustomExceptionService.handleWarning(
              'Contact Person Name Already Exists!'
            );
            return;
          }
        }
        this._SuppliersAddress[this.addressRowIndex] =
          this._supplieraddressdetailform.value;
        // this._SuppliersAddress[this.addressRowIndex].createdon = new Date();
      } else {
        if (this._SuppliersAddress.length > 0) {
          for (var i = 0; i < this._SuppliersAddress.length; i++) {
            if (
              addressdetail.contactpersonname ==
                this._SuppliersAddress[i].contactpersonname &&
              addressdetail.contactpersonname ==
                this._SuppliersAddress[i].contactpersonname
            ) {
              this._CustomExceptionService.handleWarning(
                'Contact Person Name Already Exists!'
              );
              return;
            }
          }
          this._SuppliersAddress.push(addressdetail);
        } else {
          this._SuppliersAddress.push(addressdetail);
        }
      }
      this.Clear(event);
    }
  }
  Clear(event) {
    this._supplieraddressdetailform.reset();
    this.InitializeAddressDetailForm();
    this._submitted1 = false;
    this.addressRowIndex = -1;
  }

  onRowSupplierAddressselect(Address: Supplieraddressdetail, rowIndex: number) {
    // address.customeraddressdetailid = 0;
    //address.createdby = 0;
    if (this._action == 'view') {
      return;
    }
    this._supplieraddressdetailform.setValue(Address);
    this.addressRowIndex = rowIndex;
  }
  RemoveAddress(pAddress: Supplieraddressdetail) {
    var index = this._SuppliersAddress.indexOf(pAddress);
    this._SuppliersAddress.splice(index, 1);
  }

  Removeselectedchecked(
    _SupplierAddress: Supplieraddressdetail,
    rowIndex: number
  ) {
    if (this._SuppliersAddress && this._SuppliersAddress.length) {
      for (let i = 0; i < this._SuppliersAddress.length; i++) {
        if (i === rowIndex) continue;

        this._SuppliersAddress[i].isdfaultaddress = false;
      }
    }
    this._SuppliersAddress[rowIndex].isdfaultaddress =
      _SupplierAddress.isdfaultaddress;
    //_SupplierAddress.isdfaultaddress = true;
  }
  fillFunctionRoleMap() {
    var selectedproducts = this.selectedSupplierProductsrows;
    var Supplierproductdetails: Supplierproductdetail[] = [];
    var supplierId: any = this._supplierform.get('supplierid').value;
    if (selectedproducts && selectedproducts.length) {
      Object.keys(selectedproducts).map(function (Index) {
        let productDetails = new Supplierproductdetail();
        productDetails.supplierid = supplierId;
        productDetails.supplierproductdetailid = 0;
        productDetails.productid = selectedproducts[Index].productid;
        productDetails.productcategoryid =
          selectedproducts[Index].productcategoryid;
        productDetails.producttypeid = selectedproducts[Index].producttypeid;
        productDetails.createdon = new Date();
        productDetails.modifiedon = new Date();
        productDetails.createdby = sessionStorage['userid'];
        productDetails.modifiedby = 0;
        Supplierproductdetails.push(productDetails);

        //other values needs to be filled even it is null;
      });
    }
    this._Supplierproductdetail = Supplierproductdetails;
  }

  getProductCategory() {
    this._supplierproductdetailform.controls['productcategoryid'].reset();

    //var selectedtype = this._supplierproductdetailform.get("producttypeid").value;
    var selectedtype = this._selectedProTypeIds;

    if (selectedtype.length > 0) {
      this._ProductTypeObj = {
        Producttype: selectedtype || [],
        supplier: this._supplierid,
      };
      this._SupplierService
        .getProductCategory(this._ProductTypeObj)
        .subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));

          if (this._action == 'edit') {
            this._productcategory = resultJSON.productcategories;
            //this._SupplierProducts = resultJSON.supplierpageloadviews;
            this._selectedProCatIds = this._tempselectedProCatIds;
            this.selectedCategory = this._tempselectedProCatIds;
            //this.getProduct();
            //this.selectedSupplierProductsrows = resultJSON.selectedsupplierlist;
          } else {
            this._productcategory = resultJSON.productcategories;
            //this._SupplierProducts = resultJSON.supplierpageloadviews;
            //this._selectedProCatIds.push(parseInt[1]);
            //this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
          }
          //uniqueSelectedCatArray.map((item) => item.productcategoryid);
        });
    }
  }
  getProductCategoryA() {
    this._supplierproductdetailform.controls['productcategoryid'].reset();
    this._selectedProCatIds = [];
    this._SupplierProducts = [];
    var selectedtype = this._selectedProTypeIds;

    if (selectedtype.length > 0) {
      this._ProductTypeObj = {
        Producttype: selectedtype || [],
        supplier: this._supplierid,
      };
      this._SupplierService
        .getProductCategory(this._ProductTypeObj)
        .subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));

          if (this._action == 'edit') {
            this._productcategory = resultJSON.productcategories;
          } else {
            this._productcategory = resultJSON.productcategories;
          }
        });
    }
  }
  //Product Category Multiselect:
  getProduct() {
    this._supplierproductdetailform.controls['productid'].reset();
    this._brandlist = [];
    var selectedtype = this._selectedProTypeIds;
    var selectedCategory = this._selectedProCatIds;
    this.selectedCategory = selectedCategory;

    if (selectedCategory.length > 0) {
      this._ProductCategoryObj = {
        Producttype: selectedtype || [],
        Productcategory: selectedCategory || [],
        supplierid: this._supplierid || 0,
      };

      this._SupplierService
        .getProduct(this._ProductCategoryObj)
        .subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (this._action == 'edit') {
            this._productname = resultJSON.productheaders;
            this._SupplierProducts = resultJSON.supplierpageloadviews;
            this.selectedSupplierProductsrows = resultJSON.selectedsupplierlist;
          } else {
            this._productname = resultJSON.productheaders;
            this._SupplierProducts = resultJSON.supplierpageloadviews;
          }
          //  this._BranchProducts = resultJSON.supplierpageloadviews;
          // var selectedRows: Supplierpageloadview[] = [];
          // var l_suppliers: any = this._SupplierProducts;
          // var selectedProductObj = resultJSON.supplierproductdetails;
          // this.UpdateProducts = resultJSON.supplierproductdetails;
          // console.log("log1", selectedProductObj);
          // if (selectedProductObj) {
          //   Object.keys(l_suppliers).map((Index) => {
          //     var obj = selectedProductObj.find(obj => obj.productid == l_suppliers[Index].productid);
          //     if (obj) {
          //       l_suppliers[Index].productid = obj.productid;
          //       selectedRows.push(obj);
          //       console.log("log2", obj);
          //       console.log("log3", selectedRows);
          //     }
          //   });
          //   this.selectedSupplierProductsrows = selectedRows || [];
          // }
        });
    }
  }

  getProductdetails(event) {
    var selectedproduct = event.value;
    var selectedtype =
      this._supplierproductdetailform.get('producttypeid').value;
    var selectedCategory =
      this._supplierproductdetailform.get('productcategoryid').value;

    this.brandid = null;
    if (selectedproduct.length > 0) {
      this._ProductObj = {
        Producttype: selectedtype || [],
        Productcategory: selectedCategory || [],
        Product: selectedproduct || [],
        supplierid: this._supplierid || 0,
      };
      this._SupplierService
        .getProductdetails(this._ProductObj)
        .subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._brandlist = resultJSON.productbrandlist;
          let productbrand = resultJSON.productbrandlist;
          let uniqueChars = [...new Set(productbrand)];
          uniqueChars = productbrand.filter(
            (test, index, array) =>
              index ===
              array.findIndex((findTest) => findTest.brandid === test.brandid)
          );
          this._brandlist = uniqueChars;

          // // this._SupplierProducts = resultJSON.productheaders;
          // this._SupplierProducts = resultJSON.supplierpageloadviews;
          // this._TempSupplierProducts = resultJSON.supplierpageloadviews;
          if (this._action == 'edit') {
            this._SupplierProducts = resultJSON.supplierpageloadviews;
            this.selectedSupplierProductsrows = resultJSON.selectedsupplierlist;
          } else {
            this._SupplierProducts = resultJSON.supplierpageloadviews;
          }
        });
    } else {
      //this._BranchProducts = null;
    }
  }
  // getProductbrand(event) {
  //   var selectedbrand = event.value || [];
  //   this._SupplierProductsList = this._TempSupplierProducts.filter(f => (selectedbrand.includes(f.brandid)))
  //   if (this._SupplierProductsList.length) {
  //     this._SupplierProducts = this._SupplierProductsList
  //   } else {
  //     this._SupplierProducts = this._TempSupplierProducts
  //   }

  // }
  getProductbrand(event) {
    var supplier = this._supplierid;
    var selectedbrand = event.value || [];
    var selectedproduct =
      this._supplierproductdetailform.get('productid').value;
    var selectedtype =
      this._supplierproductdetailform.get('producttypeid').value;
    var selectedCategory =
      this._supplierproductdetailform.get('productcategoryid').value;

    this._ProductObj = {
      Producttype: selectedtype || [],
      Productcategory: selectedCategory || [],
      Product: selectedproduct || [],
      supplierid: supplier || 0,
      brand: selectedbrand || [],
    };
    this._SupplierService
      .getProductbrand(this._ProductObj)
      .subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        if (this._action == 'edit') {
          this._SupplierProducts = resultJSON.supplierpageloadviews;
          this.selectedSupplierProductsrows = resultJSON.selectedsupplierlist;
        } else {
          this._SupplierProducts = resultJSON.supplierpageloadviews;
        }
      });
  }
  onSave() {
    if (this.supplierholdpopupclose == true) {
      this._submitted = false;
      this._submitted2 = false;
      this.supplierholdpopupclose = false;
      return;
    }
    this._submitted = true;
    this._submitted2 = true;

    if (this._supplierform.valid) {
      //if (this._supplieraccountdetailform.valid && this._Supplier.savetype != "MSC00187") {

      this.fillFunctionRoleMap();
      this._supplieraccountdetailform
        .get('supplierid')
        .setValue(this._supplierform.get('supplierid').value);
      //this._supplierproductdetailform.get("supplierid").setValue(this._supplierform.get("supplierid").value);
      this._supplieraddressdetailform
        .get('supplierid')
        .setValue(this._supplierform.get('supplierid').value);
      this._supplierdocumentdetailform
        .get('supplierid')
        .setValue(this._supplierform.get('supplierid').value);
      var supplierstatus = this._supplierform.get('supplierstatus').value;
      var supplierholdreason = this._supplierform.get(
        'reasonforsupplierhold'
      ).value;
      //For Model and Form Mapping
      this._Supplier = this._supplierform.value;
      this._Supplieraccountdetail = this._supplieraccountdetailform.value;
      this._Supplier.savetype = 'MSC00187';
      this._Supplieraccountdetail.createdon = new Date();
      this._Supplieraccountdetail.createdby = sessionStorage['userid'];

      if (this._Supplier.vattype == 'MSC00102' && !this._Supplier.vatno) {
        this._CustomExceptionService.handleError(usererrors.VATNumber);
        this.selectedtab = 0;
        return;
      }
      if (
        this._Supplier.credittype == 'MSC00011' &&
        (!this._Supplier.creditlimit || !this._Supplier.creditdays)
      ) {
        this._CustomExceptionService.handleError(usererrors.CreditLimitAndDays);
        this.selectedtab = 0;
        return;
      }

      if (!this._SuppliersAddress || this._SuppliersAddress.length == 0) {
        this._CustomExceptionService.handleError(
          usererrors.SupplierAddressDetails_Tab
        );
        this.selectedtab = 2;
        return;
      }

      if (
        this._SuppliersAddress &&
        this._SuppliersAddress.length &&
        !this._SuppliersAddress.some((s) => s.isdfaultaddress == true)
      ) {
        this._CustomExceptionService.handleError(
          usererrors.SupplierAddressDetails_01
        );
        this.selectedtab = 2;
        return;
      }

      if (
        supplierstatus == 'MSC00344' &&
        (!supplierholdreason || supplierholdreason == null)
      ) {
        this._CustomExceptionService.handleWarning(
          'Please Enter Reason for Holding the Supplier'
        );
        return;
      }

      this._Supplier.tempcreditlimit = this._Supplier.creditlimit;
      this._Supplier.tempcreditdays = this._Supplier.creditdays;
      // if (this.ShowErrorMsg(errMsg))
      //   return;
      if (
        this._Supplieraccountdetail.bankcode == null &&
        this._Supplieraccountdetail.swiftcode == null &&
        this._Supplieraccountdetail.accountno == null
      ) {
        this._Supplieraccountdetail = null;
      }

      //For Object Call
      // this._SupplierOBJ = { "Supplier": this._Supplier, "Supplieraccountdetail": this._Supplieraccountdetail, "Supplieraddressdetail": this._SuppliersAddress, "Supplierproductdetail": this._Supplierproductdetail,"Supplierdocumentdetail":this._Supplierdocumentdetails,"documentdetails": this._selectedDocuments };
      // this.onEd();
      //For Create
      this._IsProgressSpinner = true;
      if (this._action == 'create') {
        this._Supplier.createdby = sessionStorage['userid'];
        this._Supplier.createdon = new Date();

        this._SupplierOBJ = this.formconverstion(
          this._Supplier,
          this._Supplieraccountdetail,
          this._SuppliersAddress,
          this._Supplierproductdetail,
          this._Supplierdocumentdetails,
          this._selectedDocuments,
          this.selectedCategory
        );
        this._IsProgressSpinner = true;
        this._SupplierService.create(this._SupplierOBJ).subscribe(
          (result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            this._IsProgressSpinner = false;
            if (
              resutJSON.tranStatus.result == true &&
              (resutJSON != null || resutJSON != undefined)
            ) {
              if (resutJSON.tranStatus.lstErrorItem) {
                this._CustomExceptionService.handleSuccess(
                  resutJSON.tranStatus.lstErrorItem[0].message
                );
                this._submitted = false;
              } else {
                this._CustomExceptionService.handleSuccess(
                  usererrors.Update_Success_04
                );
                this._submitted = false;
              }
              this.reset(null);
            } else {
              this.threesuppliermappedproductlist = true;
              this._threesuppliermappedproductlist =
                resutJSON.threesuppliermappedproductlist;

              this._CustomExceptionService.handleError(
                resutJSON.tranStatus.lstErrorItem[0].message
              );
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
      }
      //For Edit
      else if (this._action == 'edit') {
        if (this._Supplieraccountdetail != null) {
          this._Supplieraccountdetail.modifiedon = new Date();
          this._Supplieraccountdetail.modifiedby = sessionStorage['userid'];
        }
        this._Supplier.modifiedon = new Date();
        this._Supplier.modifiedby = sessionStorage['userid'];
        // if (!this._Supplierproductdetail || !this._Supplierproductdetail.length) {
        //   this._Supplierproductdetail = this.UpdateProducts
        //   let suppliercategory = this._supplierform.get("suppliercategorycode").value
        //   if (suppliercategory == "MSC00131") {
        //     if (!this._Supplierproductdetail || !this._Supplierproductdetail.length) {
        //       this._CustomExceptionService.handleWarning("Please Select Atleast On Product In Supplier Product Details")
        //       this._IsProgressSpinner = false;
        //       return
        //     }
        //   }
        // }
        // this.onEd();
        this._SupplierOBJ = this.formconverstion(
          this._Supplier,
          this._Supplieraccountdetail,
          this._SuppliersAddress,
          this._Supplierproductdetail,
          this._Supplierdocumentdetails,
          this._selectedDocuments,
          this.selectedCategory
        );
        this._SupplierService.edit(this._SupplierOBJ).subscribe(
          (result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));

            this._IsProgressSpinner = false;
            if (
              resutJSON.tranStatus.result == true &&
              (resutJSON != null || resutJSON != undefined)
            ) {
              if (resutJSON.tranStatus.lstErrorItem) {
                this._CustomExceptionService.handleSuccess(
                  resutJSON.tranStatus.lstErrorItem[0].message
                );
                this._submitted = false;
              } else {
                this._CustomExceptionService.handleSuccess(
                  usererrors.Update_Success_04
                );
                this._submitted = false;
              }
            } else {
              this._CustomExceptionService.handleError(
                resutJSON.tranStatus.lstErrorItem[0].message
              );

              this._suppliernotmappedproductlist =
                resutJSON.suppliernotmappedproductlist || [];
              this._threesuppliermappedproductlist =
                resutJSON.threesuppliermappedproductlist || [];

              if (
                this._suppliernotmappedproductlist.length != 0 &&
                this._threesuppliermappedproductlist.length != 0
              ) {
                this.suppliernotmappedproduct_and_threesuppliermappedlist =
                  true;
                this._suppliernotmappedproductlist =
                  resutJSON.suppliernotmappedproductlist;
                this._threesuppliermappedproductlist =
                  resutJSON.threesuppliermappedproductlist;
              } else if (
                this._suppliernotmappedproductlist.length != 0 &&
                this._threesuppliermappedproductlist.length == 0
              ) {
                this.suppliernotmappedproductlist = true;
                this._suppliernotmappedproductlist =
                  resutJSON.suppliernotmappedproductlist;
              } else if (
                this._suppliernotmappedproductlist.length == 0 &&
                this._threesuppliermappedproductlist.length != 0
              ) {
                this.threesuppliermappedproductlist = true;
                this._threesuppliermappedproductlist =
                  resutJSON.threesuppliermappedproductlist;
              }
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
      }
      // } else {
      //   this._CustomExceptionService.handleError(usererrors.SupplierAccountDetails_Tab);
      //   this.selectedtab = 1;
      //   return;
      // }
    } else {
      this._CustomExceptionService.handleError(usererrors.SupplierDetails_Tab);
      this.selectedtab = 0;
      return;
    }
    // else {
    //   this._submitted = false;
    // }
  }
  formconverstion(
    p_Supplier: Supplier,
    p_Supplieraccountdetail: Supplieraccountdetail,
    p_Supplieraddressdetail: Supplieraddressdetail[],
    s_Supplierproductdetails: Supplierproductdetail[],
    p_Supplierdocumentdetails: Supplierdocumentdetail[],
    documents: File[],
    selectedCategory: any
  ) {
    let time = new Date();
    const formData: FormData = new FormData();
    for (var x in documents) {
      var index = p_Supplierdocumentdetails.findIndex(
        (obj) => obj.attachmentname == documents[x].name
      );
      if (index != -1) {
        var NewFileName = time.getTime() + '_' + documents[x].name;
        p_Supplierdocumentdetails[index].attachmentname = NewFileName;
        p_Supplierdocumentdetails[index].attachmenturl = NewFileName;
        if (documents[x] instanceof Blob)
          formData.append('files', documents[x], NewFileName);
        else formData.append('files', NewFileName);
      }
    }

    var SupplierOBJ = {
      Supplier: p_Supplier,
      Supplieraccountdetail: p_Supplieraccountdetail,
      Supplieraddressdetail: p_Supplieraddressdetail,
      Supplierproductdetail: s_Supplierproductdetails,
      Supplierdocumentdetail: p_Supplierdocumentdetails,
      Productcategory: selectedCategory || [],
    };
    var Supplier = JSON.stringify(SupplierOBJ);
    formData.append('strsupplier', Supplier);
    return formData;
  }
  reset(event) {
    this._submitted = true;
    this._submitted2 = true;
    this._supplierform.reset();
    this._supplieraccountdetailform.reset();
    // this._supplierproductdetailform.reset();
    this._supplieraddressdetailform.reset();
    this.InitializeForm();
    this._submitted1 = false;
    this._submitted = false;
    this._submitted2 = false;

    this._SuppliersAddress = [];
    this.selectedSupplierProductsrows = [];

    setTimeout(() => {
      this.OnChangevattype(null);
      this.OnChangecredit(null);
    }, 1000);
  }
  // ShowErrorMsg(errMsg) {
  //   if (!errMsg)
  //     return false;

  //   this.messageService.add({ severity: 'error', summary: 'Error Message', sticky: true, detail: errMsg });
  //   return true;
  // }
  onRowSelectProducts(event) {
    // console.log(event.data)
    // let Data = event.data
    // this.UpdateProducts.push(Data)
  }
  onRowUnselectProducts(event) {
    console.log(event.data);
    let Data = event.data;
    this.UpdateProducts = this.UpdateProducts.filter(
      (f) => f.productid != Data.productid
    );
  }

  selectRow(row: any) {
    console.log(row.data);
    if (this.selectedSupplierProductsrows.length == 0) {
      for (let r = 0; r < row.length; r++) {
        this.UpdateProducts = this.UpdateProducts.filter(
          (f) => f.productid != row[r].productid
        );
      }
      console.log(this.UpdateProducts);
    }
  }
  onRowCustomerdocselect(address: Supplierdocumentdetail, rowIndex: number) {
    this._supplierdocumentdetailform.setValue(address);
    this.addressRowIndex = rowIndex;
  }
  onRowSelect(event) {}
  onRowUnselect(event) {}

  RemoveDocumentDetail(p_documentDetail: Supplierdocumentdetail) {
    var index = this._Supplierdocumentdetails.indexOf(p_documentDetail);
    this._Supplierdocumentdetails.splice(index, 1);
  }

  showdocumentGridDialog(position: string, _Supplierdocumentdetail: any) {
    if (_Supplierdocumentdetail.attachmenturl) {
      this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(
        _Supplierdocumentdetail.attachmenturl
      );
      this._tempattachmentURL = _Supplierdocumentdetail.attachmenturl;
    }
    // this.position = position;
    // this.displaydocumentgridpopup = true;
    if (this._tempattachmentURL) window.open(this._tempattachmentURL, '_blank');
  }

  showdocumentasNewWindow() {
    if (this._tempattachmentURL) {
      window.open(this._tempattachmentURL, 'MsgWindow', 'width=800,height=800');
    }
  }

  closePopupGridDialog(position: string) {
    this.position1 = position;
    this.displaydocumentgridpopup = false;
  }

  goBack(event) {
    this._router.navigate(['/vSupplier']);
  }
  onEd() {
    ace.config.set('fontSize', '14px');
    ace.config.set(
      'basePath',
      'https://unpkg.com/ace-builds@1.4.12/src-noconflict'
    );
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode('ace/mode/json');
    var myObj = this._SupplierOBJ;

    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  // Create
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+s',
          (event: KeyboardEvent): boolean => {
            this.onSave();
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    if (this._action == 'create') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+alt+c',
          (event: KeyboardEvent): boolean => {
            this.reset(null);
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    this._hotkeysService.add(
      new Hotkey(
        'ctrl+left',
        (event: KeyboardEvent): boolean => {
          this.goBack(null);
          return false; // Prevent bubbling
        },
        ['INPUT', 'SELECT', 'TEXTAREA']
      )
    );
  }

  //Customer Name Validation:
  ChangeSwift() {
    if (
      !this._supplierform
        .get('suppliername')
        .value.toString()
        .match(new RegExp('^[a-zA-Z0-9 ]*$')) &&
      this._action != 'edit'
    ) {
      this._CustomExceptionService.handleWarning(
        'special characters not allowed on suppliername'
      );
      this._supplierform.controls['suppliername'].reset();
      return;
    }
  }

  //Rights
  OnChangeRights() {
    this._IsProgressSpinner = true;
    var rights = this._supplierform.get('series').value;
    this._UserList = [];

    if (rights == 'MSC00093') {
      //Standard Rights
      this._UserList = (this._UserTempList || []).filter(
        (f) => f.series == 'MSC00093'
      );
    } else {
      this._UserList = this._UserTempList;
    }
    this._IsProgressSpinner = false;
  }
  exportExcelonesuppliermappedlist() {
    let productlist = this.GetProductList();
    this.exportUtility.exportExcel(
      productlist,
      'onesuppliermappedlist',
      'xlsx'
    );
  }
  GetProductList() {
    let productlist = [];
    this._suppliernotmappedproductlist.forEach((element) => {
      let product: any = {};

      product['Product Type'] = element.producttypename;
      product['Product Category'] = element.categoryname;
      product['Product Code'] = element.productcode;
      product['Product Name'] = element.productname;

      productlist.push(product);
    });
    return productlist;
  }

  exportExcelthreesuppliermappedlist() {
    let productlist = this.GetProductList1();
    this.exportUtility.exportExcel(
      productlist,
      'threesuppliermappedlist',
      'xlsx'
    );
  }
  GetProductList1() {
    let productlist = [];
    this._threesuppliermappedproductlist.forEach((element) => {
      let product: any = {};

      product['Product Type'] = element.producttypename;
      product['Product Category'] = element.categoryname;
      product['Product Code'] = element.productcode;
      product['Product Name'] = element.productname;

      productlist.push(product);
    });
    return productlist;
  }
  ExportToPdfonesuppliermappedlist() {
    if (
      !this._suppliernotmappedproductlist ||
      !this._suppliernotmappedproductlist.length
    )
      return;
    let productdetails = this.GetProductList();
    const data = productdetails.map(Object.values);
    const head = [
      ['Product Type', 'Product Category', 'Product Code', 'Product Name'],
    ];
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0,
          },
          bodyStyles: {
            textColor: 0,
          },
          didDrawCell: (data) => {},
        });
        doc.save('onesuppliermappedlist.pdf');
      });
    });
  }
  ExportToPdfthreesuppliermappedlist() {
    if (
      !this._threesuppliermappedproductlist ||
      !this._threesuppliermappedproductlist.length
    )
      return;
    let productdetails = this.GetProductList1();
    const data = productdetails.map(Object.values);
    const head = [
      ['Product Type', 'Product Category', 'Product Code', 'Product Name'],
    ];
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0,
          },
          bodyStyles: {
            textColor: 0,
          },
          didDrawCell: (data) => {},
        });
        doc.save('threesuppliermappedlist.pdf');
      });
    });
  }

  Supplierholdreason(event) {
    var supplierstatus = event.value;
    // var supplierholdreason = this._supplierform.get("reasonforsupplierhold").value;
    if (supplierstatus == 'MSC00344') {
      this.supplierholdreason = true;
      this.supplierholdpopupsubmit = true;
    } else {
      this.supplierholdreason = false;
    }
  }

  Supplierholdreasonpopupclose() {
    var remark = this._supplierform.get('reasonforsupplierhold').value;
    if (!remark || remark == 'null' || remark == undefined) {
      this._CustomExceptionService.handleWarning(
        'Please Enter the Reason for Supplier Hold'
      );
      this.supplierholdpopupclose = true;
      return;
    }
    this.supplierholdreason = false;
    this.supplierholdpopupclose = true;
    return;
  }
}
