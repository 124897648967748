import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAction } from '../security/IActions';
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root'
})
export class SalesinvoicetaxService {
	public _Action: IAction;
	public _SalesInvoiceTaxService = environment.SalesInvoiceTaxService;
	public _RptSalesInvoicePrintService = environment.RptSalesInvoicePrintService;
	public _CustomerService = environment.CustomerService;
	public _httpOptions;
	token: any;
	header: any;

	constructor(private http: HttpClient) {
		this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token)
	}
	createHeaders(token: any): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`, // Include your token here	
		});
	}
	PageOnLoad(salesinvoiceno: string, userid: number, branchid: number): Observable<any> {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/' + salesinvoiceno + '/' + userid + '/' + branchid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetReferenceNo(referencetype: string, userid: number, customerid: number) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetReferenceNo' + '/' + referencetype + '/' + userid + '/' + customerid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetReferenceDetails(referencetype: string, referenceno: string, userid: number, currency: number) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetReferenceDetails' + '/' + referencetype + '/' + referenceno + '/' + userid + '/' + currency;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetProductCategory(producttype: object) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetProductCategory';
		return this.http.post<{ data: any }>(Query, producttype, { headers });
	}
	GetProductName(productcategory: object) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetProductName';
		return this.http.post<{ data: any }>(Query, productcategory, { headers });
	}
	GetProductPrice(productid: number, variantid: number, quantity: number, branchid: number, customerid: number, userid: number, markuplevelid: number, isdamage: boolean, severity: string, currentbranchid: number) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetProductPrice' + '/' + productid + '/' + variantid + '/' + quantity + '/' + branchid + '/' + customerid + '/' + userid + '/' + markuplevelid + '/' + isdamage + '/' + severity + '/' + currentbranchid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetProduct(data) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetProduct';
		return this.http.post<{ data: any }>(Query, data, { headers });
	}
	GetVariant(product: number, branchid: number, isDamaged, severity) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetVariant' + '/' + product + '/' + branchid + '/' + isDamaged + '/' + severity;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetCustomerDetails(customerid: number) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetCustomerDetails' + '/' + customerid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetCreditNoteDetails(customerid: number) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetCreditNoteDetails' + '/' + customerid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	CheckCollectingBranchStock(productid: number, branchid: number, variantid: number) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/CheckCollectingBranchStock' + '/' + productid + '/' + branchid + '/' + variantid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	customercreate(customer: object) {
		const headers = this.header;
		var Query = this._CustomerService + '/CustomerQuickSave';
		return this.http.post<{ data: any }>(Query, customer, { headers });
	}
	create(salesinvoicetax: object) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService;
		return this.http.post<{ data: any }>(Query, salesinvoicetax, { headers });
	}
	FetchAll(branchid: number, userid: number, fromdate: string, todate: string, roleid: number,invoicetype: string) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/FetchAll' + '/' + branchid + '/' + userid + '/' + fromdate + '/' + todate + '/' + roleid + '/' + invoicetype ;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	SendToApproval(workflowid: number, userid: number, branchid: number, salesinvoiceid: number) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/SendToApproval' + '/' + workflowid + '/' + userid + '/' + branchid + '/' + salesinvoiceid;
		return this.http.post<{ data: any }>(Query, '', { headers });
	}
	Cancel(salesinvoiceid: number, branchid: number, userid: number) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/Cancel/' + salesinvoiceid + '/' + branchid + '/' + userid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetDamagedProductName(isdamagedproduct: boolean, severity: string, branchid: number, userid: number, customerid: number) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetDamagedProductName' + '/' + isdamagedproduct + '/' + severity + '/' + branchid + '/' + userid + '/' + customerid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	Print(obj: any) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/SITAXPRINT';
		return this.http.post(Query, obj, { responseType: 'blob', headers });
	}
	CopyInvoice(obj: object) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/CopyInvoice';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}
	GetConversion(obj: object) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetConversion';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}
	GetCustomer(customer: string, user: number) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetCustomer' + '/' + customer + '/' + user;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetBarCodeBasedProductDetails(obj: object) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetBarCodeBasedProductDetails';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}
	GetBarCodeWeighingBasedProductDetails(obj: object) {
		const headers = this.header;
		var Query = this._SalesInvoiceTaxService + '/GetWeighingProductDetails';
		return this.http.post<{ data: any }>(Query, obj, { headers });
	}
}