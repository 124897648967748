import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Coupon } from 'src/app/core/Models/Coupon';
import { Couponexcludecategorydetail } from 'src/app/core/Models/Couponexcludecategory';
import { Couponexcludeproductdetail } from 'src/app/core/Models/Couponexcludeproduct';
import { Couponincludecategorydetail } from 'src/app/core/Models/Couponincludecategory';
import { Couponincludeproductdetail } from 'src/app/core/Models/Couponincludeproduct';
import { CouponService } from 'src/app/core/Services/ecommerce/coupon.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import * as ace from 'ace-builds';
import { usererrors } from 'src/app/core/errors/usererrors';
import {
  vApplyTo,
  vCouponAmount,
  vCouponName,
  vDiscountType,
  vEffectiveFrom,
  vEffectiveTo,
  vMaximumSpend,
  vMinimumSpend,
  vUsageLimitPerCoupan,
  vUsageLimitPerUsers,
} from 'src/app/core/Validators/validation';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { KeyPress } from 'src/assets/js/KeyPress';
@Component({
  selector: 'app-c-coupon',
  templateUrl: './c-coupon.component.html',
  styleUrls: ['./c-coupon.component.css'],
})
export class CCouponComponent implements OnInit {
  _couponform: FormGroup;
  _usageRestrictionform: FormGroup;
  _CouponProductform: FormGroup;
  _coupon: Coupon;
  _discounttype: any;
  selectedtab: number = 0;
  isDisabledSave: boolean = false;
  isDisabledClear: boolean = false;
  _IsProgressSpinner: boolean = false;
  _includeproduccategories: Productcategory[] = [];
  _tempincludeproduccategories: Productcategory[] = [];
  _excludeproduccategories: any;
  _includeproducts: any[] = [];
  _excludeproducts: any;
  _status: Metadatum;
  _Couponid: number;
  _action: string = 'create';
  _ProductCategoryObj: any;
  _SelectedIncludeCategory: Couponincludecategorydetail[] = [];
  _prevSelectedIncludeCategory: Couponincludecategorydetail[] = [];
  _SelectedExcludeCategory: Couponexcludecategorydetail[] = [];
  _prevSelectedExcludeCategory: Couponexcludecategorydetail[] = [];
  _SelectedIncludeProducts: Couponincludeproductdetail[] = [];
  _prevSelectedIncludeProducts: Couponincludeproductdetail[] = [];
  _SelectedExcludeProducts: Couponexcludeproductdetail[] = [];
  _prevSelectedExcludeProducts: Couponexcludeproductdetail[] = [];
  _couponOBJ: any;
  _selectedExcludeCategoryIDs: number[] = [];
  _selectedExcludeProductIDs: number[] = [];
  _selectedIncludeCategoryIDs: number[] = [];
  _selectedIncludeProductsIDs: number[] = [];
  _IsCouponPercentage: boolean = true;
  _IsCouponAmount: boolean = false;
  _submitted: boolean = false;
  HighestProductDiscount: any = [];
  maxdiscount: number = 0;
  maxDate: Date;
  OverallProducts: any[] = [];
  _PaymentMode: any[] = [];
  _ApplytoList: any[] = [];
  _Isnewcustomer: boolean = true;
  _Itemlimit: boolean = false;
  _selectedproductcount: number;
  _hiddenmaximumamtlimit: boolean = false;
  @ViewChild('editor', { static: true })
  private editor: ElementRef<HTMLElement>;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _router: Router,
    private _AccessRightsService: AccessRightsService,
    private _CounponService: CouponService,
    private _CustomExceptionService: CustomExceptionService,
    public keypress: KeyPress
  ) {
    this._Couponid = history.state?.couponid ? history.state?.couponid : 0;
    this._action =
      history.state.action == null ? 'create' : history.state.action;
  }
  InitializeForm() {
    this.utility.pageLoadScript();
    this._couponform = this.fb.group({
      couponid: [0],
      couponcode: [],
      couponname: ['', vCouponName],
      discounttype: ['ECO00051', vDiscountType],
      fromdate: ['', vEffectiveFrom],
      todate: ['', vEffectiveTo],
      couponamount: ['', vCouponAmount],
      allowfreeshipping: [],
      minamount: ['', vMinimumSpend],
      maxamount: ['', vMaximumSpend],
      usagelimitpercoupon: ['', vUsageLimitPerCoupan],
      usagelimitperitems: [],
      usagelimitperuser: ['', vUsageLimitPerUsers],
      couponstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      couponexcludecategorydetails: [],
      couponexcludeproductdetails: [],
      couponincludecategorydetails: [],
      couponincludeproductdetails: [],
      percentagemaxlimit: [],
      currentusagelimitpercoupon: [],
      currentusagelimitperitems: [],
      paymentmode: ['ECO00083', Validators.required],
      applyto: ['', vApplyTo],
    });
    this._couponform.controls['couponstatus'].setValue('MSC00001');
  }
  ngOnInit(): void {
    this.maxDate = new Date();
    this.InitializeForm();
    this.utility.pageLoadScript();
    this._IsProgressSpinner = true;

    this._CounponService.PageOnload(this._Couponid).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._IsProgressSpinner = false;
        this._status = resultJSON.status;
        this._PaymentMode = resultJSON.paymentModeList;
        this._tempincludeproduccategories = resultJSON.productcategoryList;
        this._includeproduccategories = resultJSON.productcategoryList;
        this._discounttype = resultJSON.discountType;
        this.OverallProducts = resultJSON.producthomepageviews;
        this._ApplytoList = resultJSON.applytoList;
        this._couponform.get("discounttype").disable();
        if (this._action == 'view') {
          this.isDisabledSave = true;
          this.isDisabledClear = true;
          this._couponform.disable();
        }
        if (this._action == 'edit') {
          this.isDisabledSave = false;
          this.isDisabledClear = true;
        }
        if (this._action == 'view' || this._action == 'edit') {
          const updateJSON = JSON.parse(JSON.stringify(result));
          this._includeproduccategories = updateJSON.productcategoryList;
          this._excludeproduccategories = resultJSON.productcategoryList;
          this._IsProgressSpinner = false;
          updateJSON.coupon.fromdate = new Date(updateJSON.coupon.fromdate);
          updateJSON.coupon.todate = new Date(updateJSON.coupon.todate);
          this._couponform.setValue(updateJSON.coupon);

          var applyto = this._couponform.get('applyto').value;
          //this._coupon = this._couponform.value;
          this._couponform.get('couponname').disable();
          this._couponform.get('fromdate').disable();
          this._couponform.get('allowfreeshipping').disable();
          this._couponform.get('applyto').disable();
          this._couponform.get('couponamount').disable();
          this._couponform.get('discounttype').disable();
          this._couponform.get('usagelimitpercoupon').disable();

          if (applyto == 'MSC00358') {
            this._couponform.get('usagelimitperitems').setValue(100);
            this._Isnewcustomer = false;
          }

          var discounttype = this._couponform.get('discounttype').value;

          if (discounttype == 'ECO00050') {
            this._IsCouponPercentage = false;
            this._IsCouponAmount = true;
          } else {
            this._IsCouponPercentage = true;
            this._IsCouponAmount = false;
          } // Exclude Category
          var ExcludeCategoryIDs: number[] = [];
          Object.keys(updateJSON.couponexcludecategorydetails).map((Index) => {
            ExcludeCategoryIDs.push(
              updateJSON.couponexcludecategorydetails[Index]
                .excludeproductategoryid
            );
          });
          this._selectedExcludeCategoryIDs = ExcludeCategoryIDs;
          this._prevSelectedExcludeCategory =
            updateJSON.couponexcludecategorydetails;
          this._SelectedExcludeCategory =
            updateJSON.couponexcludecategorydetails;
          var ExcludeProductIDs: number[] = []; // Exclude Product
          Object.keys(updateJSON.couponexcludeproductdetails).map((Index) => {
            ExcludeProductIDs.push(
              updateJSON.couponexcludeproductdetails[Index].excludeproductid
            );
          });
          this._selectedExcludeProductIDs = ExcludeProductIDs;
          this._prevSelectedExcludeProducts =
            updateJSON.couponexcludeproductdetails;
          this._SelectedExcludeProducts =
            updateJSON.couponexcludeproductdetails;
          for (
            var i = 0;
            i < updateJSON.couponincludecategorydetails.length;
            i++
          ) {
            updateJSON.couponincludecategorydetails[i].coupon = '';
          }
          for (
            var i = 0;
            i < updateJSON.couponincludeproductdetails.length;
            i++
          ) {
            updateJSON.couponincludeproductdetails[i].coupon = '';
          } // Include Category
          var IncludeCategoryIDs: number[] = [];
          Object.keys(updateJSON.couponincludecategorydetails).map((Index) => {
            IncludeCategoryIDs.push(
              updateJSON.couponincludecategorydetails[Index]
                .includeproductategoryid
            );
          });
          this._selectedIncludeCategoryIDs = IncludeCategoryIDs;
          this._prevSelectedIncludeCategory =
            updateJSON.couponincludecategorydetails;
          this._SelectedIncludeCategory =
            updateJSON.couponincludecategorydetails;
          var IncludeProductIDs: number[] = []; // Included Products
          Object.keys(updateJSON.couponincludeproductdetails).map((Index) => {
            IncludeProductIDs.push(
              updateJSON.couponincludeproductdetails[Index].includeproductid
            );
          });
          this._selectedIncludeProductsIDs = IncludeProductIDs;
          this._prevSelectedIncludeProducts =
            updateJSON.couponincludeproductdetails;
          this._SelectedIncludeProducts =
            updateJSON.couponincludeproductdetails;
          this.getincludeProducts(this._selectedIncludeCategoryIDs);
          //this.Editcoupon();
        }
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  getProduct(event) {}
  getincludeProducts(event) {
    var selectedProductCategory = event.value || event;
    if (
      selectedProductCategory.length == 0 ||
      selectedProductCategory == null
    ) {
      this._selectedExcludeCategoryIDs = [] = [];
      this._selectedIncludeProductsIDs = [] = [];
      this._selectedExcludeProductIDs = [] = [];
      this._excludeproducts = [] = [];
      this._includeproducts = [] = [];
      return;
    } else {
      this._ProductCategoryObj = {
        Productcategory: selectedProductCategory || [],
      };
      this._CounponService.getProduct(this._ProductCategoryObj).subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._includeproducts = resultJSON.producthomepageviews;
          if (this._action == 'edit') {
            this.IncludeProducts(this._selectedIncludeProductsIDs);
          }
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
        }
      );
    }
    this._excludeproduccategories = (
      this._includeproduccategories || []
    ).filter((f) => !selectedProductCategory.includes(f.productcategoryid));
    var ProductTypeID: number[] = [];
    Object.keys(this._excludeproduccategories).map((Index) => {
      ProductTypeID.push(
        this._excludeproduccategories[Index].productcategoryid
      );
    });
    this._selectedExcludeCategoryIDs = ProductTypeID;
    var l_IncludeCategories: Couponincludecategorydetail[] = [];
    var includecategories: Couponincludecategorydetail;
    Object.keys(selectedProductCategory).map((Index) => {
      includecategories = new Couponincludecategorydetail();
      includecategories.coupondetailid = 0;
      includecategories.couponid = this._couponform.get('couponid').value;
      includecategories.includeproductategoryid =
        selectedProductCategory[Index];
      includecategories.createdon = new Date();
      includecategories.createdby = sessionStorage['userid'];
      includecategories.modifiedby = sessionStorage['userid'];
      includecategories.modifiedon = new Date();
      l_IncludeCategories.push(includecategories);
    });
    this._SelectedIncludeCategory = l_IncludeCategories;
  }
  getexcluseProducts(event) {
    var selectedProductCategory = this._selectedExcludeCategoryIDs;
    if (
      selectedProductCategory.length == 0 ||
      selectedProductCategory == null
    ) {
      return;
    } else {
      this._ProductCategoryObj = {
        Productcategory: this._selectedExcludeCategoryIDs || [],
      };
      this._CounponService.getProduct(this._ProductCategoryObj).subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._excludeproducts = resultJSON.producthomepageviews;
          var ProductID: number[] = [];
          Object.keys(this._excludeproducts).map((Index) => {
            ProductID.push(this._excludeproducts[Index].productid);
          });
          this._selectedExcludeProductIDs = ProductID;
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
        }
      );
    }
    var l_ExcludeCategories: Couponexcludecategorydetail[] = [];
    var excludecategories: Couponexcludecategorydetail;
    Object.keys(this._selectedExcludeCategoryIDs).map((Index) => {
      excludecategories = new Couponexcludecategorydetail();
      excludecategories.coupondetailid = 0;
      excludecategories.couponid = this._couponform.get('couponid').value;
      excludecategories.excludeproductategoryid =
        this._selectedExcludeCategoryIDs[Index];
      excludecategories.createdon = new Date();
      excludecategories.createdby = sessionStorage['userid'];
      excludecategories.modifiedby = sessionStorage['userid'];
      excludecategories.modifiedon = new Date();
      l_ExcludeCategories.push(excludecategories);
    });
    this._SelectedExcludeCategory = l_ExcludeCategories;
  }
  IncludeProducts(event) {
    var selectedProductCategory = event.value || event;
    this._selectedproductcount = selectedProductCategory.length;
    this.ApplytoOnchange();
    let IncludeProductsDiscountCheckList: any[] = [];
    IncludeProductsDiscountCheckList = (this._includeproducts || []).filter(
      (f) => selectedProductCategory.includes(f.productid || f.includeproductid)
    );
    for (var i = 0; i < IncludeProductsDiscountCheckList.length; i++) {
      this.HighestProductDiscount[i] =
        IncludeProductsDiscountCheckList[i].discountpercentage;
    }
    this.maxdiscount = this.HighestProductDiscount.reduce((a, b) =>
      Math.max(a, b)
    );
    this._excludeproducts = (this.OverallProducts || []).filter(
      (f) => !selectedProductCategory.includes(f.productid)
    );
    var ProductID: number[] = [];
    Object.keys(this._excludeproducts).map((Index) => {
      ProductID.push(this._excludeproducts[Index].productid);
    });
    this._selectedExcludeProductIDs = ProductID;
    var l_IncludeProducts: Couponincludeproductdetail[] = [];
    var includeproducts: Couponincludeproductdetail;
    Object.keys(selectedProductCategory).map((Index) => {
      includeproducts = new Couponincludeproductdetail();
      includeproducts.coupondetailid = 0;
      includeproducts.couponid = this._couponform.get('couponid').value;
      includeproducts.includeproductid = selectedProductCategory[Index];
      includeproducts.createdon = new Date();
      includeproducts.createdby = sessionStorage['userid'];
      includeproducts.modifiedby = sessionStorage['userid'];
      includeproducts.modifiedon = new Date();
      l_IncludeProducts.push(includeproducts);
    });
    this._SelectedIncludeProducts = l_IncludeProducts;
  }
  ExcludeProducts(event) {
    var l_ExcludeProducts: Couponexcludeproductdetail[] = [];
    var excludeproducts: Couponexcludeproductdetail;
    Object.keys(this._selectedExcludeProductIDs).map((Index) => {
      excludeproducts = new Couponexcludeproductdetail();
      excludeproducts.coupondetailid = 0;
      excludeproducts.couponid = this._couponform.get('couponid').value;
      excludeproducts.excludeproductid = this._selectedExcludeProductIDs[Index];
      excludeproducts.createdon = new Date();
      excludeproducts.createdby = sessionStorage['userid'];
      excludeproducts.modifiedby = sessionStorage['userid'];
      excludeproducts.modifiedon = new Date();
      l_ExcludeProducts.push(excludeproducts);
    });
    this._SelectedExcludeProducts = l_ExcludeProducts;
  }
  DiscountPercentage() {
    var applyto = this._couponform.get('applyto').value;
    if (
      (!this._selectedIncludeProductsIDs ||
        !this._selectedIncludeProductsIDs.length) &&
      applyto != 'MSC00358'
    ) {
      this._CustomExceptionService.handleWarning('Please Select Products');
      this._couponform.controls['couponamount'].setValue(0);
      return;
    }
    var discounttype = this._couponform.get('discounttype').value;
    // var applyto = this._couponform.get("applyto").value;
    if (discounttype == 'ECO00051') {
      var discount = this._couponform.get('couponamount').value;
      if (discount > this.maxdiscount && applyto != 'MSC00358') {
        this._CustomExceptionService.handleWarning(
          'Discount Percentage should not greater than Product Discount Percentage'
        );
        this._couponform.controls['couponamount'].setValue(0);
        return;
      }
      if (discount > 100) {
        this._CustomExceptionService.handleWarning(
          'Discount Percentage should not greater than 100'
        );
        this._couponform.controls['couponamount'].setValue(0);
        return;
      }
    }
  }
  DiscountCheck() {
    var discounttype = this._couponform.get('discounttype').value;
    if (discounttype == 'ECO00050') {
      this._IsCouponPercentage = false;
      this._IsCouponAmount = true;
      this._couponform.controls['couponamount'].setValue(0);
      this._couponform.controls['percentagemaxlimit'].setValue(0);
    } else {
      this._IsCouponPercentage = true;
      this._IsCouponAmount = false;
      this._couponform.controls['couponamount'].setValue(0);
      this._couponform.controls['percentagemaxlimit'].setValue(0);
    }
  }
  onSave() {
    this._submitted = true;
    var maximumspend = this._couponform.get("maxamount").value;
    this._couponform.get("percentagemaxlimit").setValue(maximumspend);
    var paymentmode = this._couponform.get('paymentmode').value;
    var CouponAmount = this._couponform.get('couponamount').value;
    var CouponName = this._couponform.get('couponname').value;
    var MaximumAmount = parseFloat(this._couponform.get('maxamount').value);
    var MinimumAmount = parseFloat(this._couponform.get('minamount').value);
    if(MinimumAmount > MaximumAmount)
    {
      this._CustomExceptionService.handleError("Minimum Amount is Should Not Greater than Maximum Amount");
      return;
    }
    var MaximumPercAmount = parseFloat(
      this._couponform.get('percentagemaxlimit').value
    );
    var discounttype = this._couponform.get('discounttype').value;
    var CouponFromDate = this._couponform.get('fromdate').value;
    var CoupontoDate = this._couponform.get('todate').value;
    var UsageLimitPerCoupon = this._couponform.get('usagelimitpercoupon').value;
    var UsageLimitPerUser = this._couponform.get('usagelimitperuser').value;
    var Applyto = this._couponform.get('applyto').value;
    if (paymentmode && CouponFromDate && CoupontoDate && CouponName) {
      if (CouponAmount && MinimumAmount && MaximumAmount && discounttype) {
        if (UsageLimitPerCoupon && UsageLimitPerUser) {
          if (this._couponform.valid) {
            if (discounttype == 'ECO00050') {
              if (CouponAmount > MaximumAmount) {
                this._CustomExceptionService.handleError(
                  'Coupon amount should not greater than Maximum spend amount'
                );
                return;
              }
            } else {
              if (!MaximumPercAmount) {
                this._CustomExceptionService.handleError(
                  'Please enter maximum amount limit'
                );
                this._CustomExceptionService.handleError(
                  'Please fill Coupon Usage Restriction Tab'
                );
                this.selectedtab = 1;
                return;
              }
              if (MaximumPercAmount > MaximumAmount) {
                this._CustomExceptionService.handleError(
                  'Maximum Percentage amount should not greater than Maximum spend amount'
                );
                return;
              }
            }
            var CouponFromDate = this._couponform.get('fromdate').value;
            // var Applyto = this._couponform.get("applyto").value;
            var datePipe = new DatePipe('en-US');
            var pCouponFromDate = datePipe.transform(
              CouponFromDate,
              'yyyy-MM-dd'
            );
            var CoupontoDate = this._couponform.get('todate').value;
            var datePipe = new DatePipe('en-US');
            var pCoupontoDate = datePipe.transform(CoupontoDate, 'yyyy-MM-dd');

            if (pCouponFromDate > pCoupontoDate) {
              this._CustomExceptionService.handleError(
                'Coupon From Date should be less than the To Date'
              );
              return;
            }
            this._coupon = this._couponform.getRawValue();
            this._coupon.fromdate = new Date(pCouponFromDate);
            this._coupon.todate = new Date(pCoupontoDate);
            this._couponOBJ = {
              Coupon: this._coupon,
              Couponincludecategorydetail: this._SelectedIncludeCategory,
              Couponexcludecategorydetail: (this._SelectedExcludeCategory = []),
              Couponincludeproductdetail: this._SelectedIncludeProducts,
              Couponexcludeproductdetail: (this._SelectedExcludeProducts = []),
            };
            if (this._action == 'create') {
              this._coupon.createdby = parseInt(sessionStorage['userid']);
              this._coupon.createdon = new Date();
              this._IsProgressSpinner = true;
              this._CounponService.create(this._couponOBJ).subscribe(
                (result) => {
                  const resultJSON = JSON.parse(JSON.stringify(result));
                  this._IsProgressSpinner = false;
                  if (
                    resultJSON.tranStatus.result == true &&
                    (resultJSON != null || resultJSON != undefined)
                  ) {
                    this._CustomExceptionService.handleSuccess(
                      usererrors.Saved_Success_00
                    );
                    this._IsProgressSpinner = false;
                    this.reset(null);
                  } else {
                    this._CustomExceptionService.handleError(
                      resultJSON.tranStatus.lstErrorItem[0].message
                    );
                  }
                  this._IsProgressSpinner = false;
                },
                (error) => {
                  this._CustomExceptionService.handleError(error);
                  this._IsProgressSpinner = false;
                }
              );
            } else if (this._action == 'edit') {
              this._coupon.modifiedon = new Date();
              this._coupon.modifiedby = sessionStorage['userid'];
              this._IsProgressSpinner = true;
              this._CounponService.Update(this._couponOBJ).subscribe(
                (result) => {
                  const resultJSON = JSON.parse(JSON.stringify(result));
                  this._IsProgressSpinner = false;
                  if (
                    resultJSON.tranStatus.result == true &&
                    (resultJSON != null || resultJSON != undefined)
                  ) {
                    this._CustomExceptionService.handleSuccess(
                      usererrors.Update_Success_04
                    );
                    this._IsProgressSpinner = false;
                  } else {
                    this._CustomExceptionService.handleError(
                      resultJSON.tranStatus.lstErrorItem[0].message
                    );
                  }
                  this._IsProgressSpinner = false;
                },
                (error) => {
                  this._CustomExceptionService.handleError(error);
                  this._IsProgressSpinner = false;
                }
              );
            }
          }
        } else {
          this._CustomExceptionService.handleError(
            'Please fill Coupon Usage Limits Tab'
          );
          this.selectedtab = 2;
        }
      } else {
        this._CustomExceptionService.handleError(
          'Please fill Coupon Usage Restriction Tab'
        );
        this.selectedtab = 1;
      }
    } else {
      this._CustomExceptionService.handleError(
        'Please fill Coupon General Tab'
      );
      this.selectedtab = 0;
    }
  }
  reset(event) {
    this._submitted = false;
    this._selectedExcludeCategoryIDs = [];
    this._selectedExcludeProductIDs = [];
    this._selectedIncludeCategoryIDs = [];
    this._selectedIncludeProductsIDs = [];
    this._couponform.reset();
    this.InitializeForm();
  }
  goBack(event) {
    this._router.navigate(['/vCoupon']);
  }
  onEd() {
    ace.config.set('fontSize', '14px');
    ace.config.set(
      'basePath',
      'https://unpkg.com/ace-builds@1.4.12/src-noconflict'
    );
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode('ace/mode/json');
    var myObj = this._couponOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  ApplytoOnchange() {
    var Applyto = this._couponform.get('applyto').value;
    if (Applyto == 'MSC00358') {
      this._selectedIncludeProductsIDs = [];
      this._couponform.get('usagelimitperitems').setValue(100);
      this._couponform.get('usagelimitperuser').setValue(1);
      this._couponform.get('usagelimitperuser').disable();
      this._includeproducts = [];
      this._selectedIncludeCategoryIDs = [];
      this._includeproduccategories = [];
      this._Isnewcustomer = false;
    } else {
		// this.ngOnInit();
		this._couponform.get('usagelimitperitems').setValue(this._selectedproductcount);
		this._couponform.get('usagelimitperitems').disable();
		this._couponform.get('usagelimitperuser').enable();
		// this._couponform.get('usagelimitperuser').setValue(1);
    this._includeproduccategories = this._tempincludeproduccategories;
      this._Isnewcustomer = true;
    }
  }

  preventSpecialCharsAndSpaces(event: KeyboardEvent): void {
    // Allow only alphanumeric characters
    const allowedCharsPattern = /^[a-zA-Z0-9\s]*$/;

    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', ' '];
    
    // Check if the pressed key is a control key
    if (controlKeys.includes(event.key)) {
      return; // Allow control keys
    }
    
    // Check if the key is alphanumeric
    if (!allowedCharsPattern.test(event.key)) {
      event.preventDefault(); // Block the character if it doesn't match alphanumeric pattern
    }

    // var letters = event.key.toUpperCase();
    // this._couponform.get("couponname").setValue(letters);


    // if (event.key >= 'a' && event.key <= 'z') {
    //   event.preventDefault(); // Prevent default action (entering the lowercase letter)
      
    //   const inputElement = event.target as HTMLInputElement;
    //   const startPos = inputElement.selectionStart;
    //   const endPos = inputElement.selectionEnd;
    //   const value = inputElement.value;
  
    //   // Insert the uppercase letter in place of the lowercase one
    //   inputElement.value = 
    //     value.slice(0, startPos) + 
    //     event.key.toUpperCase() + 
    //     value.slice(endPos!);
  
    //   // Set cursor position after the inserted uppercase letter
    //   inputElement.setSelectionRange(startPos! + , startPos! + 1);
    // }
  }
}
