import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { MessageService } from 'primeng/api';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { PurchaseinvoiceService } from 'src/app/core/Services/purchase/purchaseinvoice.service';
import { Purchaseinvoiceheaderview } from 'src/app/core/Models/Purchaseinvoice';
import { Purchaseinvoicedetailview } from 'src/app/core/Models/Purchaseinvoicedetail';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Currency } from 'src/app/core/Models/Currency';
import { Purchaseinvoicegetgrnno } from 'src/app/core/Models/Purchaseinvoicegetgrnno';
import { Taxview } from 'src/app/core/Views/Taxview ';
import { vDateSelect, vDebitNote, vInvoiceNoSelect } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Purchaseinvoiceadvancereference } from 'src/app/core/Models/Purchaseinvoiceadvancereference';
import { Paymentadvancereferenceview } from 'src/app/core/Views/Paymentadvancereferenceview';
import { Supplierview } from 'src/app/core/Views/Supplierview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Supplier } from 'src/app/core/Models/Supplier';

@Component({
	selector: 'app-c-purchaseinvoice',
	templateUrl: './c-purchaseinvoice.component.html',
	styleUrls: ['./c-purchaseinvoice.component.css']
})
export class CPurchaseinvoiceComponent implements OnInit {
	_purchaseinvoiceform: FormGroup;
	_Temppurchaseinvoiceform: FormGroup;
	_Status: Metadatum;
	_purchaseinvoice: Purchaseinvoiceheaderview;
	_purchaseinvoices: Purchaseinvoiceheaderview[] = [];
	_purchaseinvoicedetails: Purchaseinvoicedetailview[] = [];
	_purchaseinvoicedetail: Purchaseinvoicedetailview[] = [];
	_purchaseinvoiceothercharges: Othercharge[];
	_grnlist: Purchaseinvoicegetgrnno[] = [];
	selectedsupplierDetailsRows: Purchaseinvoicedetailview[];
	_advances: any[] = [];
	_OtherCharges: Othercharge[] = [];
	_Descriptions: Othercharge[] = [];
	_ChargesTemp: Othercharge[] = [];
	salesreturnothercharges: Othercharge[] = [];
	_taxtypedetails: Metadatum[];
	_debitaccountdetails: Accountledger[];
	_currencydetails: Currency[] = [];
	_currencyreference: any[] = [];
	_taxdetails: Taxview[];
	_statusdetails: Metadatum[];
	_Suppliers: Supplierview;
	_Invoicetype: Metadatum[];
	displayBasic: boolean;
	minDate: Date;
	selectedProductDetailsRows: any;
	selectedOtherChargesRows: any;
	_salesinvoices: string[];
	_userid = sessionStorage["userid"];
	_branchid = sessionStorage["currentbranchid"];
	_action: string = "create";
	_submitted = false;
	_customersubmitted = false;
	isDisabledsave: boolean;
	isDisablededit: boolean;
	_purchaseinvoiceid: any;
	_purchaseinvoiceno: any;
	_PurchaseInvoiceNo: string;
	_PurchaseinvoiceObj: any;
	isDisabledControll: true;
	productingrn: any;
	_advancereferences: any[] = [];
	_purchaseinvoiceadvancereferences: Purchaseinvoiceadvancereference[] = [];
	_referenceno: Paymentadvancereferenceview[] = [];
	_IsProgressSpinner: boolean = true;
	_selectedreference: any[] = [];
	tempInvoiceamount: any;
	isDisabledsendapparoval = true;
	shouldShowTextBox: boolean = false;
	isbarcode: boolean = false;
	_Action: IAction;
	mindate: Date;
	maxdate: Date;
	PIbarcode: any;
	Pitype: string = "";
	supplierholdreason: boolean = false;
	supplierholdpopupsubmit: boolean = false;
	supplierholdpopupclose: boolean = false;
	_Tempsuppliers: Supplierview[] = [];
	reasonforsupplierhold: string;
	popupdisable: boolean = false;

	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(
		private fb: FormBuilder,
		private _router: Router,
		private utility: CommonUtilities,
		private _CustomExceptionService: CustomExceptionService,
		private _PurchaseinvoiceService: PurchaseinvoiceService,
		private _hotkeysService: HotkeysService,
		private _AccessRightsService: AccessRightsService

	) {
		this._purchaseinvoiceid = history.state?.purchaseinvoiceid ? history.state?.purchaseinvoiceid : 0;
		this._purchaseinvoiceno = history.state?.purchaseinvoiceno ? history.state?.purchaseinvoiceno : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.HotKeyIntegration();
	}
	InitializeForm() {
		this._purchaseinvoiceform = this.fb.group({
			companyid: [],
			branchid: [],
			purchaseinvoiceid: [],
			purchaseinvoiceno: [],
			purchaseinvoicedate: ['', vDateSelect],
			pono: [],
			grnno: ['', vInvoiceNoSelect],
			supplierid: [],
			suppliername: [],
			address: [],
			debitaccount: ['', vDebitNote],
			taxid: [],
			taxname: [],
			taxpercentage: [],
			taxamount: [],
			discountpercentage: [],
			discountamount: [],
			otherchargesamount: [],
			currencyreferenceid: [],
			currencyid: [],
			invoiceamount: [],
			amountinusd: [],
			paidamount: [],
			remainingamount: [],
			status: [],
			statusremarks: [],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			voucherno: [],
			grossamount: [],
			series: [],
			creditnoteamount: [],
			invoicetype: ['MSC00284'],
			advanceamount: [],
			netamount: [],
			invoiceno: [''],
			referenceno: [],
			transactionincompleted: [1]
		});
		this._Temppurchaseinvoiceform = this.fb.group({
			branchname: ['']
		});
		if (sessionStorage['Environmentenddate'] == "null") {
			this._purchaseinvoiceform.controls['purchaseinvoicedate'].setValue(new Date());
			this.maxdate = new Date();
		}
		else {
			this._purchaseinvoiceform.controls['purchaseinvoicedate'].setValue(new Date(sessionStorage['Environmentenddate']));
		}

		if (this._debitaccountdetails && this._debitaccountdetails.length) {
			this._purchaseinvoiceform.get("debitaccount").setValue(427);
		}
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(41);
		this.utility.pageLoadScript();
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.InitializeForm();
		const data = sessionStorage.getItem('LedgerData');
		if (data) {
			const parsedData = JSON.parse(data);
			this._userid = parsedData.userid;
			this._purchaseinvoiceid = parsedData.Invoiceid;
			this._purchaseinvoiceno = parsedData.invoiceno;
			this._branchid = parsedData.Branchid;
			this._action = parsedData.action;
			sessionStorage.removeItem('LedgerData');
		  }
		this.isDisabledControll = true;
		this._IsProgressSpinner = true;
		this._PurchaseinvoiceService.PageOnload(this._userid, this._purchaseinvoiceid, this._purchaseinvoiceno, this._branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log("pageload", resultJSON);
			//Rule7:invoice date should fetch the todays date and it can be editable
			//this._purchaseinvoiceform.controls['purchaseinvoicedate'].setValue(new Date());
			this._taxtypedetails = resultJSON.taxTypeList;
			this._debitaccountdetails = resultJSON.debitaccountList;
			this._currencydetails = resultJSON.currencyList;
			this._taxdetails = resultJSON.taxList;
			this._OtherCharges = resultJSON.otherChargeList;
			this._statusdetails = resultJSON.statusList;
			this._Suppliers = resultJSON.supplierList;
			this._Tempsuppliers = resultJSON.supplierList;
			this._grnlist = resultJSON.grnNoList;
			this._Invoicetype = resultJSON.invoicetype;
			this._referenceno = resultJSON.paymentadvancereference;

			this._IsProgressSpinner = false;
			if (this._debitaccountdetails && this._debitaccountdetails.length) {
				this._purchaseinvoiceform.get("debitaccount").setValue(427);
			}
			if (this._action == 'edit' || this._action == 'view') {
				this.shouldShowTextBox = true;
				const updateJSON = JSON.parse(JSON.stringify(result));
				this._purchaseinvoiceform.controls['debitaccount'].setValue(resultJSON.grnnos);
				this._purchaseinvoice = resultJSON.purchaseinvoiceheaderlist;
				this._grnlist = updateJSON.grnNoList;
				// this.productingrn = resultJSON.grnnos;
				this._referenceno = resultJSON.paymentadvancereference;
				this._selectedreference = resultJSON.purchaseinvoiceadvancereferences;
				if (this._selectedreference.length > 0) {
					var suppllierIds: string[] = [];
					Object.keys(resultJSON.purchaseinvoiceadvancereferences).map((Index) => {
						suppllierIds.push(resultJSON.purchaseinvoiceadvancereferences[Index].refNo);
					});
					this._selectedreference = suppllierIds;
				}
				resultJSON.purchaseinvoiceheaderlist[0].purchaseinvoicedate = new Date(resultJSON.purchaseinvoiceheaderlist[0].purchaseinvoicedate);
				resultJSON.purchaseinvoiceheaderlist[0].createdon = new Date(resultJSON.purchaseinvoiceheaderlist[0].createdon);
				resultJSON.purchaseinvoiceheaderlist[0].modifiedon = new Date(resultJSON.purchaseinvoiceheaderlist[0].modifiedon);
				resultJSON.purchaseinvoiceheaderlist[0].currencyid = parseInt(resultJSON.purchaseinvoiceheaderlist[0].currencyid);
				resultJSON.purchaseinvoiceheaderlist[0].debitaccount = parseInt(resultJSON.purchaseinvoiceheaderlist[0].debitaccount);
				this._purchaseinvoiceform.setValue(resultJSON.purchaseinvoiceheaderlist[0]);
				if (this._purchaseinvoiceform.get("invoicetype").value == "MSC00284") {
					this._purchaseinvoicedetails = resultJSON.productdetailsList;
					//this._Temppurchaseinvoiceform.controls['branchname'].setValue(resultJSON.productdetailsList[0].branchname)
					if (resultJSON.productdetailsList && resultJSON.productdetailsList.length > 0) {
						this._Temppurchaseinvoiceform.controls['branchname'].setValue(resultJSON.productdetailsList[0].branchname);
					}
				} else {
					this._purchaseinvoicedetails = resultJSON.purchaseinvoiceProductdetails;
				}
				this._purchaseinvoiceform.controls['debitaccount'].setValue(parseInt(resultJSON.purchaseinvoiceheaderlist[0].debitaccount));
				this._purchaseinvoiceform.controls['remainingamount'].setValue(this._purchaseinvoiceform.get("invoiceamount").value - (this._purchaseinvoiceform.get("creditnoteamount").value + this._purchaseinvoiceform.get("discountamount").value + this._purchaseinvoiceform.get("paidamount").value));
				if (this._purchaseinvoiceform.get("remainingamount").value < 0) {
					this._purchaseinvoiceform.controls['remainingamount'].setValue(0);
				}
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		if (this._action == 'view') {
			this._purchaseinvoiceform.disable();
			this.isDisabledsave = true;
		}
		else {
			this.isDisabledsave = false;
			this.isDisablededit = false;
		}
		if (this._action == 'edit') {
			this.isDisablededit = true;
		}
	}
	InvoiceTypeChange(event) {
		var invoicetype = event.value;
		// if (invoicetype == "MSC00285") { //SALES
		// 	this._purchaseinvoiceform.get("grnno").disable();
		// } else {
		// 	this._purchaseinvoiceform.get("grnno").enable();
		// }
	}
	GetGRNNos(event) {
		//14.Product Category checkbox load from Product category master based on product type 
		var selectedsupplier = event.value;
		this._purchaseinvoicedetails = []
		this._purchaseinvoiceform.controls["grossamount"].reset();
		this._purchaseinvoiceform.controls["taxamount"].reset();
		this._purchaseinvoiceform.controls["otherchargesamount"].reset();
		this._purchaseinvoiceform.controls["discountamount"].reset();
		this._purchaseinvoiceform.controls["creditnoteamount"].reset();
		this._purchaseinvoiceform.controls["invoiceamount"].reset();
		this._purchaseinvoiceform.controls["paidamount"].reset();
		this._purchaseinvoiceform.controls["remainingamount"].reset();
		this._purchaseinvoiceform.controls["netamount"].reset();
		this._purchaseinvoiceform.controls["debitaccount"].reset();
		this._purchaseinvoiceform.controls["debitaccount"].setValue(427);
		this._purchaseinvoiceform.controls["currencyid"].reset();
		this._purchaseinvoiceform.controls["address"].reset();
		this._purchaseinvoiceform.controls["grnno"].reset();
		this._purchaseinvoiceform.controls["invoiceno"].reset();
		this._purchaseinvoiceform.controls["referenceno"].reset();

		this.productingrn = "";
		let invoicetype = this._purchaseinvoiceform.get("invoicetype").value;
		this._IsProgressSpinner = true;
		this._PurchaseinvoiceService.GetGRNNos(this._userid, selectedsupplier, this._branchid, invoicetype).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log("supplier", resultJSON);
			if (invoicetype == "MSC00285") { //sales
				this._grnlist = resultJSON.grnNoList;
				this._purchaseinvoiceform.controls['debitaccount'].setValue(resultJSON.debitNoteBalance);
				this._purchaseinvoiceform.controls['currencyid'].setValue(this._currencydetails[0].currencyid);
				var referenceid = this._currencydetails.filter(f => f.currencyid == 1)[0].currencyuniquerefid;
				this._purchaseinvoiceform.controls['currencyreferenceid'].setValue(referenceid);
			} else {
				this._grnlist = resultJSON.grnNoList;
			}
			this._referenceno = resultJSON.paymentadvancereference;
			this._currencyreference = resultJSON.currencyreference;
			this.HoldSupplierorCustomerPopup();
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GetExchangeRate(currencyid: number, selectedversion: number) {
		let advcurrencylist = this._currencyreference.filter(c => c.versionid == selectedversion);
		let currency = advcurrencylist.find(f => f.currencyid == currencyid);
		if (currency)
			return advcurrencylist.find(f => f.currencyid == currencyid).exchangerate;
		else
			return 1
	}
	onSelectreferenceno() {
		let amount = 0;
		let Totlamount = 0;
		let selectedcurrencyversion = 0;
		let selectedcurrency;
		this._purchaseinvoiceadvancereferences = [];
		let u_currencyid = parseInt(this._purchaseinvoiceform.get("currencyid").value || 0);
		let selectedreferences = this._referenceno.filter(f => this._selectedreference.includes(f.refNo));
		if (this._selectedreference.length) {
			for (var i = 0; i < selectedreferences.length; i++) {
				amount = (this._referenceno || []).find(f => f.refNo == selectedreferences[i].refNo).remainingamount;
				selectedcurrencyversion = (this._referenceno || []).find(f => f.refNo == selectedreferences[i].refNo).currencyuniquerefid;
				selectedcurrency = (this._referenceno || []).find(f => f.refNo == selectedreferences[i].refNo).currentcurrencyid;
				let _advancereference = new Purchaseinvoiceadvancereference();
				_advancereference.ledgerid = this._purchaseinvoiceform.get("supplierid").value;
				_advancereference.refNo = selectedreferences[i].refNo;
				_advancereference.advanceamount = (this.GetExchangeRate(u_currencyid, selectedcurrencyversion) / this.GetExchangeRate(selectedcurrency, selectedcurrencyversion)) * amount;;
				_advancereference.currentcurrencyid = u_currencyid;
				_advancereference.currencyuniquerefid = selectedcurrencyversion;
				this._purchaseinvoiceadvancereferences.push(_advancereference);
				Totlamount += _advancereference.advanceamount;
			}
			var finalamount = this.tempInvoiceamount - Totlamount;
			this._purchaseinvoiceform.get("advanceamount").setValue(Totlamount);
			if (finalamount < 0) {
				this._purchaseinvoiceform.get("netamount").setValue(0);
			} else {
				this._purchaseinvoiceform.get("netamount").setValue(finalamount);
			}
			this._purchaseinvoiceform.get("remainingamount").setValue(finalamount);
		} else {
			this._purchaseinvoiceform.get("advanceamount").setValue(0);
			this._purchaseinvoiceform.get("netamount").setValue(this.tempInvoiceamount);
			this._purchaseinvoiceform.get("remainingamount").setValue(this.tempInvoiceamount);
		}
	}
	OnGRNChange(event) {
		var selectedgrn = event.value || this.Pitype;
		let invoicetype = this._purchaseinvoiceform.get("invoicetype").value;
		this._IsProgressSpinner = true;
		this._PurchaseinvoiceService.GetGRNdetails(selectedgrn, invoicetype).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			console.log("grn", resultJSON);
			if (invoicetype == "MSC00285") {
				this._purchaseinvoicedetails = resultJSON.productdetailsList;
				for (var i = 0; i < this._purchaseinvoicedetails.length; i++) {
					const x = this._purchaseinvoicedetails[i];
					var TaxPercentage = (this._taxdetails || []).filter(f => f.taxid == 1)[0].taxpercentage;
					var taxamount = (x.invoicequantity * x.unitprice / 100) * TaxPercentage;
					x.taxamount = taxamount;
					x.taxpercentage = TaxPercentage;
					x.taxid = 1;
					x.amount = (x.invoicequantity * x.unitprice) + taxamount;
				}
				var grossamount = 0.0;
				var taxamount = 0.0;
				var discountamount = 0.0;
				for (var i = 0; i < this._purchaseinvoicedetails.length; i++) {
					taxamount += this._purchaseinvoicedetails[i].taxamount;
					discountamount += this._purchaseinvoicedetails[i].discountamount;
					grossamount += this._purchaseinvoicedetails[i].amount;
				}
				this._purchaseinvoiceform.controls['grossamount'].setValue(grossamount - taxamount);
				this._purchaseinvoiceform.controls['otherchargesamount'].setValue(0);
				this._purchaseinvoiceform.controls['invoiceamount'].setValue(grossamount);
				this._purchaseinvoiceform.controls['netamount'].setValue(grossamount);
				this._purchaseinvoiceform.controls['discountamount'].setValue(discountamount);
				this._purchaseinvoiceform.controls['taxamount'].setValue(taxamount);
				this._purchaseinvoiceform.controls['remainingamount'].setValue(this._purchaseinvoiceform.get("invoiceamount").value - (this._purchaseinvoiceform.get("creditnoteamount").value + this._purchaseinvoiceform.get("discountamount").value + this._purchaseinvoiceform.get("paidamount").value));
				this.HoldSupplierorCustomerPopup();
				if (this._purchaseinvoiceform.get("remainingamount").value < 0) {
					this._purchaseinvoiceform.controls['remainingamount'].setValue(0);
				}
				this.tempInvoiceamount = this._purchaseinvoiceform.get("invoiceamount").value;
			} else {
				this._purchaseinvoice = resultJSON.purchaseinvoiceheaderlist;
				this._purchaseinvoicedetails = resultJSON.productdetailsList;
				this._Temppurchaseinvoiceform.controls['branchname'].setValue(this._purchaseinvoicedetails[0].branchname)
				this._purchaseinvoiceform.controls['supplierid'].setValue(resultJSON.supplierList[0].supplierid);
				this._purchaseinvoiceform.get("debitaccount").setValue(427);
				this._currencydetails = resultJSON.currencyreference;
				resultJSON.purchaseinvoiceheaderlist[0].purchaseinvoicedate = new Date(resultJSON.purchaseinvoiceheaderlist[0].purchaseinvoicedate);
				resultJSON.purchaseinvoiceheaderlist[0].createdon = new Date(resultJSON.purchaseinvoiceheaderlist[0].createdon);
				resultJSON.purchaseinvoiceheaderlist[0].modifiedon = new Date(resultJSON.purchaseinvoiceheaderlist[0].modifiedon);
				resultJSON.purchaseinvoiceheaderlist[0].currencyid = parseInt(resultJSON.purchaseinvoiceheaderlist[0].currencyid);
				resultJSON.purchaseinvoiceheaderlist[0].debitaccount = parseInt(resultJSON.purchaseinvoiceheaderlist[0].debitaccount);
				this._purchaseinvoiceform.setValue(resultJSON.purchaseinvoiceheaderlist[0]);
				this._purchaseinvoiceform.get("advanceamount").setValue(0);
				this.HoldSupplierorCustomerPopup();
				if (resultJSON.purchaseinvoicegrnheaderlist)
					this._purchaseinvoiceform.get("invoiceno").setValue(resultJSON.purchaseinvoicegrnheaderlist[0].invoiceno || 0);
				this.tempInvoiceamount = this._purchaseinvoiceform.get("invoiceamount").value;
				this._purchaseinvoiceform.controls['debitaccount'].setValue(427);
				this._purchaseinvoiceform.controls['remainingamount'].setValue(this._purchaseinvoiceform.get("invoiceamount").value - (this._purchaseinvoiceform.get("creditnoteamount").value + this._purchaseinvoiceform.get("discountamount").value + this._purchaseinvoiceform.get("paidamount").value));
				if (this._purchaseinvoiceform.get("remainingamount").value < 0) {
					this._purchaseinvoiceform.controls['remainingamount'].setValue(0);
				}
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	onSave() {
		if (this.supplierholdpopupclose == true) {
			this._submitted = false;
			this.supplierholdpopupclose = false;
			return;
		  }
		this._submitted = true;
		if (this._purchaseinvoiceform.valid) {
			this.isDisabledsave = true;
			var PurchaseInvoiceDate = this._purchaseinvoiceform.get("purchaseinvoicedate").value;
			var datePipe = new DatePipe("en-US");
			var pPurchaseInvoiceDate = datePipe.transform(PurchaseInvoiceDate, 'yyyy-MM-dd');
			this._purchaseinvoice = this._purchaseinvoiceform.value;

			// if (this._referenceno && this._referenceno.length && this._referenceno.length > 0) {
			// 	let refnoTotalamount: number = 0,
			// 		selectedrefnoTotalamount: number = 0,
			// 		NetAmount: number = this._purchaseinvoiceform.get("netamount").value,
			// 		currencyid = parseInt(this._purchaseinvoice.currencyid),
			// 		productfinalamount: number = 0;
			// 	for (let i = 0; i < this._referenceno.length; i++) {
			// 		const x = this._referenceno[i];
			// 		refnoTotalamount += (this.GetExchangeRate(currencyid, x.currencyuniquerefid) / this.GetExchangeRate(x.currentcurrencyid, x.currencyuniquerefid)) * x.remainingamount;;
			// 	}
			// 	for (let i = 0; i < this._purchaseinvoicedetails.length; i++) {
			// 		const x = this._purchaseinvoicedetails[i];
			// 		productfinalamount = x.amount;
			// 	}
			// 	if (!this._purchaseinvoiceadvancereferences.length || this._purchaseinvoiceadvancereferences.length == 0) {
			// 		this._CustomExceptionService.handleWarning("This Supplier Has Advance Amount, Kindly select Ref.No and Proceeed.")
			// 		this.isDisabledsave = false;
			// 		return
			// 	}
			// 	for (let i = 0; i < this._purchaseinvoiceadvancereferences.length; i++) {
			// 		const x = this._purchaseinvoiceadvancereferences[i];
			// 		selectedrefnoTotalamount = x.advanceamount
			// 	}
			// 	if ((productfinalamount < refnoTotalamount) && (NetAmount > 0) && (this._referenceno.length != this._purchaseinvoiceadvancereferences.length)) {
			// 		this._CustomExceptionService.handleWarning("This Supplier Has Advance Amount, Kindly select Ref.No and Proceeed.")
			// 		this.isDisabledsave = false;
			// 		return
			// 	}
			// }

			this._PurchaseinvoiceObj = {
				"Purchaseinvoice": this._purchaseinvoice,
				"Purchaseinvoicedetails": this._purchaseinvoicedetails,
				"Purchaseinvoiceothercharges": this._purchaseinvoiceothercharges,
				"Purchaseinvoicereferencedetails": this._purchaseinvoiceadvancereferences
			};
			if (this._action == 'create') {
				this._purchaseinvoice.companyid = 0;
				this._purchaseinvoice.purchaseinvoiceid = 0;
				this._purchaseinvoice.createdby = sessionStorage["userid"];
				this._purchaseinvoice.createdon = new Date();
				this._purchaseinvoice.accountingyear = new Date().getFullYear();
				this._purchaseinvoice.branchid = sessionStorage["BranchID"];
				this._purchaseinvoice.status = "MSC00045";
				this._purchaseinvoice.paidamount = 0;
				this._purchaseinvoice.purchaseinvoicedate = (new Date(pPurchaseInvoiceDate));
				this._IsProgressSpinner = true;
				this._PurchaseinvoiceService.create(this._PurchaseinvoiceObj).subscribe((result) => {
					const resultJSON = JSON.parse(JSON.stringify(result));
					this._IsProgressSpinner = false;
					if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
						this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
						this._PurchaseInvoiceNo = resultJSON.purchaseinvoiceno;
						this.isDisabledsendapparoval = false;
						this.isDisabledsave = true;
						this.reset(null)
						this._purchaseinvoiceform.controls["invoicetype"].enable();
					} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); this.isDisabledsave = false; }
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isDisabledsave = false; });
			}
		}
	}
	payableamount() {
		if (this._purchaseinvoiceform.get("remainingamount").value > this._purchaseinvoiceform.get("invoiceamount").value) {
			this._CustomExceptionService.handleWarning("Amount Should be Less than Invoice Amount");
			this._purchaseinvoiceform.controls['remainingamount'].setValue(0);
		}
	}
	SendToApproval(event) {
		this._IsProgressSpinner = true;
		this._PurchaseinvoiceService.SendToApproval(this._userid, this._branchid, this._PurchaseInvoiceNo).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
			this._IsProgressSpinner = false;
			this.isDisabledsendapparoval = true;
			this.isDisabledsave = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	goBack(event) {
		this._router.navigate(['/vPurchaseinvoice']);
	}
	reset(event) {
		this._purchaseinvoiceform.reset();
		this._purchaseinvoicedetails = [];
		this._referenceno = [];
		this._selectedreference = [];
		this.InitializeForm();
		this._submitted = false;
	}
	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._action != 'view' && !this.isDisabledsave) {
			this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
				this.onSave();
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		if (this._action == 'create') {
			this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
				this.reset(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
			this.goBack(null);
			return false; // Prevent bubbling
		}, ['INPUT', 'SELECT', 'TEXTAREA']));
	}

	FetchBarcode() {
		let type = this._purchaseinvoiceform.get('invoicetype').value;
		  if (!type) {
			this._CustomExceptionService.handleWarning('Please Select Invoice Type');
			return;
		  }
		  //setTimeout(() => {
		  let barcodevalue = <string>this.PIbarcode;
		  if (!barcodevalue) {
			this.PIbarcode = '';
			// this.Isoverallbarcode = false;
			return;
		  }
		  barcodevalue = barcodevalue.trim();
		  this.isbarcode = true;
		  if (barcodevalue) {
			this._purchaseinvoiceform.controls["invoicetype"].disable();
			this.Pitype = barcodevalue;
			this.OnGRNChange(event);
		  }
		  this.PIbarcode = ""
	}

	HoldSupplierorCustomerPopup() {
		let InvoiceType = this._purchaseinvoiceform.get('invoicetype').value;
		let supplieridorbranchid = this._purchaseinvoiceform.get(
		  'supplierid'
		).value;

		  var suppliername = this._Tempsuppliers.filter(
			(a) => a.supplierid == supplieridorbranchid
		  );
		  var supplierholdreason = this._Tempsuppliers.filter(
			(a) => a.supplierid == suppliername[0].supplierid
		  );
		  var supplierstatus = suppliername[0].supplierstatus;
		  if (supplierstatus == 'MSC00344') {
			this.supplierholdreason = true;
			this.supplierholdpopupsubmit = true;
			this.reasonforsupplierhold = supplierholdreason[0].reasonforsupplierhold;
			this.popupdisable = true;
			// this._purchaseinvoiceform.get('reasonforsupplierhold').setValue(supplierholdreason[0].reasonforsupplierhold);
			// this._purchaseinvoiceform.get('reasonforsupplierhold').disable();
		  } else {
			this.supplierholdreason = false;
		  }
	  }
	
	  Supplierholdreasonpopupclose() {
		this.supplierholdreason = false;
		this.supplierholdpopupclose = true;
		return;
	  }
}