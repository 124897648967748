import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Currency } from 'src/app/core/Models/Currency';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productvariant } from 'src/app/core/Models/Productvariant';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Level } from 'src/app/core/Models/Level';
import { Customer } from 'src/app/core/Models/Customer';
import { SalesinvoicetaxService } from 'src/app/core/Services/sales/salesinvoicetax.service';
import { Salesinvoiceothercharge } from 'src/app/core/Models/Salesinvoiceothercharge';
import { Branch } from 'src/app/core/Models/Branch';
import { Employee } from 'src/app/core/Models/Employee';
import { Salesgetreferencenoview } from 'src/app/core/Models/Salesgetreferencenoview';
import { vAddress, vCustomerName, vCustomerNameSelect, vEmailID, vMobileNumber, vProductCategorySelect, vProductNameSelect, vProductTypeSelect } from 'src/app/core/Validators/validation';
import { Salescustomerquicksaveview } from 'src/app/core/Models/Salescustomerquicksave';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { Salesinvoice } from 'src/app/core/Models/Salesinvoicetax';
import * as ace from "ace-builds";
import { Salesinvoiceproductpriceview } from 'src/app/core/Views/Salesinvoiceproductpriceview ';
import { Salescustomerdetailsview } from 'src/app/core/Views/Salescustomerdetailsview';
import { Accountledger } from 'src/app/core/Models/Accountledger';
import { Customerslabwisediscount } from 'src/app/core/Models/Customerslabwisediscount';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { Salesinvoicecreditdetail } from 'src/app/core/Models/salesinvoicecreditnotedetail';
import { HotKeyIntegration } from 'src/assets/js/hot-key-integration';
import { KeyPress } from 'src/assets/js/KeyPress';
import { Reward } from 'src/app/core/Models/Reward';
import { Salesinvoiceview } from 'src/app/core/Views/Salesinvoiceposview';
import { Productoverallbarcodeview } from 'src/app/core/Views/Productoverallbarcodeview';
import { Tax } from 'src/app/core/Models/Tax';
import { Creditnoteview } from 'src/app/core/Views/Creditnoteview';
import { DatePipe } from '@angular/common';
import { Salesinvoiceadvancereference } from 'src/app/core/Models/Salesinvoiceadvancereference';
import { Receiptadvancereferenceview } from 'src/app/core/Views/Receiptadvancereferenceview';
import * as moment from 'moment';
import { Salesinvoicerewarddetail } from 'src/app/core/Models/SalesInvoiceRewardDetails';
declare var $: any;
@Component({
	selector: 'app-c-salesinvoicetax',
	templateUrl: './c-salesinvoicetax.component.html',
	styleUrls: ['./c-salesinvoicetax.component.css']
})
export class CSalesinvoicetaxComponent implements OnInit {
	isSaveClicked: boolean = false;
	creditnotemodel: any = {};
	tempcreditnotebalanceamount: number = 0;
	_maxdate = new Date();
	_customertype: Metadatum[] = [];
	_IsSaveVisible: boolean = false;
	_IsClearVisible: boolean = false;
	_IsFormHide: boolean = true;
	_salesinvoicetaxform: FormGroup;
	_salesinvoicetaxproductform: FormGroup;
	_customerform: FormGroup;
	_creditnotedetailform: FormGroup;
	_toaccountsTemp: Accountledger[];
	_toaccounts: Accountledger[];
	_ReturnCurrencies: Currency[];
	_isCollectingBranchChanged: boolean = false;
	_OtherCharges: Salesinvoiceothercharge[] = [];
	_ProductDetails: Salesinvoiceproductpriceview[] = [];
	selectedProductDetailsRows: any;
	selectedOtherChargesRows: any;
	selectedOtherCurrenciesRows: any;
	_SalesInvoicetax: Salesinvoice;
	_creditnotedetail: Salesinvoicecreditdetail[] = [];
	_advancereferences: Receiptadvancereferenceview[] = [];
	_advances: any[] = [];
	_Customernames: Customer[];
	_customer: Salescustomerdetailsview;
	_Salesreferences: Metadatum;
	_Salesreferenceno: Salesgetreferencenoview[];
	_CreditNoteRefernces: Creditnoteview[] = [];
	_salesinvoiceadvancereferences: Salesinvoiceadvancereference[] = [];
	filteredProducts: Productoverallview[] = [];
	_Collectingbranch: Branch;
	taxes: Tax[] = [];
	_Markuplevels: Level;
	_Currencies: Currency[];
	_ProductTypes: Producttype[] = [];
	_ProductCategories: Productcategory[] = [];
	_ProductNames: Productoverallview[];
	_productBarcodesearch: Productoverallbarcodeview[] = [];
	_ProductNamesTemp: Productoverallview[];
	_customerslabwisediscount: Customerslabwisediscount[] = [];
	_Variants: Productvariant[];
	_Descriptions: Othercharge;
	_PaymentModes: Metadatum[] = [];
	_TempPaymentModes: Metadatum[] = [];
	_Salesby: Employee;
	_CurrenciesTemp: Currency[];
	_Multicurrencies: Currency;
	_action: string = "create";
	_userid = sessionStorage["userid"];
	_series = sessionStorage["Series"];
	_branchid = sessionStorage["currentbranchid"];
	ManageDialog: boolean;
	_customersubmitted = false;
	_Customer: Salescustomerquicksaveview;
	_OtherBalance: number;
	_ChargesTemp: Othercharge[];
	_selectedlevel: number;
	_selectedcustomer: number;
	_selectedproduct: number;
	_selectedcurrency: string;
	_selectedamount: number;
	_UnitPrice: number;
	_selectedReferenceType: string;
	_submitted1 = false;
	_submitted = false;
	_SalesInvoiceTaxObj: any;
	_Productdiscountpercentage: number;
	_Userdiscountpercentage = sessionStorage["userdiscountpercentage"];
	_RemainingPoints: number;
	_Stock: number;
	_Quantity: number;
	row: Salesinvoiceproductpriceview;
	_SelectedVariant: number;
	_Customermaxvalue: number;
	_Customerminvalue: number;
	isDisabledcurrency = true;
	isDisabledlevel = true;
	_salesinvoiceid: number;
	_salesinvoiceno: string;
	isDisabledsendtoapproval: boolean = true;
	isDisabledinvoiceslip: boolean = true;
	isDisabledprint: boolean = true;
	_SelectedExchangerate: number;
	_IsProgressSpinner: boolean = false;
	productcatid: any[] = [];
	producttypeid: any[] = [];
	_isDisabledpoints: boolean = true
	Profit: boolean = true
	_Action: IAction;
	_Approve: Boolean;
	_Severities: Metadatum;
	_SalesInvoiceid: number;
	series: string;
	selectedtab: number = 0;
	_Deliverymode: Metadatum;
	_IsAddVisible: boolean = false
	_IsRemoveVisible: boolean = false
	_finalrewardamount: number;
	_exchangerate: number;
	_customercurrencyrewardpoint: number;
	_rewardsvalue: Reward[];
	_RewardValue: Reward;
	_Multicurrencies1: Currency[];
	_Multicurrencies2: Currency[];
	_quotationresult: any
	excludeVatType = 'MSC00063';
	_branchresult: any
	_selectedProduct: any
	_ProductTypeObj: any;
	_ProductCategoryObj: any;
	_Branch: any
	showpprofit: boolean = true;
	reward: Reward = null;
	_creditnoteids: number[] = [];
	_customercreditlimit: number;
	_tempcustomercreditlimit: number;
	_copyinvoiceno: string;
	_isDeliveryRequired: boolean = false;
	unitprice: any;
	_unitpricechange: number = 0;
	datepipe = new DatePipe("en-Us");
	_rewardlists: Reward[] = [];
	displayBasicProductType: boolean;
	dispalyBasicScheme: boolean;
	Salesinvoiceresultjson: any;
	salesproductprice: any
	schemeRemarks: any[] = []
	_IsdisabledAdvanceRef: boolean = false;
	dispalyBasicReward: boolean;
	earnedrewarddetails: any[] = []
	usedrewarddetails: any[] = [];
	totalearningpoints: number = 0;
	totalusedpoints: number = 0;
	totalremainingpoints: number = 0;
	//For Currency based payment:
	_TempCreditNoteRefernces: Creditnoteview[] = [];
	_Tempadvancereferences: Receiptadvancereferenceview[] = [];
	IsDisabledLayBuy: boolean = false;
	_creditlimit: number;
	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	SalesinvoiceRewardDetailslist: Salesinvoicerewarddetail[] = [];
	_Paymentlist: any[] = []
	_Deliverylist: any[] = []
	_Otherlist: any[] = []
	paymenttermsid: any[] = []
	deliverytermsid: any[] = []
	othertermsid: any[] = []
	TermsAndCondition: any[] = []
	IsDisableRequestDiscount: boolean = false;
	hidequantity: boolean = false;
	_TempLayBuyadvancereferences: Receiptadvancereferenceview[] = [];
	_TempCustomernames: Customer[];
	constructor(
		private fb: FormBuilder,
		private _router: Router,
		private utility: CommonUtilities,
		private messageService: MessageService,
		private _CustomExceptionService: CustomExceptionService,
		private _SalesInvoiceTaxService: SalesinvoicetaxService,
		private _AccessRightsService: AccessRightsService,
		private confirmationService: ConfirmationService,
		private _hotkeysService: HotKeyIntegration<void>,
		public keypress: KeyPress
	) { }
	InitializeForm() {
		this._salesinvoicetaxform = this.fb.group({
			companyid: [0],
			branchid: [],
			salesinvoiceid: [0],
			salesinvoiceno: [],
			salesinvoicedate: [(new Date())],
			salesreference: [],
			salesreferenceno: [],
			deliveryrequired: [0],
			deliverymode: [],
			customerid: ['', vCustomerNameSelect],
			currencyreferenceid: [],
			currencyid: [],
			markuplevelid: [],
			salesby: ['', vProductTypeSelect],
			applicablerewardpoints: [],
			creditnoteno: [],
			creditnoteamount: [],
			laybuyno: [],
			laybuyamount: [],
			productamount: [],
			taxamount: [],
			discountamount: [],
			otherchargesamount: [],
			advanceamount: [],
			salesinvoiceamount: [],
			paidamount: [],
			balanceamount: [],
			purchaseamount: [],
			profitamount: [],
			amountinusd: [],
			paymenttype: ['', vProductTypeSelect],
			paymentreferenceno: [],
			status: [],
			statusremarks: [],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			ispossales: [],
			rv: [],
			voucherno: [],
			mobileno: [],
			mailid: [],
			collectingbranch: [],
			applyrewardpoints: [false],
			remainingpoints: [],
			creditnoteapplicable: [],
			netamountincustomercurrency: [],
			creditnoteid: [],
			bankledgerid: [],
			creditnoteinvoiceno: [],
			temprewardpoints: [],
			cuscurrencyid1: [],
			cuscurrencyamount1: [],
			cuscurrencyid2: [],
			cuscurrencyamount2: [],
			customerbalanceamount: [],
			appliedrewardamount: [],
			returncurrencyid: [''],
			returnamount: [],
			currencyname: [],
			ispaymentmaded: [],
			gatheredrewardpoints: [],
			customeradvanceamount: [0],
			creditnotebalanceamount: [0],
			referenceorderno: [],
			eorderno: [],
			isselfpickup: [],
			isquicksales: [false],
			iscopyinvoice: [false],
			copyinvoiceno: [''],
			otherchargeid: [0],
			vattype: [null],
			specificvattype: [null],
			isprintallowed: [],
			transactionincompleted: [1],
			remarks: [''],
			invoiceconvertto: [null],
			isconsignmentinvoice: [false],
			printcount: [0]
		});
		this.InitializeCustomerForm();
		this.InitializeProductForm();
		this._IsFormHide = true;
		this._salesinvoicetaxform.controls['collectingbranch'].setValue(parseInt(this._branchid));
		this._salesinvoicetaxform.controls['salesby'].setValue(parseInt(this._userid));
		this._salesinvoicetaxform.controls['paymenttype'].setValue("MSC00146");
		this._salesinvoicetaxform.get('applyrewardpoints').disable();
		if (this._isDeliveryRequired) {
			this._salesinvoicetaxform.get("deliveryrequired").setValue(this._isDeliveryRequired);
			this.OnChangeDeliverymode({ checked: true });
		}
		if (sessionStorage['Environmentenddate'] == "null") {
			this._salesinvoicetaxform.get("salesinvoicedate").setValue(new Date);
		}
		else {
			let date = new Date(sessionStorage['Environmentenddate'])
			this._salesinvoicetaxform.get("salesinvoicedate").setValue(date);
		}
	}
	InitializeCustomerForm() {
		this._customerform = this.fb.group({
			customerid: [0],
			customercode: [],
			customername: ['', vCustomerName],
			currencyid: [0],
			mailid: ['', vEmailID],
			mobileno: ['', vMobileNumber],
			address: ['', vAddress],
			createdby: [],
			createdon: [],
			vatno: ['', vCustomerName],
			customertype: ['', vCustomerName]
		});
	}
	InitializeProductForm() {
		this._salesinvoicetaxproductform = this.fb.group({
			producttypeid: [],
			productcategoryid: [],
			productid: ['', vProductNameSelect],
			variantid: [],
			quantity: [null, [Validators.required]],
			isdamaged: [false],
			severity: [],
			remainingstock: [],
			productbarcode: [''],
			stockinhand: []
		});
		this._creditnotedetailform = this.fb.group({
			salesinvoicecreditdetailid: [],
			salesinvoiceid: [],
			creditnoteid: [],
			customerid: [],
			creditnoteamount: [],
			creditnoteusedamount: [],
		});
	}
	ngOnInit(): void {
		this.utility.pageLoadScript();
		this.hidequantity = true;
		this._salesinvoiceno = history.state?.salesinvoiceno ? history.state?.salesinvoiceno : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.series = history.state?.series ? history.state?.series : null;
		this._Action = this._AccessRightsService.getAccessRights(55);
		this._hotkeysService.Save(event => { this.onSave(); }, this._Action).Clear(event => { this.reset(event); }).Navigate(event => {
			this.goBack(event);
		}, this._Action, 'left');
		this.checkprofitRights();
		this.InitializeForm();
		const data = sessionStorage.getItem('LedgerData');
		if (data) {
			const parsedData = JSON.parse(data);
			this._salesinvoiceno = parsedData.salesposinvoiceno;
			this._userid = parsedData.userid;
			this._branchid = parsedData.Branchid;
			this._action = parsedData.action;
			sessionStorage.removeItem('LedgerData');
		}
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.PageOnLoad(this._salesinvoiceno, this._userid, this._branchid).subscribe((result: any) => {
			const resultJSON = result;
			this._salesinvoicetaxform.get("applicablerewardpoints").disable();
			this._salesinvoicetaxproductform.controls['severity'].disable();
			this._salesinvoicetaxform.controls['paymenttype'].setValue("MSC00146");
			this._Collectingbranch = resultJSON.collectingbranches;
			this._Currencies = resultJSON.currencies;
			this._Multicurrencies = resultJSON.currencies;
			this._Multicurrencies1 = resultJSON.currencies;
			this._Multicurrencies2 = resultJSON.currencies;
			this._ReturnCurrencies = resultJSON.currencies;
			// this._Customernames = resultJSON.customers;
			this._TempCustomernames = resultJSON.customers;
			if (this._TempCustomernames.length) {
				this._Customernames = this._TempCustomernames.filter(f => f.isconsignmentcustomer != true);
			}
			this._Markuplevels = resultJSON.levels;
			let salesreference = resultJSON.salesReference;
			this._Salesreferences = salesreference.filter(s => s.metasubcode != "MSC00361")
			this._Salesby = resultJSON.salesby;
			this._ProductTypes = resultJSON.producttypes;
			this._Descriptions = resultJSON.othercharges;
			this._ChargesTemp = resultJSON.othercharges;
			this._PaymentModes = resultJSON.cashtype;
			this._TempPaymentModes = resultJSON.cashtype;
			this._CurrenciesTemp = resultJSON.currencies;
			this._Severities = resultJSON.severities
			this._Deliverymode = resultJSON.deliverymode
			this.taxes = resultJSON.taxes
			this._rewardsvalue = resultJSON.reward;
			this.reward = resultJSON.reward;
			this._customertype = resultJSON.customertype || [];
			this._productBarcodesearch = <Productoverallbarcodeview[]>resultJSON.productbarcodesearch || [];
			this._salesinvoicetaxproductform.controls['isdamaged'].setValue(false);
			this._salesinvoicetaxform.controls['collectingbranch'].setValue(parseInt(this._branchid));
			this._salesinvoicetaxform.controls['salesby'].setValue(parseInt(this._userid));
			this._toaccountsTemp = resultJSON.toaccounts;
			this._IsProgressSpinner = false;
			this._rewardlists = resultJSON.rewards;
			this._isDeliveryRequired = resultJSON.isdeliveryrequired;
			this._Paymentlist = resultJSON.paymentlist
			this._Deliverylist = resultJSON.deliverylist
			this._Otherlist = resultJSON.otherlist
			
			if (this._action == 'create') {
				this._Paymentlist = this._Paymentlist.filter(f => f.termsandconditionstatus == "MSC00001")
				this._Deliverylist = this._Deliverylist.filter(f => f.termsandconditionstatus == "MSC00001")
				this._Otherlist = this._Otherlist.filter(f => f.termsandconditionstatus == "MSC00001")
			}
			if (this._isDeliveryRequired) {
				this._salesinvoicetaxform.get("deliveryrequired").setValue(this._isDeliveryRequired);
				this.OnChangeDeliverymode({ checked: true });
			}
			if (this._action == 'view' || this._action == 'edit') {
				if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
					let salesinvoice = (<Salesinvoiceview>resultJSON.salesinvoiceview);
					salesinvoice.salesinvoicedate = new Date(salesinvoice.salesinvoicedate);
					delete salesinvoice.customername;
					delete salesinvoice.statusname;
					delete salesinvoice.series;
					delete salesinvoice.ispaymentmadedtext;
					delete salesinvoice.username;
					this._toaccounts = resultJSON.toaccounts;
					this._salesinvoicetaxform.setValue(salesinvoice);
					this._ProductDetails = resultJSON.salesinvoiceproductspriceview;
					this._OtherCharges = resultJSON.salesinvoiceothercharges;
					this._CreditNoteRefernces = (<Creditnoteview[]>resultJSON.creditnotereferencenos || []);
					this._creditnotedetail = resultJSON.salesinvoicecreditdetails || [];
					this._creditnoteids = [];
					for (var i = 0; i < this._CreditNoteRefernces.length; i++) {
						this._creditnoteids.push(this._CreditNoteRefernces[i].creditnoteid);
					}
					this._IsFormHide = false;
					this._IsProgressSpinner = false;
					for (var i = 0; i < this._ProductDetails.length; i++) {
						this._ProductDetails[i].productamount = this._ProductDetails[i].quantity * this._ProductDetails[i].unitprice
					}
					this._advancereferences = resultJSON.receiptadvancereferenceviewlist;
					this._advances = (resultJSON.receiptadvancereferenceselectedlist || []).map(m => m.refNo);
					this._salesinvoiceadvancereferences = resultJSON.receiptadvancereferenceselectedlist || [];
					this.paymenttermsid = resultJSON.salesinvoicetermsandconditionlist.filter(f => f.termtypeid == "MSC00337").map(x => x.termid)
					this.deliverytermsid = resultJSON.salesinvoicetermsandconditionlist.filter(f => f.termtypeid == "MSC00338").map(x => x.termid)
					this.othertermsid = resultJSON.salesinvoicetermsandconditionlist.filter(f => f.termtypeid == "MSC00339").map(x => x.termid)
					this.paymenttermsid = [...this.paymenttermsid]
					this.deliverytermsid = [...this.deliverytermsid]
					this.othertermsid = [...this.othertermsid]
				} else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); this._IsProgressSpinner = false; }
				if (this._action == 'view') {
					this.isSaveClicked = true;
					this._salesinvoicetaxform.disable();
					this._salesinvoicetaxproductform.disable();
					this._customerform.disable();
					this._IsAddVisible = true;
					this._IsRemoveVisible = true;
				}
			}
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		//Other Charges 
		if (this._action == 'create') {
			var l_othercharge = new Salesinvoiceothercharge();
			l_othercharge.salesinvoiceid = this._salesinvoicetaxform.get("salesinvoiceid").value;
			l_othercharge.salesinvoiceotherchargesdetailsid = 0;
			l_othercharge.createdby = sessionStorage["userid"];
			l_othercharge.modifiedby = sessionStorage["userid"];
			l_othercharge.createdon = new Date();
			l_othercharge.modifiedon = new Date();
			this._OtherCharges.push(l_othercharge);
		}
		if (this._action == 'create') {
			this.isDisabledsendtoapproval = true;
			this.isDisabledinvoiceslip = true;
			this.isDisabledprint = true;
		}
	}
	GetExchangeRate(currencyid: number) {
		return this._Currencies.find(f => f.currencyid == currencyid).exchangerate || 1;
	}
	OnUserPreferredCurrencyChange() {
		let currency = parseInt(this._salesinvoicetaxform.get("currencyid").value || 0);
		var Amount = (this._CurrenciesTemp || []).filter(f => f.currencyid == currency)[0].exchangerate;
		this._customercreditlimit = (this._tempcustomercreditlimit || 0.00) * Amount;
		this._ProductDetails = [];
		if (currency == 4) {
			this._PaymentModes = this._TempPaymentModes.filter(f => f.metasubid != 146);
			this._salesinvoicetaxform.get("paymenttype").setValue("MSC00148");
		} else {
			this._PaymentModes = this._TempPaymentModes.filter(f => f.metasubid == 146);
			this._salesinvoicetaxform.get("paymenttype").setValue("MSC00146");
		}
		this._salesinvoicetaxform.get("cuscurrencyid2").reset();
		this._salesinvoicetaxform.get("cuscurrencyamount1").reset();
		this._salesinvoicetaxform.get("cuscurrencyamount2").reset();
		this.CurrencyBasedRef();
		this.OnChangePaymode();
		this.OnChangeAdvanceReferences()
		this.OnChangeCreditNote(null);
		this._salesinvoicetaxproductform.reset();
		this.InitializeProductForm();
		this._salesinvoicetaxform.controls['cuscurrencyid1'].setValue(currency);
	}
	checkprofitRights() {
		if (!this._Action._Profit) {
			this.showpprofit = true;
		} else {
			this.showpprofit = false;
		}
	}
	AddOtherCharges(event: any) {
		let otherChargesExists = (this._OtherCharges.filter(s => s.otherchargesid > 0).length == this._OtherCharges.length) && this._OtherCharges.some(s => s.otherchargesid > 0);
		if (!otherChargesExists)
			return;
		var l_othercharge = new Salesinvoiceothercharge();
		l_othercharge.salesinvoiceid = this._salesinvoicetaxform.get("salesinvoiceid").value;
		l_othercharge.salesinvoiceotherchargesdetailsid = 0;
		l_othercharge.createdby = sessionStorage["userid"];
		l_othercharge.modifiedby = sessionStorage["userid"];
		l_othercharge.createdon = new Date();
		l_othercharge.modifiedon = new Date();
		l_othercharge.accountingyear = new Date().getFullYear();
		this._OtherCharges.push(l_othercharge);
	}
	RemoveOtherCharges(pOthercharges: Salesinvoiceothercharge) {
		var index = this._OtherCharges.indexOf(pOthercharges);
		this._OtherCharges.splice(index, 1);
		this.TotalCalculation();
		if (this._OtherCharges.length <= 0) {
			var l_othercharge = new Salesinvoiceothercharge();
			l_othercharge.salesinvoiceid = this._salesinvoicetaxform.get("salesinvoiceid").value;
			l_othercharge.salesinvoiceotherchargesdetailsid = 0;
			l_othercharge.createdby = sessionStorage["userid"];
			l_othercharge.modifiedby = sessionStorage["userid"];
			l_othercharge.createdon = new Date();
			l_othercharge.modifiedon = new Date();
			l_othercharge.accountingyear = new Date().getFullYear();
			this._OtherCharges.push(l_othercharge);
		}
	}
	OnchangeOtherCharge(pOtherCharges: Salesinvoiceothercharge, event: any) {
		let errorMsg = '';
		let isAlreadyExists = this._OtherCharges.filter(s => s.otherchargesid == event.value).length > 1;
		if (isAlreadyExists) {
			this._CustomExceptionService.handleWarning(usererrors.alreadyexists);
			return;
		}
		var otherchargeamount = (this._ChargesTemp || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount || 0;
		pOtherCharges.otherchargesamount = parseFloat(otherchargeamount.toFixed(2));
		this.TotalCalculation();
	}
	Calculateothercharge(row: any, event: any) {
		var selectedindex = this._OtherCharges.findIndex(obj => obj == row);
		let OtherChargesAmount = this._OtherCharges[selectedindex].otherchargesamount || 0;
		this._salesinvoicetaxform.controls['otherchargesamount'].setValue(OtherChargesAmount.toFixed(2));
		this.TotalCalculation();
	}
	GetReferenceNo() {
		this._selectedReferenceType = this._salesinvoicetaxform.get("salesreference").value;
		this._salesinvoicetaxform.get("currencyid").enable();
		this._salesinvoicetaxform.controls["salesreferenceno"].reset();
		this._ProductDetails = [];
		this._selectedcustomer = this._salesinvoicetaxform.get("customerid").value;
		if (!this._selectedcustomer) {
			this._salesinvoicetaxform.controls["salesreference"].reset();
			this._CustomExceptionService.handleWarning("Please select customer");
			return;
		}
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.GetReferenceNo(this._selectedReferenceType, this._userid, this._selectedcustomer).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._Salesreferenceno = resultJSON.referenceno;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	disableLaybuyOptions() {
		this._salesinvoicetaxform.get("cuscurrencyid2").enable();
		this._salesinvoicetaxform.get('cuscurrencyamount1').enable();
		this._salesinvoicetaxform.get('cuscurrencyamount2').enable();
		this._salesinvoicetaxform.get("paymenttype").enable();
		this._salesinvoicetaxform.get("bankledgerid").enable();
		this._salesinvoicetaxform.get("paymentreferenceno").enable();
		this._salesinvoicetaxform.get("creditnoteapplicable").enable();
		if (this._selectedlevel == 1 || this._selectedlevel == 2) {
			this._salesinvoicetaxform.get("applyrewardpoints").enable();
		} else {
			this._salesinvoicetaxform.get("applyrewardpoints").disable();
		}
		if (this._salesinvoicetaxform.get("salesreference").value == "MSC00221") {
			this._salesinvoicetaxform.get("cuscurrencyid1").reset();
			this._salesinvoicetaxform.get("cuscurrencyid1").disable();
			this._salesinvoicetaxform.get("cuscurrencyid2").reset();
			this._salesinvoicetaxform.get("cuscurrencyid2").disable();
			this._salesinvoicetaxform.get('cuscurrencyamount1').reset();
			this._salesinvoicetaxform.get('cuscurrencyamount1').disable();
			this._salesinvoicetaxform.get('cuscurrencyamount2').reset();
			this._salesinvoicetaxform.get('cuscurrencyamount2').disable();
			this._salesinvoicetaxform.get("paymenttype").disable();
			this._salesinvoicetaxform.get("bankledgerid").disable();
			this._salesinvoicetaxform.get("bankledgerid").reset();
			this._salesinvoicetaxform.get("paymentreferenceno").disable();
			this._salesinvoicetaxform.get("paymentreferenceno").reset();
			this._salesinvoicetaxform.get("creditnoteapplicable").reset();
			this._salesinvoicetaxform.get("creditnoteapplicable").disable();
			this._salesinvoicetaxform.get("applyrewardpoints").setValue(false);
			this._salesinvoicetaxform.get("applyrewardpoints").disable();
			this._salesinvoicetaxform.get("applyrewardpoints").reset();
			this._salesinvoicetaxform.get("salesinvoiceno").disable();
			this._salesinvoicetaxform.get("customerid").disable();
			this._salesinvoicetaxform.get("salesinvoicedate").disable();
			this._salesinvoicetaxform.get("currencyid").disable();
			this._salesinvoicetaxform.get("mobileno").disable();
			this._salesinvoicetaxform.get("referenceorderno").disable();
			this._salesinvoicetaxform.get("collectingbranch").disable();
			this._salesinvoicetaxform.get("salesreference").disable();
			this._salesinvoicetaxform.get("salesreferenceno").disable();
			this._salesinvoicetaxform.get("customeradvanceamount").disable();
			this._salesinvoicetaxform.get("applyrewardpoints").disable();
			this._salesinvoicetaxform.get("applicablerewardpoints").disable();
			this._salesinvoicetaxform.get("remainingpoints").disable();
			this._salesinvoicetaxform.get("creditnoteapplicable").disable();
			this._salesinvoicetaxform.get("creditnoteinvoiceno").disable();
			this._salesinvoicetaxform.get("creditnoteid").disable();
			this._salesinvoicetaxform.get("creditnoteamount").disable();
			this._salesinvoicetaxform.get("otherchargesamount").disable();
			this._salesinvoicetaxform.get("productamount").disable();
			this._salesinvoicetaxform.get("taxamount").disable();
			this._salesinvoicetaxform.get("amountinusd").disable();
			this._salesinvoicetaxform.get("profitamount").disable();
			this._salesinvoicetaxform.get("netamountincustomercurrency").disable();
			this._salesinvoicetaxform.get("paidamount").disable();
			this._salesinvoicetaxform.get("customerbalanceamount").disable();
			this._salesinvoicetaxform.get("balanceamount").disable();
			this._salesinvoicetaxform.get("deliveryrequired").disable();
			this._salesinvoicetaxform.get("deliverymode").disable();
			this._salesinvoicetaxform.get("salesby").disable();
			this._salesinvoicetaxform.get("paymenttype").disable();
			this._salesinvoicetaxform.get("bankledgerid").disable();
			this._salesinvoicetaxform.get("paymentreferenceno").disable();
			this._salesinvoicetaxform.get("cuscurrencyid1").disable();
			this._salesinvoicetaxform.get("cuscurrencyamount1").disable();
			this._salesinvoicetaxform.get("cuscurrencyid2").disable();
			this._salesinvoicetaxform.get("cuscurrencyamount2").disable();
			this._salesinvoicetaxform.get("returncurrencyid").disable();
			this._salesinvoicetaxform.get("returnamount").disable();
			this._salesinvoicetaxform.get("creditnotebalanceamount").disable();
			this._salesinvoicetaxform.get("returncurrencyid").disable();
			this._salesinvoicetaxform.get("cuscurrencyamount2").disable();
			this._salesinvoicetaxform.get("paidamount").disable();
			this._salesinvoicetaxform.get("cuscurrencyamount2").disable();
			this._salesinvoicetaxproductform.get("isdamaged").disable();
			this._salesinvoicetaxproductform.get("severity").disable();
			this._salesinvoicetaxproductform.get("productbarcode").disable();
			this._salesinvoicetaxproductform.get("producttypeid").disable();
			this._salesinvoicetaxproductform.get("variantid").disable();
			this._salesinvoicetaxproductform.get("quantity").disable();
			this._salesinvoicetaxproductform.get("stockinhand").disable();
		}
	}
	GetReferenceDetails() {
		var salesreferenceno = this._salesinvoicetaxform.get("salesreferenceno").value;
		this._ProductDetails = [];
		let currencyid = this._salesinvoicetaxform.get("currencyid").value;
		this._salesinvoicetaxform.get("currencyid").enable();

		if (this._selectedReferenceType == 'MSC00081') {
			this.IsDisableRequestDiscount = true;
		}
		else {
			this.IsDisableRequestDiscount = false;
		}
		this._SalesInvoiceTaxService.GetReferenceDetails(this._selectedReferenceType, salesreferenceno, this._userid, currencyid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			let salesinvoicereferencedetails = resultJSON.salesinvoicereferencedetails[0];
			if (salesinvoicereferencedetails) {
				if (resultJSON.currencyreference) {
					this._Currencies = resultJSON.currencyreference;
					this._ReturnCurrencies = resultJSON.currencyreference;
				}
				this._salesinvoicetaxform.get("currencyid").setValue(salesinvoicereferencedetails.currencyid || 1);
				this._salesinvoicetaxform.get("currencyreferenceid").setValue(salesinvoicereferencedetails.currencyreferenceid || 1);
				this._salesinvoicetaxform.get("currencyid").disable();
				this.OnUserPreferredCurrencyChange();
			}
			this._quotationresult = resultJSON.salesinvoicereferenceproductdetail;
			for (var i = 0; i < this._quotationresult.length; i++) {
				if (this._quotationresult[i].quantity > this._quotationresult[i].stocktemp) {
					this._salesinvoicetaxform.get("deliveryrequired").setValue(true);
					this._salesinvoicetaxform.get("deliveryrequired").disable();
					if (this._quotationresult[i].disallownegativeqty == true) {
						this._CustomExceptionService.handleWarning("stock not available");
						return;
					}
				}
			}
			this.AddProductToGrid(resultJSON, 'salesinvoicereferenceproductdetail');
			this._salesinvoicetaxform.controls['markuplevelid'].setValue(this._customer.levelid);
			if (resultJSON.salesinvoicereferenceothercharges && resultJSON.salesinvoicereferenceothercharges.length) {
				this._OtherCharges = resultJSON.salesinvoicereferenceothercharges;
				this.TotalCalculation();
			}
			if (this._selectedReferenceType == "MSC00221") {

				this._salesinvoicetaxform.get("cuscurrencyid1").setValue(salesinvoicereferencedetails.currencyid);
				//lay buy changes S
				var currencyid = this._salesinvoicetaxform.get("currencyid").value;
				this._advancereferences = this._TempLayBuyadvancereferences.filter(f => f.currencyid == currencyid && f.type == 2) || [];
				this._advances = (this._TempLayBuyadvancereferences.filter(f => f.laybuyno == salesreferenceno && f.type == 2) || []).map(m => m.refNo);
				this.OnChangeAdvanceReferences();
				//lay buy changes E
				this._IsdisabledAdvanceRef = true;
				this.IsDisabledLayBuy = true;
				this._salesinvoicetaxform.get("salesreferenceno").enable();
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GetLevel(event: { value: any; }) {
		var selectedlevel = event.value;
		console.log(selectedlevel);
		this._selectedlevel = selectedlevel;
	}

	GetCustomer(event: { value: any; }): void {
		this._IsProgressSpinner = true;
		var selectedcustomer = event.value;
		this._selectedcustomer = selectedcustomer;
		this._IsProgressSpinner = true;
		this._advancereferences = [];
		this._salesinvoicetaxform.get("creditnoteinvoiceno").reset();
		this._salesinvoicetaxform.controls["creditnoteapplicable"].setValue(false);
		this._CreditNoteRefernces = [];
		this._TempCreditNoteRefernces = [];
		this._Tempadvancereferences = [];
		this._ProductDetails = [];
		this._creditnoteids = [];
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.GetCustomerDetails(selectedcustomer).subscribe((result: any) => {
			this._IsProgressSpinner = false;
			const resultJSON = result;
			this.earnedrewarddetails = []
			this.usedrewarddetails = []
			this.totalearningpoints = 0
			this.totalusedpoints = 0
			this.totalremainingpoints = 0
			this.earnedrewarddetails = resultJSON.earnedrewarddetails;
			this.usedrewarddetails = resultJSON.usedrewarddetails;
			this.totalearningpoints = resultJSON.totalearningpoints;
			this.totalusedpoints = resultJSON.totalusedpoints;
			this.totalremainingpoints = resultJSON.totalremainingpoints;
			// if(this.totalremainingpoints){
			//   this.dispalyBasicReward =true;
			// }
			let cus = (this._Customernames || []).find(f => f.customerid == selectedcustomer);
			this._Currencies = this._Multicurrencies1 = this._Multicurrencies2 = this._ReturnCurrencies = (this._CurrenciesTemp || []);
			if (cus.series == "MSC00094" && this._action != 'view') {
				this._Currencies = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
				this._Multicurrencies1 = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
				this._Multicurrencies2 = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
				this._ReturnCurrencies = (this._CurrenciesTemp || []).filter(f => f.currencyid != 4) || [];
			}
			this._salesinvoicetaxform.get("currencyid").enable();
			let customer = <Salescustomerdetailsview>(resultJSON.customerdetails);
			this._customer = customer;
			// var dt = new Date();
			// dt.setDate(dt.getDate() + (this._customer.creditdays));
			// this._salesinvoicetaxform.controls['duedate'].setValue(dt);
			this._salesinvoicetaxform.controls['currencyid'].setValue(customer.currencyid);
			this._salesinvoicetaxform.controls['cuscurrencyid1'].setValue(this._customer.currencyid);

			var referenceid = this._Currencies.filter(f => f.currencyid == customer.currencyid)[0].currencyuniquerefid
			this._salesinvoicetaxform.controls['currencyreferenceid'].setValue(referenceid);
			this._selectedlevel = customer.levelid;
			this._Markuplevels = resultJSON.levels;
			this._salesinvoicetaxform.controls['markuplevelid'].setValue(customer.levelid);
			this._salesinvoicetaxform.controls['mobileno'].setValue(customer.mobileno);
			this._salesinvoicetaxform.controls['mailid'].setValue(customer.mailid);
			this._salesinvoicetaxform.controls['remainingpoints'].setValue(customer.rewardpoints);

			this._salesinvoicetaxform.controls['vattype'].setValue(this._customer.vattype);
			this._salesinvoicetaxform.controls['specificvattype'].setValue(this._customer.specificvattype);
			this._RemainingPoints = customer.rewardpoints;
			this._customerslabwisediscount = resultJSON.customerslabwisediscounts;

			this._customercreditlimit = resultJSON.balancecreditamount || 0.00;
			this._tempcustomercreditlimit = resultJSON.balancecreditamount || 0.00;

			if ((resultJSON.customerslabwisediscounts || []).length) {
				this._Customermaxvalue = resultJSON.customerslabwisediscounts[0].maximumvalue;
				this._Customerminvalue = resultJSON.customerslabwisediscounts[0].minimumvalue;
			}
			// this._CreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
			// this._TempCreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
			this._advancereferences = resultJSON.receiptadvancereferenceviewlist || [];
			// let RemovedLaybuylist = (resultJSON.receiptadvancereferenceviewlist  || []).filter(f => f.refNo.indexOf(("LAP@" || '').toUpperCase()) !== -1).map(m=>m.refNo)
			// this._advancereferences = (resultJSON.receiptadvancereferenceviewlist  || []).filter(f => !(RemovedLaybuylist||[]).includes(f.refNo));
			this._Tempadvancereferences = this._advancereferences;
			this._TempLayBuyadvancereferences = this._advancereferences;
			//lay buy changes S
			this._advancereferences = this._advancereferences.filter(f => f.type == 1);
			this._Tempadvancereferences = this._advancereferences.filter(f => f.type == 1);
			//lay buy changes E
			this._salesinvoicetaxform.get("salesreference").reset();
			this._salesinvoicetaxform.get("salesreferenceno").reset();

			if (this._selectedlevel == 1 || this._selectedlevel == 2) {
				this._salesinvoicetaxform.get("applyrewardpoints").enable();
			}
			else {
				this._salesinvoicetaxform.get("applyrewardpoints").disable();
			}

			this._IsFormHide = false;
			$("input[name='productbarcode']").focus();
			this.OnUserPreferredCurrencyChange();
			this.CurrencyBasedRef();
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	GetCreditNoteDetails() {
		let isApplicable: boolean = <boolean>this._salesinvoicetaxform.get("creditnoteapplicable").value;
		if (!isApplicable) {
			return;
		}
		let customerid = this._salesinvoicetaxform.get("customerid").value;
		if (!customerid) {
			this._CustomExceptionService.handleError("Please Select Customer");
			return;
		}
		this._CreditNoteRefernces = [];
		this._TempCreditNoteRefernces = [];
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.GetCreditNoteDetails(customerid).subscribe((result: any) => {
			this._IsProgressSpinner = false;
			const resultJSON = result;
			this._customercreditlimit = resultJSON.balancecreditamount || 0.00;
			this._tempcustomercreditlimit = resultJSON.balancecreditamount || 0.00;
			this._CreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
			this._TempCreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferenceno;
			this.CurrencyBasedRef();
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	//Credit / Advance reference loading based on currency:
	CurrencyBasedRef() {
		this._advancereferences = [];
		this._advances = [];
		this._salesinvoicetaxform.get("creditnoteinvoiceno").reset();
		this._creditnoteids = [];
		this._CreditNoteRefernces = [];
		var currencyid = this._salesinvoicetaxform.get("currencyid").value;
		this._CreditNoteRefernces = this._TempCreditNoteRefernces.filter(f => f.currentcurrencyid == currencyid) || [];
		this._advancereferences = this._Tempadvancereferences.filter(f => f.currencyid == currencyid) || [];
		this._salesinvoicetaxform.get("cuscurrencyid1").disable();
	}

	OnChangeRewardPointApplicable(event: { checked: boolean; }) {

		if (event.checked == true) {
			this._salesinvoicetaxform.get("applicablerewardpoints").enable();
			if (this.totalremainingpoints) {
				this.dispalyBasicReward = true;
			}
		}
		else if (event.checked == false) {
			this._salesinvoicetaxform.get("applicablerewardpoints").disable();
			this._salesinvoicetaxform.get("applicablerewardpoints").reset();
			this._salesinvoicetaxform.controls["remainingpoints"].setValue(this._RemainingPoints.toFixed(2));
		}
		this.TotalCalculation();
	}

	// For Reward Point Calculation:
	allocateRewardPoints(event: any) {
		var applicablerewardpoints = this._salesinvoicetaxform.get("applicablerewardpoints").value;
		this._salesinvoicetaxform.get("applicablerewardpoints").setValue(0);
		this.TotalCalculation();
		this._salesinvoicetaxform.get("applicablerewardpoints").setValue(applicablerewardpoints);
		var netamountinusd = parseFloat(this._salesinvoicetaxform.get('amountinusd').value || 0);
		if (applicablerewardpoints > this._RemainingPoints) {
			this._CustomExceptionService.handleWarning("Applicable Reward Points Should be Less Than Remaining Points");
			this._salesinvoicetaxform.get('applicablerewardpoints').reset();
			this._salesinvoicetaxform.controls['remainingpoints'].setValue(this._RemainingPoints);
		}
		else {
			this._salesinvoicetaxform.controls['remainingpoints'].setValue(this._RemainingPoints);
			if (this.reward) {
				let rewardsValue = this.GetrewardsValue(netamountinusd);
				let applicablenetamount = (netamountinusd * (30 / 100)); // 112.5
				let rewardamount = applicablerewardpoints * rewardsValue;
				if (rewardamount > netamountinusd || rewardamount > applicablenetamount) {
					this._salesinvoicetaxform.get("applicablerewardpoints").setValue(0);
					if (applicablenetamount > this._RemainingPoints) {
						this._salesinvoicetaxform.get("applicablerewardpoints").setValue(this._RemainingPoints);
						this._CustomExceptionService.handleWarning("Applicable Reward Points " + this._RemainingPoints);
					} else {
						this._salesinvoicetaxform.get("applicablerewardpoints").setValue(applicablenetamount.toFixed(2));
						this._CustomExceptionService.handleWarning("Applicable Reward Points " + applicablenetamount);
						var RewardPoints = this._RemainingPoints - applicablenetamount;
						this._salesinvoicetaxform.controls['remainingpoints'].setValue(RewardPoints.toFixed(2));
					}
				} else {
					var RewardPoints = this._RemainingPoints - applicablerewardpoints;
					this._salesinvoicetaxform.controls['remainingpoints'].setValue(RewardPoints.toFixed(2));
				}
			} else {
				this._salesinvoicetaxform.get("applicablerewardpoints").setValue(0);
			}
		}
		this.TotalCalculation();
	}

	OnChangeDamagedProduct() {
		let isApplicable = this._salesinvoicetaxproductform.get("isdamaged").value;
		if (isApplicable) {
			this._salesinvoicetaxproductform.controls['severity'].enable();
			this._salesinvoicetaxproductform.controls['severity'].setValue("MSC00197");
		}
		else {
			this._salesinvoicetaxproductform.controls['severity'].reset();
			this._salesinvoicetaxproductform.controls['severity'].disable();
		}
		this.GetVariantName();
	}

	OnChangeSeverity() {
		this.GetVariantName();
	}

	GetProductCategory(event: { value: any; }) {
		var selectedProductType = event.value;
		if (this._selectedcustomer == undefined || this._selectedlevel == undefined || selectedProductType == undefined) {
			this._CustomExceptionService.handleWarning("Please select customer");
			//this._salesinvoicetaxproductform.reset();
			this.InitializeProductForm();

			return;
		}
		if (selectedProductType.length == 0 || selectedProductType == null) {
			//this._salesinvoicetaxproductform.controls["productcategoryid"].reset();
			this._ProductCategories = [];
			this.productcatid = [];
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": this._branchid || 0 };
			this._SalesInvoiceTaxService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._ProductCategories = resultJSON.productcategories;
				//this._ProductNames = <Productoverallview[]>resultJSON.productoverallList || [];
				this._IsProgressSpinner = false;
			},
				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		// let productList = this._ProductNamesTemp.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
		// this._ProductNames = productList.length ? productList : this._ProductNamesTemp;
	}
	GetProductName(event: { value: any; }) {
		var selectedProductCategory = event.value;
		var isdamagedproduct = this._salesinvoicetaxproductform.get("isdamaged").value || false;
		this._ProductNames = this._ProductNamesTemp;
		if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
			// this._salesinvoicetaxproductform.reset();
			this._salesinvoicetaxproductform.reset();
			// this._ProductNames = [];
			// this._Variants = [];
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductCategoryObj = { "isdemageproduct": isdamagedproduct, "productcategory": selectedProductCategory || [], "branchid": this._branchid || 0 };
			this._SalesInvoiceTaxService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				//this._ProductNames = resultJSON.productsearch;
				this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
				this._IsProgressSpinner = false;
			},
				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
		// let productList = [];
		// if (this.productcatid.length) {
		//   productList = this._ProductNamesTemp.filter(f => this.producttypeid.includes(f.producttypeid) && this.productcatid.includes(f.productcategoryid)) || [];
		// } else {
		//   productList = this._ProductNamesTemp.filter(f => this.producttypeid.includes(f.producttypeid)) || [];
		// }
		// this._ProductNames = productList;
	}

	GetVariantName() {
		var selectedproduct = this._salesinvoicetaxproductform.get("productid").value.productid;
		this._selectedproduct = selectedproduct;
		this._salesinvoicetaxproductform.controls["variantid"].reset();
		this._Variants = [];
		let isDamaged = (this._salesinvoicetaxproductform.get("isdamaged").value == true ||
			this._salesinvoicetaxproductform.get("isdamaged").value == 1);
		let severity = this._salesinvoicetaxproductform.get("severity").value;
		if (!selectedproduct) {
			return;
		}

		this._IsProgressSpinner = true;
		let product = this.filteredProducts.filter(f => f.productid == selectedproduct)[0];
		this.producttypeid = [];
		this.producttypeid.push(product.producttypeid);
		this.productcatid = [];
		this.productcatid.push(product.productcategoryid);
		var uom = product.salesuom;
		this._salesinvoicetaxproductform.get("quantity").reset();
		if (uom == "MSC00037") {
			this.hidequantity = true;
		}
		else {
			this.hidequantity = false
		}
		this._salesinvoicetaxproductform.controls['producttypeid'].setValue(this.producttypeid);
		this._salesinvoicetaxproductform.controls['productcategoryid'].setValue(this.productcatid);
		var selectedbranch = this._salesinvoicetaxform.get("collectingbranch").value;
		this._SalesInvoiceTaxService.GetVariant(selectedproduct, selectedbranch, isDamaged, severity).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			if (isDamaged) {
				this._salesinvoicetaxproductform.controls['stockinhand'].setValue(0);
				if (resultJSON.collectingBranchDamagedStock) {
					this._salesinvoicetaxproductform.controls['stockinhand'].setValue(resultJSON.collectingBranchDamagedStock.damagedstock);
				}
			} else {
				if (!resultJSON.collectingBranchStock) {
					this._salesinvoicetaxproductform.controls['stockinhand'].setValue(0);
				}
				else {
					this._Branch = resultJSON.collectingBranchStock.stock1 || 0;
					this._salesinvoicetaxproductform.controls['stockinhand'].setValue(this._Branch);
				}
			}
			this._Variants = resultJSON.productvariants;
			this._ProductCategories = resultJSON.productcategories;
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	GetVariants(event: { value: any; }) {
		var selectedvariant = event.value;
		this._SelectedVariant = selectedvariant || 0;
	}

	GetProductPrice(event: any) {
		this._submitted1 = true;

		if (!this._salesinvoicetaxproductform.valid) {
			this._salesinvoicetaxproductform.markAllAsTouched();
			return
		}

		var quantity = 0;
		let existproduct = this._ProductDetails.filter(f => f.productid == this._salesinvoicetaxproductform.get("productid").value.productid);
		quantity = (this._salesinvoicetaxproductform.get("quantity").value || 0);
		if (existproduct.length > 0) {
			quantity = existproduct[0].quantity + (this._salesinvoicetaxproductform.get("quantity").value || 0)
		}

		let productid = this._salesinvoicetaxproductform.get("productid").value.productid;
		let variant = this._salesinvoicetaxproductform.get("variantid").value || 0;
		var IsDamaged = this._salesinvoicetaxproductform.get("isdamaged").value || false;
		var severity = this._salesinvoicetaxproductform.get("severity").value || null;
		var selectedbranch = this._salesinvoicetaxform.get("collectingbranch").value;
		var remainingatock = this._salesinvoicetaxproductform.get("stockinhand").value;
		//var isdamagedproduct = this._salesinvoicetaxproductform.get("isdamaged").value;
		let markuplevelid = sessionStorage["levelid"];
		let id = this._salesinvoicetaxform.get("currencyid").value;
		let selectedCurrencyList = (this._Currencies || []).filter(f => f.currencyid == id);
		let errorMsg = '';

		if (IsDamaged) {
			this._ProductDetails.every(element => {
				if (element.productid == productid && element.severity != severity) {
					errorMsg = 'Same Product Different Severity NOT Allowed';
				}
			});
		}

		this._ProductDetails.every(element => {
			if (element.isdamaged != IsDamaged) {
				errorMsg = 'Should NOT Allowed Damaged and OK Product in One Invoice';
			}
		});

		// if (!(resultJSON.salesproductprice || []).length) {
		//   errorMsg = 'No products found'
		// }
		if (!quantity) {
			errorMsg = 'Quantity Required'
		}
		else if (quantity < 0) {
			errorMsg = 'Quantity should not be in negative.'
		}

		if (!productid) {
			errorMsg = 'Choose Product'
		}
		if (!this._selectedcustomer) {
			errorMsg = 'Choose Customer'
		}
		if (!selectedCurrencyList.length)
			errorMsg = 'Please select currency'
		if (remainingatock < quantity && selectedbranch != this._branchid)
			errorMsg = 'Your Collecting Branch Stock Not In a Hand'

		if (this.ShowErrorMsg(errorMsg))
			return;
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.GetProductPrice(productid, variant, quantity, selectedbranch, this._selectedcustomer, this._userid, markuplevelid, IsDamaged, severity, this._branchid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._salesinvoicetaxform.controls['markuplevelid'].setValue(resultJSON.salesproductprice[0].markuplevel);
				//this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
				if (resultJSON.schemeremarks.length > 0) {
					this.dispalyBasicScheme = true
					this.Salesinvoiceresultjson = resultJSON;
					this.salesproductprice = 'salesproductprice';
					this.schemeRemarks = resultJSON.schemeremarks ? resultJSON.schemeremarks.map(m => m.SCHEMEREMARKS || []) : [""];
					this.schemeRemarks.length > 0 ? this.dispalyBasicScheme = true : this.dispalyBasicScheme = false;
					this.CloseProductTypeDialog(this.dispalyBasicScheme, this.Salesinvoiceresultjson, this.salesproductprice);
					return;
				} else {
					this.schemeRemarks = [""];
				}
				let producttypeid = this._ProductTypes.filter(f => (this.producttypeid || []).includes(f.producttypeid)).map(m => m.producttypeid);
				if (producttypeid.some(s => s == 17)) {
					this.displayBasicProductType = true;
					this.Salesinvoiceresultjson = resultJSON;
					this.salesproductprice = 'salesproductprice';
					this.CloseProductTypeDialog(this.displayBasicProductType, this.Salesinvoiceresultjson, this.salesproductprice);
				} else {
					this._advances = [];
					this._salesinvoicetaxform.controls["creditnoteapplicable"].setValue(false);
					this._salesinvoicetaxform.controls["creditnoteamount"].setValue(0);
					this._salesinvoicetaxform.controls["customeradvanceamount"].setValue(0);
					this._salesinvoicetaxform.controls["creditnoteapplicable"].enable();
					this._creditnoteids = [];
					this._IsdisabledAdvanceRef = false
					//this._salesinvoicetaxform.controls["creditnotebalanceamount"].setValue(0);
					//24-11-2022
					this._salesinvoicetaxform.get("creditnotebalanceamount").setValue(0);
					//
					this.AddProductToGrid(resultJSON, 'salesproductprice');
				}
				this._submitted1 = false;
				this._IsProgressSpinner = false;
			}
			else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
				this._IsProgressSpinner = false;
			}
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	CloseProductTypeDialog(displayBasicProductType, Salesinvoiceresultjson: any, salesproductprice: string) {
		if (displayBasicProductType) {
		}
		else {
			this.displayBasicProductType = false;
			this.dispalyBasicScheme = false;
			this.AddProductToGrid(Salesinvoiceresultjson, 'salesproductprice');
		}


	}
	AddProductToGrid(resultJSON: any, prop: string) {
		this._advances = [];
		this._salesinvoiceadvancereferences = [];
		this._salesinvoicetaxform.controls["customeradvanceamount"].setValue(0);
		let errorMsg = '';
		let customerwisediscount = null;
		this._salesinvoicetaxform.controls["applicablerewardpoints"].reset();
		this._salesinvoicetaxform.controls["applicablerewardpoints"].disable();
		this._salesinvoicetaxform.controls["applyrewardpoints"].setValue(false);
		this._salesinvoicetaxform.controls["applyrewardpoints"].enable();
		this._salesinvoicetaxform.controls["remainingpoints"].setValue(this._RemainingPoints);
		let currencyid = this._salesinvoicetaxform.get("currencyid").value;
		if (resultJSON.customerwisediscounts && resultJSON.customerwisediscounts.length) {
			customerwisediscount = resultJSON.customerwisediscounts[0];
		}
		let c_maxdiscountper = 0;
		if (customerwisediscount) {
			// c_maxdiscountper = customerwisediscount.discount || 0;
			c_maxdiscountper = currencyid == 1 ? customerwisediscount.usddiscount : currencyid == 2 ? customerwisediscount.randdiscount :
				currencyid == 3 ? customerwisediscount.bonddiscount : currencyid == 4 ? customerwisediscount.bankdiscount : 0;
		}
		let Productdetails = (<Salesinvoiceproductpriceview[]>resultJSON[prop]) || [];
		let p_quantity = this._salesinvoicetaxproductform.get("quantity").value || 0;
		let reference = this._salesinvoicetaxform.get("salesreference").value;
		let referenceno = this._salesinvoicetaxform.get("salesreferenceno").value;
		let userSelectedCurrency = this._CurrenciesTemp.find(f => f.currencyid == currencyid);
		let userselectedExchangeRate = userSelectedCurrency.exchangerate || 1;
		let customerid = this._salesinvoicetaxform.get("customerid").value;
		let customer = this._Customernames.find(f => f.customerid == customerid);
		let vatType = customer.vattype
		if (this.ShowErrorMsg(errorMsg))
			return;
		for (var p = 0; p < resultJSON[prop].length; p++) {
			// usd
			let product = Productdetails[p];
			let quantity = (p_quantity || product.quantity);

			let unitPrice = 0;
			if (!referenceno)
				unitPrice = currencyid == 1 ? product.usd : currencyid == 2 ? product.rand : currencyid == 3 ? product.bond : currencyid == 4 ? product.bank : userselectedExchangeRate * product.usd;
			else
				unitPrice = product.usd;
			if (product.vattype == "MSC00062") {
				this.unitprice = product.unitprice;
				product.tempunitprice1 = product.unitprice;
			}
			else {
				this.unitprice = unitPrice;
			}
			product.tempunitprice = unitPrice;

			let unitpriceWithoutTax = product.unitprice;
			let productAmountWithoutTax = quantity * unitpriceWithoutTax;
			let productAmount = quantity * unitPrice;
			let exculdeTaxAmount = (productAmount * (product.taxpercentage / 100));
			var taxAmount = 0;
			if (!referenceno) {
				if (product.vatapplicable)
					taxAmount = userselectedExchangeRate * (productAmountWithoutTax * (product.taxpercentage / 100));
			}
			else {
				if (product.vatapplicable) {
					taxAmount = product.taxamount;
				}
			}


			let app_discountamount = (productAmount * (product.customerdiscountpercentage / 100)) || 0;
			let def_discountamount = (productAmount * (product.discountpercentage / 100)) || 0;
			if ((product.iscshemeavailable)) {
				def_discountamount = 0;
			}
			let finalAmount = (productAmount) - (def_discountamount + app_discountamount)
			//Same Product Update the Property Start
			let existingProductIndex = this._ProductDetails.findIndex(f => f.productid == product.productid && f.variantid == product.variantid && f.producttype == product.producttype);
			if (existingProductIndex != -1) {

				if (this._ProductDetails[existingProductIndex].producttype == 'Free Product') {
					this._ProductDetails[existingProductIndex].quantity = parseFloat(product.quantity + '' || '0');
				}
				else {
					this._ProductDetails[existingProductIndex].quantity += (quantity);
					this._ProductDetails[existingProductIndex].quantity = parseFloat((this._ProductDetails[existingProductIndex].quantity).toFixed(3))

				}
				this._ProductDetails[existingProductIndex].productamount += productAmount;

				//new Update discount or tax
				product.customerdiscountpercentage = 0;
				product.customerdiscountamount = 0;
				app_discountamount = 0
				def_discountamount = 0
				finalAmount = this._ProductDetails[existingProductIndex].productamount - (def_discountamount + app_discountamount)
				productAmountWithoutTax = this._ProductDetails[existingProductIndex].quantity * unitpriceWithoutTax;
				taxAmount = userselectedExchangeRate * (productAmountWithoutTax * (product.taxpercentage / 100));
				//End
				this._ProductDetails[existingProductIndex].taxamount = taxAmount;
				if (product.vattype == "MSC00062")
					this._ProductDetails[existingProductIndex].finalamount = finalAmount;
				else
					this._ProductDetails[existingProductIndex].finalamount = finalAmount + taxAmount;
				this._ProductDetails[existingProductIndex].unitprice = unitPrice;
				this._ProductDetails[existingProductIndex].customerdiscountpercentage = 0;
				this._ProductDetails[existingProductIndex].customerdiscountamount = 0;
				product = this._ProductDetails[existingProductIndex]
				//Updated Object
				product.approveddiscountamount = product.approveddiscountamount || 0;
				product.approveddiscountpercentage = product.approveddiscountpercentage || 0;
				product.taxamount = this._ProductDetails[existingProductIndex].taxamount;
				product.discountamount = def_discountamount;
				product.approveddiscountamount = 0;
				product.approveddiscountpercentage = product.approveddiscountpercentage || 0;
				product.finalamount = this._ProductDetails[existingProductIndex].finalamount;
				product.quantity = this._ProductDetails[existingProductIndex].quantity
				product.productamount = this._ProductDetails[existingProductIndex].productamount;
				////Updated Object End
			}
			//Same Product Update the Property End
			else {
				if ((product.stock - (product.quantity + quantity)) < 0 && !reference) {
				}
				product.customerdiscountamount = product.customerdiscountamount || 0;
				product.approveddiscountamount = product.approveddiscountamount || 0;
				product.approveddiscountpercentage = product.approveddiscountpercentage || 0;
				product.unitprice = unitPrice;
				if (!referenceno) {
					product.taxamount = taxAmount;
				}
				if (product.vatapplicable)
					product.taxpercentage = Productdetails[p].taxpercentage;
				product.productamount = product.unitprice * quantity;
				product.discountamount = def_discountamount;
				product.customerdiscountamount = app_discountamount;
				product.approveddiscountamount = 0;
				product.customerdiscountpercentage = product.customerdiscountpercentage || 0;
				product.approveddiscountpercentage = product.approveddiscountpercentage || 0;
				product.disableFreeProductFeild = (product.producttype == "Free Product") || (product.iscshemeavailable);
				product.stocktemp = product.stock;
				product.isdamaged = this._salesinvoicetaxproductform.get("isdamaged").value;
				product.severity = this._salesinvoicetaxproductform.get("severity").value;
				product.cus_dispercentage = c_maxdiscountper;
				product.purchasepriceinusd = product.purchaserateinusd || 0;

				if (!referenceno) {
					product.finalamount = finalAmount;
					if (customer.vattype == "MSC00063") {
						product.finalamount += taxAmount;
					}
				}
				this._ProductDetails.push(product);
			}
			let producttype = product.producttype.toLowerCase();
			//Is This Product Scheme
			if (product.iscshemeavailable && producttype != 'free product') {
				this.allocatequantity(product);
			}
			//Other Wise  
			else {
				this.CalculateRewardPoints(product);
			}
			//Customer Wise Discount
			if (product.cus_dispercentage > 0) {
				product.customerdiscountpercentage = 0;
				product.disableFreeProductFeild = true;
				this.allocatequantity(product);
			} else {
				this.allocatequantity(product);
			}
			this.OnCustomerCreditChange(product);
		}
		this.TotalCalculation();
		this.InitializeProductForm();
		this.Clear(null);
		$("input[name='productbarcode']").focus();

		this.disableLaybuyOptions();
	}

	OnChangeDeliverymode(event) {
		if (event.checked) {
			this._salesinvoicetaxform.controls['deliverymode'].setValue(205);
		} else {
			this._salesinvoicetaxform.controls['deliverymode'].setValue(null);
		}
	}

	OnChangeAdvanceReferences() {
		this._salesinvoicetaxform.get("customeradvanceamount").setValue(0);
		let id = this._salesinvoicetaxform.get("currencyid").value;
		this._IsProgressSpinner = true;
		this.Multicurrencycalculation();
		this.DisabledAdvanceOrCredit();
		this._IsProgressSpinner = false;
		this._salesinvoiceadvancereferences = [];
		if (!this._advances.length) return;
		this._IsProgressSpinner = true;
		let selectedreferences = this._advancereferences.filter(f => this._advances.includes(f.refNo));
		let vouchers = [];
		selectedreferences.forEach(element => {
			vouchers.push(element.voucherno);
		});
		let data = {
			isCreditnote: false,
			currencyid: id,
			refnos: (vouchers || [])
		};
		this._SalesInvoiceTaxService.GetConversion(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._salesinvoiceadvancereferences = [];
				selectedreferences.forEach(element => {
					let amount = parseFloat(resultJSON.exchangeRate[element.voucherno]) * element.remainingamount;
					let _advancereference = new Salesinvoiceadvancereference();
					_advancereference.refNo = element.refNo;
					_advancereference.remainingamount = amount;
					_advancereference.voucherno = element.voucherno;
					_advancereference.currencyrefid = element.currencyuniquerefid;
					this._salesinvoiceadvancereferences.push(_advancereference);
				});
				this._salesinvoicetaxform.get("customeradvanceamount").setValue(resultJSON.converionAmount);
				this.Multicurrencycalculation();
				this.DisabledAdvanceOrCredit();

			}
			else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
			}
			this._IsProgressSpinner = false;
			this.isSaveClicked = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isSaveClicked = false; });
	}


	CheckCollectingBranchStock() {
		var selectedbranch = this._salesinvoicetaxform.get("collectingbranch").value;
		if (selectedbranch != this._branchid) {
			this._salesinvoicetaxform.get("deliveryrequired").setValue(true);
			this._salesinvoicetaxform.controls['deliverymode'].setValue(205);
		}
		else {
			this._salesinvoicetaxform.get("deliveryrequired").setValue(this._isDeliveryRequired);
			this.OnChangeDeliverymode({ checked: this._isDeliveryRequired });
		}
		let variant = this._salesinvoicetaxproductform.get("variantid").value || 0;
		this._selectedProduct = this._salesinvoicetaxproductform.get("productid").value.productid || 0;
		if (this._ProductDetails.length >= 1) {
			this.confirmationService.confirm({
				message: ' Existing products will clear once you change the collecting branch',
				accept: () => {
					this._IsProgressSpinner = true;
					this._SalesInvoiceTaxService.CheckCollectingBranchStock(this._selectedProduct, selectedbranch, variant).subscribe((result) => {
						const resultJSON = JSON.parse(JSON.stringify(result));
						this._ProductDetails = [];
						this._branchresult = resultJSON.collectingBranchStock;
						this._IsProgressSpinner = false;
						if (!this._branchresult || !this._branchresult.length) {
							return;
						}
						else {
							for (var i = 0; i < this._branchresult.length; i++) {
								if (this._salesinvoicetaxproductform.get("quantity").value > this._branchresult[i].stock1 || 0) {
									this._CustomExceptionService.handleWarning("stock not available");
									return;
								}

							}
						}
					},
						error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
				},
				reject: () => {
				}
			});
		}
	}


	OnchangeOthercurrency() {

	}
	Calculateothercurrency() {

	}
	TotalCalculation() {
		let customerid = this._salesinvoicetaxform.get("customerid").value;
		let finalAmountInUC = 0;
		let id = this._salesinvoicetaxform.get("currencyid").value;
		let creditAmount = parseFloat(this._salesinvoicetaxform.get("creditnoteamount").value || 0);
		let advanceamount = parseFloat(this._salesinvoicetaxform.get("customeradvanceamount").value || 0);
		let paidamount = parseFloat(this._salesinvoicetaxform.get("paidamount").value || 0);
		let creditnotebalanceamount = 0;
		//24-11-2022
		this._salesinvoicetaxform.get('creditnotebalanceamount').setValue(0);

		//
		let usdCurrency = (this._Currencies || []).find(f => f.currencyid == 1);
		let cusCurrency = (this._Currencies || []).find(f => f.currencyid == id);
		let totaldiscountamount = 0;
		let totalrewardpoints = 0;
		let otherCharges = 0, TotalAmount = 0, FinalTotalAmount = 0, FinalTaxAmout = 0, balance = 0, profitamountUSD = 0, purchaseAmountUSD = 0;
		(this._ProductDetails || []).forEach((item) => {
			TotalAmount += (item.finalamount);
			FinalTotalAmount += item.finalamount;
			FinalTaxAmout += item.taxamount;
			purchaseAmountUSD += (item.purchaserateinusd * (0 || item.quantity));
			// totaldiscountamount += parseFloat(item.totaldiscountamountapplied + '' || '0');
			totaldiscountamount += item.totaldiscountamountapplied || 0;
			totalrewardpoints += item.rewardpointsapplied || 0;
		});

		(this._OtherCharges || []).forEach((item) => {
			otherCharges += item.otherchargesamount || 0;
		});
		this._finalrewardamount = 0;
		let netamountUSD = (usdCurrency.exchangerate / cusCurrency.exchangerate) * (FinalTotalAmount);
		let rewardsValue = this.GetrewardsValue(netamountUSD);

		var applicablerewardpoints = this._salesinvoicetaxform.get("applicablerewardpoints").value;
		if (this.reward && applicablerewardpoints) {
			let applicablerewardamount = Math.floor(applicablerewardpoints / rewardsValue);
			//this._customercurrencyrewardpoint = rewardpoints * rewardsValue;
			this._finalrewardamount = applicablerewardamount * cusCurrency.exchangerate;
			//FinalTotalAmount -= this._finalrewardamount;
		} FinalTotalAmount += (otherCharges);
		//FinalTotalAmount -= creditAmount;
		//var NetAmountInUSD = (FinalTotalAmount) / ((userSelectedCurrency.exchangerate));
		creditnotebalanceamount = this.Fixed2(creditAmount - FinalTotalAmount);
		//this._exchangerate = userSelectedCurrency.exchangerate;
		finalAmountInUC = FinalTotalAmount - (creditAmount + this._finalrewardamount + advanceamount);

		// if (finalAmountInUC <= 0) finalAmountInUC = 0;
		//this._salesinvoicetaxform.get('cuscurrencyid1').enable();
		this._salesinvoicetaxform.get('cuscurrencyamount1').enable();
		this._salesinvoicetaxform.get('cuscurrencyid2').enable();
		this._salesinvoicetaxform.get('cuscurrencyamount2').enable();
		if (finalAmountInUC <= 0) {
			finalAmountInUC = 0;

			this._salesinvoicetaxform.get('cuscurrencyid1').disable();
			this._salesinvoicetaxform.get('cuscurrencyamount1').disable();
			this._salesinvoicetaxform.get('cuscurrencyid2').disable();
			this._salesinvoicetaxform.get('cuscurrencyamount2').disable();
		}

		var NetAmountInUSD = (usdCurrency.exchangerate / cusCurrency.exchangerate) * (finalAmountInUC);
		balance = paidamount - finalAmountInUC;

		let netamnt = (usdCurrency.exchangerate / cusCurrency.exchangerate) * (FinalTotalAmount);

		profitamountUSD = (netamnt - purchaseAmountUSD);

		this._salesinvoicetaxform.controls['salesinvoiceamount'].setValue(FinalTotalAmount.toFixed(2));

		this._salesinvoicetaxform.get('productamount').setValue((FinalTotalAmount - FinalTaxAmout).toFixed(2)); // Gross Amount
		this._salesinvoicetaxform.get('taxamount').setValue(FinalTaxAmout.toFixed(2));
		this._salesinvoicetaxform.controls['otherchargesamount'].setValue(otherCharges.toFixed(2));
		this._salesinvoicetaxform.get('purchaseamount').setValue(purchaseAmountUSD.toFixed(2));
		this._salesinvoicetaxform.get('profitamount').setValue(profitamountUSD.toFixed(2));
		this._salesinvoicetaxform.get('amountinusd').setValue(NetAmountInUSD.toFixed(2));
		this._salesinvoicetaxform.get('netamountincustomercurrency').setValue(finalAmountInUC.toFixed(2));
		this._salesinvoicetaxform.get('appliedrewardamount').setValue(this._finalrewardamount || 0);
		this._salesinvoicetaxform.get("discountamount").setValue(totaldiscountamount);
		this._salesinvoicetaxform.get("gatheredrewardpoints").setValue(totalrewardpoints);

		if (this._salesinvoicetaxform.get("salesreference").value == "MSC00221") {
			balance = 0;
		}

		this._salesinvoicetaxform.controls['balanceamount'].setValue(balance.toFixed(2));
		this._salesinvoicetaxform.controls['customerbalanceamount'].setValue(balance.toFixed(2));

		// if (id == 4) {
		//   this._salesinvoicetaxform.get('returncurrencyid').disable();
		// }

		this.tempcreditnotebalanceamount = 0;
		if (creditnotebalanceamount > 0 && creditAmount > 0) {
			if (this._action != 'view') {
				this._CustomExceptionService.handleWarning("Please ensure to pay credit note balance amount to customer.")
			}
			this._salesinvoicetaxform.get("creditnotebalanceamount").setValue(creditnotebalanceamount);

			this.tempcreditnotebalanceamount = creditnotebalanceamount;

			//this._salesinvoicetaxform.get('returncurrencyid').enable();
		}

		this.isDisabledinvoiceslip = true;
		this.isDisabledprint = true;
		this.isDisabledsendtoapproval = true;

		this._salesinvoicetaxform.get("returnamount").setValue(balance.toFixed(2));
		this.ChangeReturnCurrency();
	}

	Fixed2(value: number): number {
		if (!value) return 0;

		return parseFloat(value.toFixed(2));
	}

	GetrewardsValue(netmount: number): number {
		let id = this._salesinvoicetaxform.get("currencyid").value;
		let rewardsValue = 0;
		if (this.reward) {
			let netamountUSD = (this.GetExchangeRate(1) / this.GetExchangeRate(id)) * (netmount);
			if (this.reward.minimumamount > 0 && this.reward.maximumamount > 0) {
				if (netmount >= this.reward.minimumamount && netmount <= this.reward.maximumamount) {
					if (this.reward.rewardsvalue)
						rewardsValue = this.reward.rewardsvalue;
				}
				else if (this.reward.maximumamount > 0 && netmount <= this.reward.maximumamount) {
					if (this.reward.rewardsvalue)
						rewardsValue = this.reward.rewardsvalue;
				}
				else if (this.reward.minimumamount > 0 && netmount >= this.reward.minimumamount) {
					if (this.reward.rewardsvalue)
						rewardsValue = this.reward.rewardsvalue;
				}
			}
		}
		return rewardsValue;
	}

	RemoveProduct(pProduct: Salesinvoiceproductpriceview) {
		var index = this._ProductDetails.indexOf(pProduct);
		this._ProductDetails.splice(index, 1);

		this._salesinvoicetaxform.get("cuscurrencyid2").reset();
		this._salesinvoicetaxform.get("cuscurrencyamount1").reset();
		this._salesinvoicetaxform.get("cuscurrencyamount2").reset();

		this._advances = [];
		this._salesinvoicetaxform.controls["creditnoteapplicable"].setValue(false);
		this._salesinvoicetaxform.controls["creditnoteamount"].setValue(0);
		this._salesinvoicetaxform.controls["customeradvanceamount"].setValue(0);
		this._salesinvoicetaxform.controls["creditnoteapplicable"].enable();
		this._creditnoteids = [];
		this._IsdisabledAdvanceRef = false
		this.Multicurrencycalculation();
		this.OnCustomerReduceCreditChange(pProduct);

		// this.DisabledAdvanceOrCredit();
	}

	CalculateQuantity() {
		if (!this._Stock) {
			this._Stock = 0;
		}
		var Quantity = this._salesinvoicetaxproductform.get("quantity").value;
		var remainingstock = this._Stock - Quantity;
		this._salesinvoicetaxproductform.controls['remainingstock'].setValue(remainingstock);
	}
	// addproductdetail(){
	//   this.AddProductToGrid(resultJSON:any);
	// }
	Multicurrencycalculation() {

		let usernetamount = parseFloat(this._salesinvoicetaxform.get("netamountincustomercurrency").value || 0);


		let id = this._salesinvoicetaxform.get("currencyid").value;
		let userSelectedCurrency = (this._Currencies || []).filter(f => f.currencyid == id)[0].exchangerate;
		var PaidAmount = this._salesinvoicetaxform.get("cuscurrencyamount1").value || 0;
		let cuscurrencyid1 = this._salesinvoicetaxform.get("cuscurrencyid1").value || 0;
		let cuscurrencyid2 = this._salesinvoicetaxform.get("cuscurrencyid2").value || 0;

		if (!cuscurrencyid1)
			return;

		//let cutomerselectedcurrency1 = this.GetExchangeRate(currencyid1);
		//var netamountinCustomerinusd = this._salesinvoicetaxform.get('netamountincustomercurrency').value;

		//var CustomerCurrencyRate1 = netamountinCustomerinusd * cutomerselectedcurrency1;

		let usercurrencyamount = this.GetUserAmount();

		let balanceamount = (usercurrencyamount - usernetamount);
		this._salesinvoicetaxform.controls["paidamount"].setValue(usercurrencyamount.toFixed(2));

		this._salesinvoicetaxform.controls["customerbalanceamount"].setValue(balanceamount.toFixed(2));

		// if (cuscurrencyid2) {
		//   let cutomerselectedcurrency2 = (this._Multicurrencies2 || []).filter(f => f.currencyid == cuscurrencyid2)[0].exchangerate || 0;
		//   var balanceamt = this._salesinvoicetaxform.get("customerbalanceamount").value || 0;
		//   var convertusd = balanceamt / cutomerselectedcurrency1;
		//   var customercurrency2 = convertusd * cutomerselectedcurrency2;
		//   this._salesinvoicetaxform.controls["customerbalanceamount"].setValue(customercurrency2.toFixed(2))
		//   var PaidAmount1 = this._salesinvoicetaxform.get("cuscurrencyamount2").value || 0;
		//   var CustomerBalanceAmount = customercurrency2 - PaidAmount1;
		//   this._salesinvoicetaxform.controls["customerbalanceamount"].setValue(CustomerBalanceAmount.toFixed(2));
		//   if (PaidAmount1) {
		//     var convertusd2 = PaidAmount1 / cutomerselectedcurrency2;
		//     var finalAmountInUC1 = convertusd2 * userSelectedCurrency;
		//     var totalamount = finalAmountInUC + finalAmountInUC1;
		//     this._salesinvoicetaxform.controls["paidamount"].setValue(totalamount.toFixed(2));
		//   }
		// }
		this._salesinvoicetaxform.controls["balanceamount"].setValue(balanceamount.toFixed(2));
		this.TotalCalculation();


		this._ReturnCurrencies = [];
		if (cuscurrencyid1) {
			let currency: Currency = this._Currencies.find(f => f.currencyid == cuscurrencyid1);
			if (currency) {
				this._ReturnCurrencies.push(currency);
			}
		}
		if (cuscurrencyid2) {
			let currency: Currency = this._Currencies.find(f => f.currencyid == cuscurrencyid2);
			if (currency) {
				this._ReturnCurrencies.push(currency);
			}
		}
	}

	ChangeReturnCurrency() {
		let balanceamount = parseFloat(this._salesinvoicetaxform.get("balanceamount").value || 0);
		let id = this._salesinvoicetaxform.get("currencyid").value;
		let creditbalanceamount = this.tempcreditnotebalanceamount;
		let returncurrencyid = this._salesinvoicetaxform.get("returncurrencyid").value;

		if (id == 4) return;

		this._salesinvoicetaxform.get("returnamount").setValue(balanceamount);
		this._salesinvoicetaxform.get("creditnotebalanceamount").setValue(creditbalanceamount);

		if (id != returncurrencyid) {
			let rerate1 = 0;
			let exrate1 = 0;
			let returnCurrency = (this._Currencies || []).find(f => f.currencyid == returncurrencyid);
			let currency = (this._Currencies || []).find(f => f.currencyid == id);

			if (currency)
				exrate1 = currency.exchangerate;
			if (returnCurrency)
				rerate1 = returnCurrency.exchangerate;

			if (balanceamount > 0) {
				balanceamount = ((rerate1 / exrate1) * balanceamount);
				this._salesinvoicetaxform.get("returnamount").setValue(balanceamount.toFixed(3));
			}
			else {
				creditbalanceamount = ((rerate1 / exrate1) * creditbalanceamount);
				this._salesinvoicetaxform.get("creditnotebalanceamount").setValue(creditbalanceamount.toFixed(3));
			}

		}
	}

	CalculateCurrencyAmount1(event) {
		this.Multicurrencycalculation();
	}
	onSelectCurrency2(event) {
		this.Multicurrencycalculation();
	}
	CalculateCurrencyAmount2(event) {
		this.Multicurrencycalculation();
	}
	onSelectCurrency(event) {
		this.Multicurrencycalculation();
	}
	//Quotationbased Calculation:
	QuotationBasedCalculate() {
		//this._salesinvoicetaxform.get("deliveryrequired").disable;
		for (var i in this._ProductDetails) {
			this.row = this._ProductDetails[i];

			this.allocatequantity(this.row);
		}
	}

	OnChangeUnitPrice(_ProductDetail: Salesinvoiceproductpriceview) {
		let currencyid = this._salesinvoicetaxform.get("currencyid").value;
		let referenceno = this._salesinvoicetaxform.get("salesreferenceno").value;
		_ProductDetail.unitprice = parseFloat(_ProductDetail.unitprice + '');
		if (_ProductDetail.unitprice < _ProductDetail.tempunitprice) {
			this._CustomExceptionService.handleWarning("Unit price should not less than actual price.");
			_ProductDetail.unitprice = _ProductDetail.tempunitprice;
		}
		let userSelectedCurrency = this._Currencies.find(f => f.currencyid == currencyid);
		let userselectedExchangeRate = userSelectedCurrency.exchangerate;
		this.unitprice = _ProductDetail.unitprice / userselectedExchangeRate;
		this._unitpricechange = 1;

		if (!referenceno) {
			switch (currencyid) {
				case 2:
					_ProductDetail.rand = _ProductDetail.unitprice;
					break;
				case 3:
					_ProductDetail.bond = _ProductDetail.unitprice;
					break
				case 4:
					_ProductDetail.bank = _ProductDetail.unitprice;
					break;
				default:
				case 1:
					_ProductDetail.usd = _ProductDetail.unitprice;
					break
			}
		}

		this.allocatequantity(_ProductDetail);
	}


	allocatequantity(row: Salesinvoiceproductpriceview) {
		//this.Calculate(row);
		if (row.customerdiscountpercentage < 0) {
			row.customerdiscountpercentage = 0;
			return;
		}
		let userdiscountpercentage = parseFloat(sessionStorage["userdiscountpercentage"] || 0);
		let referenceno = this._salesinvoicetaxform.get("salesreferenceno").value;
		let customerid = this._salesinvoicetaxform.get("customerid").value;
		let customer = this._Customernames.find(f => f.customerid == customerid);

		if (row.disallownegativeqty && row.quantity > row.stocktemp) {
			this._CustomExceptionService.handleWarning("Available stock at this branch " + row.stocktemp);
			row.quantity = row.stocktemp;
		}

		row.rewardpointsapplied = 0;
		let scheme_discount = 0;
		let cus_dispercentage = 0;
		let applyScheme = false;
		//Scheme
		if (row.iscshemeavailable) {
			// if (row.minimumqty > 0 && row.maximumqty > 0) {
			//   if (row.quantity >= row.minimumqty && row.quantity <= row.maximumqty)
			//     applyScheme = true;
			// } else if (row.minimumqty > 0) {
			//   if (row.quantity >= row.minimumqty)
			//     applyScheme = true;
			// } else if (row.maximumqty > 0) {
			//   if (row.quantity <= row.maximumqty)
			//     applyScheme = true;
			// }
			if (row.minimumqty > 0) {
				if (row.quantity >= row.minimumqty)
					applyScheme = true;
			}
			if (applyScheme) {
				scheme_discount = row.sch_discountpercentage;
				row.rewardpointsapplied = row.rewardpoints;
			}
			row.iscshemeapplied = applyScheme;
		}

		//Not a Scheme
		if (!row.iscshemeavailable || !applyScheme) {
			if (row.customerdiscountpercentage > row.maximumdiscountpercentage) {
				row.customerdiscountpercentage = 0;
				this._CustomExceptionService.handleWarning("Not a applicable discount.");
			} else {
				let maxdiscount = row.cus_dispercentage > userdiscountpercentage ? row.cus_dispercentage : userdiscountpercentage;
				//User DiscountPercentage
				if ((row.customerdiscountpercentage > userdiscountpercentage)) {
					this._CustomExceptionService.handleWarning("This document sent for approval since requested discount greater that user discount.")
				}
				// else if (row.cus_dispercentage > 0 && row.customerdiscountpercentage > row.cus_dispercentage) {
				//   this._CustomExceptionService.handleWarning("This document sent for approval since requested discount greater that customer discount.")
				// }
				//Customer Wise Discount
				else if (row.cus_dispercentage > 0) {
					// this._CustomExceptionService.handleWarning("This Document Sent for Approval Since the requested Customer Discount is grater than Product Discount");
					row.customerdiscountpercentage = row.cus_dispercentage;
				}
				//Manual Enter Discount
				else {
					cus_dispercentage = row.customerdiscountpercentage
				}
				cus_dispercentage = row.customerdiscountpercentage;
			}
		}


		let customerwisedis = 0;
		if (!cus_dispercentage) {
			customerwisedis = row.discountpercentage;
		}
		let currencyid = this._salesinvoicetaxform.get("currencyid").value;
		let userSelectedCurrency = this._Currencies.find(f => f.currencyid == currencyid);
		let userselectedExchangeRate = userSelectedCurrency.exchangerate;
		let unitPrice = 0;

		if (!referenceno)
			if (row.vattype == "MSC00062") {
				// var unitPrice1 = userselectedExchangeRate * this.unitprice;
				var unitPrice1 = userselectedExchangeRate * row.tempunitprice1;
				unitPrice = currencyid == 1 ? row.usd : currencyid == 2 ? row.rand : currencyid == 3 ? row.bond : currencyid == 4 ? row.bank : userselectedExchangeRate * row.usd;
			}
			else {
				unitPrice = currencyid == 1 ? row.usd : currencyid == 2 ? row.rand : currencyid == 3 ? row.bond : currencyid == 4 ? row.bank : userselectedExchangeRate * row.usd;
			}
		else {
			unitPrice = row.usd;
		}

		if (this._unitpricechange == 1) {
			unitPrice1 = userselectedExchangeRate * this.unitprice;
			unitPrice = unitPrice1;
		}

		if (row.vattype == "MSC00062") {
			var productAmount1 = row.quantity * unitPrice1;
			var productAmount = row.quantity * unitPrice;
		}
		else {
			productAmount = row.quantity * unitPrice;
		}
		let app_discountamount = (productAmount * (cus_dispercentage / 100)) || 0;
		let cuswise_discountamount = (productAmount * (customerwisedis / 100)) || 0;
		let def_discountamount = 0;
		let sch_discountamount = (productAmount * (scheme_discount / 100)) || 0;
		let totalDiscounts = 0


		//Scheme Discounts applicable created by rajesh
		if ((row.iscshemeavailable || applyScheme) && scheme_discount > 0 && (row.sch_discountpercentage > row.cus_dispercentage)) {
			totalDiscounts = sch_discountamount;
			row.customerdiscountamount = sch_discountamount;
			row.customerdiscountpercentage = row.sch_discountpercentage;
			this._CustomExceptionService.handleWarning("This Document Sent for Approval Since the applied Scheme Discount Greater than Product Discount")
		}
		//Customer Wise Discount
		else if (row.cus_dispercentage > 0 && (row.cus_dispercentage > row.sch_discountpercentage)) {
			totalDiscounts = (productAmount * (row.cus_dispercentage / 100)) || 0;
			row.customerdiscountamount = totalDiscounts
			row.customerdiscountpercentage = row.cus_dispercentage;
			row.iscshemeapplied = false;
			this._CustomExceptionService.handleWarning("This Document Sent for Approval Since the requested Customer Discount is grater than Product Discount");
		}
		//Manual Discount
		else if (!row.iscshemeavailable) {
			totalDiscounts = (def_discountamount + app_discountamount + sch_discountamount + cuswise_discountamount)
			row.customerdiscountamount = totalDiscounts
		}
		var taxAmount = 0;
		if (row.vattype == "MSC00062") {
			var productwithDiscountAmount1 = productAmount1 - totalDiscounts;
			var productwithDiscountAmount = productAmount - totalDiscounts;
			if (row.vatapplicable)
				if (!referenceno)
					taxAmount = (productwithDiscountAmount1 * (row.taxpercentage / 100));
				else
					taxAmount = row.taxamount;
		}
		else {
			productwithDiscountAmount = productAmount - totalDiscounts;
			if (row.vatapplicable)
				if (!referenceno)
					taxAmount = (productwithDiscountAmount * (row.taxpercentage / 100));
				else
					taxAmount = row.taxamount;
		}
		row.stock = (row.stocktemp - row.quantity);
		row.productamount = productAmount;
		row.discountamount = def_discountamount;
		//  else{
		//  row.customerdiscountamount = app_discountamount;
		// } 
		row.taxamount = taxAmount;
		let finalAmount = (productAmount) - totalDiscounts;
		row.finalamount = finalAmount;
		row.totaldiscountamountapplied = totalDiscounts;
		//if (!referenceno) {
		if (customer.vattype == "MSC00063") {
			row.finalamount += taxAmount;
		}
		//}
		if (!applyScheme) {
			this.CalculateRewardPoints(row);
		}
		//Update the row object in main List
		let existingProductIndex = this._ProductDetails.findIndex(f => f.productid == row.productid && f.variantid == row.variantid && f.producttype == row.producttype);
		this._ProductDetails[existingProductIndex] = row;
		this.TotalCalculation();
		this._unitpricechange = 0;
	}

	CalculateRewardPoints(product: Salesinvoiceproductpriceview) {
		if (!product || !this.reward)
			return;

		let rewardPoint = 0;
		if (this.reward && this.reward.rewardsvalue) {
			rewardPoint = this.reward.rewardsvalue;
		}

		let currencyid = this._salesinvoicetaxform.get("currencyid").value;
		let usdCurrency = (this._Currencies || []).find(f => f.currencyid == 1);
		let cusCurrency = (this._Currencies || []).find(f => f.currencyid == currencyid);

		let exrate = 1;
		if (currencyid != 1) {
			exrate = (usdCurrency.exchangerate / cusCurrency.exchangerate);
		}

		// let sellingprice = (exrate * (product.finalamount - (product.taxamount || 0)));
		let sellingprice: number = 0;
		let customerincludeorexclude: boolean = this._Customernames.some(f => f.customerid == this._salesinvoicetaxform.get("customerid").value && f.vattype == "MSC00062");
		if (customerincludeorexclude) {
			sellingprice = (exrate * (product.productamount - (product.taxamount || 0)));
		} else {
			sellingprice = (exrate * (product.productamount));
		}
		let costprice = (exrate * (product.purchaserate * product.quantity));
		let profit = (sellingprice - costprice);
		let margin = Math.round((profit / costprice) * 100);
		let point = 0;
		if (margin >= 30) {
			point = (profit * (margin / 1000));
		}

		if (product.customerdiscountpercentage == 0 && product.approveddiscountpercentage == 0) {
			let existingProductIndex = this._ProductDetails.findIndex(f => f.productid == product.productid && f.variantid == product.variantid && f.producttype == product.producttype);
			if (existingProductIndex != -1) {
				this._ProductDetails[existingProductIndex].rewardpointsapplied = (point * rewardPoint);
				product.rewardpointsapplied = (point * rewardPoint);

			}
			else {
				product.rewardpointsapplied = (point * rewardPoint);
			}

		}
	}

	CalculateRequestdiscount(row: Salesinvoiceproductpriceview) {
		// if(row.requestdiscountpercentage > 0){
		//row.productdiscountpercentage = 0;
		row.discountamount = 0;
		let taxAmount = (row.productamount * (row.taxpercentage / 100));
		let finalAmount = row.productamount;
		var userdiscount = parseInt(sessionStorage["userdiscountpercentage"]);
		if (row.customerdiscountpercentage > row.maximumdiscountpercentage) {
			this._CustomExceptionService.handleWarning("Customer discount percentage should not greater than product discount percentage");
			row.customerdiscountpercentage = 0;

			// row.defaultdiscountamount = (row.productamount * (row.productdiscountpercentage / 100));
			// finalAmount = (row.productamount + taxAmount) - row.defaultdiscountamount;

			// row.finalamount = finalAmount;
			// this.TotalCalculation();
			return;
		}

		if (row.customerdiscountpercentage > row.discountpercentage || row.customerdiscountpercentage > userdiscount) {

			row.customerdiscountamount = (row.productamount * (row.customerdiscountpercentage / 100));
			finalAmount = (row.productamount) - row.customerdiscountamount;

			row.finalamount = finalAmount;
			this.TotalCalculation();
			//errorMsg = 'Request discount not exceeds to User applicable markup level --> ' + row.maximumdiscountpercentage;
			this.confirmationService.confirm({
				message: 'Your discount percentage exceeds allocated percentage.so this document go for approval<b>"' + row.customerdiscountpercentage + '"</b> ?',
				accept: () => {
					//row.productdiscountpercentage = 0;
					row.customerdiscountamount = (row.productamount * (row.customerdiscountpercentage / 100));
					finalAmount = (row.productamount) - row.customerdiscountamount;
					row.finalamount = finalAmount;
					this.TotalCalculation();

					// if (row.requestdiscountpercentage <= 0) {
					//   row.defaultdiscountamount = (row.productamount * (row.productdiscountpercentage / 100));
					//   finalAmount = (row.productamount + taxAmount) - row.defaultdiscountamount;

					//   row.finalamount = finalAmount;
					//   this.TotalCalculation();
					// }
				},
				reject: () => {
					row.customerdiscountpercentage = 0;
					row.customerdiscountamount = (row.productamount * (row.customerdiscountpercentage / 100));
					finalAmount = (row.productamount) - row.customerdiscountamount;
					row.finalamount = finalAmount;
					this.TotalCalculation();

					if (row.customerdiscountpercentage <= 0) {
						row.customerdiscountamount = 0;
						row.discountamount = (row.productamount * (row.discountpercentage / 100));
						finalAmount = (row.productamount) - row.discountamount;

						row.finalamount = finalAmount;
						this.TotalCalculation();
					}
				}
			});
		}

		row.customerdiscountamount = (row.productamount * (row.customerdiscountpercentage / 100));
		finalAmount = (row.productamount) - row.customerdiscountamount;
		row.finalamount = finalAmount;
		this.TotalCalculation();

		if (row.customerdiscountpercentage <= 0) {
			row.discountamount = (row.productamount * (row.discountpercentage / 100));
			finalAmount = (row.productamount) - row.discountamount;

			row.finalamount = finalAmount;
			this.TotalCalculation();
		}
		// }
		// this.TotalCalculation();
	}

	Clear(event: any) {
		this._salesinvoicetaxproductform.reset();
		// this._salesinvoicetaxproductform.controls[''];
		this._ProductCategories = [];
		this.productcatid = [];

		this.InitializeProductForm();
	}

	showBasicDialog() {
		this.ManageDialog = true;
	}

	//Customers Quick save
	Save() {
		this._customersubmitted = true;
		if (this._customerform.valid) {
			this._Customer = this._customerform.value;
			this._Customer.customerid = 0;
			this._Customer.currencyid = 1;
			this._Customer.createdon = new Date();
			this._Customer.createdby = sessionStorage["userid"];
			this._Customer.levelid = sessionStorage["levelid"];
			this._Customer.branchid = sessionStorage["currentbranchid"];

			//this.onEd();
			this._IsProgressSpinner = true;
			this._SalesInvoiceTaxService.customercreate(this._Customer).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._Customernames = resultJSON.customers;
				if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
					this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
					this.Cancel();
					this._IsProgressSpinner = false;
				}
				else {
					this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
					this._IsProgressSpinner = false;
				}
			},
				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}
	Cancel() {
		this._customersubmitted = false;
		this.ManageDialog = false;
		this._customerform.reset();
	}
	//Sales Invoice Tax Save



	onSave() {

		if (this.isSaveClicked) return;
		this._submitted = true;
		let salesBy = this._salesinvoicetaxform.get("salesby").value;
		let Paymenttype = this._salesinvoicetaxform.get("paymenttype").value;
		let ledger = this._salesinvoicetaxform.get("bankledgerid").value;
		let refCode = this._salesinvoicetaxform.get("salesreference").value;
		let returnAmount = parseFloat(this._salesinvoicetaxform.get("returnamount").value || 0);
		let returncurrencyid = this._salesinvoicetaxform.get("returncurrencyid").value;
		let customerpaidamount = parseFloat(this._salesinvoicetaxform.get("paidamount").value || 0);
		let customernetamount = parseFloat(this._salesinvoicetaxform.get("netamountincustomercurrency").value || 0);
		if ((!salesBy || !Paymenttype) && refCode != "MSC00221") {
			this.selectedtab = 1;
			this._CustomExceptionService.handleWarning("Please Select Payment type.");
			return;
		}
		let paymodecode = this._salesinvoicetaxform.get("paymenttype").value;
		if (paymodecode != "MSC00146" && refCode != "MSC00221") {
			var selectedpaymentcode = this._salesinvoicetaxform.get("bankledgerid").value;
			if (!selectedpaymentcode && customernetamount > 0) {
				this._CustomExceptionService.handleWarning("Please select bank");
				return;
			}
		}

		if (this.ValidateCurrency())
			return;

		if (this._salesinvoicetaxform.valid) {
			//For Model and Form Mapping
			if (this._ProductDetails.length <= 0) {
				this._CustomExceptionService.handleWarning("Please add atleast one product");
				return;
			}
			//Advance Validation 
			let salesreference = this._salesinvoicetaxform.get("salesreference").value || 0;
			if (this._advancereferences.length && salesreference != "MSC00221") {
				if (customernetamount > 0) {
					if (this._salesinvoiceadvancereferences.length == 0) {
						this._CustomExceptionService.handleWarning("This customer having Advance Amount, Kindly use it first");
						return;
					}
				}
				if (customernetamount > 0) {
					let totaladvanceamount: number = 0,
						selectedadvanceamount: number = 0
					for (var i = 0; i < this._advancereferences.length; i++) {
						totaladvanceamount += parseFloat(this._advancereferences[i].remainingamount.toFixed(2));
					}
					// for (var i = 0; i < this._salesinvoiceadvancereferences.length; i++) {
					//   selectedadvanceamount += this._salesinvoiceadvancereferences[i].remainingamount;
					// }
					if (totaladvanceamount < customernetamount && customernetamount > 0 && this._advancereferences.length != this._salesinvoiceadvancereferences.length) {
						this._CustomExceptionService.handleWarning("Kindly Choose Another Advance");
						return;
					}
					if (totaladvanceamount > customernetamount && customernetamount > 0 && this._advancereferences.length != this._salesinvoiceadvancereferences.length) {
						this._CustomExceptionService.handleWarning("Kindly Choose Another Advance");
						return;
					}
				}
			}
			//

			if (sessionStorage["currentbranchid"] != this._salesinvoicetaxform.get("collectingbranch").value) {
				this._isCollectingBranchChanged = true;
			}

			this._SalesInvoicetax = this._salesinvoicetaxform.getRawValue();
			if (this._action == 'create') {
				this._SalesInvoicetax.createdby = sessionStorage["userid"];
				this._SalesInvoicetax.createdon = new Date();
				this._SalesInvoicetax.modifiedby = sessionStorage["userid"];
				this._SalesInvoicetax.modifiedon = new Date();
				this._SalesInvoicetax.accountingyear = new Date().getFullYear();
				this._SalesInvoicetax.branchid = sessionStorage["currentbranchid"];
				this._SalesInvoicetax.ispossales = false;
				this.SalesinvoiceRewardDetailslist = [];
				if (this._SalesInvoicetax.applyrewardpoints && this._SalesInvoicetax.applicablerewardpoints > 0) {
					let salesinvoiceRewardDetails = new Salesinvoicerewarddetail();
					salesinvoiceRewardDetails.salesinvoicerewardid = 0
					salesinvoiceRewardDetails.salesinvoiceid = this._SalesInvoicetax.salesinvoiceid;
					salesinvoiceRewardDetails.salesinvoicedate = this._SalesInvoicetax.salesinvoicedate;
					salesinvoiceRewardDetails.customerid = this._salesinvoicetaxform.get("customerid").value;
					salesinvoiceRewardDetails.effectivefrom = this._SalesInvoicetax.salesinvoicedate;
					salesinvoiceRewardDetails.effectiveto = new Date(moment(this._SalesInvoicetax.salesinvoicedate).add(30, 'days').format('YYYY-MM-DD HH:mm:ss'));
					salesinvoiceRewardDetails.expirydate = salesinvoiceRewardDetails.effectiveto
					salesinvoiceRewardDetails.rewardpoints = this._SalesInvoicetax.applicablerewardpoints;
					salesinvoiceRewardDetails.createdby = sessionStorage["userid"];
					salesinvoiceRewardDetails.createdon = new Date();
					salesinvoiceRewardDetails.rewardstatus = "MSC00302";
					this.SalesinvoiceRewardDetailslist.push(salesinvoiceRewardDetails);
					this._SalesInvoicetax.gatheredrewardpoints = 0
				}
				if (this._SalesInvoicetax.gatheredrewardpoints) {
					let salesinvoiceRewardDetails = new Salesinvoicerewarddetail();
					this.SalesinvoiceRewardDetailslist = [];
					salesinvoiceRewardDetails.salesinvoicerewardid = 0
					salesinvoiceRewardDetails.salesinvoiceid = this._SalesInvoicetax.salesinvoiceid;
					salesinvoiceRewardDetails.salesinvoicedate = this._SalesInvoicetax.salesinvoicedate;
					salesinvoiceRewardDetails.customerid = this._salesinvoicetaxform.get("customerid").value;
					salesinvoiceRewardDetails.effectivefrom = this._SalesInvoicetax.salesinvoicedate;
					salesinvoiceRewardDetails.effectiveto = new Date(moment(this._SalesInvoicetax.salesinvoicedate).add(30, 'days').format('YYYY-MM-DD HH:mm:ss'));
					salesinvoiceRewardDetails.expirydate = salesinvoiceRewardDetails.effectiveto
					salesinvoiceRewardDetails.rewardpoints = this._salesinvoicetaxform.get("gatheredrewardpoints").value;
					salesinvoiceRewardDetails.createdby = sessionStorage["userid"];
					salesinvoiceRewardDetails.createdon = new Date();
					salesinvoiceRewardDetails.rewardstatus = "MSC00301";
					this.SalesinvoiceRewardDetailslist.push(salesinvoiceRewardDetails);
				}
				this._SalesInvoicetax.paidamount = this._SalesInvoicetax.paidamount ?? 0;
				this._SalesInvoicetax.balanceamount = this._SalesInvoicetax.balanceamount ?? 0;
				var userdiscountpercentage = parseFloat(sessionStorage["userdiscountpercentage"] || 0);
				var collectingbranch = this._salesinvoicetaxform.get("collectingbranch").value;
				let isRequestAmountGreater = this._ProductDetails
					.some(f =>
						f.customerdiscountpercentage > 0
						&& (f.customerdiscountpercentage > this.GetMax(f.cus_dispercentage, userdiscountpercentage)));
				let hasDamagedProduct = this._ProductDetails.some(s => s.isdamaged == true);
				let customerwisediscount = this._ProductDetails
					.some(f => f.customerdiscountpercentage > 0 && f.cus_dispercentage > 0);
				let schemediscount = this._ProductDetails
					.some(f => f.customerdiscountpercentage > 0 && f.iscshemeapplied);
				if (isRequestAmountGreater || hasDamagedProduct || customerwisediscount || schemediscount
					|| (parseInt(collectingbranch) != parseInt(sessionStorage["currentbranchid"]) && this._action == 'create')) {
					this._SalesInvoicetax.status = "MSC00046";
				} else {
					this._SalesInvoicetax.status = "MSC00047";
				}
				this._SalesInvoicetax.salesinvoicedate = this.datepipe.transform(this._SalesInvoicetax.salesinvoicedate);
				this._SalesInvoicetax.currencyreferenceid = this._salesinvoicetaxform.get("currencyreferenceid").value;

				let grossamount = parseFloat(this._SalesInvoicetax.productamount + '' || '0');

				if (grossamount <= 0) {
					this._CustomExceptionService.handleWarning("gross amount should not be in negative or 0.");
					return;
				}

				this.isSaveClicked = true;

				let balanceCreditnoteamount = this._SalesInvoicetax.creditnotebalanceamount;
				if (balanceCreditnoteamount > 0) {
					this.creditnotemodel.showdialog = true;
					this._SalesInvoicetax.returnamount = balanceCreditnoteamount;
					let currencyname = this._Currencies.find(f => f.currencyid == this._SalesInvoicetax.returncurrencyid).currencyname;
					this.creditnotemodel.message = "Did you paid credit note balance amount of <b>(" + currencyname + " - " + balanceCreditnoteamount + ")</b> ? ";
					this.creditnotemodel.Yes = () => {
						this.OnSaveDbCall();
						this.creditnotemodel.showdialog = false;
					}

					this.creditnotemodel.Cancel = () => {
						this.creditnotemodel.showdialog = false;
						this.isSaveClicked = false;
					}
				}
				else {
					this.OnSaveDbCall();
				}
			}
		}
		else {
			this._CustomExceptionService.handleError(usererrors.salesinvoicetaxtab);
			this.selectedtab = 0;
			return;
		}
	}

	OnSaveDbCall() {
		let otherCharges = this._OtherCharges.filter(f => f.otherchargesamount > 0) || [];
		if (this._selectedlevel == 1 || this._selectedlevel == 2)//RETAIL 1 AND RETAIL 2
		{
			this.SalesinvoiceRewardDetailslist = this.SalesinvoiceRewardDetailslist || [];
		}
		else {
			this.SalesinvoiceRewardDetailslist = [];
		}
		this.OnChangePaymentTerms();
		this.OnChangeDeliveryTerms();
		this.OnChangeOtherTerms();
		this._SalesInvoiceTaxObj = {
			"Salesinvoice": this._SalesInvoicetax,
			"Salesinvoicedetail": this._ProductDetails,
			"Salesinvoiceothercharge": otherCharges,
			"Salesinvoicecreditdetail": this._creditnotedetail || [],
			"IsCollectingBranchChange": this._isCollectingBranchChanged,
			"SalesInvoiceAdvanceReferences": this._salesinvoiceadvancereferences || [],
			"SalesInvoiceRewardDetails": this.SalesinvoiceRewardDetailslist || [],
			"SalesInvoiceTermsAndCondition": this.TermsAndCondition
		};
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.create(this._SalesInvoiceTaxObj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._salesinvoiceid = resultJSON.salesinvoiceid;
			this._salesinvoiceno = resultJSON.salesinvoiceno;
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
				var Url = window.open("revmax-service:$" + this._salesinvoiceno);
				setTimeout(() => { Url.close(); }, 10000);
				this.reset(null);
				this._IsProgressSpinner = false;
			} else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
				this._IsProgressSpinner = false;
				this.isSaveClicked = false;
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isSaveClicked = false; });
	}

	GetMax(x: number, y: number): number {
		return x > y ? x : y;
	}

	ValidateCurrency() {
		let iserror = false;
		let usernetamount = parseFloat(this._salesinvoicetaxform.get("netamountincustomercurrency").value || 0);
		let currencyid = this._salesinvoicetaxform.get("currencyid").value;
		let returncurrencyid = this._salesinvoicetaxform.get("returncurrencyid").value;
		let returnamount = parseFloat(this._salesinvoicetaxform.get("returnamount").value || 0);
		let paidcurrencyid1 = this._salesinvoicetaxform.get("cuscurrencyid1").value;
		let paidcurrencyid2 = this._salesinvoicetaxform.get("cuscurrencyid2").value;
		let customerpaidamount = parseFloat(this._salesinvoicetaxform.get("paidamount").value || 0);
		let customernetamount = parseFloat(this._salesinvoicetaxform.get("netamountincustomercurrency").value || 0);
		let paidcurrencyamount1 = this._salesinvoicetaxform.get("cuscurrencyamount1").value;
		let paidcurrencyamount2 = this._salesinvoicetaxform.get("cuscurrencyamount2").value;
		let bankTotalamount = parseFloat(paidcurrencyamount1 || 0) + parseFloat(paidcurrencyamount2 || 0);
		let usercurrencyamount = this.GetUserAmount();

		if (usercurrencyamount > usernetamount || this.tempcreditnotebalanceamount > 0) {
			if (((customerpaidamount > customernetamount) && !returncurrencyid && currencyid != 4) || (this.tempcreditnotebalanceamount > 0 && !returncurrencyid)) {
				iserror = true;
				this._CustomExceptionService.handleWarning("Please select return currency.");
			}
			else if (currencyid == 4) {
				if ((paidcurrencyid1 && paidcurrencyid1 != currencyid) || (paidcurrencyid2 && paidcurrencyid2 != currencyid)) {
					iserror = true;
					this._CustomExceptionService.handleWarning("Invoice currency and paid currency should be same for bank currency.");
				}
				else if (bankTotalamount && bankTotalamount > usernetamount) {
					iserror = true;
					this._CustomExceptionService.handleWarning("Paid amount should not exceed net amount for bank currency.");
				}
			}
		} else {
			this._salesinvoicetaxform.get("returncurrencyid").reset();
			this._salesinvoicetaxform.get("returnamount").reset();
		}

		return iserror;
	}

	//Debug
	onEd() {
		ace.config.set("fontSize", "14px");
		ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
		const aceEditor = ace.edit(this.editor.nativeElement);
		aceEditor.getSession().setMode("ace/mode/json");
		var myObj = this._SalesInvoiceTaxObj;
		var myJSON = JSON.stringify(myObj);
		aceEditor.session.setValue(myJSON);
	}

	// on clicking back button , it Navigates to the search screen
	goBack(event: any) {
		this._router.navigate(['/vSalesInvoicetax']);
	}

	SendToApproval(event: any) {
		var workflowid = 45;
		this.isDisabledinvoiceslip = false;
		this.isDisabledprint = false;
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.SendToApproval(workflowid, this._userid, this._branchid, this._salesinvoiceid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	reset(event) {
		this._submitted = false;
		this._salesinvoicetaxform.reset();
		this._salesinvoicetaxproductform.reset();
		this._customerform.reset();
		this._ProductDetails = [];
		this._OtherCharges = [];
		this._Salesreferenceno = [];
		this._salesinvoiceadvancereferences = [];
		this._advancereferences = [];
		this._advances = [];
		this.InitializeForm();
		var l_othercharge = new Salesinvoiceothercharge();
		l_othercharge.salesinvoiceid = this._salesinvoicetaxform.get("salesinvoiceid").value;
		l_othercharge.salesinvoiceotherchargesdetailsid = 0;
		l_othercharge.createdby = sessionStorage["userid"];
		l_othercharge.modifiedby = sessionStorage["userid"];
		l_othercharge.createdon = new Date();
		l_othercharge.modifiedon = new Date();
		this._OtherCharges.push(l_othercharge);
		this._salesinvoicetaxform.get("currencyid").enable();
		this._creditnoteids = [];
		this.isSaveClicked = false;
		this.tempcreditnotebalanceamount = 0;
		this._action = "create";
	}

	ShowErrorMsg(errMsg: string) {
		if (!errMsg)
			return false;
		this._CustomExceptionService.handleError(errMsg);
		return true;
	}

	OnChangeCreditNote(event: any) {
		let id = this._salesinvoicetaxform.get("currencyid").value;
		let creditNoteArray = this._creditnoteids || [];
		let amount = 0;
		this._creditnotedetail = [];
		this._salesinvoicetaxform.get("creditnoteamount").setValue(0);
		this.Multicurrencycalculation();
		this.DisabledAdvanceOrCredit();
		if (!creditNoteArray.length) return;
		let selectedCreditnotes = this._CreditNoteRefernces.filter(f => this._creditnoteids.includes(f.creditnoteid));
		let vouchers = [];
		selectedCreditnotes.forEach(element => {
			vouchers.push(element.voucherno);
		});

		let data = {
			isCreditnote: true,
			currencyid: id,
			creditnotenos: (vouchers || [])
		};
		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.GetConversion(data).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._IsProgressSpinner = false;
				selectedCreditnotes.forEach(element => {
					var l_creditnote = new Salesinvoicecreditdetail();
					l_creditnote.creditnoteid = element.creditnoteid;
					l_creditnote.customerid = this._salesinvoicetaxform.get("customerid").value || [];
					l_creditnote.salesinvoicecreditdetailid = 0;
					l_creditnote.creditnoteamount = parseFloat(resultJSON.exchangeRate[element.voucherno] || 0) * element.creditamount;
					l_creditnote.salesinvoiceid = this._salesinvoicetaxform.get("salesinvoiceid").value;
					l_creditnote.cnvoucherno = element.voucherno;
					l_creditnote.currencyrefid = element.currencyuniquerefid;
					this._creditnotedetail.push(l_creditnote);
				});
				this._salesinvoicetaxform.get("creditnoteamount").setValue(this.Fixed2(resultJSON.converionAmount));
				this.Multicurrencycalculation();
				this.DisabledAdvanceOrCredit();
			}
			else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
			} this._IsProgressSpinner = false;
			this.isSaveClicked = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isSaveClicked = false; });

	}
	OnChangeCreditNoteApplicable() {
		let isApplicable: boolean = <boolean>this._salesinvoicetaxform.get("creditnoteapplicable").value;
		if (!isApplicable) {
			this._salesinvoicetaxform.get("creditnoteid").reset();
			this._salesinvoicetaxform.get("creditnoteamount").reset();
		}
		this.DisabledAdvanceOrCredit();
		this.TotalCalculation();
	}

	OnChangePaymode() {
		this._toaccounts = [];
		let paymodecode = this._salesinvoicetaxform.get("paymenttype").value;
		if (paymodecode != "MSC00146") {
			this._toaccounts = this._toaccountsTemp.filter(f => f.accountgroupid == 6);
		}

		// else {
		//   this._toaccounts = this._toaccountsTemp.filter(f => f.accountgroupid == 31 && f.partycode == parseInt(sessionStorage["currentbranchid"]));
		//   // this._salesinvoicetaxform.controls['bankledgerid'].setValue(sessionStorage["currentbranchid"]);
		// }

	}

	filterProducts(event: { query: string; }) {
		this._IsProgressSpinner = true;

		let branchid = sessionStorage["currentbranchid"];
		let currency = this._salesinvoicetaxform.get("currencyid").value;
		let categoryids = this.productcatid || null;
		//this.filteredProducts = [];
		//this._IsProgressSpinner = true;
		let data = {
			product: event.query,
			branchid: branchid,
			category: [],
			currency: currency,
			postax: 1,
			quicksales: 0
		}

		// if(event.query){
		this._SalesInvoiceTaxService.GetProduct(data).subscribe((result: any) => {
			this.filteredProducts = <Productoverallview[]>result.productsearch || [];
			this._ProductCategories = result.productcategories;
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	// Create
	// HotKeyIntegration() {
	//   this._hotkeysService.reset();
	//   if (this._action != 'view') {
	//     this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
	//       this.onSave();
	//       return false; // Prevent bubbling
	//     }, ['INPUT', 'SELECT', 'TEXTAREA']));
	//   }
	//   if (this._action == 'create') {
	//     this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
	//       this.ClearAll();
	//       return false; // Prevent bubbling
	//     }, ['INPUT', 'SELECT', 'TEXTAREA']));
	//   }
	//   this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
	//     this.goBack(null);
	//     return false; // Prevent bubbling
	//   }, ['INPUT', 'SELECT', 'TEXTAREA']));
	// }

	// FetchProductUsingBarcode() {
	// 	if (this.IsDisabledLayBuy) return;
	// 	this._Variants = [];
	// 	setTimeout(() => {
	// 		let barcodevalue = this._salesinvoicetaxproductform.get("productbarcode").value;
	// 		if (!barcodevalue) {
	// 			this._salesinvoicetaxproductform.get("productbarcode").reset();
	// 			return;
	// 		}
	// 		barcodevalue = barcodevalue.trim();
	// 		let splitBarcode = barcodevalue.split('-');
	// 		let variant = 0;
	// 		let barcode = '';

	// 		barcode = splitBarcode[0];

	// 		if (splitBarcode.length == 2) {
	// 			variant = parseInt(splitBarcode[1]);
	// 		}


	// 		this._SalesInvoiceTaxService.GetBarCodeBasedProductDetails({ "barcode": barcodevalue, "branchid": this._branchid }).subscribe((result: any) => {

	// 			this._productBarcodesearch = result.productbarcodesearch || [];
	// 			let product: any = this._productBarcodesearch.find(f => f.barcode == barcodevalue);
	// 			this._IsProgressSpinner = false;

	// 			if (product) {
	// 				this._salesinvoicetaxproductform.get("productid").setValue(product);
	// 				let productcategory: Productcategory = new Productcategory();
	// 				productcategory.productcategoryid = product.productcategoryid;
	// 				productcategory.producttypeid = product.producttypeid + '';
	// 				productcategory.categoryname = product.categoryname;
	// 				this._ProductCategories.push(productcategory);

	// 				this.filteredProducts.push(product);
	// 				this.GetVariantName();
	// 				if (variant) {
	// 					(this._productBarcodesearch.filter(f => f.productid == product.productid && f.productvariantdetailid == variant) || []).forEach(element => {
	// 						let variants: Productvariant = {
	// 							productvariantdetailid: element.productvariantdetailid,
	// 							variantdescription: element.variantdescription,
	// 							productid: element.productid,
	// 							createdby: 0,
	// 							createdon: new Date(),
	// 							modifiedby: 0,
	// 							modifiedon: new Date(),
	// 							recordstatus: ''
	// 						}
	// 						this._Variants.push(variants);
	// 					});
	// 					this._salesinvoicetaxproductform.get("variantid").setValue(variant);
	// 				}

	// 				this._salesinvoicetaxproductform.get("productbarcode").reset();
	// 				this._salesinvoicetaxproductform.controls["quantity"].setValue(1);
	// 				this.GetProductPrice(1)
	// 				$("input[name='productbarcode']").focus();
	// 			}
	// 			else {
	// 				this._CustomExceptionService.handleWarning("Product may not available at this branch");
	// 				this._salesinvoicetaxproductform.get("productbarcode").reset();
	// 			}
	// 		},
	// 			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	// 	}, 400)
	// }


	FetchProductUsingBarcode() {

		if (this.IsDisabledLayBuy) return;
		this._Variants = [];
		setTimeout(() => {
			let barcodevalue = this._salesinvoicetaxproductform.get("productbarcode").value;
			if (!barcodevalue) {
				this._salesinvoicetaxproductform.get("productbarcode").reset();
				return;
			}
			barcodevalue = barcodevalue.trim();

			let isnormalbarcode: string = barcodevalue.substring(0, 2)

			if (isnormalbarcode != "99")// Normal Product
			{
				var selectedbranch = this._salesinvoicetaxform.get("collectingbranch").value;
				let severity = this._salesinvoicetaxproductform.get("severity").value;
				let isDamaged = (this._salesinvoicetaxproductform.get("isdamaged").value == true ||
					this._salesinvoicetaxproductform.get("isdamaged").value == 1);
				this._SalesInvoiceTaxService.GetBarCodeBasedProductDetails({ "barcode": barcodevalue, "branchid": this._branchid, "Collectingbranchid": selectedbranch, "severity": severity, postax: 1 }).subscribe((result: any) => {

					this._productBarcodesearch = result.productbarcodesearch || [];

					if (isDamaged) {
						this._salesinvoicetaxproductform.controls['stockinhand'].setValue(0);
						if (result.collectingBranchDamagedStock) {
							this._salesinvoicetaxproductform.controls['stockinhand'].setValue(result.collectingBranchDamagedStock.damagedstock);
						}
					} else {
						if (!result.collectingBranchStock) {
							this._salesinvoicetaxproductform.controls['stockinhand'].setValue(0);
						}
						else {
							this._Branch = result.collectingBranchStock.stock1 || 0;
							this._salesinvoicetaxproductform.controls['stockinhand'].setValue(this._Branch);
						}
					}

					let product: any = this._productBarcodesearch.find(f => f.barcode == barcodevalue);
					if (product) {
						this._salesinvoicetaxproductform.get("productid").setValue(product);
						let productcategory: Productcategory = new Productcategory();
						productcategory.productcategoryid = product.productcategoryid;
						productcategory.producttypeid = product.producttypeid + '';
						productcategory.categoryname = product.categoryname;
						this._ProductCategories.push(productcategory);

						this.filteredProducts.push(product);
						this.GetVariantName();
						this._salesinvoicetaxproductform.get("productbarcode").reset();
						this._salesinvoicetaxproductform.controls["quantity"].setValue(1);
						this.GetProductPrice(1)
						$("input[name='productbarcode']").focus();
					}
					else {
						this._CustomExceptionService.handleWarning("Product may not available at this branch");
						this._salesinvoicetaxproductform.get("productbarcode").reset();
					}
				},
					error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
			else {
				this.weighingproductBarcode();
			}
		}, 400)
	}

	GetUserAmount(): number {
		let currencyid = this._salesinvoicetaxform.get("currencyid").value;
		let currencyid1 = this._salesinvoicetaxform.get("cuscurrencyid1").value;
		let currencyid2 = this._salesinvoicetaxform.get("cuscurrencyid2").value;
		let currencyamnt1 = this._salesinvoicetaxform.get("cuscurrencyamount1").value;
		let currencyamnt2 = this._salesinvoicetaxform.get("cuscurrencyamount2").value;
		let usercurrencyamount = 0;
		if (currencyid == currencyid1) {
			usercurrencyamount += currencyamnt1;
		}
		else {
			usercurrencyamount += parseFloat(((this.GetExchangeRate(currencyid) / this.GetExchangeRate(currencyid1)) * currencyamnt1).toFixed(2));
		}

		if (currencyid2 && currencyamnt2) {
			if (currencyid == currencyid2) {
				usercurrencyamount += currencyamnt2;
			}
			else {
				usercurrencyamount += parseFloat(((this.GetExchangeRate(currencyid) / this.GetExchangeRate(currencyid2)) * currencyamnt2).toFixed(2));
			}
		}

		return usercurrencyamount;
	}

	async CopyInvoice() {

		if (!this._copyinvoiceno) {
			this._CustomExceptionService.handleWarning("Please enter invoice no to copy.")
			return;
		};

		let data = {
			userid: sessionStorage["userid"],
			branchid: sessionStorage["currentbranchid"],
			invoiceno: this._copyinvoiceno
		}

		this._IsProgressSpinner = true;
		this._SalesInvoiceTaxService.CopyInvoice(data).subscribe(async (result: any) => {
			if (result && result.tranStatus.result == true) {
				let salesinvoice = (<Salesinvoiceview>result.salesinvoiceview);
				this.GetCustomerForCopyInvoice(salesinvoice.customerid);
				salesinvoice.salesinvoicedate = new Date(salesinvoice.salesinvoicedate);
				delete salesinvoice.customername;
				delete salesinvoice.statusname;
				delete salesinvoice.series;
				delete salesinvoice.ispaymentmadedtext;
				this._toaccounts = result.toaccounts;
				this._salesinvoicetaxform.setValue(salesinvoice);
				//this._salesinvoicetaxform.get("netamountincustomercurrency").setValue(salesinvoice.salesinvoiceamount);

				if (salesinvoice.salesreferenceno) {
					this.GetReferenceNo();
				}
				//this._ProductDetails = result.salesinvoiceproductspriceview;
				this._OtherCharges = result.salesinvoiceothercharges;

				//this._submitted = true;
				var selectedbranch = this._salesinvoicetaxform.get("collectingbranch").value;
				//var isdamagedproduct = this._salesinvoicetaxproductform.get("isdamaged").value;
				let markuplevelid = sessionStorage["levelid"];

				let customer = this._salesinvoicetaxform.get("customerid").value;

				if (!salesinvoice.salesreferenceno) {
					let response = [];
					for (var i = 0; i < (result.salesinvoiceproductspriceview || []).length; i++) {
						let product = <Salesinvoiceproductpriceview>result.salesinvoiceproductspriceview[i];
						response[i] = this._SalesInvoiceTaxService.GetProductPrice(product.productid, product.variantid, product.quantity, selectedbranch, customer, this._userid, markuplevelid, product.isdamaged, (product.severity || null), this._branchid).toPromise();
						//this.GetProductPriceForCopyInvoice(product.productid, product.quantity, product.variantid, product.isdamaged, product.severity);
					}
					response.forEach((element: Promise<any>) => {
						this.GetProductPriceForCopyInvoice(element);
					});
				} else {
					this._salesinvoicetaxform.get("salesreferenceno").setValue(salesinvoice.salesreferenceno);
					this.GetReferenceDetails();
				}
			}
			else {
				this._CustomExceptionService.handleError(result.tranStatus.lstErrorItem[0].message);
			}
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	GetProductPriceForCopyInvoice(response: Promise<any>) {
		this._IsProgressSpinner = true;
		response.then((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this.AddProductToGrid(resultJSON, 'salesproductprice');
				this._salesinvoicetaxform.controls['markuplevelid'].setValue(resultJSON.salesproductprice[0].markuplevel);
				this._IsProgressSpinner = false;
			}
			else {
				this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
				this._IsProgressSpinner = false;
			}
			this._IsProgressSpinner = false;
		},
			error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

	async GetCustomerForCopyInvoice(customerid) {
		await this._SalesInvoiceTaxService.GetCustomerDetails(customerid).toPromise().then((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			this._salesinvoicetaxform.get("currencyid").enable();
			let customer = <Salescustomerdetailsview>(resultJSON.customerdetails);
			this._customer = customer;
			this._salesinvoicetaxform.controls['currencyid'].setValue(customer.currencyid);
			this._salesinvoicetaxform.controls['cuscurrencyid1'].setValue(this._customer.currencyid);
			var referenceid = this._Currencies.filter(f => f.currencyid == customer.currencyid)[0].currencyuniquerefid
			this._salesinvoicetaxform.controls['currencyreferenceid'].setValue(referenceid);
			this._selectedlevel = customer.levelid;
			this._Markuplevels = resultJSON.levels;
			this._salesinvoicetaxform.controls['markuplevelid'].setValue(customer.levelid);
			this._salesinvoicetaxform.controls['mobileno'].setValue(customer.mobileno);
			this._salesinvoicetaxform.controls['mailid'].setValue(customer.mailid);
			this._salesinvoicetaxform.controls['remainingpoints'].setValue(customer.rewardpoints);
			this._RemainingPoints = customer.rewardpoints;
			this._customerslabwisediscount = resultJSON.customerslabwisediscounts;
			if ((resultJSON.customerslabwisediscounts || []).length) {
				this._Customermaxvalue = resultJSON.customerslabwisediscounts[0].maximumvalue;
				this._Customerminvalue = resultJSON.customerslabwisediscounts[0].minimumvalue;
			}
			this._CreditNoteRefernces = <Creditnoteview[]>resultJSON.creditnotereferencenos;
			this._IsFormHide = false;
			this._advancereferences = resultJSON.receiptadvancereferenceviewlist || [];
			this._IsProgressSpinner = false;
		}).catch((error: any) => {
			this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false;
		});
	}

	// Onchange credit amount calculation:
	OnCustomerCreditChange(row: any) {
		if (this._ProductDetails.length == 0) {
			this._customercreditlimit = this._tempcustomercreditlimit;
			return;
		}
		this._customercreditlimit = parseFloat(((this._customercreditlimit || 0.00) - row.finalamount).toFixed(2));
	}

	OnCustomerReduceCreditChange(row: any) {
		if (this._ProductDetails.length == 0) {
			var currencyid = this._salesinvoicetaxform.get('currencyid').value;
			let exchangeRate: number = this.GetExchangeRate(currencyid);
			this._customercreditlimit = this._tempcustomercreditlimit * exchangeRate;
			return;
		}
		this._customercreditlimit = parseFloat(((this._customercreditlimit || 0.00) + row.finalamount).toFixed(2))
	}


	modifyqty() {
		$("input[name='quantity']").focus();
		this.displayBasicProductType = false;
		this.dispalyBasicScheme = false;
	}


	DisabledAdvanceOrCredit() {
		this._salesinvoicetaxform.get("cuscurrencyamount1").reset();
		this._salesinvoicetaxform.get("cuscurrencyamount2").reset();

		this._IsdisabledAdvanceRef = false;
		this._salesinvoicetaxform.get("creditnoteapplicable").enable();
		let customeradvance: number = this._salesinvoicetaxform.get("customeradvanceamount").value;
		let salesinvoiceamt: number = this._salesinvoicetaxform.get("salesinvoiceamount").value;
		let creditamount: number = this._salesinvoicetaxform.get("creditnoteamount").value;
		if (this._advances && this._advances.length > 0 && customeradvance >= salesinvoiceamt) {
			this._IsdisabledAdvanceRef = false;
			if (this._creditnoteids && this._creditnoteids.length > 0) {
				this._salesinvoicetaxform.get("creditnoteapplicable").enable()
			} else {
				this._salesinvoicetaxform.get("creditnoteapplicable").disable()
			}
		}
		else if (this._salesinvoicetaxform.get("creditnoteapplicable").value && this._creditnoteids && this._creditnoteids.length > 0 && creditamount >= salesinvoiceamt) {

			if (this._advances && this._advances.length > 0) {
				this._IsdisabledAdvanceRef = false;
			} else {
				this._IsdisabledAdvanceRef = true;
			}
			this._salesinvoicetaxform.get("creditnoteapplicable").enable()
		}
		else {
			this._IsdisabledAdvanceRef = false;
		}
	}
	OnChangePaymentTerms() {
		if (this.paymenttermsid.length && this.paymenttermsid.length > 0 && this.paymenttermsid) {
			for (let i = 0; i < this.paymenttermsid.length; i++) {
				const d = this.paymenttermsid[i];
				var x: any = {};
				x.invoicetermsdetailid = 0
				x.salesinvoiceid = this._salesinvoicetaxform.get("salesinvoiceid").value
				x.salesinvoicedate = this._salesinvoicetaxform.get("salesinvoicedate").value
				x.termtypeid = this._Paymentlist.find(f => f.termsandconditionid == d).termstype
				x.termid = d
				this.TermsAndCondition.push(x)
			}
		}
	}
	OnChangeDeliveryTerms() {
		if (this.deliverytermsid && this.deliverytermsid.length && this.deliverytermsid.length > 0) {
			for (let i = 0; i < this.deliverytermsid.length; i++) {
				const d = this.deliverytermsid[i];
				var x: any = {};
				x.invoicetermsdetailid = 0
				x.salesinvoiceid = this._salesinvoicetaxform.get("salesinvoiceid").value
				x.salesinvoicedate = this._salesinvoicetaxform.get("salesinvoicedate").value
				x.termtypeid = this._Deliverylist.find(f => f.termsandconditionid == d).termstype
				x.termid = d
				this.TermsAndCondition.push(x)
			}
		}
	}
	OnChangeOtherTerms() {
		if (this.othertermsid && this.othertermsid.length && this.othertermsid.length > 0) {
			for (let i = 0; i < this.othertermsid.length; i++) {
				const d = this.othertermsid[i];
				var x: any = {};
				x.invoicetermsdetailid = 0
				x.salesinvoiceid = this._salesinvoicetaxform.get("salesinvoiceid").value
				x.salesinvoicedate = this._salesinvoicetaxform.get("salesinvoicedate").value
				x.termtypeid = this._Otherlist.find(f => f.termsandconditionid == d).termstype
				x.termid = d
				this.TermsAndCondition.push(x)
			}
		}
	}

	weighingproductBarcode() {
		let barcodevalue = this._salesinvoicetaxproductform.get("productbarcode").value;
		barcodevalue = barcodevalue.trim();
		let isweighingbarcode: string = barcodevalue.substring(0, 2)

		if (isweighingbarcode == "99") {
			let productcode: number = 0;
			let weight: number = 0
			productcode = parseInt(barcodevalue.substring(2, 7));
			weight = parseFloat(barcodevalue.substring(7, 12)) / 1000;
			var selectedbranch = this._salesinvoicetaxform.get("collectingbranch").value;
			let severity = this._salesinvoicetaxproductform.get("severity").value;
			let isDamaged = (this._salesinvoicetaxproductform.get("isdamaged").value == true ||
				this._salesinvoicetaxproductform.get("isdamaged").value == 1);

			this._SalesInvoiceTaxService.GetBarCodeWeighingBasedProductDetails({ "productid": productcode, "branchid": this._branchid, "Collectingbranchid": selectedbranch, "severity": severity, postax: 1 }).subscribe((result: any) => {

				this._productBarcodesearch = result.productbarcodesearch || [];

				if (isDamaged) {
					this._salesinvoicetaxproductform.controls['stockinhand'].setValue(0);
					if (result.collectingBranchDamagedStock) {
						this._salesinvoicetaxproductform.controls['stockinhand'].setValue(result.collectingBranchDamagedStock.damagedstock);
					}
				} else {
					if (!result.collectingBranchStock) {
						this._salesinvoicetaxproductform.controls['stockinhand'].setValue(0);
					}
					else {
						this._Branch = result.collectingBranchStock.stock1 || 0;
						this._salesinvoicetaxproductform.controls['stockinhand'].setValue(this._Branch);
					}
				}

				let product: any = this._productBarcodesearch.find(f => f.productid == productcode);
				if (product) {
					this._salesinvoicetaxproductform.get("productid").setValue(product);
					let productcategory: Productcategory = new Productcategory();
					productcategory.productcategoryid = product.productcategoryid;
					productcategory.producttypeid = product.producttypeid + '';
					productcategory.categoryname = product.categoryname;
					this._ProductCategories.push(productcategory);
					this.filteredProducts.push(product);
					this.GetVariantName();
					this._salesinvoicetaxproductform.get("productbarcode").reset();
					this._salesinvoicetaxproductform.controls["quantity"].setValue(weight);
					this.GetProductPrice(1)
					$("input[name='productbarcode']").focus();
				}
				else {
					this._CustomExceptionService.handleWarning("Product may not available at this branch");
					this._salesinvoicetaxproductform.get("productbarcode").reset();
				}
			},
				error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

		}
	}
}