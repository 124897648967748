<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Supplier</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" (click)="onSave()"
                                [disabled]="isDisabledSave" icon="pi pi-save"></button>
                            <button pButton pRipple type="button" title="Clear" icon="pi pi-trash"
                                [disabled]="isDisabledClear" (click)="reset($event)" class="p-button-danger"></button>
                            <button pButton pRiple type="button" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <p-tabView [(activeIndex)]="selectedtab">
                    <p-tabPanel header="Supplier Details">
                        <form [formGroup]="_supplierform" (ngSubmit)="onSave()">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" [disabled]="true" pInputText disabled name="suppliercode"
                                                formControlName="suppliercode">
                                            <label for="suppliercode">Supplier Code </label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="suppliername" (focusout)="ChangeSwift()"
                                                formControlName="suppliername">
                                            <label for="suppliername">Supplier Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_supplierform.get('suppliername').touched || _submitted) && _supplierform.get('suppliername').errors?.NameEmpty">
                                            Please enter supplier name
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_supplierform.get('suppliername').touched || _submitted) && _supplierform.get('suppliername').errors?.vInputNamewithoutspecialcharater">
                                            Supplier Name should not allow Special Characters
                                        </span> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Currencies"
                                                name="currencyid" optionLabel="currencyname"
                                                formControlName="currencyid" optionValue="currencyid">
                                            </p-dropdown>
                                            <label for="Currency">Currency <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_supplierform.get('currencyid').touched || _submitted) && _supplierform.get('currencyid').errors?.SelectCurrency">
                                            Please select currency
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Suppliercategories"
                                                name="supplierStatus" optionLabel="metasubdescription"
                                                formControlName="suppliercategorycode" optionValue="metasubcode"
                                                (onChange)="OnChangeSupCat($event)">
                                            </p-dropdown>
                                            <label for="suppliercategorycode">Supplier Category <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_supplierform.get('suppliercategorycode').touched || _submitted) && _supplierform.get('suppliercategorycode').errors?.SelectSupplierCategory">
                                            Please select supplier category
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label" [hidden]="true">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Accountgroups"
                                                name="accountgroupid" optionLabel="accountgroupname"
                                                formControlName="accountgroupid" optionValue="accountgroupid">
                                            </p-dropdown>
                                            <label for="accountgroupid">Account Group <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- <span class="text-danger"
                                            *ngIf="(_supplierform.get('accountgroupid').touched || _submitted) && _supplierform.get('accountgroupid').errors?.AccountGroupNameRequired">
                                            Please Select Account group
                                        </span> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="bpno" pInputText maxlength="20"
                                                formControlName="bpno">
                                            <label for="bpno">TIN Number</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_rating" name="stateid"
                                                optionLabel="metasubdescription" formControlName="supplierrating"
                                                optionValue="metasubcode">
                                            </p-dropdown>
                                            <label for="supplierRating">Supplier Rating </label>
                                        </span>
                                    </td>

                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Supplierstatus"
                                                name="supplierStatus" optionLabel="metasubdescription" (onChange)="Supplierholdreason($event)"
                                                formControlName="supplierstatus" optionValue="metasubcode">
                                            </p-dropdown>
                                            <label for="supplierstatus">Status</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label" [hidden]="showseries">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Supplierseries"
                                                [disabled]="disableseries" name="series"
                                                optionLabel="metasubdescription" formControlName="series"
                                                (onChange)="OnChangeRights()" optionValue="metasubcode">
                                            </p-dropdown>
                                            <label for="series">Rights</label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <h6>Pay Mode <span class="hlt-txt">*</span></h6>
                                            <div class="p-field-radiobutton" *ngFor="let Pm of _PayModes">
                                                <p-radioButton name="paymodecode" value="Paymodes"
                                                    formControlName="paymodecode" label={{Pm.metasubdescription}}
                                                    value={{Pm.metasubcode}}>
                                                </p-radioButton>
                                            </div>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _supplierform.get('paymodecode').touched) && _supplierform.get('paymodecode').errors?.SelectPayMode">
                                            Please select pay mode
                                        </span>
                                    </td>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <h6>Supplier Type <span class="hlt-txt">*</span></h6>
                                            <div class="p-field-radiobutton" *ngFor="let ST of _Suppliertypes">
                                                <p-radioButton name="suppliertypecode" value="SupplierType"
                                                    formControlName="suppliertypecode" label={{ST.metasubdescription}}
                                                    value={{ST.metasubcode}}></p-radioButton>
                                            </div>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _supplierform.get('suppliertypecode').touched) && _supplierform.get('suppliertypecode').errors?.SelectSupplierType">
                                            Please select supplier type
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_UserList"
                                                name="dealuserid" optionLabel="username" filter="true"
                                                filterBy="username" [showClear]="true" formControlName="dealuserid"
                                                optionValue="userid">
                                            </p-dropdown>
                                            <label for="dealuserid">Handled By</label>
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>VAT Details</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <div class="p-formgroup-inline">
                                            <!-- <h6>VAT Details<span class="hlt-txt">*</span></h6> -->
                                            <div class="p-field-radiobutton" *ngFor="let VT of _VAT">
                                                <p-radioButton name="vattype" value="VAT" formControlName="vattype"
                                                    (onClick)="OnChangevattype($event)" label={{VT.metasubdescription}}
                                                    value={{VT.metasubcode}}>
                                                </p-radioButton>
                                            </div>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _supplierform.get('vattype').touched) && _supplierform.get('vattype').errors?.SelectVatType">
                                            Please select Vat type
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="vatno" pInputText maxlength="20"
                                                formControlName="vatno">
                                            <label for="vatno">VAT Number</label>
                                        </span>

                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                            </table>
                            <div class="sub-title">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Credit Details</h5>
                                    </div>
                                    <div class="col-md-7"></div>
                                </div>
                            </div>
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-radiobutton" *ngFor="let CDT of _Creditdetails">
                                                <p-radioButton name="credittype" value="Creditdetails"
                                                    formControlName="credittype" (onClick)="OnChangecredit($event)"
                                                    label={{CDT.metasubdescription}} value={{CDT.metasubcode}}>
                                                </p-radioButton>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-inputNumber name="creditlimit" inputId="withoutgrouping" maxlength="9"
                                                [useGrouping]="false" formControlName="creditlimit" placeholder="">
                                            </p-inputNumber>
                                            <label for="creditlimit">Limit in</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-inputNumber name="creditdays" inputId="withoutgrouping" maxlength="4"
                                                [useGrouping]="false" formControlName="creditdays"></p-inputNumber>
                                            <label for="Days">Days</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <!-- Debug -->
                                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                                    </td>
                                    <td style="width: 20%;">
                                    </td>
                                </tr>

                            </table>
                            <p-dialog header="Reason for Supplier Hold" [(visible)]="supplierholdreason" [modal]="true"
                            [style]="{width: '20vw',height: '215px'}" [baseZIndex]="10000">
                            <ng-template pTemplate="content">
                                <div class="popup-body">
                                    <div class="card">
                                        <textarea name="reasonforsupplierhold" formControlName="reasonforsupplierhold" pInputTextarea
                                            style="width: 100%; height: 100px; padding: 5px;">
                                        </textarea>
                                    </div>
                                    <div style="text-align: right;" *ngIf="supplierholdpopupsubmit">
                                        <button pButton pRipple label="submit" title="submit" (click)="Supplierholdreasonpopupclose()"
                                            class="p-mr-2">
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dialog>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel header="Supplier Account Details">
                        <form [formGroup]="_supplieraccountdetailform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <input type="text" pInputText name="bankcode" disabled
                                                    formControlName="bankcode">
                                                <label for="bankcode">Bank Code <span class="hlt-txt">*</span></label>
                                            </span>
                                            <button class="p-inputgroup-addon p-button"
                                                [disabled]="IsDisabledPopupButton" (click)="showBasicDialog()">
                                                <i class="pi pi-external-link"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 || _supplieraccountdetailform.get('bankcode').touched) && _supplieraccountdetailform.get('bankcode').errors?.SelectBankCode">
                                            Please select bank code
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="bankname" disabled
                                                formControlName="bankname">
                                            <label for="bankname"> Enter Bank Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 || _supplieraccountdetailform.get('bankname').touched) && _supplieraccountdetailform.get('bankname').errors?.SelectBankName">
                                            Please select bank name
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="branchname"
                                                formControlName="branchname">
                                            <label for="branchname"> Enter Bank Branch <span class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 || _supplieraccountdetailform.get('branchname').touched) && _supplieraccountdetailform.get('branchname').errors?.BranchNameRequired">
                                            Please enter bank branch
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="swiftcode" maxlength="30"
                                                formControlName="swiftcode">
                                            <label for="swiftcode"> Enter Swift Code <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 || _supplieraccountdetailform.get('swiftcode').touched) && _supplieraccountdetailform.get('swiftcode').errors?.SwiftCodeRequired">
                                            Please enter swift code
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="accountno" maxlength="20"
                                                (keypress)="keypress.kpNumber($event)" formControlName="accountno">
                                            <!-- <p-inputNumber name="accountno" inputId="withoutgrouping" 
                                            [useGrouping]="false" formControlName="accountno" maxlength="30" [min]="0" [max]="999999999999999999999999999999" ></p-inputNumber> -->

                                            <label for="accountno">Bank Account Number <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 || _supplieraccountdetailform.get('accountno').touched) && _supplieraccountdetailform.get('accountno').errors?.AccountNoRequired">
                                            Please enter account number
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Accounttypes"
                                                name="accounttype" optionLabel="metasubdescription"
                                                optionValue="metasubcode" formControlName="accounttype">
                                            </p-dropdown>
                                            <label for="accounttype">Account Type <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 || _supplieraccountdetailform.get('accounttype').touched) && _supplieraccountdetailform.get('accounttype').errors?.SelectAccountTypeName">
                                            Please select account type
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="accountholdername"
                                                formControlName="accountholdername">
                                            <label for="accountholdername"> Enter Account Holder Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 || _supplieraccountdetailform.get('accountholdername').touched) && _supplieraccountdetailform.get('accountholdername').errors?.AccountHolderNameRequired">
                                            Please enter account holder name
                                        </span>

                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel header="Supplier Address Details">
                        <form [formGroup]="_supplieraddressdetailform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="contactpersonname" pInputText
                                                formControlName="contactpersonname">
                                            <label for="contactpersonname">Contact Person Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted1 || _supplieraddressdetailform.get('contactpersonname').touched) && _supplieraddressdetailform.get('contactpersonname').errors?.ContactPersonNameRequired">
                                            Please enter contact person name
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="mobileno" pInputText formControlName="mobileno"
                                                (keypress)="keypress.kpMobileNumber($event)" maxlength="20">
                                            <label for="mobileno">Mobile Number <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted1 || _supplieraddressdetailform.get('mobileno').touched) && _supplieraddressdetailform.get('mobileno').errors?.MobileNumberRequired">
                                            Please enter mobile number
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted1 || _supplieraddressdetailform.get('mobileno').touched) && _supplieraddressdetailform.get('mobileno').errors?.MobileNumberInvalid">
                                            Mobile number invalid (only 20 digits allowed)
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="number" name="phoneno" pInputText maxlength="20"
                                                (keypress)="keypress.kpNumber($event)" formControlName="phoneno">
                                            <label for="phoneno">TelePhone Number</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="mailid" pInputText formControlName="mailid">

                                            <label for="mailid">Email ID <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_supplieraddressdetailform.get('mailid').touched || _submitted1) && _supplieraddressdetailform.get('mailid').errors?.EmailIDRequired">
                                            Please enter Email ID
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_supplieraddressdetailform.get('mailid').touched || _submitted1) && _supplieraddressdetailform.get('mailid').errors?.ValidEmailID">
                                            Please enter valid Email ID
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" name="faxno" pInputText maxlength="20"
                                                (keypress)="keypress.kpMobileNumber($event)" formControlName="faxno">

                                            <label for="faxno">Fax Number</label>
                                        </span>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_Countries"
                                                name="countryid" optionLabel="countryname" formControlName="countryid"
                                                (onChange)="getStates($event)" optionValue="countryid">
                                            </p-dropdown>
                                            <label for="countryid">Country </label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_States" name="stateid"
                                                optionLabel="statename" formControlName="stateid" optionValue="stateid">
                                            </p-dropdown>
                                            <label for="stateid">Province </label>
                                        </span>
                                    </td>

                                    <td rowspan="2">
                                        <span class="p-float-label">
                                            <textarea rows="3" name="address" pInputTextarea
                                                formControlName="address"></textarea>
                                            <label for="address">Address <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted1 || _supplieraddressdetailform.get('address').touched) && _supplieraddressdetailform.get('address').errors?.AddressRequired">
                                            Please enter address
                                        </span>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button pButton pRipple label="Add" title="Add" icon="pi pi-plus"
                                            [disabled]="_action == 'view'" (click)="onAddAddress($event)"
                                            class="p-button-success p-mr-2"></button>
                                        <button pButton pRipple label="Clear" (click)="Clear($event)" title="Clear"
                                            icon="pi pi-times" class="p-button-danger p-mr-2"
                                            [disabled]="_action == 'view'"></button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <div class="card">
                            <p-table #dt [value]="_SuppliersAddress" [rows]="5" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]" [rowHover]="true" dataKey="address"
                                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width: 100px;">Action</th>
                                        <th>Is Default</th>
                                        <th>Contact Person</th>
                                        <th>Mobile Number</th>
                                        <th>Email ID</th>
                                        <th>Address</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_SupplierAddress let-ri="rowIndex">
                                    <tr (dblclick)="onRowSupplierAddressselect(_SupplierAddress,ri)">
                                        <td style="text-align: center;">
                                            <button pButton pRipple label="" title="Remove" type="button"
                                                [disabled]="_action == 'view'" icon="pi pi-trash"
                                                (click)="RemoveAddress(_SupplierAddress)"
                                                class="p-button-danger p-mr-2"></button>
                                        </td>
                                        <td style="text-align: center;width: 50px;">
                                            <p-checkbox [(ngModel)]="_SupplierAddress.isdfaultaddress"
                                                [ngModelOptions]="{standalone: true}"
                                                (onChange)="Removeselectedchecked(_SupplierAddress,ri)"
                                                [disabled]="_action == 'view'" binary="true">
                                            </p-checkbox>
                                        </td>
                                        <td>
                                            {{_SupplierAddress.contactpersonname}}

                                        </td>
                                        <td>

                                            {{_SupplierAddress.mobileno}}

                                        </td>
                                        <td>
                                            {{_SupplierAddress.mailid}}

                                        </td>
                                        <td>
                                            {{_SupplierAddress.address}}

                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>


                    </p-tabPanel>
                    <p-tabPanel header="Supplier Product Details" [disabled]="IsDisabledProductTab">
                        <form [formGroup]="_supplierproductdetailform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect [options]="_producttype" optionLabel="producttypename"
                                                formControlName="producttypeid" optionValue="producttypeid"
                                                (onChange)="getProductCategoryA()" [(ngModel)]="_selectedProTypeIds">
                                            </p-multiSelect>
                                            <label for="supplierid">Product Type</label>
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect [options]="_productcategory"
                                                formControlName="productcategoryid" optionLabel="categoryname"
                                                (onChange)="getProduct()" optionValue="productcategoryid"
                                                [(ngModel)]="_selectedProCatIds">
                                            </p-multiSelect>
                                            <label for="supplierid">Product Category</label>
                                        </span>
                                    </td>
                                    <!-- <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect [filter]="true" filterBy="searchfilter"
                                                [options]="_productname" formControlName="productid"
                                                (onChange)="getProductdetails($event)" optionLabel="productname"
                                                optionValue="productid">
                                            </p-multiSelect>
                                            <label for="productid">Product Name </label>
                                        </span>
                                    </td> -->
                                    <!-- <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect [options]="_brandlist" optionLabel="brandname"
                                                optionValue="brandid" (onChange)="getProductbrand($event)"
                                                [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}">
                                            </p-multiSelect>
                                            <label for="brandid">Brand Name </label>
                                        </span>
                                    </td> -->
                                    <td style="width: 20%;">
                                    </td>
                                    <td style="width: 20%;">
                                    </td>
                                    <td style="width: 20%;">
                                    </td>
                                </tr>

                            </table>
                        </form>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="DTSupplierProducts.filterGlobal($event.target.value,'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>
                            </p-toolbar>
                            <p-table #DTSupplierProducts [value]="_SupplierProducts" [rows]="50" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]"
                                [globalFilterFields]="['producttypename','categoryname','productname','productid','productprice']"
                                [rowHover]="true" dataKey="productid" [(selection)]="selectedSupplierProductsrows"
                                (onRowSelect)="onRowSelectProducts($event)"
                                (onRowUnselect)="onRowUnselectProducts($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center; width: 100px;">
                                            <!-- <p-tableHeaderCheckbox (click)="selectRow(_SupplierProducts)"
                                                [disabled]="_action == 'view'">
                                            </p-tableHeaderCheckbox> -->
                                        </th>
                                        <th>Product Type</th>
                                        <th>Product Category</th>
                                        <th>Product Name</th>
                                        <th>Brand Name</th>
                                        <th>Product Price</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_SupplierProduct let-i="rowIndex">
                                    <tr>
                                        <td style="text-align:center;">
                                            <p-tableCheckbox [value]="_SupplierProduct" [disabled]="_action == 'view'">
                                            </p-tableCheckbox>
                                        </td>
                                        <td>{{_SupplierProduct.producttypename}}</td>
                                        <td>{{_SupplierProduct.categoryname}}</td>
                                        <td>{{_SupplierProduct.productname}}</td>
                                        <td>{{_SupplierProduct.brandname}}</td>
                                        <td>{{_SupplierProduct.productprice}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>

                    </p-tabPanel>
                    <p-tabPanel header="Supplier Document Details">

                        <form [formGroup]="_supplierdocumentdetailform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <!-- <span class="p-float-label">
                                                                <input type="text" pInputText formControlName="documentname">
                                                                <label for="documentname">Document Name <span
                                                                        class="hlt-txt">*</span></label>
                                                            </span> -->
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_DocumentDescriptions"
                                                optionValue="documentdescriptionname"
                                                optionLabel="documentdescriptionname" formControlName="documentname"
                                                [filter]="true" filterBy="documentdescriptionname">
                                            </p-dropdown>
                                            <label for="documentname">Document Name<span
                                                    class="hlt-txt">*</span></label>

                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 || _supplierdocumentdetailform.get('documentname').touched) && _supplierdocumentdetailform.get('documentname').errors?.DocumentNameRequired">
                                            Please Select Document Name
                                        </span>
                                    </td>
                                    <!-- <td style="width: 20%;">
                                                            <span class="p-float-label">
                                                                <input type="text" pInputText formControlName="documentdescription">
                                                                <label for="documentdescription">Document Description</label>
                                                            </span>
                                                        </td> -->
                                    <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <div class="browse-links">
                                                    <p-button label="Customer Document" styleClass="p-button-link"
                                                        title="{{documentselect}}"
                                                        (click)="showsuppliergrid(documentgrid,'bottom-right')">
                                                    </p-button>
                                                </div>
                                            </span>
                                            <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                                pTooltip="Browse File" tooltipPosition="top" mode="basic" #documentgrid
                                                accept="image/*,.pdf,.xls,.xlsx,.docx,.doc,.txt" maxFileSize="1000000"
                                                [auto]="true" (onSelect)="onDocumentSupplierSelect($event,documentgrid)"
                                                [disabled]="_action=='view'">
                                            </p-fileUpload>
                                            <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                                (click)="onDocumentSupplierClear()" tooltipPosition="top"
                                                [disabled]="_action=='view'">
                                                <i class="pi pi-times"></i>
                                            </button>
                                        </div>
                                        <span class="text-danger"
                                            *ngIf="(_submitted2 && _LastAddedGridDocument == null)">
                                            Please Add Document
                                        </span>
                                    </td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <button type="submit" pButton pRipple label="Add" title="Add" icon="pi pi-plus"
                                            [disabled]="_action == 'view'" (click)="onAddDocuments($event)"
                                            class="p-button-success p-mr-2"></button>
                                        <button pButton pRipple label="Clear" title="Clear" icon="pi pi-times"
                                            type="button" [disabled]="_action == 'view'" class="p-button-danger p-mr-2"
                                            (click)="SupplierClear()"></button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <!-- Form group -->

                        <div class="card">
                            <p-table #dt [value]="_Supplierdocumentdetails" [rows]="10" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]"
                                [globalFilterFields]="['documentdescription','documentname']" [rowHover]="true"
                                dataKey="address" [(selection)]="selectedrows" (onRowSelect)="onRowSelect($event)"
                                (onRowUnselect)="onRowUnselect($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width: 100px;">Action</th>
                                        <th>Document Name</th>
                                        <!-- <th>Document Description</th> -->
                                        <th>Attachment Name</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_supplierdocumentdetail>
                                    <tr (dblclick)="onRowCustomerdocselect(_supplierdocumentdetail,ri)">
                                        <td style="text-align: center;">
                                            <button pButton pRipple title="Remove" icon="pi pi-trash"
                                                [disabled]="_removedocoumenthidden"
                                                (click)="RemoveDocumentDetail(_supplierdocumentdetail)"
                                                class="p-button-danger p-mr-2"></button>
                                        </td>
                                        <td>
                                            {{_supplierdocumentdetail.documentname}}
                                        </td>
                                        <!-- <td>
                                                                {{_Employeedocumentdetail.documentdescription}}
                                                            </td> -->
                                        <td>
                                            <a href="javascript:void(0);"
                                                (click)="showdocumentGridDialog('bottom-right',_supplierdocumentdetail)">
                                                {{_supplierdocumentdetail.attachmentname}}
                                            </a>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <!-- Document Upload -->
                        <p-dialog header="Supplier Document" [(visible)]="displaydocumentgridpopup"
                            [position]="position" [style]="{width: '500px'}" [baseZIndex]="10000">
                            <img [src]="documentimage" alt="" class="img-preview">
                            <ng-template pTemplate="footer">
                                <p-button icon="pi pi-check" (click)="closePopupGridDialog()" label="Ok"
                                    styleClass="p-button-text">
                                </p-button>
                                <p-button (click)="showdocumentasNewWindow()" label="Open in new window"
                                    styleClass="p-button-text">
                                </p-button>
                            </ng-template>
                        </p-dialog>

                        <table>
                            <tr>
                                <td>
                                    <!-- Debug -->
                                    <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                                </td>
                            </tr>
                        </table>


                    </p-tabPanel>
                </p-tabView>
                <p-dialog header="Bank Code" [(visible)]="displayBasic" [modal]="true"
                    [style]="{width: '50vw',height: '500px'}" [baseZIndex]="10000">

                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="search-container">
                                <div class="p-inputgroup" style="width:150px !important;">
                                    <span class="p-float-label">
                                        <input type="text" pInputText
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                    <!-- <button class="p-inputgroup-addon p-button" title="Search">
                                        <i class="pi pi-search"></i>
                                    </button> -->
                                </div>
                            </div>

                            <div class="card">
                                <p-table #dt [value]="_BankList" selectionMode="single" [(selection)]="selectedBank"
                                    [globalFilterFields]="['bankcode','bankname']"
                                    (onRowSelect)="onRowSelectPopup($event)" [paginator]="true" [rows]="5"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [showCurrentPageReport]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="bankcode">Code<p-sortIcon field="bankcode">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="bankname">Name <p-sortIcon field="bankname">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_selectedBank>
                                        <tr [pSelectableRow]="rowData">
                                            <td>{{_selectedBank.bankcode}}</td>
                                            <td>{{_selectedBank.bankname}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <p-dialog header="Product list mapped only to this supplier" [(visible)]="suppliernotmappedproductlist"
                    [modal]="true" [style]="{width: '80vw',height: '500px'}" [baseZIndex]="10000">

                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="card">
                                <p-toolbar styleClass="p-mb-4">
                                    <ng-template pTemplate="left">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text"
                                                (input)="dtSupplierProductmapping.filterGlobal($event.target.value, 'contains')"
                                                placeholder="Search..." />
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="right">
                                        <button type="button" pButton pRipple icon="pi pi-file-excel"
                                            title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS"
                                            tooltipPosition="bottom"
                                            (click)="exportExcelonesuppliermappedlist()"></button>
                                        <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                            class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                            (click)="ExportToPdfonesuppliermappedlist()"></button>
                                    </ng-template>
                                </p-toolbar>
                                <p-table #dtSupplierProductmapping [value]="_suppliernotmappedproductlist"
                                    selectionMode="single" [columns]="ProductWiseLockedcols" [rowHover]="true"
                                    dataKey="productid"
                                    [globalFilterFields]="['producttypename','categoryname','productcode','productname','brandname']"
                                    [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [rows]="10" [paginator]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="SNO" style="width: 60px;">S.no<p-sortIcon field="SNO">
                                                </p-sortIcon>
                                            <th pSortableColumn="producttypename">Product Type<p-sortIcon
                                                    field="producttypename">
                                                </p-sortIcon>
                                            <th pSortableColumn="categoryname">Product Category<p-sortIcon
                                                    field="categoryname">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="productcode">Product Code<p-sortIcon
                                                    field="productcode">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="productname">Product Name<p-sortIcon
                                                    field="productname">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_SupplierProductmapping
                                        let-i="rowIndex">
                                        <tr>
                                            <td style="width: 60px;">
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{_SupplierProductmapping.producttypename}}
                                            </td>
                                            <td>
                                                {{_SupplierProductmapping.categoryname}}
                                            </td>
                                            <td>
                                                {{_SupplierProductmapping.productcode}}
                                            </td>
                                            <td>
                                                {{_SupplierProductmapping.productname}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <p-dialog header="Product list already mapped with three supplier"
                    [(visible)]="threesuppliermappedproductlist" [modal]="true"
                    [style]="{width: '80vw',height: '500px'}" [baseZIndex]="10000">

                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="card">
                                <p-toolbar styleClass="p-mb-4">
                                    <ng-template pTemplate="left">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text"
                                                (input)="dtSupplierProductmapping.filterGlobal($event.target.value, 'contains')"
                                                placeholder="Search..." />
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="right">
                                        <button type="button" pButton pRipple icon="pi pi-file-excel"
                                            title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS"
                                            tooltipPosition="bottom"
                                            (click)="exportExcelthreesuppliermappedlist()"></button>
                                        <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF"
                                            class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"
                                            (click)="ExportToPdfthreesuppliermappedlist()"></button>
                                    </ng-template>
                                </p-toolbar>
                                <p-table #dtSupplierProductmapping [value]="_threesuppliermappedproductlist"
                                    selectionMode="single" [columns]="ProductWiseLockedcols" [rowHover]="true"
                                    dataKey="productid"
                                    [globalFilterFields]="['producttypename','categoryname','productcode','productname','brandname']"
                                    [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [rows]="10" [paginator]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="SNO" style="width: 60px;">S.no<p-sortIcon field="SNO">
                                                </p-sortIcon>
                                            <th pSortableColumn="producttypename">Product Type<p-sortIcon
                                                    field="producttypename">
                                                </p-sortIcon>
                                            <th pSortableColumn="categoryname">Product Category<p-sortIcon
                                                    field="categoryname">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="productcode">Product Code<p-sortIcon
                                                    field="productcode">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="productname">Product Name<p-sortIcon
                                                    field="productname">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_SupplierProductmapping
                                        let-i="rowIndex">
                                        <tr>
                                            <td style="width: 60px;">
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{_SupplierProductmapping.producttypename}}
                                            </td>
                                            <td>
                                                {{_SupplierProductmapping.categoryname}}
                                            </td>
                                            <td>
                                                {{_SupplierProductmapping.productcode}}
                                            </td>
                                            <td>
                                                {{_SupplierProductmapping.productname}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
                <p-dialog [(visible)]="suppliernotmappedproduct_and_threesuppliermappedlist" [modal]="true" header="Product Lists"
                    [style]="{width: '80vw',height: '500px'}" [baseZIndex]="10000">

                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <p-tabView [(activeIndex)]="selectedtab">
                                <p-tabPanel header="Products Mapped only to this supplier">
                                    <div class="card">
                                        <p-toolbar styleClass="p-mb-4">
                                            <ng-template pTemplate="left">
                                                <span class="p-input-icon-left">
                                                    <i class="pi pi-search"></i>
                                                    <input pInputText type="text"
                                                        (input)="dtSupplierProductmapping.filterGlobal($event.target.value, 'contains')"
                                                        placeholder="Search..." />
                                                </span>
                                            </ng-template>
                                            <ng-template pTemplate="right">
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS"
                                                    tooltipPosition="bottom"
                                                    (click)="exportExcelonesuppliermappedlist()"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-pdf"
                                                    title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF"
                                                    tooltipPosition="bottom"
                                                    (click)="ExportToPdfonesuppliermappedlist()"></button>
                                            </ng-template>
                                        </p-toolbar>
                                        <p-table #dtSupplierProductmapping [value]="_suppliernotmappedproductlist"
                                            selectionMode="single" [columns]="ProductWiseLockedcols" [rowHover]="true"
                                            dataKey="productid"
                                            [globalFilterFields]="['producttypename','categoryname','productcode','productname','brandname']"
                                            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                            [rows]="10" [paginator]="true">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn="SNO" style="width: 60px;">S.no<p-sortIcon
                                                            field="SNO">
                                                        </p-sortIcon>
                                                    <th pSortableColumn="producttypename">Product Type<p-sortIcon
                                                            field="producttypename">
                                                        </p-sortIcon>
                                                    <th pSortableColumn="categoryname">Product Category<p-sortIcon
                                                            field="categoryname">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="productcode">Product Code<p-sortIcon
                                                            field="productcode">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="productname">Product Name<p-sortIcon
                                                            field="productname">
                                                        </p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-_SupplierProductmapping
                                                let-i="rowIndex">
                                                <tr>
                                                    <td style="width: 60px;">
                                                        {{i+1}}
                                                    </td>
                                                    <td>
                                                        {{_SupplierProductmapping.producttypename}}
                                                    </td>
                                                    <td>
                                                        {{_SupplierProductmapping.categoryname}}
                                                    </td>
                                                    <td>
                                                        {{_SupplierProductmapping.productcode}}
                                                    </td>
                                                    <td>
                                                        {{_SupplierProductmapping.productname}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </p-tabPanel>
                            </p-tabView>
                            <p-tabView [(activeIndex)]="selectedtab">
                                <p-tabPanel header="Products already Mapped to three suppliers">

                                    <div class="card">
                                        <p-toolbar styleClass="p-mb-4">
                                            <ng-template pTemplate="left">
                                                <span class="p-input-icon-left">
                                                    <i class="pi pi-search"></i>
                                                    <input pInputText type="text"
                                                        (input)="dtSupplierProductmapping.filterGlobal($event.target.value, 'contains')"
                                                        placeholder="Search..." />
                                                </span>
                                            </ng-template>
                                            <ng-template pTemplate="right">
                                                <button type="button" pButton pRipple icon="pi pi-file-excel"
                                                    title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS"
                                                    tooltipPosition="bottom"
                                                    (click)="exportExcelthreesuppliermappedlist()"></button>
                                                <button type="button" pButton pRipple icon="pi pi-file-pdf"
                                                    title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF"
                                                    tooltipPosition="bottom"
                                                    (click)="ExportToPdfthreesuppliermappedlist()"></button>
                                            </ng-template>
                                        </p-toolbar>
                                        <p-table #dtSupplierProductmapping [value]="_threesuppliermappedproductlist"
                                            selectionMode="single" [columns]="ProductWiseLockedcols" [rowHover]="true"
                                            dataKey="productid"
                                            [globalFilterFields]="['producttypename','categoryname','productcode','productname','brandname']"
                                            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                            [rows]="10" [paginator]="true">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn="SNO" style="width: 60px;">S.no<p-sortIcon
                                                            field="SNO">
                                                        </p-sortIcon>
                                                    <th pSortableColumn="producttypename">Product Type<p-sortIcon
                                                            field="producttypename">
                                                        </p-sortIcon>
                                                    <th pSortableColumn="categoryname">Product Category<p-sortIcon
                                                            field="categoryname">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="productcode">Product Code<p-sortIcon
                                                            field="productcode">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="productname">Product Name<p-sortIcon
                                                            field="productname">
                                                        </p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-_SupplierProductmapping
                                                let-i="rowIndex">
                                                <tr>
                                                    <td style="width: 60px;">
                                                        {{i+1}}
                                                    </td>
                                                    <td>
                                                        {{_SupplierProductmapping.producttypename}}
                                                    </td>
                                                    <td>
                                                        {{_SupplierProductmapping.categoryname}}
                                                    </td>
                                                    <td>
                                                        {{_SupplierProductmapping.productcode}}
                                                    </td>
                                                    <td>
                                                        {{_SupplierProductmapping.productname}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </p-tabPanel>
                            </p-tabView>
                        </div>
                    </ng-template>

                </p-dialog>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>

        </div>
    </div>
</div>