import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { ProductCategory } from 'src/app/core/Models/overallclass';
import { Producttype } from 'src/app/core/Models/Producttype';
import { ProductpricechangeService } from 'src/app/core/Services/sales/productpricechange.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Productratechangeview } from 'src/app/core/Views/Productratechangeview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';
@Component({
  selector: 'app-v-productpricechange',
  templateUrl: './v-productpricechange.component.html',
  styleUrls: ['./v-productpricechange.component.css']
})
export class VProductpricechangeComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _Pricechangeviews: Productratechangeview[];
  _Pricechangeview: Productratechangeview;
  _IsProgressSpinner: boolean = true;
  cols: any[];
  fromdate: Date = new Date();
  todate: Date = new Date();
  datepipe = new DatePipe('en-US');
  onlynewproducts: boolean = false;
  _branchid = sessionStorage["currentbranchid"];
  _ProductTypes: Producttype[];
  _ProductTypeObj: any;
  _ProductCategories: ProductCategory[];
  _brandlist: any;
  _ProductCategoryObj: any;
  _ProductNames: Productoverallview[];
  producttypeid: any;
  productcategoryid: any;
  productid: any;
  brandid: any;
  _ProductObj: any;
  disablefromdate: boolean = false;
  disabletodate: boolean = false;
  weekDate: any;
  _datefrombackend: any[];
  _levelid = sessionStorage["levelid"];
  mindate: Date;
  maxdate: Date;

  constructor(private fb: FormBuilder, private router: Router, private utility: CommonUtilities, public exportUtility: ExportUtility,
    private _ProductpricechangeService: ProductpricechangeService, private _AccessRightsService: AccessRightsService,
    private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService) { }


  ngOnInit(): void {

    this._ProductpricechangeService.PageOnLoad(0, this._branchid).subscribe((result) => {

      const resultJSON = JSON.parse(JSON.stringify(result));
      this._ProductTypes = resultJSON.producttypes;
      this._brandlist = resultJSON.brandList;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    this._Action = this._AccessRightsService.getAccessRights(58);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);

    if (sessionStorage['Environmentenddate'] == "null") {
      this.fromdate = new Date();
      this.todate = new Date();
      this.maxdate = new Date();
    }
    else {
      this.fromdate = new Date(sessionStorage['Environmentenddate']);
      this.todate = new Date(sessionStorage['Environmentenddate']);
    }
    this.FetchAll();
    this.cols = [
      { field: 'productratechangeidppc', header: 'Product Price Change Code' },
      { field: 'productname', header: 'Product Name' },
      { field: 'currencyname', header: 'Currency Name' },
      { field: 'oldrate', header: 'Old Rate' },
      { field: 'newrate', header: 'New Rate' },
    ]

    this.HotKeyIntegration();
    // this._ProductpricechangeService.FetchAll().subscribe((result) => {
    //   const resultJSON = JSON.parse(JSON.stringify(result));
    //   this._Pricechangeviews = resultJSON.productpricechangeview;
    // },


    // error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  GetProductCategory(event) {
    this.productcategoryid = [];
    this.productid = [];
    this.brandid = [];
    var selectedProductType = event.value;

    this._IsProgressSpinner = true;
    this._ProductTypeObj = {
      "Producttype": selectedProductType || [],
      "branchid": this._branchid || 0
    };
    this._ProductpricechangeService.GetProductCategory(this._ProductTypeObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let categories = resultJSON.productcategories;
      let uniqueChars = [...new Set(categories)];
      uniqueChars = categories.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.productcategoryid === test.productcategoryid
        )
      );
      this._ProductCategories = uniqueChars;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }
  GetProductName(event) {
    this.productid = [];
    this.brandid = [];
    var selectedProductCategory = event.value;
    var selectedproducttype = this.producttypeid;

    if (selectedProductCategory.length == 0 || !selectedProductCategory || !selectedProductCategory.length) {
      this._CustomExceptionService.handleWarning("Please Select At Least One Product Category")
      return;
    } else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = {
        "Productcategory": selectedProductCategory || [],
        "selectedproducttype": selectedproducttype || [],
        "branchid": this._branchid || 0,
        "levelid": this._levelid || 0,
      };
      this._ProductpricechangeService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._ProductNames = resultJSON.products;
        this._IsProgressSpinner = false;
      }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  FetchAll() {
    let fromdate = this.datepipe.transform(this.fromdate, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.todate, 'yyyy-MM-dd');
    if (!this.fromdate) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (!this.todate) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }

    if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than the To Date");
      return;
    }
    this._IsProgressSpinner = true;

    var data = {
      "fromdate": fromdate,
      "todate": todate,
      "type": this.onlynewproducts,
      "producttype": this.producttypeid || [],
      "productcat": this.productcategoryid || [],
      "productid": this.productid || [],
      "brand": this.brandid || []
    }
    this._ProductpricechangeService.FetchAll(data).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._Pricechangeviews = resultJSON.productpricechangeview;
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  getnewproducts() {
    if (this.onlynewproducts == true) {
      this.disabletodate = true;
      this.disablefromdate = true;
      this._IsProgressSpinner = true;
      this._ProductpricechangeService.datechange(0).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._datefrombackend = resultJSON.updateddate;

        var fromdate = new Date(this.datepipe.transform(this._datefrombackend[0].FROMDATE, 'yyyy-MM-dd'));
        var todate = new Date(this.datepipe.transform(this._datefrombackend[0].TODATE, 'yyyy-MM-dd'));

        this.fromdate = fromdate;
        this.todate = todate;

      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
      this._IsProgressSpinner = false;
    }
    else {
      this.disabletodate = false;
      this.disablefromdate = false;
      this.fromdate = new Date();
      this.todate = new Date();
      //this.clear(event);
    }
  }
  ChangeProduct(event) {
    this.brandid = [];
    var selectedProduct = event.value;
    this._IsProgressSpinner = true;
    this._ProductObj = {
      "Products": selectedProduct || [],
      "branchid": parseInt(sessionStorage["currentbranchid"])
    };
    this._ProductpricechangeService.getBrand(this._ProductObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      let productbrand = resultJSON.productbrandlist;
      let uniqueChars = [...new Set(productbrand)];
      uniqueChars = productbrand.filter((test, index, array) =>
        index === array.findIndex((findTest) =>
          findTest.brandid === test.brandid
        )
      );
      this._brandlist = uniqueChars;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });

  }

  clear(event) {
    this.fromdate = new Date();
    this.todate = new Date();
    this.onlynewproducts = false;
    this._Pricechangeviews = [];

    this.producttypeid = [];

    this._ProductCategories = [];
    this.productcategoryid = [];

    this._ProductNames = [];
    this.productid = [];

    this._brandlist = [];
    this.brandid = [];

    this.disabletodate = false;
    this.disablefromdate = false;

    this.ngOnInit();

  }

  view(event) {
    this.router.navigate(['/cProductpricechange'], { state: { productratechangeid: event.productratechangeid, action: 'view' } });
  }
  delete(event) {
    alert("delete");
  }
  edit(event) {
    this.router.navigate(['/cProductpricechange'], { state: { productratechangeid: event.productratechangeid, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cProductpricechange']);
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let ProductpricechangeList = this.GetSalesQuotationList();
    this.exportUtility.exportExcel(ProductpricechangeList, 'ProductPriceChange', 'xlsx');
  }

  ExportToPdf() {
    // let ProductpricechangeList = this.GetSalesQuotationList();
    // this.exportUtility.ExportToPdf(ProductpricechangeList, 'ProductPriceChange.pdf');
    if (!this._Pricechangeviews || !this._Pricechangeviews.length)
      return;
    let StatusHistoryDetails = this.GetSalesQuotationList();
    const data = StatusHistoryDetails.map(Object.values);
    const head = [['Product Name', 'Old Rate', 'New Rate', 'Currency Name', 'Brand', 'Status', 'Is new product', 'Modified on', 'Modified by']]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('Product_price_change.pdf');
      })
    })
  }
  GetSalesQuotationList() {
    if (!this._Pricechangeviews || !this._Pricechangeviews.length)
      return;
    let ProductpricechangeList = [];
    this._Pricechangeviews.forEach(element => {
      let productpricechange: any = {};
      productpricechange["Product Type"] = element.producttypename;
      productpricechange["Product Category"] = element.categoryname;
      productpricechange["Product Name"] = element.productname;
      productpricechange["Currency Name"] = element.currencyname;
      productpricechange["Old Rate"] = element.oldrate;
      productpricechange["New Rate"] = element.newrate;
      productpricechange["Status"] = element.remarks;
      productpricechange["Is new product"] = element.isnewproduct;
      productpricechange["Brand"] = element.brandname;
      productpricechange["Modified on"] = this.datepipe.transform(element.modifiedon, 'dd-MMM-yyyy HH:mm');
      productpricechange["Modified by"] = element.createdbyname;

      ProductpricechangeList.push(productpricechange);
    });
    return ProductpricechangeList;
  }

  Print(event) {
    this._IsProgressSpinner = true;
    this._ProductpricechangeService.Print({ "PRODUCTPRICEID": event.productratechangeid, "branchid": this._branchid }).subscribe((result: any) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
      let url: any = URL.createObjectURL(result);
      window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
}
