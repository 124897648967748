import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonUtilities } from "../assets/js/common-utilities";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/Layouts/header/header.component';
// import { HomeComponent } from './core/Layouts/home/home.component';
import { LeftMenuComponent } from './core/Layouts/left-menu/left-menu.component';
import { MastersModule } from "../../src/app/core/Components/masters/masters.module";
import { MastersRoutingModule } from "../../src/app/core/Components/masters/masters-routing.module";
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { CustomExceptionService } from '../app/core/errorHandlers/custom-exception.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
//  import { UsersRoutingModule } from './core/Components/users/users-routing.module';
//  import { UsersModule } from '../app/core/Components/users/
import { LoginModule } from './core/Components/login/login.module';
import { LoginRoutingModule } from './core/Components/login/login-routing.module';
import { LoginComponent } from './core/Components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RewardsModule } from './core/Components/rewards/rewards.module';
import { RewardsRoutingModule } from './core/Components/rewards/rewards-routing.module';
import { UsersModule } from './core/Components/users/users.module';
import { UsersRoutingModule } from './core/Components/users/users-routing.module';
import { MenuModule } from 'primeng/menu';
import { AccountsModule } from './core/Components/accounts/accounts.module';
import { AccountsRoutingModule } from './core/Components/accounts/accounts-routing.module';
import { AccessRightsService } from './core/Services/security/access-rights.service';
import { InventoryModule } from './core/Components/inventory/inventory.module';
import { InventoryRoutingModule } from './core/Components/inventory/inventory-routing.module';
import { SalesModule } from './core/Components/sales/sales.module';
import { SalesRoutingModule } from './core/Components/sales/sales-routing.module';
import { PurchaseRoutingModule } from './core/Components/purchase/purchase-routing.module';
import { PurchaseModule } from './core/Components/purchase/purchase.module';
import { TrackingRoutingModule } from './core/Components/tracking/tracking-routing.module';
import { TrackingModule } from './core/Components/tracking/tracking.module';
import { HotkeyModule } from 'angular2-hotkeys';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ReportsRoutingModule } from './core/Components/reports/reports-routing.module';
import { ReportsModule } from './core/Components/reports/reports.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DashboardModule } from './core/Components/dashboard/dashboard.module';
import { DashboardRoutingModule } from './core/Components/dashboard/dashboard-routing.module';
import {PanelMenuModule} from 'primeng/panelmenu';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'ngx-moment';
import { OnlineOfflineRoutingModule } from './core/Components/online-offline/onlineoffline-routing.module';
import { OnlineOfflineModule } from './core/Components/online-offline/onlineoffline.module';
import { EcommerceModule } from './core/Components/ecommerce/ecommerce.module';
import { EcommerceRoutingModule } from './core/Components/ecommerce/ecommerce-routing.module';
import { WorkshopModule } from './core/Components/workshop/workshop.module';
import { WorkshopRoutingModule } from './core/Components/workshop/workshop-routing.module';
import { CustomerconsignmentModule } from './core/Components/customerconsignment/customerconsignment.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    // HomeComponent,
    LeftMenuComponent,
    LoginComponent,
  ],
  
  imports: [DashboardModule,
    DashboardRoutingModule,
    BrowserModule,
    AppRoutingModule,
    MastersModule,
    MastersRoutingModule,
    RewardsModule,
    RewardsRoutingModule,
    UsersModule,
    UsersRoutingModule,
    AccountsModule,
    AccountsRoutingModule,
    InventoryModule,
    InventoryRoutingModule,
    SalesModule,
    SalesRoutingModule,
    PurchaseRoutingModule,
    PurchaseModule,
    TrackingModule,
    TrackingRoutingModule,
	  ReportsModule,
    ReportsRoutingModule,
    ConfirmDialogModule,
    ToastModule,
    LoginModule,
    LoginRoutingModule,
	  EcommerceRoutingModule,
    EcommerceModule,				
    ReactiveFormsModule,
    ToastModule,
    InputTextModule,
    FormsModule,
    OnlineOfflineModule,
    OnlineOfflineRoutingModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    PasswordModule,
    MenuModule,
    PanelMenuModule,
    ProgressSpinnerModule,
    DialogModule,
    DropdownModule,
    HotkeyModule.forRoot(),
    AutoCompleteModule,
    NgIdleKeepaliveModule.forRoot(), 
    MomentModule,
    WorkshopModule,
    WorkshopRoutingModule,
    CustomerconsignmentModule
  ],
  providers: [{ provide: ErrorHandler, useClass: CustomExceptionService }, CommonUtilities, MessageService, ConfirmationService, AccessRightsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
