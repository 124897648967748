import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import autoTable from 'jspdf-autotable';
import { Branch } from 'src/app/core/Models/Branch';
import { Brand } from 'src/app/core/Models/Brand';
import { Currency } from 'src/app/core/Models/Currency';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Supplier } from 'src/app/core/Models/Supplier';
import { RptProductRequestService } from 'src/app/core/Services/reports/Stock/Rpt-product-request/rpt-product-request.service';
import { RptbranchwisestockService } from 'src/app/core/Services/reports/Stock/RptBranchwiseStock/rptbranchwisestock.service';
import { RptdamagedproductService } from 'src/app/core/Services/reports/Stock/RptDamagedProduct/rptdamagedproduct.service';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-rpt-productrequest',
  templateUrl: './rpt-productrequest.component.html',
  styleUrls: ['./rpt-productrequest.component.css']
})
export class RptProductrequestComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _supplierlist: Supplier[];
  _branchlist: Branch[];
  _currencylist: Currency[];
  _rptproductrequestform: FormGroup;
  _branchid: number;
  _userid: number;
  _src: SafeResourceUrl;
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _DamagedProductPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _brandlist: any;
  brandid: number[] = [];
  _ProductObj: any;
  _Tempbrandlist: Brand;
  branchid: number[] = [];
  mindate: Date;
  maxdate: Date;
  _requesttypelist: any[];
  _userslist: any[];
  userid: number[] = [];
  requesteduserid: number[] = [];
  _ReportLists: any[] = [];
  datepipe = new DatePipe('en-US');

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private exportUtility: ExportUtility,
    private _RptProductRequestService: RptProductRequestService,
    private _CustomExceptionService: CustomExceptionService,
    private _RptbranchwisestockService: RptbranchwisestockService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptproductrequestform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      supplierid: [0],
      productid: [0],
      currencyid: [0],
      productcategoryid: [0],
      producttypeid: [0],
      requesttypeid: [],
    })
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptproductrequestform.get("fromdate").setValue(new Date);
      this._rptproductrequestform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptproductrequestform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptproductrequestform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    this._RptProductRequestService.PageOnLoad({}).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchlist;
      this._producttypelist = resultJSON.producttypelist;
      this._requesttypelist = resultJSON.requesttypelist;
      this._userslist = resultJSON.userslist;
      this._brandlist = resultJSON.brandlist;
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  getProductCategory(event) {
		var selectedProductType = event.value;
		var branchid = this._rptproductrequestform.get("branchid").value == null ? 0 : this._rptproductrequestform.get("branchid").value;
		this.productcatid = [];
		this.productid = [];
		this._productlist = [];
		if (selectedProductType.length == 0 || selectedProductType == null) {
			this._productcategorylist = [];
			this._rptproductrequestform.controls["productcategoryid"].reset();
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductTypeObj = {
				"Producttype": selectedProductType || [],
				"branchid": parseInt(sessionStorage["currentbranchid"])
			};
			this._RptbranchwisestockService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._productcategorylist = resultJSON.productcategories;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}

  ChangeProduct(event) {
		var selectedProduct = event.value;
		var branchid = this._rptproductrequestform.get("branchid").value == null ? 0 : this._rptproductrequestform.get("branchid").value;
		if (selectedProduct.length == 0 || selectedProduct == null) {
			this._brandlist = this._Tempbrandlist
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductObj = {
				"Products": selectedProduct || [],
				"branchid": parseInt(sessionStorage["currentbranchid"])
			};
			this._RptbranchwisestockService.getBrand(this._ProductObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				let productbrand = resultJSON.productbrandlist;
				let uniqueChars = [...new Set(productbrand)];
				uniqueChars = productbrand.filter((test, index, array) =>
					index === array.findIndex((findTest) =>
						findTest.brandid === test.brandid
					)
				);
				this._brandlist = uniqueChars;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}

  getProduct(event) {
		var selectedProductCategory = event.value;
		this.productid = [];
		var branchid = this._rptproductrequestform.get("branchid").value == null ? 0 : this._rptproductrequestform.get("branchid").value;
		if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
			this._productlist = [];
			return;
		} else {
			this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid || parseInt(sessionStorage["currentbranchid"]) };
			this._RptbranchwisestockService.getProduct(this._ProductCategoryObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._productlist = resultJSON.productoverallList;
			});
		}
	}

  clear(event) {
    this._rptproductrequestform.reset();
    this._rptproductrequestform.controls['fromdate'].setValue(new Date());
    this._rptproductrequestform.controls['todate'].setValue(new Date());
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this._productcategorylist = [];
    this._productlist = [];
    this.brandid = [];
    this._brandlist = [];
    this._ReportLists = [];
    this.branchid = [];
    this.userid = [];
    this.requesteduserid = [];
  }

  Print(event) {
    var _fromdate = this._rptproductrequestform.get("fromdate").value;
    var _todate = this._rptproductrequestform.get("todate").value;
    var requesttypeid = this._rptproductrequestform.get("requesttypeid").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    this._IsProgressSpinner = true;
    var Obj = {
      "FromDate": fromdate, 
      "ToDate": todate,
      "Producttype": this.producttypeid, 
      "Productcategory": this.productcatid || [], 
      "Productheader": this.productid, 
      "Brandid": this.brandid || [],
      "Branchid": this.branchid || [], 
      "Requesttype": requesttypeid || '',
      "Createdby": this.userid,
      "Requestedby": this.requesteduserid
    };
    this._RptProductRequestService.Print(Obj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._IsProgressSpinner = false;
			this._ReportLists = resultJSON.rptproductrequestlist;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  exportExcel() {
    let branchwisestocklists = this.ProductRequestList();
    this.exportUtility.exportExcel(
      branchwisestocklists,
      'ProductRequestreport',
      'xlsx'
    );
  }

  ExportToPdf() {
    let branchwisestocklists = this.ProductRequestList();
    const data = branchwisestocklists.map(Object.values);
    const head = [
      [
        'Material Request Date',
        'Product Type',
        'Product Category',
        'Product Name',
        'Brand Name',
        'Branch Name',
        'Request Type',
        'Request Reason',
        'Request By',
        'Created By'
      ],
    ];
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0,
          },
          bodyStyles: {
            textColor: 0,
          },
          didDrawCell: (data) => {},
        });
        doc.save('ProductRequestreport.pdf');
      });
    });
  }

  ProductRequestList() {
    let productrequestlist = [];
    this._ReportLists.forEach((element) => {
      let productrequest: any = {};
      productrequest['Material Request Date'] = this.datepipe.transform(element.materialrequestdate, 'dd-MM-yyyy');
      productrequest['Product Type'] = element.producttypename;
      productrequest['Product Category'] = element.categoryname;
      productrequest['Product Name'] = element.productname;
      productrequest['Brand Name'] = element.brandname;
      productrequest['Branch Name'] = element.branchname;
      productrequest['Request Type'] = element.requesttype;
      productrequest['Request Reason'] = element.requestreason || '';
      productrequest['Request By'] = element.requestedby;
      productrequest['Created By'] = element.createdby;
      productrequestlist.push(productrequest);
    });
    return productrequestlist;
  }
}